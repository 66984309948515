import React, { useEffect } from "react";
import { Redirect, Router, Switch } from "react-router-dom";

// Components
import AuthenticatedRoute from "../components/Layout/AuthenticatedRoute";
import GuestRoute from "../components/Layout/GuestRoute";

// Pages
import AddPrescription from "../pages/Prescriptions/AddPrescription";
import Prescriptions from "../pages/Prescriptions/Prescriptions";
import EditPrescription from "../pages/Prescriptions/EditPrescription";
import PrescriptionsEastone from "../pages/Prescriptions/PrescriptionsEastone";

import Prescribers from "../pages/Prescriber/Prescribers";
import NewPrescriber from "../pages/Prescriber/NewPrescriber";
import EditPrescriber from "../pages/Prescriber/EditPrescriber";

import Administrators from "../pages/Administrators/Administrators";
import NewAdministrator from "../pages/Administrators/NewAdministrator";
import EditAdministrator from "../pages/Administrators/EditAdministrator";

import Carers from "../pages/Carers/Carers";
import EditCarer from "../pages/Carers/EditCarer";

import Patients from "../pages/Patients/Patients";
import EditPatient from "../pages/Patients/EditPatient";

import Products from "../pages/Products/Products";
import NewProduct from "../pages/Products/NewProduct";
import EditProduct from "../pages/Products/EditProduct";

import Profile from "../pages/Account/Profile";
import Login from "../pages/Account/Login";
import ResetPassword from "../pages/Account/ResetPassword";
import ResetEmailSent from "../pages/Account/ResetEmailSent";

import { AdminTypesGroups } from "../enums";
import history from "../util/history";
import Metrics from "../pages/Metrics";
import NewPatient from "../pages/Patients/NewPatient";
import ToBeAssigned from "../pages/Prescriptions/ToBeAssigned";
import AssignPrescription from "../pages/Prescriptions/AssignPrescription";
import NewOrganisation from "../pages/Organisations/NewOrganisation";
import Organisations from "../pages/Organisations/Organisations";
import EditOrganisation from "../pages/Organisations/EditOrganisation";

function MainRouter({ container, administratorChecked }) {
	// Hook-related data
	useEffect(() => {
		container.readAdministratorFromStorage();
	}, [container]);

	return (
		// don't render anything until the api returns a response
		administratorChecked ? (
			<Router history={history}>
				<Switch>
					<AuthenticatedRoute path="/profile" component={Profile} />
					<GuestRoute path="/login/:status" component={Login} />
					<GuestRoute path="/login" component={Login} />
					<GuestRoute path="/resetpassword/:secretID?/:mandatory?" component={ResetPassword} />
					<GuestRoute path="/resetEmailSent" component={ResetEmailSent} />

					<AuthenticatedRoute path="/prescriptions/add" component={AddPrescription} />
					<AuthenticatedRoute path="/prescriptions/edit/:id" component={EditPrescription} />
					<AuthenticatedRoute path="/prescriptions/assign/:id" component={AssignPrescription} adminTypes={AdminTypesGroups.SuperRegular} />
					<AuthenticatedRoute path="/prescriptions/eastone" adminTypes={AdminTypesGroups.Eastone} component={PrescriptionsEastone} />
					<AuthenticatedRoute path="/prescriptions/toBeAssigned" component={ToBeAssigned} adminTypes={AdminTypesGroups.SuperRegular} />
					<AuthenticatedRoute path="/prescriptions/:phase" component={Prescriptions} />
					<AuthenticatedRoute path="/prescriptions" component={Prescriptions} />

					<AuthenticatedRoute path="/prescribers/new" component={NewPrescriber} adminTypes={AdminTypesGroups.SuperRegular} />
					<AuthenticatedRoute path="/prescribers/edit/:id" component={EditPrescriber} adminTypes={AdminTypesGroups.SuperRegular} />
					<AuthenticatedRoute path="/prescribers" component={Prescribers} adminTypes={AdminTypesGroups.SuperRegular} />

					<AuthenticatedRoute path="/administrators/new" component={NewAdministrator} adminTypes={AdminTypesGroups.Super} />
					<AuthenticatedRoute path="/administrators/edit/:id" component={EditAdministrator} adminTypes={AdminTypesGroups.Super} />
					<AuthenticatedRoute path="/administrators" component={Administrators} adminTypes={AdminTypesGroups.Super} />

					<AuthenticatedRoute path="/carers/edit/:id" component={EditCarer} adminTypes={AdminTypesGroups.SuperRegular} />
					<AuthenticatedRoute path="/carers" component={Carers} adminTypes={AdminTypesGroups.SuperRegular} />

					<AuthenticatedRoute path="/patients/new" component={NewPatient} adminTypes={AdminTypesGroups.SuperRegular} />
					<AuthenticatedRoute path="/patients/edit/:id" component={EditPatient} adminTypes={AdminTypesGroups.SuperRegular} />
					<AuthenticatedRoute path="/patients" component={Patients} adminTypes={AdminTypesGroups.SuperRegular} />

					<AuthenticatedRoute path="/products/new" component={NewProduct} adminTypes={AdminTypesGroups.Super} />
					<AuthenticatedRoute path="/products/edit/:id" component={EditProduct} />
					<AuthenticatedRoute path="/products" component={Products} />

					<AuthenticatedRoute path="/organisations/new" component={NewOrganisation} adminTypes={AdminTypesGroups.Super} />
					<AuthenticatedRoute path="/organisations/edit/:id" component={EditOrganisation} adminTypes={AdminTypesGroups.Super} />
					<AuthenticatedRoute path="/organisations" component={Organisations} adminTypes={AdminTypesGroups.Super} />

					<AuthenticatedRoute path="/metrics" component={Metrics} adminTypes={AdminTypesGroups.Super} />

					<Redirect to="/prescriptions/all" />
				</Switch>
			</Router>
		) : (
			"Loading..."
		)
	);
}

export default MainRouter;
