import React from "react";

function UnlinkIcon(props) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.35771 2.57315L2.56971 1.36029L5.49771 4.28743L4.28571 5.50029L1.35771 2.57315Z" fill="#2F3EFB" />
			<path d="M18.498 19.7074L19.7109 18.4954L22.638 21.4234L21.4251 22.6354L18.498 19.7074Z" fill="#2F3EFB" />
			<path d="M7.71429 0H9.42857V3.42857H7.71429V0Z" fill="#2F3EFB" />
			<path d="M0 7.71429H3.42857V9.42857H0V7.71429Z" fill="#2F3EFB" />
			<path d="M20.5714 14.5714H24V16.2857H20.5714V14.5714Z" fill="#2F3EFB" />
			<path d="M14.5714 20.5714H16.2857V24H14.5714V20.5714Z" fill="#2F3EFB" />
			<path d="M12.4971 16.3457L9.31714 19.5343C8.9986 19.8528 8.62042 20.1055 8.20422 20.2779C7.78801 20.4503 7.34193 20.5391 6.89143 20.5391C6.44093 20.5391 5.99485 20.4503 5.57864 20.2779C5.16244 20.1055 4.78427 19.8528 4.46572 19.5343C3.82238 18.8909 3.46095 18.0184 3.46095 17.1086C3.46095 16.1988 3.82238 15.3262 4.46572 14.6829L7.65429 11.4943L6.43714 10.2857L3.25714 13.4743C2.76915 13.9493 2.38034 14.5164 2.11328 15.1429C1.84623 15.7693 1.70625 16.4426 1.70147 17.1235C1.69668 17.8045 1.82719 18.4797 2.08541 19.1098C2.34364 19.7399 2.72444 20.3125 3.20572 20.7943C3.68626 21.2709 4.25618 21.648 4.88277 21.904C5.50936 22.1599 6.18031 22.2896 6.85714 22.2857C7.54574 22.2864 8.22762 22.1503 8.86325 21.8855C9.49887 21.6207 10.0756 21.2323 10.56 20.7429L13.7143 17.5629L12.4971 16.3457Z" fill="#2F3EFB" />
			<path d="M11.4943 7.65429L14.6829 4.46571C15.0014 4.14716 15.3796 3.89448 15.7958 3.72208C16.212 3.54968 16.6581 3.46095 17.1086 3.46095C17.5591 3.46095 18.0052 3.54968 18.4214 3.72208C18.8376 3.89448 19.2157 4.14716 19.5343 4.46571C19.8528 4.78426 20.1055 5.16244 20.2779 5.57864C20.4503 5.99485 20.5391 6.44093 20.5391 6.89143C20.5391 7.34193 20.4503 7.78801 20.2779 8.20422C20.1055 8.62042 19.8528 8.99859 19.5343 9.31714L16.3457 12.5057L17.5629 13.7143L20.7429 10.5257C21.2309 10.0507 21.6197 9.48357 21.8867 8.85712C22.1538 8.23068 22.2938 7.55744 22.2985 6.87646C22.3033 6.19549 22.1728 5.52035 21.9146 4.89021C21.6564 4.26008 21.2756 3.6875 20.7943 3.20571C20.3137 2.72907 19.7438 2.35197 19.1172 2.09603C18.4906 1.8401 17.8197 1.71037 17.1429 1.71429C16.4543 1.71362 15.7724 1.84966 15.1368 2.1145C14.5011 2.37935 13.9244 2.76773 13.44 3.25714L10.2857 6.43714L11.4943 7.65429Z" fill="#2F3EFB" />
		</svg>

	);
}

export default UnlinkIcon;