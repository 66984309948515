import React, { useEffect, useState } from "react";
import FormRow from "../../components/Form/FormRow";
import { Subscribe } from "unstated";
import OrganisationsContainer from "../../state/OrganisationsContainer";
import TextInput from "../../components/Form/TextInput";
import Button from "../../components/Form/Button";
import { PlusInACircle, SpinnerIcon } from "../../assets/icons";
import { Link, useParams } from "react-router-dom";
import Table from "../../components/Table/Table";
import {
	OrganisationEmployeeTypeForEditModal,
	OrganisationsTables,
	PasswordRulePoints,
} from "../../enums";
import Modal from "../../components/Modal/Modal";
import CustomSelect from "../../components/Form/CustomSelect";
import Toggler from "../../components/Form/Toggler";
import InputFieldValidationBulletPoints from "../../components/Form/InputFieldValidationBulletPoints";
import CustomBanner from "../../components/Banner/CustomBanner";
import InfoIcon from "../../assets/icons/InfoIcon";
import LoadingCard from "../../components/SharedComponents/LoadingCard";

function EditOrganisation({ container }) {
	const { id } = useParams();

	const onFieldChange = (event) => container.onFormChange(event, updateOrganisationFormKey);
	const onSelectChange = (e) => container.onSelectChange(e, createNewEmployeeForOrganisationKey);

	const updateOrganisationFormKey = "updateOrganisation";
	const updateOrganisationState = container.state[updateOrganisationFormKey];
	const loadingOrganisationInfo = container.state.loadingOrganisation

	const passwordMismatch = updateOrganisationState.employeePassword.value !== updateOrganisationState.employeeRepeatPassword.value;

	const isSaveButtonDisabled = !container.isFormValid("updateOrganisation")
		? true
		: updateOrganisationState.employeePassword.value
			? passwordMismatch
			: false;

	const newEmployeeValue = (event) => container.onFormChange(event, createNewEmployeeForOrganisationKey);
	const createNewEmployeeForOrganisationKey = "newEmployeeForOrganisation";
	const createNewEmployeeForOrganisationState = container.state[createNewEmployeeForOrganisationKey];

	const editEmployeeValue = (event) => container.onFormChange(event, editEmployeeForOrganisationKey);
	const editEmployeeForOrganisationKey = "editEmployeeForOrganisation";
	const editEmployeeForOrganisationState = container.state[editEmployeeForOrganisationKey];

	const deleteEmployeeForOrganisationKey = "deleteEmployeeForOrganisation";
	const deleteEmployeeForOrganisationState = container.state[deleteEmployeeForOrganisationKey];

	const [showNewEmployeeForClinicModal, setShowNewEmployeeForClinicModal] = useState(false);
	const [showNewEmployeeForIPModal, setShowNewEmployeeForIPModal] = useState(false);
	const [showDeleteEmployeeForOrganisationModal, setShowDeleteEmployeeForOrganisationModal] = useState(false);
	const [showEditEmployeeForOrganisationModal, setShowEditEmployeeForOrganisationModal] = useState(
		container.state.setShowEditEmployeeForOrganisationModal
	);

	const [isAccountAccessToggled, setIsAccountAccessToggled] = useState(createNewEmployeeForOrganisationState.hasAccountAccess.value);
	const [selectedRoleForUpdate, setSelectedRoleForUpdate] = useState(container.state.editEmployeeForOrganisation.role.value || 0);

	const [isAccountAccessToggledEdit, setIsAccountAccessToggledEdit] = useState(container.state.editEmployeeForOrganisation.hasAccountAccess.value);

	useEffect(() => {
		setIsAccountAccessToggledEdit(container.state.editEmployeeForOrganisation.hasAccountAccess.value);
	}, [container.state.editEmployeeForOrganisation.hasAccountAccess.value]);

	const [selectedRoleForNewIP, setSelectedRoleForNewIP] = useState(container.state.organisationEmployeeTypesForNewIPModal[1].value);

	const isCreateNewEmployeeForOrganisationButtonDisabled =
		!container.isFormValid(createNewEmployeeForOrganisationKey) ||
		createNewEmployeeForOrganisationState.password.value !== createNewEmployeeForOrganisationState.repeatPassword.value;

	const isEditEmployeeForOrganisationButtonDisabled =
		!container.isFormValid(editEmployeeForOrganisationKey) ||
		editEmployeeForOrganisationState.password.value !== editEmployeeForOrganisationState.repeatPassword.value;

	useEffect(() => {
		container.setForUpdate(id);
		container.getAllEmployeesFromOrganisation(id);
		createNewEmployeeForOrganisationState.organisationId.value = id;
		editEmployeeForOrganisationState.organisationId.value = id;
		return () => {
			container.resetUpdateOrganisationState();
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setShowEditEmployeeForOrganisationModal(container.state.setShowEditEmployeeForOrganisationModal);
	}, [container.state.setShowEditEmployeeForOrganisationModal]);

	const createNewOrganisationEmployee = () => {
		container.createNewEmployeeForOrganisation(isAccountAccessToggled);
		container.getAllEmployeesFromOrganisation(id);
		setShowNewEmployeeForClinicModal(false);
	};

	const confirmEditOrganisationEmployee = () => {
		container.updateForOrganisationEmployees(editEmployeeForOrganisationState.id.value, selectedRoleForUpdate, isAccountAccessToggledEdit);
		container.getAllEmployeesFromOrganisation(id);
	};

	const deletEmployeeFromOrganisation = () => {
		container.deleteEmployeeFromOrganisation(deleteEmployeeForOrganisationState.id.value, id);
		container.getAllEmployeesFromOrganisation(id);
		setShowDeleteEmployeeForOrganisationModal(false);
	};

	function renderFilters() {
		return (
			<FormRow>
				<div className="col-4" />
				<div className="col-3">
					{typeOrganisation ? (
						<Button onClick={() => setShowNewEmployeeForClinicModal(true)} bordered={true} className="m-r-s height-search">
							<PlusInACircle /> &nbsp; NEW USER
						</Button>
					) : (
						<Button onClick={() => setShowNewEmployeeForIPModal(true)} bordered={true} className="m-r-s height-search">
							<PlusInACircle /> &nbsp; NEW USER
						</Button>
					)}
				</div>
			</FormRow>
		);
	}

	const openEditOrganisationEmployeeModal = (employeeId) => {
		container.setOrganisationEmployeeForUpdate(id, employeeId);
		setShowEditEmployeeForOrganisationModal(true);
	};

	const openDeleteOrganisationEmployeeModal = (employeeId) => {
		container.setOrganisationEmployeeForDelete(id, employeeId);
		setShowDeleteEmployeeForOrganisationModal(true);
	};

	const typeOrganisation = updateOrganisationState.type.value === 0 ? true : false;

	const toggleAccountAccess = () => {
		setIsAccountAccessToggled(!isAccountAccessToggled);
	};

	const toggleAccountAccessEdit = () => {
		setIsAccountAccessToggledEdit(!isAccountAccessToggledEdit);
	};

	useEffect(() => {
		setIsAccountAccessToggled(createNewEmployeeForOrganisationState.hasAccountAccess.value);
	}, [createNewEmployeeForOrganisationState.hasAccountAccess.value]);

	// This reset state of organisation employee for modals when redirected to other page
	useEffect(() => {
		container.resetOrganisationEmployeeState()
		return () => {
			container.resetOrganisationEmployeeState()
		}
	}, [])

	const editDescriptionBannerContnet = () => {
		return (
			<div style={{ display: "flex", width: "100%", justifyContent: "flexStart", alignItems: "center" }}>
				<div>
					To edit a Prescriber's details, you can do this from their&nbsp;
					<Link
						to={`/prescribers/edit/${editEmployeeForOrganisationState.prescriberId?.value}`}
						className="link_for_prescriber "
					>
						Prescriber profile
					</Link>
				</div>
			</div>
		);
	};

	return (
		<>{loadingOrganisationInfo ?
			<LoadingCard text={"Loading organisation information"} />
			:
			<div className="card p-m">
				<FormRow>
					<h1 className="h4">{updateOrganisationState.name.value}</h1>
				</FormRow>

				<FormRow>
					{updateOrganisationState.type.value === 0 && <h2 className="h6">Clinic details</h2>}
					{updateOrganisationState.type.value === 1 && <h2 className="h6">Independent Prescriber details</h2>}
				</FormRow>

				<FormRow className="j-c-s">
					<div className="col-5">
						<TextInput
							value={updateOrganisationState.name.value}
							error={updateOrganisationState.name.error}
							onChange={onFieldChange}
							name="name"
							label={typeOrganisation ? "Clinic Name" : "Full Name"}
							disabled={true}
							placeholder="Clinic name"
						/>
					</div>

					<div className="col-5">
						<TextInput
							value={updateOrganisationState.referenceNumber.value}
							error={updateOrganisationState.referenceNumber.error}
							onChange={onFieldChange}
							name="referenceNumber"
							label={typeOrganisation ? "CQC Provider" : "Reference Number GMC/GPHC"}
							disabled={true}
						/>
					</div>
				</FormRow>

				<FormRow>
					<div className="col-5">
						<TextInput
							value={updateOrganisationState.email.value}
							error={updateOrganisationState.email.error}
							onChange={onFieldChange}
							name="email"
							label="email"
							disabled={false}
							placeholder="Organisation name"
						/>
					</div>
					<div className="col-7">
						<TextInput
							value={updateOrganisationState.phone.value}
							error={updateOrganisationState.phone.error}
							onChange={onFieldChange}
							name="phone"
							label="telephone"
							disabled={false}
							placeholder="Phone number"
						/>
					</div>
				</FormRow>
				<FormRow>
					<div className="col-4">
						<TextInput
							value={updateOrganisationState.address1.value}
							error={updateOrganisationState.address1.error}
							onChange={onFieldChange}
							name="address1"
							label="address 1"
							disabled={false}
							placeholder="Organisation adress"
						/>
					</div>
					<div className="col-4">
						<TextInput
							value={updateOrganisationState.address2.value}
							error={updateOrganisationState.address2.error}
							onChange={onFieldChange}
							name="address2"
							label="address 2"
							disabled={false}
							placeholder="City"
						/>
					</div>
					<div className="col-4">
						<TextInput
							value={updateOrganisationState.postCode.value}
							error={updateOrganisationState.postCode.error}
							onChange={onFieldChange}
							name="postCode"
							label="post code"
							disabled={false}
							placeholder="Post code"
						/>
					</div>
				</FormRow>

				<FormRow>
					<h2 className="h6">Communications</h2>
				</FormRow>

				<FormRow>
					<div className="col-4">
						<TextInput
							value={updateOrganisationState.emailRewrite.value}
							error={updateOrganisationState.emailRewrite.error}
							onChange={onFieldChange}
							name="emailRewrite"
							label="re-writes email"
							disabled={false}
							placeholder="Email for re-writes"
						/>
					</div>
				</FormRow>

				<FormRow>
					<h2 className="h6">User account details</h2>
				</FormRow>

				<Table
					title={`Users`}
					tableData={{
						head: container.state.tableHeaders[OrganisationsTables.AllOrganisationEmployees],
						body: container.state.tableData[OrganisationsTables.AllOrganisationEmployees],
					}}
					titleColumnSize={6}
					filters={renderFilters()}
					onColumnSort={(columnIndex) => container.sort(columnIndex, [OrganisationsTables.AllOrganisationEmployees])}
					showSpinner={container.state.loadingAllOrganisationEmployees}
					adjustable
					onItemDelete={(employeeId) => openDeleteOrganisationEmployeeModal(employeeId)}
					onItemEdit={(employeeId) => openEditOrganisationEmployeeModal(employeeId)}
					useitemId
					medium
				/>

				<FormRow>
					<Button onClick={() => container.updateOrganisation()} disabled={isSaveButtonDisabled}>
						{container.state.updatingOrganisation ? <SpinnerIcon /> : "Save"}
					</Button>
				</FormRow>

				{/* Add new employee for Clinic modal*/}
				<Modal
					isOpen={showNewEmployeeForClinicModal}
					onClose={() => {
						setShowNewEmployeeForClinicModal(false);
						container.resetPrepopulatedFieldsForEmployee();
					}}
				>
					<FormRow>
						<h2 className="h6">New user account</h2>
					</FormRow>

					<FormRow>
						<CustomSelect
							label="ROLE"
							name="role"
							values={[{ label: "Organisation Admin", value: 1 }]}
							options={container.state.organisationEmployeeTypes}
							onChange={(e) => {
								onSelectChange(e);
							}}
							disabled={true}
						/>
					</FormRow>
					<FormRow>
						<div className="new_employee_account_modal-disclaimer">
							<p className="normal">Organisation Admin accounts can:</p>
							<br></br>
							<p>• Upload prescription images (image only area)</p>
							<p>• Select which Prescriber the prescription belongs to</p>
							<p>• Upload prescriptions to the DG platform with patient details</p>
							<p>• View all prescriptions associated with Organisation</p>
							<p>• View all patients associated with organisation</p>
						</div>
					</FormRow>

					<FormRow>
						<div className="col-4">
							<TextInput
								value={createNewEmployeeForOrganisationState.firstName.value}
								error={createNewEmployeeForOrganisationState.firstName.error}
								onChange={newEmployeeValue}
								name="firstName"
								label="First name"
								disabled={false}
								placeholder="e.g John"
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={createNewEmployeeForOrganisationState.lastName.value}
								error={createNewEmployeeForOrganisationState.lastName.error}
								onChange={newEmployeeValue}
								name="lastName"
								label="Last name"
								disabled={false}
								placeholder="e.g Doe"
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={createNewEmployeeForOrganisationState.email.value}
								error={createNewEmployeeForOrganisationState.email.error}
								onChange={newEmployeeValue}
								name="email"
								label="Email"
								disabled={false}
								placeholder="Email"
							/>
						</div>
					</FormRow>
					<FormRow>
						<div>
							<p className="m-l-8 m-b-s normal">Please make sure password has:</p>
							<InputFieldValidationBulletPoints value={createNewEmployeeForOrganisationState.password.value} points={PasswordRulePoints} />
						</div>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<TextInput
								value={createNewEmployeeForOrganisationState.password.value}
								error={createNewEmployeeForOrganisationState.password.error}
								onChange={newEmployeeValue}
								name="password"
								label="Password"
								disabled={false}
								placeholder="Password"
								type={"password"}
							/>
						</div>
						<div className="col-6">
							<TextInput
								value={createNewEmployeeForOrganisationState.repeatPassword.value}
								error={createNewEmployeeForOrganisationState.repeatPassword.error}
								onChange={newEmployeeValue}
								name="repeatPassword"
								label="Repeat password"
								disabled={false}
								placeholder="Repeat password"
								type={"password"}
							/>
						</div>
					</FormRow>

					<FormRow>
						<Button
							className="m-r-xs "
							onClick={() => {
								createNewOrganisationEmployee();
								setShowNewEmployeeForClinicModal(false);
							}}
							disabled={isCreateNewEmployeeForOrganisationButtonDisabled}
						>
							Add User
						</Button>
						<Button
							className="m-r-xs btn--bordered--no_radius btn--bg-white"
							onClick={() => {
								setShowNewEmployeeForClinicModal(false);
							}}
						>
							{" "}
							Cancel
						</Button>
					</FormRow>
				</Modal>

				{/* Add new employee for IP modal  */}
				<Modal
					isOpen={showNewEmployeeForIPModal}
					onClose={() => {
						setShowNewEmployeeForIPModal(false);
						container.resetPrepopulatedFieldsForEmployee();
					}}
				>
					<FormRow>
						<h2 className="h6">New user account</h2>
					</FormRow>

					<FormRow>
						<CustomSelect
							label="ROLE"
							name="role"
							values={container.state.newEmployeeForOrganisation.role.value ? [container.state.newEmployeeForOrganisation.role.value] : []}
							options={container.state.organisationEmployeeTypesForNewIPModal}
							onChange={(e) => {
								onSelectChange(e);
								setSelectedRoleForNewIP(e.value[0]);
							}}
							placeholder={"Type"}
							disabled={false}
						/>
					</FormRow>
					{selectedRoleForNewIP.value === 1 && (
						<FormRow>
							<div className="new_employee_account_modal-disclaimer">
								<p className="normal">Organisation Admin accounts can:</p>
								<br></br>
								<p>• Upload prescription images (image only area)</p>
								<p>• Select which Prescriber the prescription belongs to</p>
								<p>• Upload prescriptions to the DG platform with patient details</p>
								<p>• View all prescriptions associated with Organisation</p>
								<p>• View all patients associated with organisation</p>
							</div>
						</FormRow>
					)}

					{selectedRoleForNewIP.value === 0 && (
						<div className="new_employee_account_modal-disclaimer">
							<p className="normal">Prescriber accounts can:</p>
							<br></br>
							<p>• Upload prescription images auto-associated to the prescriber (holding area)</p>
							<p>• Add patient details</p>
							<p>• Any prescriptions they upload are auto-assigned to them</p>
							<p>• View prescriptions associated to prescriber within the organisation</p>
							<p>• View patients associated to Prescriber</p>

							<div className="disclaimer_search_section">
								<p>Search for prescriber</p>
								<p>
									If you cannot find the Prescriber, you need to link them to this organisation or if they don’t exist, you need to{" "}
									<a href="/">create them as a prescriber</a>
								</p>
								<CustomSelect
									label=""
									name="prescribers"
									values={[]}
									options={container.state.organisationEmployeesForDropdownSearch}
									onChange={(e) => {
										onSelectChange(e);
										container.setPrepopulatedFieldsForEmployee(e);
									}}
									placeholder={"Prescribers"}
								/>
							</div>
						</div>
					)}

					{selectedRoleForNewIP.value === 0 && (
						<FormRow>
							<div className="col-6">
								<TextInput
									value={createNewEmployeeForOrganisationState.prescriberIdCode.value}
									error={createNewEmployeeForOrganisationState.prescriberIdCode.error}
									onChange={newEmployeeValue}
									name="gphc/gmc"
									label="Gmc/gphc"
									disabled={true}
									placeholder="1234567"
								/>
							</div>
						</FormRow>
					)}

					<FormRow>
						<div className="col-4">
							<TextInput
								value={createNewEmployeeForOrganisationState.firstName.value}
								error={createNewEmployeeForOrganisationState.firstName.error}
								onChange={newEmployeeValue}
								name="firstName"
								label="first name"
								disabled={selectedRoleForNewIP.value === 1 ? false : true}
								placeholder="e.g John"
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={createNewEmployeeForOrganisationState.lastName.value}
								error={createNewEmployeeForOrganisationState.lastName.error}
								onChange={newEmployeeValue}
								name="lastName"
								label="last name"
								disabled={selectedRoleForNewIP.value === 1 ? false : true}
								placeholder="e.g Doe"
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={createNewEmployeeForOrganisationState.email.value}
								error={createNewEmployeeForOrganisationState.email.error}
								onChange={newEmployeeValue}
								name="email"
								label="Email"
								disabled={selectedRoleForNewIP.value === 1 ? false : true}
								placeholder="Email"
							/>
						</div>
					</FormRow>
					{selectedRoleForNewIP.value === 1 && (
						<FormRow>
							<div>
								<p className="m-l-8 m-b-s normal">Please make sure password has:</p>
								<InputFieldValidationBulletPoints
									value={createNewEmployeeForOrganisationState.password.value}
									points={PasswordRulePoints}
								/>
							</div>
						</FormRow>
					)}
					<FormRow>
						<div className="col-6">
							<TextInput
								value={createNewEmployeeForOrganisationState.password.value}
								error={createNewEmployeeForOrganisationState.password.error}
								onChange={newEmployeeValue}
								name="password"
								label="Password"
								disabled={selectedRoleForNewIP.value === 1 ? false : true}
								placeholder="Password"
								type={"password"}
							/>
						</div>
						<div className="col-6">
							<TextInput
								value={createNewEmployeeForOrganisationState.repeatPassword.value}
								error={createNewEmployeeForOrganisationState.repeatPassword.error}
								onChange={newEmployeeValue}
								name="repeatPassword"
								label="Repeat password"
								disabled={selectedRoleForNewIP.value === 1 ? false : true}
								placeholder="Repeat password"
								type={"password"}
							/>
						</div>
					</FormRow>

					{selectedRoleForNewIP.value === 0 && (
						<FormRow className="j-c-s" style={{ alignItems: "center" }}>
							<p>Account Access</p>
							<Toggler
								isChecked={isAccountAccessToggled}
								onChange={() => {
									toggleAccountAccess();
								}}
							/>
						</FormRow>
					)}

					<FormRow>
						<Button
							className="m-r-xs "
							onClick={() => {
								createNewOrganisationEmployee();
								setShowNewEmployeeForIPModal(false);
							}}
							disabled={isCreateNewEmployeeForOrganisationButtonDisabled}
						>
							Add User
						</Button>
						<Button
							className="m-r-xs btn--bordered--no_radius btn--bg-white"
							onClick={() => {
								setShowNewEmployeeForClinicModal(false);
								setShowNewEmployeeForIPModal(false);
							}}
						>
							{" "}
							Cancel
						</Button>
					</FormRow>
				</Modal>

				{/* Delete employee form organisation modal */}
				<Modal
					isOpen={showDeleteEmployeeForOrganisationModal}
					onClose={() => {
						setShowDeleteEmployeeForOrganisationModal(false);
						container.resetOrganisationEmployeeState()
					}}
				>
					{deleteEmployeeForOrganisationState.role.value !== 1 ? <>
						<FormRow>
							<h2 className="h6">Unlink Prescriber?</h2>
						</FormRow>
						<FormRow>
							<p>
								Please confirm you would like to unlink {deleteEmployeeForOrganisationState.name.value} from{" "}
								{updateOrganisationState.name.value}.<br></br>
								<br></br>
								Once unlinked, you will not be able to assign prescriptions to this Prescriber when selecting this Organisation.
							</p>
						</FormRow>
					</>
						:
						<>
							<FormRow>
								<h2 className="h6">Delete user account?</h2>
							</FormRow>
							<FormRow>
								<p>
									Please confirm you would like to delete {deleteEmployeeForOrganisationState.name.value} at{" "}
									{updateOrganisationState.name.value}.<br></br>
								</p>
							</FormRow>
						</>}

					<FormRow>
						<Button
							className="m-r-xs "
							onClick={() => {
								deletEmployeeFromOrganisation();
							}}
						>
							Delete
							{/* {container.state.removingAwaitingRewrinewEmployeeValueatus ? <Spinner /> : "Remove"} */}
						</Button>
						<Button
							className="m-r-xs btn--bordered--no_radius btn--bg-white"
							onClick={() => {
								setShowDeleteEmployeeForOrganisationModal(false);
								container.resetOrganisationEmployeeState()
							}}
						>
							{" "}
							Cancel
						</Button>
					</FormRow>
				</Modal>

				{/* Edit employee for organisation modal */}
				<Modal
					className="edit_employee-modal"
					isOpen={showEditEmployeeForOrganisationModal}
					onClose={() => {
						setShowEditEmployeeForOrganisationModal(false);
						container.resetOrganisationEmployeeState();
					}}
				>
					<FormRow>
						<h2 className="h6">Edit user account</h2>
					</FormRow>

					{container.state.editEmployeeForOrganisation.role.value !== 1 && (
						<FormRow>
							<CustomBanner className="banner--blue" icon={<InfoIcon />} text={editDescriptionBannerContnet()} />
						</FormRow>
					)}

					<FormRow className="j-c-s">
						<div className="col-5">
							<TextInput
								value={editEmployeeForOrganisationState.firstName.value}
								error={editEmployeeForOrganisationState.firstName.error}
								onChange={editEmployeeValue}
								name="firstName"
								label="first name"
								disabled={container.state.editEmployeeForOrganisation.role.value === 1 ? false : true}
								placeholder="first name"
							/>
						</div>
						<div className="col-5">
							<TextInput
								value={editEmployeeForOrganisationState.lastName.value}
								error={editEmployeeForOrganisationState.lastName.error}
								onChange={editEmployeeValue}
								name="lastName"
								label="last name"
								disabled={container.state.editEmployeeForOrganisation.role.value === 1 ? false : true}
								placeholder="last name"
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<TextInput
								value={editEmployeeForOrganisationState.email.value}
								error={editEmployeeForOrganisationState.email.error}
								onChange={editEmployeeValue}
								name="email"
								label="Email"
								disabled={container.state.editEmployeeForOrganisation.role.value === 1 ? false : true}
								placeholder="Email"
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<TextInput
								value={editEmployeeForOrganisationState.password.value}
								error={editEmployeeForOrganisationState.password.error}
								onChange={editEmployeeValue}
								name="password"
								label="Password"
								disabled={container.state.editEmployeeForOrganisation.role.value === 1 ? false : true}
								placeholder="Password"
								type={"password"}
							/>
						</div>
						<div className="col-6">
							<TextInput
								value={editEmployeeForOrganisationState.repeatPassword.value}
								error={editEmployeeForOrganisationState.repeatPassword.error}
								onChange={editEmployeeValue}
								name="repeatPassword"
								label="Repeat password"
								disabled={container.state.editEmployeeForOrganisation.role.value === 1 ? false : true}
								placeholder="Repeat password"
								type={"password"}
							/>
						</div>
					</FormRow>

					<FormRow>
						<div className="col-8">
							<CustomSelect
								label="ROLE"
								name="role"
								values={container.state.editEmployeeForOrganisation.role ? [container.state.editEmployeeForOrganisation.role] : []}
								options={container.state.organisationEmployeeTypesForEditModal}
								onChange={(e) => {
									onSelectChange(e);
									setSelectedRoleForUpdate(e.value[0]);
								}}
								placeholder={"Type"}
								disabled={container.state.editEmployeeForOrganisation.role.value == 1 ? true : false}
							/>
						</div>
					</FormRow>

					{container.state.editEmployeeForOrganisation.role.value !== 1 && (
						<FormRow className="j-c-s" style={{ alignItems: "center" }}>
							<p>Account Access</p>
							<Toggler
								isChecked={isAccountAccessToggledEdit}
								onChange={() => {
									toggleAccountAccessEdit();
								}}
							/>
						</FormRow>
					)}

					{isAccountAccessToggledEdit && (
						<>
							{selectedRoleForUpdate.label === OrganisationEmployeeTypeForEditModal[2] && (
								<FormRow>
									<div className="new_employee_account_modal-disclaimer">
										<p>Organisation Admin Prescriber accounts can:</p>
										<br></br>
										<p>• Upload prescription images (image only area)</p>
										<p>• Select which Prescriber the prescription belongs to</p>
										<p>• Upload prescriptions to the DG platform with patient details</p>
										<p>• View all prescriptions associated with Organisation</p>
										<p>• View all patients associated with Organisation</p>
									</div>
								</FormRow>
							)}

							{selectedRoleForUpdate.label === OrganisationEmployeeTypeForEditModal[0] && (
								<FormRow>
									<div className="new_employee_account_modal-disclaimer">
										<p>Organisation Prescriber accounts with account access will be able to:</p>
										<br></br>
										<p>• Upload prescription images (image only area)</p>
										<p>• Upload prescriptions auto-assigned to them</p>
										<p>• View prescriptions associated to them within the clinic</p>
										<p>• View patients associated to them</p>
									</div>
								</FormRow>
							)}
						</>
					)}
					<FormRow className="j-c-s">
						<Button
							className="m-r-xs "
							onClick={() => confirmEditOrganisationEmployee()}
							disabled={isEditEmployeeForOrganisationButtonDisabled}
						>
							{container.state.updatingEmployeeForOrganisation ? <SpinnerIcon /> : "Confirm"}
						</Button>
						<Button
							className="m-r-xs btn--bordered--no_radius btn--bg-white"
							onClick={() => {
								setShowEditEmployeeForOrganisationModal(false);
								container.resetOrganisationEmployeeState();
							}}
						>
							{" "}
							Cancel
						</Button>
					</FormRow>
				</Modal>
			</div>}
		</>
	);
}

function SubscribedEditOrganisation(props) {
	return <Subscribe to={[OrganisationsContainer]}>{(container) => <EditOrganisation {...props} container={container} />}</Subscribe>;
}

export default SubscribedEditOrganisation;
