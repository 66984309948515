import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Subscribe } from "unstated";
import Button from "../../components/Form/Button";
import CustomSelect from "../../components/Form/CustomSelect";
import Dropdown from "../../components/Form/Dropdown";
import FormRow from "../../components/Form/FormRow";
import TextInput from "../../components/Form/TextInput";
import Modal from "../../components/Modal/Modal";
import PrescriptionPreview from "../../components/PrescriptionPreview/PrescriptionPreview";
import { AllRejectReasons, DoctorErrorRejectReasons, HighLevelRejectReasons } from "../../enums";
import AuthenticationContainer from "../../state/AuthenticationContainer";
import PatientContainer from "../../state/PatientContainer";
import contentHelper from "../../util/helpers/contentHelper";
import SpinnerIcon from "../../assets/icons/SpinnerIcon";
import { formatDateForPresenting } from "../../util/dates";
import PrescriptionContainer from "../../state/PrescriptionContainer";
import Textarea from "../../components/Form/Textarea";
import Spinner from "../../components/Spinner/Spinner";
import DateInput from "../../components/Form/DateInput";


function SendToOrganisationModal({ container, patientContainer, rejectModalVisible, setRejectModalVisible, imageUrl }) {
	const { location: { pathname } } = useHistory();

	const [showQuickPatientCreateModal, setShowQuickPatientCreateModal] = useState(false);

	const [showAdditionalComments, setShowAdditionalComments] = useState(false);

	// State-related data
	const assignPrescriptionStateFormKey = "assignPrescription";
	const rejectPrescriptionStateFormKey = "rejectPrescription";
	const formKey = "createPatient";

	const state = patientContainer.state[formKey];
	const filteredPatients = container.state.filteredPatients;
	const rejectPrescriptionState = container.state[rejectPrescriptionStateFormKey];
	const products = container.state["products"];

	const onFieldChange = (event) => patientContainer.onFormChange(event, formKey);
	const onRejectStateFieldChange = (event) => container.onFormChange(event, rejectPrescriptionStateFormKey);
	const onSelectChangeRejectPrescription = (event) => container.onSelectChange(event, rejectPrescriptionStateFormKey);
	const onSelectChangeMultiple = (event, index) => container.onSelectChangeMultiple(event, index, rejectPrescriptionStateFormKey);

	const onSelectKeyDownPrescriptionPatientField = async (event) => {
		await container.onSelectKeyDownPrescriptionPatientField(event);
	};

	const toggleRejectModal = () => {
		setRejectModalVisible(!rejectModalVisible);
	}

	const handleQuickCreatePatient = async () => {
		const patient = await patientContainer.create(false);
		if (!patient) {
			return;
		}
		const createdPatient = {
			label: `${patient.firstName} ${patient.lastName} - ${formatDateForPresenting(patient.dateOfBirth, false)}`,
			value: patient.id,
		};
		filteredPatients.unshift(createdPatient);

		container.onSelectChange({ name: 'patient', value: [createdPatient] }, assignPrescriptionStateFormKey);
		setShowQuickPatientCreateModal(false);
		patientContainer.resetCreateState();
	}

	const constructPayloadForSendToOrganisationWithProducts = () => {
		return {
			paperPrescriptionId: rejectPrescriptionState.paperPrescriptionId.value,
			patientId: rejectPrescriptionState.patient.value.value,
			prescriptionId: rejectPrescriptionState.prescriptionId.value * 1,
			errorReason: rejectPrescriptionState.highLevelReason.value.value,
			additionalComment: rejectPrescriptionState.comment.value,
			productIds: rejectPrescriptionState.product.map(p => {
				return p?.value?.value
			}),
			datePrescribed: rejectPrescriptionState.datePrescribed.value
		}
	}

	const constructPayloadForSendToOrganisationPrescriberPrescriberError = () => {
		return {
			paperPrescriptionId: rejectPrescriptionState.paperPrescriptionId.value,
			patientId: rejectPrescriptionState.patient.value.value,
			prescriptionId: rejectPrescriptionState.prescriptionId.value * 1,
			errorReason: rejectPrescriptionState.reason.value.value,
			additionalComment: rejectPrescriptionState.comment.value,
			datePrescribed: rejectPrescriptionState.datePrescribed.value
		}
	}

	const constructPayloadForSendToOrganisationPrescriptionExpired = () => {
		return {
			paperPrescriptionId: rejectPrescriptionState.paperPrescriptionId.value,
			patientId: rejectPrescriptionState.patient.value.value,
			prescriptionId: rejectPrescriptionState.prescriptionId.value * 1,
			errorReason: rejectPrescriptionState.highLevelReason.value.value,
			additionalComment: rejectPrescriptionState.comment.value,
			datePrescribed: rejectPrescriptionState.datePrescribed.value
		}
	}

	const highLevelReasonToApiCallMapper = {
		[HighLevelRejectReasons.ProductNotInStock]: () => container.sendToOrganisationWithProducts(constructPayloadForSendToOrganisationWithProducts(), pathname),
		[HighLevelRejectReasons.DiscontinuedProduct]: () => container.sendToOrganisationWithProducts(constructPayloadForSendToOrganisationWithProducts(), pathname),
		[HighLevelRejectReasons.PrescriberProductError]: () => container.sendToOrganisationWithProducts(constructPayloadForSendToOrganisationWithProducts(), pathname),
		[HighLevelRejectReasons.PrescriptionExpired]: () => container.sendToOrganisationWithProducts(constructPayloadForSendToOrganisationPrescriptionExpired(), pathname),
		[HighLevelRejectReasons.PrescriberError]: () => container.sendToOrganisationWithProducts(constructPayloadForSendToOrganisationPrescriberPrescriberError(), pathname),
	}

	const handleRejectPrescription = () => {
		highLevelReasonToApiCallMapper[rejectPrescriptionState.highLevelReason.value.value]()

		toggleRejectModal();
		container.resetAllFieldsExcept('rejectPrescription', ["prescriptionNumber", "patient", "paperPrescriptionId", "prescriptionId"])
	}

	const handleCloseReject = () => {
		toggleRejectModal(false);
		container.resetAllFieldsExcept('rejectPrescription', ["prescriptionNumber", "patient", "paperPrescriptionId", "prescriptionId"])
	}

	const handleHideShowAdditionalComments = () => {
		setShowAdditionalComments(!showAdditionalComments);
	}

	const renderQuickPatientCreateModal = () => {
		return <Modal large isOpen={showQuickPatientCreateModal} >
			<FormRow>
				<div className='col-7' style={{ flexDirection: 'column' }} >
					<FormRow>
						<h1 className="h4">New patient</h1>
					</FormRow>
					<FormRow>
						<div className="col-2">
							<Dropdown
								value={state.title?.value}
								error={state.title.error}
								onChange={onFieldChange}
								name="title"
								label="Title"
								optional
								required
								options={contentHelper.getTitlesAsDropdownItems()}
							/>
						</div>
						<div className="col-5">
							<TextInput
								value={state.firstName?.value}
								error={state.firstName.error}
								onChange={onFieldChange}
								name="firstName"
								label="First name"
								placeholder="e.g. John"
								required={true}
							/>
						</div>
						<div className="col-5">
							<TextInput
								value={state.lastName?.value}
								error={state.lastName.error}
								onChange={onFieldChange}
								name="lastName"
								label="Last name"
								placeholder="e.g. Doe"
								required={true}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<TextInput
								value={state.dateOfBirth?.value}
								error={state.dateOfBirth.error}
								onChange={onFieldChange}
								name="dateOfBirth"
								label="Date of birth"
								placeholder="e.g. 25/08/1973"
								required={true}
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.email?.value}
								error={state.email.error}
								onChange={onFieldChange}
								name="email"
								label="Email address"
								placeholder="e.g. johndoe@email.com"
								required={true}
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.telephoneNo?.value}
								error={state.telephoneNo.error}
								onChange={onFieldChange}
								name="telephoneNo"
								label="Telephone number"
								placeholder="e.g. +00256478921"
								required={true}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<TextInput
								value={state.address1?.value}
								error={state.address1.error}
								onChange={onFieldChange}
								name="address1"
								label="Address 1"
								placeholder="e.g. Avenue Road 134A, Illinois"
								required
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.address2?.value}
								error={state.address2.error}
								onChange={onFieldChange}
								name="address2"
								label="Address 2"
								placeholder="e.g. Avenue Road 134B, Illinois"
								required
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.zipCode?.value}
								error={state.zipCode.error}
								onChange={onFieldChange}
								name="zipCode"
								label="Postal code"
								placeholder="e.g. 35492 DB"
								required
							/>
						</div>
					</FormRow>
					<FormRow>

						<div className="col-4">
							<Dropdown
								name="t21Patient"
								value={state.t21Patient?.value}
								error={state.t21Patient.error}
								onChange={onFieldChange}
								options={contentHelper.getYesNoDropdownItems()}
								label="T21 patient"
								required
							/>
						</div>
						<div className="col-4">
							<Dropdown
								name="organisationName"
								value={state.organisationName?.value}
								error={state.organisationName.error}
								onChange={onFieldChange}
								options={[
									{ text: "Select", value: "" },
									{ text: "The Medical Cannabis Clinics", value: "The Medical Cannabis Clinics" },
								]}
								label="clinic name"
								required
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.clinicPatientId?.value}
								error={state.clinicPatientId.error}
								onChange={onFieldChange}
								name="clinicPatientId"
								label="Clinic patient id"
								placeholder="e.g. 123456"
								required
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">

							<Button onClick={() => { handleQuickCreatePatient() }} disabled={!patientContainer.isFormValid(formKey) || patientContainer.state.creatingPatient} >
								{patientContainer.state.creatingPatient ? <SpinnerIcon /> : "Create patient"}
							</Button>
						</div>
						<div className="col-1">

						</div>
						<div className="col-4">

							<Button onClick={() => setShowQuickPatientCreateModal(false)}  >
								Cancel
							</Button>
						</div>
						<div className="col-8 " style={{ color: 'red' }}>

						</div>
					</FormRow>
				</div>
				<div className='col-5' style={{ flexDirection: 'column' }} >
					<FormRow >
						<div className='col-8'>

						</div>
						<div className='col-4'>
							<Link to='/patients/new' >
								Open full patient creator
							</Link>
						</div>
					</FormRow>
					{
						imageUrl && <PrescriptionPreview showChangeButton={false} onChange={container.uploadPrescriptionPhoto} defaultImage={imageUrl} />
					}
				</div>
			</FormRow>


		</Modal>
	}

	const highlevelReasonToreasonOptionsMapper = {
		[HighLevelRejectReasons.PrescriberProductError]: contentHelper.getPrescriberProductErrorRejectReasons,
		[HighLevelRejectReasons.PrescriberError]: contentHelper.getPrescriberErrorRejectReasons,
		[HighLevelRejectReasons.DoctorError]: contentHelper.getDoctorErrorArchiveReasons,
		[HighLevelRejectReasons.AdminError]: contentHelper.getAdminErrorArchiveReasons,
		undefined: () => [{ value: "", label: "select" }],
		null: () => [{ value: "", label: "select" }],
	}

	const renderContentsUnreadableInputs = () => {
		return !container.state.loadingPatients && (rejectPrescriptionState.reason.value.value === DoctorErrorRejectReasons.ContentsUnreadable) &&
			<>
				<FormRow >
					<CustomSelect
						key={rejectPrescriptionState.patient.value ? rejectPrescriptionState.patient.value : "defaultKey"}
						label="Patient"
						name="patient"
						id='patientinputs'
						values={rejectPrescriptionState.patient.value ? [rejectPrescriptionState.patient.value] : []}
						options={filteredPatients}
						onChange={(onSelectChangeRejectPrescription)}
						onKeyDown={(e) => { onSelectKeyDownPrescriptionPatientField(e) }}
						clearable={true}
						showDropdownOnlyOnSearch={true}
						placeholder={"Search patient"}
					/>
				</FormRow>
				<FormRow >
					<TextInput
						value={rejectPrescriptionState.paperPrescriptionId?.value}
						name='paperPrescriptionId'
						label="Prescription number"
						placeholder="e.g. 792343049824"
						onChange={onRejectStateFieldChange}
					/>
				</FormRow>
			</>
	}

	const renderRejectModalPatientInput = () => {
		return !container.state.loadingPatients ? (
			<FormRow>

				<CustomSelect
					key={rejectPrescriptionState.patient.value ? rejectPrescriptionState.patient.value : "defaultKey"}
					label="Patient"
					name="patient"
					id='patientinput'
					values={rejectPrescriptionState.patient.value ? [rejectPrescriptionState.patient.value] : []}
					options={filteredPatients}
					onChange={onSelectChangeRejectPrescription}
					onKeyDown={(e) => { onSelectKeyDownPrescriptionPatientField(e) }}
					clearable={true}
					showDropdownOnlyOnSearch={true}
					placeholder={"Search patient"}
				/>
				<div
					onClick={() => { setShowQuickPatientCreateModal(true) }}
					className="badge--no-border  highlighted m-l-xs"
					title="Remove field">
					<i className="icon-plus" />
				</div>
			</FormRow>
		)
			:
			<></>
	}

	const renderRejectModalPrescriptionInput = () => {
		return <>
			<FormRow >
				<TextInput
					value={rejectPrescriptionState.paperPrescriptionId?.value}
					name='paperPrescriptionId'
					error={rejectPrescriptionState.paperPrescriptionId.error}
					label="Prescription number"
					placeholder="e.g. 792343049824"
					onChange={onRejectStateFieldChange}
				/>
			</FormRow>
			{
				// datePrescribed picker should only show during the assignment phase
				pathname.includes('assign') && <FormRow>
					<DateInput
						value={rejectPrescriptionState.datePrescribed?.value}
						error={rejectPrescriptionState.datePrescribed?.error}
						onChange={onRejectStateFieldChange}
						name="datePrescribed"
						label="Date prescribed"
						placeholder="e.g. 10/09/2020"
					/>
				</FormRow>
			}
		</>
	}

	const renderDynamicProductInput = () => {

		const addButtonText = {
			[HighLevelRejectReasons.DiscontinuedProduct]: "Add discontinued product",
			[HighLevelRejectReasons.ProductNotInStock]: "Add oos product",
			[HighLevelRejectReasons.PrescriberProductError]: "Add another product",
		}

		const labelTextMapper = {
			[HighLevelRejectReasons.PrescriberProductError]: "Which product has the error",
		}

		return <>
			{rejectPrescriptionState.product.map((p, i) => {
				return <FormRow key={i} >
					<CustomSelect
						label={labelTextMapper[rejectPrescriptionState.highLevelReason.value.value] || `Product ${i + 1}`}
						name="product"
						key={products.length + i + p.value}
						values={[p.value]}
						options={products}
						disabled={false}
						onChange={(e) => { onSelectChangeMultiple(e, i) }}
					/>
					{i !== 0 && <div
						onClick={() => { container.removeField('product', rejectPrescriptionStateFormKey, i) }}
						className="badge--no-border color-red highlighted m-l-xs"
						title="Remove field">
						<i className="icon-minus" />
					</div>
					}
				</FormRow>
			})}
			{rejectPrescriptionState.product.length < 6 &&
				<FormRow>

					<div
						onClick={() => { container.appendField('product', rejectPrescriptionStateFormKey) }}
						className="badge--no-border badge--titled  highlighted"
						title="Append field"
					>
						<i className="icon-plus" />
						<div className='badge__name' >{addButtonText[rejectPrescriptionState.highLevelReason.value.value]}</div>
					</div>
				</FormRow>
			}
		</>
	}

	const renderAdditionalCommentsInput = () => {
		return <>
			<FormRow>
				<div
					onClick={() => { handleHideShowAdditionalComments() }}
					className="badge--no-border badge--titled  highlighted"
					title="Append field"
				>
					{showAdditionalComments ? <i className="icon-minus" /> : <i className="icon-plus" />}
					<div className='badge__name' >Additional comments</div>
				</div>
			</FormRow>
			{showAdditionalComments && <FormRow>
				<Textarea
					label={"Addtional comments"}
					name="comment"
					placeholder="Add an additional comment if required"
					value={rejectPrescriptionState.comment.value}
					error={rejectPrescriptionState.comment.error}
					onChange={onRejectStateFieldChange}
					maxLength={255}
				/>
			</FormRow>}
		</>
	}

	const renderProductNotInStockInputs = () => {
		return <>

			{renderDynamicProductInput()}
			{renderRejectModalPatientInput()}
			{renderRejectModalPrescriptionInput()}
			{renderAdditionalCommentsInput()}

		</>
	}

	const renderPrescriberProductErrorInputs = () => {
		return <>
			{renderReasonInput()}
			{renderDynamicProductInput()}
			{renderRejectModalPatientInput()}
			{renderRejectModalPrescriptionInput()}
			{renderAdditionalCommentsInput()}

		</>
	}
	const renderPrescriptionExpiredInputs = () => {
		return <>
			{renderRejectModalPatientInput()}
			{renderRejectModalPrescriptionInput()}
			{renderAdditionalCommentsInput()}

		</>
	}
	const renderPrescriberErrorInputs = () => {
		return <>
			{renderReasonInput()}
			{renderRejectModalPatientInput()}
			{renderRejectModalPrescriptionInput()}
			{renderAdditionalCommentsInput()}

		</>
	}

	const renderRestOfRejectInputsMapper = {
		[AllRejectReasons.ContentsUnreadable]: renderContentsUnreadableInputs,
		[AllRejectReasons.DoctorSentADuplicate]: () => <></>,
		[AllRejectReasons.DoctorSentADuplicate]: () => <></>,
		[AllRejectReasons.DoctorSentADuplicate]: () => <></>,
		[AllRejectReasons.JerseyGuernsey]: () => <></>,
		[AllRejectReasons.ThisIsADupicate]: () => <></>,
		[AllRejectReasons.ImageUploadedByMistake]: () => <></>,
		[AllRejectReasons.DobMissing]: () => <></>,
		[AllRejectReasons.FullNameMissing]: () => <></>,
		[AllRejectReasons.AddressMissing]: () => <></>,
		[AllRejectReasons.SignitureOrDateMissing]: () => <></>,
		[AllRejectReasons.Other]: () => <></>,
		undefined: () => <></>,
		null: () => <></>,
	}

	const renderRest = renderRestOfRejectInputsMapper[rejectPrescriptionState.reason.value.value] || function () { };

	const renderInputsbasedOnHighLevelReasonMapper = {
		[HighLevelRejectReasons.ProductNotInStock]: () => <>{renderProductNotInStockInputs()}</>,
		[HighLevelRejectReasons.DiscontinuedProduct]: () => <>{renderProductNotInStockInputs()}</>,
		[HighLevelRejectReasons.PrescriberProductError]: () => <>{renderPrescriberProductErrorInputs()}</>,
		[HighLevelRejectReasons.PrescriptionExpired]: () => <>{renderPrescriptionExpiredInputs()}</>,
		[HighLevelRejectReasons.PrescriberError]: () => <>{renderPrescriberErrorInputs()}</>,
	}
	const renderInputsBasedOnHighLevelReason = renderInputsbasedOnHighLevelReasonMapper[rejectPrescriptionState.highLevelReason.value.value] || function () { };


	const renderReasonInput = useCallback(() => {

		return [
			HighLevelRejectReasons.PrescriberError,
			HighLevelRejectReasons.PrescriberProductError,
			HighLevelRejectReasons.DoctorError,
			HighLevelRejectReasons.AdminError,
			HighLevelRejectReasons.PrescriberError,

		].includes(rejectPrescriptionState?.highLevelReason?.value?.value) ?
			<FormRow>
				<CustomSelect
					label="What was the error"
					key={`${rejectPrescriptionState.highLevelReason.value.value}+${rejectPrescriptionState.reason.value.value}`}
					name="reason"
					values={[!rejectPrescriptionState.reason.value ? { label: "Select", value: "" } : rejectPrescriptionState.reason.value]}
					options={highlevelReasonToreasonOptionsMapper[rejectPrescriptionState.highLevelReason.value.value]()}
					disabled={false}
					onChange={onSelectChangeRejectPrescription}
				/>
			</FormRow>
			:
			<></>

		// eslint-disable-next-line
	}, [rejectPrescriptionState.highLevelReason.value.value, rejectPrescriptionState.reason.value.value])


	const isSendToOrganisationButtonEnabledProductNotInStock = () => {
		return rejectPrescriptionState.paperPrescriptionId?.value &&
			!rejectPrescriptionState.paperPrescriptionId?.error &&
			rejectPrescriptionState.patient?.value?.value &&
			rejectPrescriptionState.product[0]?.value?.value &&
			rejectPrescriptionState.comment.value.length <= 255

	}

	const isSendToOrganisationButtonEnabledPrescriberProductError = () => {
		return rejectPrescriptionState.paperPrescriptionId?.value &&
			!rejectPrescriptionState.paperPrescriptionId?.error &&
			rejectPrescriptionState.patient?.value?.value &&
			rejectPrescriptionState.reason.value?.value &&
			rejectPrescriptionState.product[0]?.value?.value &&
			rejectPrescriptionState.comment.value.length <= 255
	}

	const isSendToOrganisationButtonEnabledProductExpired = () => {

		return !!(rejectPrescriptionState.paperPrescriptionId?.value &&
			!rejectPrescriptionState.paperPrescriptionId?.error &&
			rejectPrescriptionState.patient?.value?.value &&
			rejectPrescriptionState.highLevelReason.value?.value) &&
			rejectPrescriptionState.comment.value.length <= 255
	}

	const isSendToOrganisationButtonEnabledPrescriberError = () => {

		return !!(rejectPrescriptionState.paperPrescriptionId?.value &&
			!rejectPrescriptionState.paperPrescriptionId?.error &&
			rejectPrescriptionState.patient?.value?.value &&
			rejectPrescriptionState.highLevelReason.value?.value &&
			rejectPrescriptionState.reason.value?.value &&
			rejectPrescriptionState.comment.value.length <= 255)
	}

	const isSendToOrganisationButtonEnabledHighLevelReasonMapper = {
		[HighLevelRejectReasons.ProductNotInStock]: () => isSendToOrganisationButtonEnabledProductNotInStock(),
		[HighLevelRejectReasons.DiscontinuedProduct]: () => isSendToOrganisationButtonEnabledProductNotInStock(),
		[HighLevelRejectReasons.PrescriberProductError]: () => isSendToOrganisationButtonEnabledPrescriberProductError(),
		[HighLevelRejectReasons.PrescriptionExpired]: () => isSendToOrganisationButtonEnabledProductExpired(),
		[HighLevelRejectReasons.PrescriberError]: () => isSendToOrganisationButtonEnabledPrescriberError(),
	}

	const callIsSendToOrganisationButtonEnabledHighLevelReasonMapper = () => {

		return isSendToOrganisationButtonEnabledHighLevelReasonMapper[rejectPrescriptionState.highLevelReason.value.value] || function () { return false }
	}

	return (
		<Modal style={{ minHeight: "390px" }} className='modal--assign' isOpen={rejectModalVisible} onClose={() => handleCloseReject()}>
			<FormRow>
				<h1 className="h4">Why are you sending this prescription to the organisation?</h1>
			</FormRow>
			<FormRow>
				<CustomSelect
					label="Reason"
					name="highLevelReason"
					values={[rejectPrescriptionState.highLevelReason]}
					options={contentHelper.getHighLevelRejectReasonsAsDropDownItems()}
					disabled={false}
					onChange={(e) => { onSelectChangeRejectPrescription(e); container.resetAllFieldsExcept(rejectPrescriptionStateFormKey, ["highLevelReason", "patient", "paperPrescriptionId", "prescriptionId"]) }}
				/>
			</FormRow>
			{renderInputsBasedOnHighLevelReason()}

			{renderRest()}
			<FormRow>
				<div className="col-6" >
					<Button
						className="m-r-xs"
						onClick={() => { handleRejectPrescription() }}
						disabled={!callIsSendToOrganisationButtonEnabledHighLevelReasonMapper()()}
					>
						{container.state.sendingToClinic ? <Spinner /> : "Send for Rewrite"}
					</Button>
				</div>
				<div className="col-6" >
					<Button
						className="btn--bordered--no_radius"
						onClick={() => { handleCloseReject() }}
						text="Cancel"
					/>
				</div>
			</FormRow>
			{renderQuickPatientCreateModal()}
		</Modal>
	);
}

function SubscribedSendToOrganisationModal(props) {
	return (
		<Subscribe to={[PrescriptionContainer, AuthenticationContainer, PatientContainer]}>
			{(container, authContainer, patientContainer) => (
				<SendToOrganisationModal {...props} container={container} patientContainer={patientContainer} currentAdministrator={authContainer.state.currentAdministrator} />
			)}
		</Subscribe>
	);
}

export default SubscribedSendToOrganisationModal;