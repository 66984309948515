import React from "react";

function Checkbox({
  value = false,
  name = '',
  label = "Check",
  onChange = () => null,
  right = false,
  disabled = false
}) {
  return (
    <div className="checkbox-container">
      {right &&
        <label className="checkbox">
          <span className="checkbox-text-right">{`${label} `}</span>

          <input
            type="checkbox"
            name={name}
            className="input--hidden"
            checked={value}
            onChange={(e) => onChange({ name, value: e.target.checked })}
            disabled={disabled}
          />
          <span className={disabled ? "inner-label--disabled" : "inner-label"}></span>
        </label>
      }
      {!right &&
        <label className="checkbox">
          <input
            type="checkbox"
            name={name}
            className="input--hidden"
            checked={value}
            onChange={(e) => onChange({ name, value: e.target.checked })}
            disabled={disabled}
          />
          <span className={disabled ? "inner-label--disabled" : "inner-label"}></span>
          <span className="checkbox-text">{label}</span>
        </label>
      }
    </div>
  );
}

export default Checkbox;
