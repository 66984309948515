import React from 'react'

const PaperCopyIcon = (props) => {
    let size = props.size ? props.size : 20
    let color = props.color
    return (
        <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6.5" cy="6.5" r="6.5" fill={color} />
        </svg>
    )
}

export default PaperCopyIcon