import moment from "moment";
import { PrescriptionPhases } from "../../enums";

/**
 * Used in containers to sort data when a container only has one table
 *
 * !!! this needs to be bound using ".bind" in the container
 *
 * @param {*} columnIndex
 */
export function containerSort(columnIndex, splitBySpace) {
	const tableHeaders = this.state.tableHeaders;
	const tableData = this.state.tableData;

	sortTableDataAndUpdateHeaders(tableHeaders, tableData, columnIndex, splitBySpace);

	this.setState({ tableData, tableHeaders });
}

export function sortPassedData(columnIndex, tableHeaders, tableBodyData) {

	sortTableDataAndUpdateHeaders(tableHeaders, tableBodyData, columnIndex);

	this.setState({ tableBodyData, tableHeaders });
}

/**
 * Used by @see containerSort. And by containers that have multiple tables
 * like @see PrescriptionContainer
 *
 * @param {*} headers
 * @param {*} body
 * @param {*} columnIndex
 */
export function sortTableDataAndUpdateHeaders(headers, body, columnIndex, splitBySpace) {
	resetHeaderData(headers, columnIndex)
	// reset all sorted flags except the clicked column
	for (let i = 0; i < headers.length; i++) {
		if (i === columnIndex) continue;

		headers[i].ascending = null;
	}

	// figure out sort order and set a flag that
	// the given column is now sorted by
	let ascending = true;
	if (headers[columnIndex].ascending === true) ascending = false;
	headers[columnIndex].ascending = ascending;

	// sort body
	body = body.sort(customSort(ascending, columnIndex, splitBySpace));

	body = body.sort((a, b) => {
		return (Number(b[0].isUrgent) - Number(a[0].isUrgent));
	})

	return [headers, body];
}
function containsSpecialCharsOrEmptyString(str) {
	if (str === "") return true
	const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
	return specialChars.test(str);
}

function customSort(ascending, columnIndex, splitBySpace) {
	return function (a, b) {

		const convertValueToType = typeToConverterMapper[a[columnIndex].type];

		a = convertValueToType(a[columnIndex].value ? a[columnIndex].value : a[columnIndex].text);
		b = convertValueToType(b[columnIndex].value ? b[columnIndex].value : b[columnIndex].text);

		if (typeof a === "string") a = a.toLowerCase();
		if (typeof b === "string") b = b.toLowerCase();

		if (splitBySpace) {
			const lastA = a.split(" ")
			const doesHaveSpecialCharA = containsSpecialCharsOrEmptyString(lastA[lastA.length - 1])
			a = doesHaveSpecialCharA ? lastA[lastA.length - 2] : lastA[lastA.length - 1]

			const lastB = b.split(" ")
			const doesHaveSpecialCharB = containsSpecialCharsOrEmptyString(lastB[lastB.length - 1])
			b = doesHaveSpecialCharB ? lastB[lastB.length - 2] : lastB[lastB.length - 1]
		}
		let result = 0;

		if (a > b) result = 1;
		else if (a < b) result = -1
		else result = 0;

		// flip order if descending
		if (!ascending) result *= -1;

		return result;
	};
}

const typeToConverterMapper = Object.freeze({
	undefined: (val) => val,
	date: (val) => {
		let value = val.value || val;
		let format = val.format || "DD/MM/YY";
		const date = moment(value, format);

		// if the date isn't invalid
		if (Number.isNaN(date.day())) {
			return moment("01/01/70", format);
		}

		return date;
	},
});



/*For sorting table data on initial fetch and when using the filter (search) field within the table*/

const tablesToSortByExpiryDate = [
	PrescriptionPhases.outofstock,
	PrescriptionPhases.readyforpayment,
	PrescriptionPhases.requirepapercopy,
	PrescriptionPhases.readyforpharmacy,
	PrescriptionPhases.readyforpatient,
	PrescriptionPhases.shipped,
	PrescriptionPhases.paymentmissed,
];

const phasesToEnums = {
	"outofstock": PrescriptionPhases.outofstock,
	"readyforpayment": PrescriptionPhases.readyforpayment,
	"requirepapercopy": PrescriptionPhases.requirepapercopy,
	"readyforpharmacy": PrescriptionPhases.readyforpharmacy,
	"readyforpatient": PrescriptionPhases.readyforpatient,
	"shipped": PrescriptionPhases.shipped,
	"paymentmissed": PrescriptionPhases.paymentmissed,
};

function sortCertainTableByExpiryDate(tableData) {
	if (!tableData) {
		return
	}

	const indexOfExpiryDate = tableData.length ? tableData[0].length - 1 : 0;

	tableData.sort((a, b) => {
		return moment(b[indexOfExpiryDate].text) - moment(a[indexOfExpiryDate].text)
	})
	//second sort - so that urgent scripts appear first - we are converting bool to Number to prevent implicit type conversion
	tableData.sort((a, b) => {
		return (Number(b[0].isUrgent) - Number(a[0].isUrgent));
	})
}

function sortTableDataByExpiryDate(tableData, phase) {
	const tableDataKeys = Object.keys(tableData);
	if (!phase) {
		tableDataKeys.forEach(tableEnum => {
			if (tablesToSortByExpiryDate.includes(tableEnum * 1)) {
				sortCertainTableByExpiryDate(tableData[tableEnum])
			}
		})
	}

	if (phase) {
		sortCertainTableByExpiryDate(tableData[phasesToEnums[phase]])
	}
}

export function sortTableData(tableData, phase) {
	sortTableDataByExpiryDate(tableData, phase)
}

export function resetHeaderData(headers, columnIndex) {
	// reset all sorted flags except the clicked column
	for (let i = 0; i < headers.length; i++) {
		if (i === columnIndex) continue;

		headers[i].ascending = null;
	}
}