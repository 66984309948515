import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Subscribe } from "unstated";

import { env, clientUrls, TMCC_CLINIC_ID } from "../../config.json";

import Button from "../../components/Form/Button";
import Dropdown from "../../components/Form/Dropdown";
import TextInput from "../../components/Form/TextInput";
import TextInputWithTooltip from "../../components/Form/TextInputWithTooltip";
import FormRow from "../../components/Form/FormRow";
import Textarea from "../../components/Form/Textarea";

import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";
import Prompt from "../../components/Modal/Prompt";

import tableHeaders from "../../util/constants/tableHeaders";
import contentHelper from "../../util/helpers/contentHelper";
import PatientContainer from "../../state/PatientContainer";
import AuthenticationContainer from "../../state/AuthenticationContainer";
import {
	AdminType, BannerTypes,
	genericTagSignupTextMapper,
	genericTagSubscriptionTextMapper,
	genericTagSubscriptionClassnameMapper,
	genericTagSignupClassnameMapper,
	SubscriptionTypes,
} from "../../enums";
import Toggler from "../../components/Form/Toggler";
import GenericTag from "../../components/SharedComponents/GenericTag";
import Separator from "../../components/Separator/Separator";
import SpinnerIcon from "../../assets/icons/SpinnerIcon";
import CustomSelect from "../../components/Form/CustomSelect";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";
import Banner from "../../components/Banner/Banner";
import FlagIcon from "../../assets/icons/FlagIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import EditFlagButton from "../../components/Form/EditFlagButton";
import EditNoteIcon from "../../assets/icons/EditNoteIcon";
import LoadingCard from "../../components/SharedComponents/LoadingCard";
import CustomBanner from "../../components/Banner/CustomBanner";


function EditPatient({ container, currentAdministrator }) {
	// State-related data
	const formKey = "updatePatient";
	const commentFormKey = "createComment";
	const urgentCommentFormKey = "urgentComment";
	const updateCommentFormKey = "updateComment";
	const commentsTableDataKey = "tableData";
	const state = container.state[formKey];

	const comment = container.state[commentFormKey];
	const urgentComment = container.state[urgentCommentFormKey];
	const updateComment = container.state[updateCommentFormKey];
	const commentsTableData = container.state.comments[commentsTableDataKey];
	const loadingPatientInfo = container.state.updatingPatient

	const onFieldChange = (event) => container.onFormChange(event, formKey);
	const onCommentChange = (event) => container.onFormChange(event, commentFormKey);
	const onUrgentCommentChange = (event) => container.onFormChange(event, urgentCommentFormKey);
	const onUpdateCommentChange = (event) => container.onFormChange(event, updateCommentFormKey);
	const onSelectChange = (event) => container.onSelectChange(event, formKey);

	const onUpdateClick = () => { container.updateComment(); setShowEditCommentModal(false) }

	const [isEmailToggleChecked, setIsEmailToggleChecked] = useState(state.areEmailNotificationsEnabled.value);
	const [isEmailToggleDisabled, setIsEmailToggleDisabled] = useState(false);
	const [isAftercareEmailToggleChecked, setIsAftercareEmailToggleChecked] = useState(state.areAftercareNotificationsEnabled.value);
	const [isAftercareEmailToggleDisabled, setIsAftercareEmailToggleDisabled] = useState(false);
	const [isUrgentToggleChecked, setIsUrgentToggleChecked] = useState(state.isUrgent.value);
	const [isUrgentToggleDisabled, setIsUrgentToggleDisabled] = useState(false);
	const [showUrgentPopup, setShowUrgentPopup] = useState(false);

	const [noteValue, setNoteValue] = useState(state.patientNote.value);
	const [showNoteModal, setNoteModal] = useState(false);
	const [openEditNoteDropdown, setOpenEditNoteDropdown] = useState(false);


	useEffect(() => {
		setIsEmailToggleChecked(state.areEmailNotificationsEnabled.value)
	}, [state.areEmailNotificationsEnabled.value])

	useEffect(() => {
		setIsAftercareEmailToggleChecked(state.areAftercareNotificationsEnabled.value)
	}, [state.areAftercareNotificationsEnabled.value])

	useEffect(() => {
		setIsUrgentToggleChecked(state.isUrgent.value)
	}, [state.isUrgent.value])

	const onEmailToggleChange = async () => {
		setIsEmailToggleDisabled(true);
		await container.setAreEmailNotificationsEnabled({
			userId: state.userId.value,
			areEmailNotificationsEnabled: !state.areEmailNotificationsEnabled.value,
			adminId: currentAdministrator.id
		})
		setIsEmailToggleDisabled(false);
	}

	const onAftercareEmailToggleChange = async () => {
		setIsAftercareEmailToggleDisabled(true);
		await container.setAreAftercareNotificationsEnabled({
			patientId: id,
			areAftercareNotificationsEnabled: !state.areAftercareNotificationsEnabled.value,
			adminId: currentAdministrator.id
		})
		setIsAftercareEmailToggleDisabled(false);
	}

	const onUrgentToggleChange = async () => {
		setShowUrgentPopup(true);
	}

	// Hook-related data
	const { id } = useParams();

	const [showEditCommentModal, setShowEditCommentModal] = useState(false);

	useEffect(() => {
		container.setForUpdate(id);
		container.setCreateCommentState(currentAdministrator.id, id);

		return container.resetUpdateState;
		// eslint-disable-next-line
	}, [id, container]);

	useEffect(() => {
		setNoteValue(state.patientNote.value)
	}, [state.patientNote.value])

	//Click outside hook
	const ref = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpenEditNoteDropdown(false)
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [setOpenEditNoteDropdown]);

	useEffect(() => {
		if (state?.organisationId?.value?.value != TMCC_CLINIC_ID) {
			container.onFormChange({
				name: "subscriptionType",
				value: 0
			}, formKey);

			container.onFormChange({
				name: "subscriptionTier",
				value: 1
			}, formKey);
		}

	}, [state?.organisationId?.value])

	const handleOpenEditNoteDropdown = () => {
		setOpenEditNoteDropdown(!openEditNoteDropdown)
	}

	const handleEditNoteButton = () => {
		setNoteModal(true)
		setOpenEditNoteDropdown(false)
	}

	const handleRemoveNoteButton = () => {
		container.removeNoteFromPatient()
		setOpenEditNoteDropdown(false)
	}

	function renderNoteBannerContent() {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', position: "relative" }} >
			<div className="m-r-s" >
				<b>{state.noteAdmin?.value?.name}</b>: {state.patientNote.value}
			</div>
			<EditFlagButton>
				<EditNoteIcon onClick={handleOpenEditNoteDropdown} />
				<div ref={ref} className={`edit-flag-button--dropdown ${openEditNoteDropdown ? "edit-flag-button--dropdown--expand" : ""}`}>
					<div onClick={handleEditNoteButton}>Edit</div>
					<div onClick={handleRemoveNoteButton}>Remove</div>
				</div>
			</EditFlagButton>
		</div>
	}

	function renderPrompts() {
		return (
			<div>
				<Prompt
					isOpen={showNoteModal}
					title="Add patient note"
					description="A patient note will be visible on all prescriptions that get assigned to this patient."
					onYes={() => {
						container.addNoteToPatient(noteValue, currentAdministrator)
						setNoteModal(false);
					}}
					onNo={() => {
						setNoteModal(false)
						setNoteValue("")
					}}
					onClose={() => {
						setNoteModal(false)
						setNoteValue("")
					}}
					yesDisabled={!noteValue || noteValue.length > 255}
				>
					<Textarea
						name="patientNote"
						placeholder="Add note..."
						value={noteValue || ""}
						onChange={(event) => setNoteValue(event.value)}
						maxLength={255}
					/>
				</Prompt>
				<Prompt
					isOpen={showUrgentPopup}
					title="Urgent patient"
					description={!isUrgentToggleChecked ?
						"Please provide a reason for why all prescriptions for this patient should be flagged as urgent." :
						"Please provide a reason for removing the urgent status for this patient"}
					onYes={async () => {
						setIsUrgentToggleDisabled(true);
						await container.setIsUrgent({
							Id: state.id.value,
							isUrgent: !state.isUrgent.value,
							adminId: currentAdministrator.id,
							comment: urgentComment.text.value
						})
						setIsUrgentToggleDisabled(false);
						setShowUrgentPopup(false);
					}}
					onNo={() => {
						setShowUrgentPopup(false)
						if (!isUrgentToggleChecked)
							setIsUrgentToggleChecked()
						urgentComment.text.value = "";
					}}
					onClose={() => setShowUrgentPopup(false)}
					yesDisabled={!urgentComment.text.value || urgentComment.text.value.length > 255}
				>
					<Textarea
						name="text"
						placeholder="Add a reason..."
						error={urgentComment.text.error}
						value={urgentComment.text.value}
						onChange={onUrgentCommentChange}
						maxLength={255}
					/>
				</Prompt>


			</div>
		)
	}

	return (
		<>
			{loadingPatientInfo ?
				<LoadingCard text={"Loading patient information"} />
				:
				<div className="card p-m">
					{renderPrompts()}
					{state.patientNote.value && <FormRow>
						<Banner type={BannerTypes.Note} text={renderNoteBannerContent()} />
					</FormRow>}
					<FormRow className='m-b-none'>
						<div className="col-7 flex-col align-i-start">
							<div className="shadow_text_2 m-b-xs" >
								PATIENT ID: {state?.id.value} |
								CLINIC ID: {state?.clinicIdNumber?.value || "N/A"} |
								CLINIC: {state?.organisationId?.value?.label || "N/A"}
							</div>
						</div>
					</FormRow>
					<FormRow className={'p-t-none'}>
						<div className='col-6'>
							<h1 className="h4">{`${state.firstName.value} ${state.lastName.value}`}</h1>
							<GenericTag className={`m-l-s ${genericTagSignupClassnameMapper[state.patientAccountType.value]}`} text={genericTagSignupTextMapper[state.patientAccountType.value]} />
							<GenericTag className={`m-l-s ${genericTagSubscriptionClassnameMapper[state.subscriptionType.value]}`} text={`Subscription: ${genericTagSubscriptionTextMapper[state.subscriptionType.value]} ${state.subscriptionType.value != SubscriptionTypes.None ? ("Tier " + state.subscriptionTier.value) : ""}`} />
						</div>
						<>
							{state.patientNote.value === "" && <Button
								className="note_button"
								onClick={() => setNoteModal(true)}
							>
								<FlagIcon />
								<span>Add note</span>
							</Button>}

							{state.patientNote.value && <Button
								className="note_button"
								onClick={() => container.removeNoteFromPatient()}
							>
								<FlagIcon />
								<span>Remove note</span>
							</Button>}
						</>
					</FormRow>

					<FormRow>
						<div className="col-2">
							<Dropdown
								value={state.title?.value}
								error={state.title.error}
								onChange={onFieldChange}
								name="title"
								label="Title"
								optional
								options={contentHelper.getTitlesAsDropdownItems()}
							/>
						</div>
						<div className="col-5">
							<TextInput
								value={state.firstName?.value}
								error={state.firstName.error}
								onChange={onFieldChange}
								name="firstName"
								label="First name"
								placeholder="e.g. John"
							/>
						</div>
						<div className="col-5">
							<TextInput
								value={state.lastName?.value}
								error={state.lastName.error}
								onChange={onFieldChange}
								name="lastName"
								label="Last name"
								placeholder="e.g. Doe"
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<TextInput
								value={state.dateOfBirth?.value}
								error={state.dateOfBirth.error}
								onChange={onFieldChange}
								name="dateOfBirth"
								label="Date of birth"
								placeholder="e.g. 25/08/1973"
								required={true}
							/>
						</div>
						<div className="col-6">
							<TextInput
								value={state.telephoneNo?.value}
								error={state.telephoneNo.error}
								onChange={onFieldChange}
								name="telephoneNo"
								label="Telephone number"
								placeholder="e.g. +00256478921"
							/>
						</div>
					</FormRow>

					<FormRow>
						<div className="col-4">
							<TextInput
								value={state.address1?.value}
								error={state.address1.error}
								onChange={onFieldChange}
								name="address1"
								label="Address 1"
								placeholder="e.g. Avenue Road 134A, Illinois"
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.address2?.value}
								error={state.address2.error}
								onChange={onFieldChange}
								name="address2"
								label="Address 2"
								placeholder="e.g. Avenue Road 134B, Illinois"
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.zipCode?.value}
								error={state.zipCode.error}
								onChange={onFieldChange}
								name="zipCode"
								label="Postal code"
								placeholder="e.g. 35492 DB"
							/>
						</div>
					</FormRow>

					<FormRow>
						<div className="col-4">
							<TextInput
								value={state.clinicIdNumber?.value}
								error={state.clinicIdNumber.error}
								onChange={onFieldChange}
								name="clinicIdNumber"
								label="clinic patient id"
								placeholder="Patient clinic id"
							/>
						</div>
						<div className="col-4">
							<CustomSelect
								key={container.state.filteredOrganisations.length}
								label="Clinic name"
								name="organisationId"
								values={state.organisationId ? [state.organisationId.value] : []}
								options={container.state.filteredOrganisations}
								placeholder={"Select clinic"}
								onChange={onSelectChange}
							/>
						</div>
						<div className="col-4" />
					</FormRow>

					{state.carer?.value && (
						<FormRow>
							<span className="rounded-info-span">
								Carer:{" "}
								<Link to={`/carers/edit/${state.carer?.value.id}`}>{state.carer?.value.firstName + " " + state.carer?.value.lastName}</Link>
							</span>
						</FormRow>
					)}
					{!state.carer?.value &&
						<>
							<FormRow>
								<h2 className="h6">Lyphe Dispensary account</h2>
							</FormRow>

							<FormRow>
								<div className="col-12">
									<TextInput
										value={state.email?.value}
										error={state.email.error}
										onChange={onFieldChange}
										name="email"
										label="Email"
										placeholder="e.g. johndoe@email.com"
									/>
								</div>
							</FormRow>

							<FormRow>
								<div className="col-4">
									<Dropdown
										name="agreedToTerms"
										value={state.agreedToTerms?.value}
										error={state.agreedToTerms.error}
										onChange={onFieldChange}
										options={contentHelper.getYesNoDropdownItems()}
										label="Agreed to terms"
										disabled={[AdminType.Regular].includes(currentAdministrator.type)}
									/>
								</div>
								<div className="col-4">
									<Dropdown
										name="isEmailVerified"
										value={state.isEmailVerified?.value}
										error={state.isEmailVerified.error}
										onChange={onFieldChange}
										options={contentHelper.getYesNoDropdownItems()}
										label="Email verified"
										disabled={[AdminType.Regular].includes(currentAdministrator.type)}
									/>
								</div>
								<div className="col-4">
									<Dropdown
										name="isIDVerified"
										value={state.isIDVerified?.value}
										error={state.isIDVerified.error}
										onChange={onFieldChange}
										options={contentHelper.getYesNoDropdownItems()}
										label="ID verified"
										disabled={[AdminType.Regular].includes(currentAdministrator.type)}
									/>
								</div>
							</FormRow>
							<FormRow>
								<h2 className="h6">Subscription</h2>
							</FormRow>
							<FormRow>
								<div className="col-3">
									<Dropdown
										name="subscriptionType"
										value={state.subscriptionType.value}
										error={state.subscriptionType.error}
										onChange={onFieldChange}
										options={contentHelper.getSubscriptionTypesAsDropdowns()}
										label="Subscription Type"
										disabled={state?.organisationId?.value?.value != TMCC_CLINIC_ID}
									/>
								</div>
								{(state.subscriptionType.value != SubscriptionTypes.None && state?.organisationId?.value?.value == TMCC_CLINIC_ID) &&
									<>
										<div className="col-3">
											<Dropdown
												name="subscriptionTier"
												value={state.subscriptionTier.value}
												error={state.subscriptionTier.error}
												onChange={onFieldChange}
												options={contentHelper.getSubscriptionTiersAsDropdowns()}
												label="Subscription Tier"
												disabled={true} //For the time being, the business made the decision to only have one tier of subscription
											/>
										</div>
										<div className="col-3">
											<TextInput value={state.subscriptionCreatedAt?.value} label="Subscribed since" placeholder="Not subscribed" disabled />
										</div>
										<div className="col-3">
											<TextInput value={state.subscriptionUpdatedAt?.value} label="Subscription updated" placeholder="Not subscribed" disabled />
										</div>
									</>
								}
							</FormRow>
							{state.subscriptionType.value != SubscriptionTypes.None && <>
								<CustomBanner
									className="banner--info"
									icon={<InfoIcon />}
									text={"Tiers are currently only limited to tier 1"}
								/>
							</>}
							{([AdminType.Super].includes(currentAdministrator.type) || env === "TESTING") &&
								<>
									<div className="col-8">
										<FormRow >
											<h2 className="h6">Flags</h2>
										</FormRow>
										<FormRow >
											<h2 className="h6">Communications</h2>
										</FormRow>
									</div>
									<div className="col-8">
										<FormRow>
											<div className='col-12'>
												<span style={{ display: 'flex', marginRight: '1rem', fontWeight: '600' }}>Urgent patient
													<CustomIconWithTooltip style={{ marginLeft: '5px' }} toolTipText={"Flagging a patient as Urgent will prioritise any prescriptions assigned to them in Active Orders"} icon={<InfoIconGray />} />
												</span>
												<Toggler isDisabled={isUrgentToggleDisabled} isChecked={isUrgentToggleChecked} onChange={() => { onUrgentToggleChange() }} />
											</div>
										</FormRow>
										<FormRow>
											<div className='col-5'>
												<span style={{ display: 'inline-block', marginRight: '1rem', fontWeight: '600' }}>Emails </span> <Toggler isDisabled={isEmailToggleDisabled} isChecked={isEmailToggleChecked} onChange={() => { onEmailToggleChange() }} />
											</div>
											<div className='col-5'>
												<span style={{ display: 'inline-block', marginRight: '1rem', fontWeight: '600' }}>Aftercare emails </span> <Toggler isDisabled={isAftercareEmailToggleDisabled} isChecked={isAftercareEmailToggleChecked} onChange={() => { onAftercareEmailToggleChange() }} />
											</div>
										</FormRow>
									</div>
								</>
							}
						</>
					}
					<FormRow>
						<div className="col-12">
							<Table
								title="Comments"
								tableData={{
									head: tableHeaders.patientComments,
									body: commentsTableData
								}}
								adjustable
								small
								bottomContent={<></>}
							/>
						</div>
					</FormRow>
					<FormRow>
						<Textarea
							name="text"
							placeholder="Add a comment"
							value={comment.text.value}
							error={comment.text.error}
							onChange={onCommentChange}
							maxLength={255}
						/>
					</FormRow>

					<FormRow>
						<Button disabled={!container.isFormValid(commentFormKey)} onClick={container.createComment}>Add Comment</Button>
					</FormRow>
					<FormRow>
						<h2 className="h6">Metadata</h2>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<TextInput value={state.secretId?.value} label="Secret" placeholder="Secret" disabled />
						</div>
						<div className="col-6">
							<TextInput value={state.secretExpiry?.value} label="Secret expiry" placeholder="Secret expiry" disabled />
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<TextInput value={state.createdAt?.value} label="Created at" placeholder="Created at" disabled />
						</div>
						<div className="col-4">
							<TextInput value={state.updatedAt?.value} label="Updated at" placeholder="Updated at" disabled />
						</div>
						<div className="col-4">
							<TextInput value={state.lastLoggedIn?.value} label="Last login" placeholder="Last login" disabled />
						</div>
					</FormRow>
					<FormRow>
						<div className="col-5">
							<TextInputWithTooltip className="t-grey" disabled value={state.secretId?.value ? `${clientUrls[env]}/verify/${state.secretId?.value}` : ""} label="Email verification link" placeholder="Email verification link" toolTip={<CustomIconWithTooltip toolTipText="For the Patient to become registered, they need to verify their Email by clicking on this link. If the link expires, select 'Update' for a new link which you can send to the patient" icon={<InfoIconGray size={16} />} />} />
						</div>
						<div className="col-5">
							<TextInputWithTooltip className="t-grey" disabled value={state.secretId?.value ? `${clientUrls[env]}/onboarding/${state.secretId?.value}` : ""} label="Onboarding link" placeholder="Onboarding link" toolTip={<CustomIconWithTooltip toolTipText="An Onboarding link is sent to patients when an account is created for them in the platform. If the link expires, you can select 'Update' for a new link which you can send to the patient." icon={<InfoIconGray size={16} />} />} />
						</div>
						<div className="col-2">
							<Button
								className="m-r-xs btn--bordered--no_radius"
								onClick={() => { container.resetPatientOnboardingLink() }}
								disabled={container.state.resettingPatientOnboardingLink}
							>
								{container.state.resettingPatientOnboardingLink ? <SpinnerIcon color={'black'} /> : "Update"}
							</Button>
						</div>
					</FormRow>

					<FormRow>
						<Table
							small
							title="Associated prescribers"
							tableData={{
								head: tableHeaders.associatedPrescribers,
								body: state.prescribers?.value,
							}}
							adjustable
						/>
					</FormRow>

					<Separator />

					<FormRow>
						<div className="col-4" />
						<Button text="Save" onClick={container.update} disabled={!container.isFormValid(formKey) || container.state.updatingPatient} />
						<div className="col-4" />
					</FormRow>
					<Modal
						isOpen={showEditCommentModal}
						onClose={() => {
							setShowEditCommentModal(false);
							container.setUpdateCommentState(currentAdministrator.id, id, -1, "")
						}}
					>
						<FormRow>
							<h2 className="h4">Edit comment</h2>
						</FormRow>
						<FormRow>
							<div className="col-6">
								<TextInput
									placeholder="Patient Id"
									label="Patient Id"
									name="patientId"
									value={currentAdministrator.id}
									disabled={true}
								/>
							</div>
							<div className="col-6">
								<TextInput
									placeholder="Admin Id"
									label="Admin Id"
									name="adminId"
									value={id}
									disabled={true}
								/>
							</div>
						</FormRow>
						<FormRow>
							<div className="col-12">
								<Textarea placeholder="Edit comment" name='text' label="Edit comment" onChange={onUpdateCommentChange} value={updateComment.text.value} />
							</div>
						</FormRow>
						<FormRow>
							<Button text="Update " onClick={onUpdateClick} disabled={!container.isFormValid(updateCommentFormKey)} />
						</FormRow>
					</Modal>
				</div>}
		</>
	);
}

function SubscribedEditPatient(props) {
	return <Subscribe to={[PatientContainer, AuthenticationContainer]}>{(container, authContainer) => <EditPatient {...props} container={container} currentAdministrator={authContainer.state.currentAdministrator} />}</Subscribe>;
}

export default SubscribedEditPatient;