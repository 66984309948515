import React from 'react'

const EditNoteIcon = ({ onClick }) => {
	return (
		<svg onClick={onClick} width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.125 8.2959C12.7463 8.2959 13.25 7.79222 13.25 7.1709C13.25 6.54958 12.7463 6.0459 12.125 6.0459C11.5037 6.0459 11 6.54958 11 7.1709C11 7.79222 11.5037 8.2959 12.125 8.2959Z" fill="#2F3EFB" stroke="#2F3EFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12.125 16.5459C12.7463 16.5459 13.25 16.0422 13.25 15.4209C13.25 14.7996 12.7463 14.2959 12.125 14.2959C11.5037 14.2959 11 14.7996 11 15.4209C11 16.0422 11.5037 16.5459 12.125 16.5459Z" fill="#2F3EFB" stroke="#2F3EFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12.125 24.7959C12.7463 24.7959 13.25 24.2922 13.25 23.6709C13.25 23.0496 12.7463 22.5459 12.125 22.5459C11.5037 22.5459 11 23.0496 11 23.6709C11 24.2922 11.5037 24.7959 12.125 24.7959Z" fill="#2F3EFB" stroke="#2F3EFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>

	)
}

export default EditNoteIcon