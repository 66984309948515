import React from "react";
import { useHistory } from "react-router-dom";
import { Subscribe } from "unstated";
import { AdminType } from "../../enums";

import AuthenticationContainer from "../../state/AuthenticationContainer";
import PrescriptionContainer from "../../state/PrescriptionContainer";
import GlobalSearchField from "../Form/GlobalSearchField";

function Header({ container, prescriptionContainer }) {
	const history = useHistory();

	const handleOnSearchChange = (e) => {
		if (e.target.value.length >= 1) {
			prescriptionContainer.quickSearch(e.target.value)
		}
	}
	const searchListOptions = prescriptionContainer.state.searchListOptions;

	const navigateToPrescription = (o) => {
		history.push(`/prescriptions/edit/${o.parameter}`)
	}
	const navigateToPatient = (o) => {
		history.push(`/patients/edit/${o.parameter}`)
	}

	const renderEmpty = () => {

		return (prescriptionContainer.state.noResultsFromGlobalSearch
		) ?
			<div className="global_search_field__options__section_title">
				No results
			</div>
			:
			<></>
	}

	const renderOptions = (options, sectionTitle, onClick = () => null) => {
		return <>
			{options.length ?
				<>
					<div className="global_search_field__options__section_title">
						{sectionTitle}
					</div>
					{
						options.map((o, i) => {
							return <div key={i} onClick={() => onClick(o)} className={o.isSelected ? "global_search_field__options__option active" : "global_search_field__options__option"}>
								{o.text}
							</div>
						})
					}
				</>
				:
				<></>
			}
		</>
	}


	const searchOptions = () => {
		return <>
			{renderEmpty()}

			{renderOptions(searchListOptions.prescriptionNumbers, "Prescription Number", navigateToPrescription)}
			{renderOptions(searchListOptions.prescriptionOrdersIds, "Order Id", navigateToPrescription)}
			{renderOptions(searchListOptions.prescriptionIds, "Prescription Id", navigateToPrescription)}
			{renderOptions(searchListOptions.patientClinicIds, "Patient Clinic Id", navigateToPatient)}
			{renderOptions(searchListOptions.patientIds, "Patient Id", navigateToPatient)}
			{renderOptions(searchListOptions.patients, "Patients", navigateToPatient)}
			{renderOptions(searchListOptions.patientTelephoneNumber, "Patient Telephone Number", navigateToPatient)}
		</>
	}

	return (
		<header>
			<span className="breadcrumbs">
				&nbsp;
			</span>
			{container.state.currentAdministrator !== AdminType.Eastone ?
				<GlobalSearchField loading={prescriptionContainer.state.loadingGlobalSearch} onChange={handleOnSearchChange} Options={searchOptions} />
				:
				<></>
			}
			<div className="account-actions-container">
				<span className="active-administrator-name">{container.state.currentAdministrator.name} </span>

				<div className="account-navigation">
					<nav>
						<span onClick={() => history.push("/profile")}>Profile</span>
						<span onClick={() => container.signout()}>Sign out</span>
					</nav>
				</div>
			</div>
		</header>
	);
}

function SubscribedHeader(props) {
	return <Subscribe to={[AuthenticationContainer, PrescriptionContainer]}>{(container, prescriptionContainer) => <Header {...props} container={container} prescriptionContainer={prescriptionContainer} />}</Subscribe>;
}

export default SubscribedHeader;
