import React from 'react'

const GenericTag = ({ text, className }) => {
    return (
        <div className={`generic_tag ${className}`}>
            {text}
        </div>
    )
}

export default GenericTag
