import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import PasswordInput from "../../components/Form/PasswordInput";
import TextInput from "../../components/Form/TextInput";
import FormRow from "../../components/Form/FormRow";

import Prompt from "../../components/Modal/Prompt";

import AdministratorContainer from "../../state/AdministratorContainer";
import { SpinnerIcon } from "../../assets/icons";
import Table from "../../components/Table/Table";
import tableHeaders from "../../util/constants/tableHeaders";
import SquareCheckbox from "../../components/Form/SquareCheckbox";
import LoadingCard from "../../components/SharedComponents/LoadingCard";

function EditAdministrator({ container }) {
	// State-related data
	const formKey = "updateAdministrator";
	const state = container.state[formKey];
	const onFieldChange = (event) => container.onFormChange(event, formKey);

	// Hook-related data
	const { id } = useParams();
	const [showPrompt, setPromptVisibility] = useState(false);

	const loadingAdminInfo = container.state.updatingAdmin

	useEffect(() => {
		container.setForUpdate(id);

		return container.resetUpdateAdministratorState;
	}, [id, container]);

	return (
		<>
			{loadingAdminInfo ?
				<LoadingCard text={"Loading administrator information"} />
				:
				<div className="card p-m">
					<Prompt
						isOpen={showPrompt}
						title="Are you sure you want to delete this administrator?"
						description="This action will permanently remove all their data. However, their past actions will remain associated with their account."
						onYes={() => {
							container.delete(id);
							setPromptVisibility(false);
						}}
						onNo={() => setPromptVisibility(false)}
						onClose={() => setPromptVisibility(false)}
					/>

					<FormRow>
						<h1 className="h4">Personal info</h1>
					</FormRow>

					<FormRow>
						<div className="col-6">
							<TextInput
								name="name"
								value={state.name.value}
								error={state.name.error}
								onChange={onFieldChange}
								label="Full name"
								placeholder="e.g. John Doe"
							/>
						</div>
						<div className="col-6">
							<TextInput
								name="email"
								value={state.email.value}
								error={state.email.error}
								onChange={onFieldChange}
								label="Email"
								placeholder="e.g. johndoe@email.com"
							/>
						</div>
					</FormRow>

					<FormRow>
						<h2 className="h6">Password</h2>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<PasswordInput name="password" error={state.password.error} onChange={onFieldChange} label="Password" placeholder="Password" />
						</div>
						<div className="col-6">
							<PasswordInput
								name="repeatPassword"
								error={state.repeatPassword.error}
								onChange={onFieldChange}
								label="Repeat password"
								placeholder="Repeat password"
							/>
						</div>
					</FormRow>

					<FormRow>
						<h2 className="h6">Permissions</h2>
					</FormRow>
					<FormRow>
						<SquareCheckbox
							name="canAccessUserData"
							value={state.canAccessUserData.value}
							onChange={onFieldChange}
							label="Can access User data (patients, carers, doctors)"
							className="bold_text"
						/>
					</FormRow>
					<FormRow>
						<SquareCheckbox
							name="canAccessAdministratorData"
							value={state.canAccessAdministratorData.value}
							onChange={onFieldChange}
							label="Can access Administrator data and edit Product data"
							className="bold_text"
						/>
					</FormRow>

					<FormRow>
						<h2 className="h6">Metadata</h2>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<TextInput value={state.createdAt.value} label="Created at" placeholder="e.g. 20/10/2020 19:47" disabled={true} />
						</div>
						<div className="col-4">
							<TextInput value={state.updatedAt.value} label="Updated at" placeholder="e.g. 20/10/2020 19:47" disabled={true} />
						</div>
						<div className="col-4">
							<TextInput value={state.lastLoggedIn.value} label="Last login" placeholder="e.g. 20/10/2020 19:47" disabled={true} />
						</div>
					</FormRow>

					<FormRow>
						<div className="col-12">
							<Table
								title="Event history"
								tableData={{
									head: tableHeaders.eventHistory,
									body: state.eventHistory,
								}}
								small
								showSpinner={container.state.loadingEventHistory}
							/>
						</div>
					</FormRow>

					<FormRow>
						<Button onClick={container.update} disabled={!container.isFormValid(formKey) || container.state.updatingAdmin} >
							{container.state.updatingAdmin ? <SpinnerIcon /> : "Save"}
						</Button>
						<Button text="Delete" onClick={() => setPromptVisibility(true)} />
					</FormRow>
				</div>}
		</>
	);
}

function SubscribedEditAdministrator(props) {
	return <Subscribe to={[AdministratorContainer]}>{(container) => <EditAdministrator {...props} container={container} />}</Subscribe>;
}

export default SubscribedEditAdministrator;
