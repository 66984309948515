/*eslint-disable eqeqeq*/
import React, { useEffect } from "react";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import Dropdown from "../../components/Form/Dropdown";
import Checkbox from "../../components/Form/Checkbox";
import Textarea from "../../components/Form/Textarea";
import TextInput from "../../components/Form/TextInput";
import NumberInput from "../../components/Form/NumberInput";
import FormRow from "../../components/Form/FormRow";

import ProductContainer from "../../state/ProductContainer";
import { ProductTypes } from "../../enums";
import contentHelper from "../../util/helpers/contentHelper";
import { SpinnerIcon } from "../../assets/icons";
import ImageDragAndDrop from "../../components/ImageDragAndDrop/ImageDragAndDrop";
import DocumentDragAndDrop from "../../components/DocumentDragAndDrop/DocumentDragAndDrop";
import { useState } from "react";

import DateInput from "../../components/Form/DateInput";
import CustomSelect from "../../components/Form/CustomSelect";
import RemoveLinkedOrganisationIcon from "../../assets/icons/RemoveLinkedOrganisationIcon";

function NewProduct({ container }) {
	// State-related data
	const formKey = "newProduct";
	const state = container.state[formKey];
	const suppliers = container.state.suppliers
	const onFieldChange = (event) => container.onFormChange(event, formKey);
	const onTerpeneSelectChange = (event, index) => container.onTerpeneSelectChange(event, index);
	const onRemoveTerpene = (index) => container.onRemoveTerpene(index);

	const [isPercentageSelected, setIsPercentageSelected] = useState(true)
	const [isMajorMinorSelected, setIsMajorMinorSelected] = useState()

	const [images, setImages] = useState([]);
	const [documents, setDocuments] = useState([]);

	const createProduct = () => {
		state.images = images
		state.documents = documents
		container.create()
	}

	const onDeleteImage = (index) => {
		setImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
	};

	const onDeleteDocument = (index) => {
		setDocuments((prevDocuments) => {
			const newDocuments = [...prevDocuments];
			newDocuments.splice(index, 1);
			return newDocuments;
		});
	};

	useEffect(() => {
		container.getAllSuppliers(formKey);
		container._getTerpenes();
	}, [])


	const fieldHeaderSign = state.type.value === ProductTypes.Oil ? "mg/ml" : "%";

	return (
		<div className="card p-m">
			<FormRow>
				<h1 className="h4">New product</h1>
			</FormRow>

			<FormRow>
				<h2 className="h6">Product info</h2>
			</FormRow>

			<FormRow>
				<div className="col-2">
					<TextInput
						name="sku"
						value={state.sku.value}
						error={state.sku.error}
						onChange={onFieldChange}
						label="sku"
						placeholder="e.g. 402384901123"
					/>
				</div>
				<div className="col-4">
					<TextInput
						name="name"
						value={state.name.value}
						error={state.name.error}
						onChange={onFieldChange}
						label="Name"
						placeholder="e.g. High potency CBD oil"
					/>
				</div>
				<div className="col-2">
					<Dropdown
						name="type"
						value={state.type.value}
						error={state.type.error}
						onChange={onFieldChange}
						label="Type"
						options={contentHelper.getProductTypesAsDropdownItems()}
					/>
				</div>
				<div className="col-2">
					<Dropdown
						name="subtype"
						value={state.subtype.value}
						error={state.subtype.error}
						onChange={onFieldChange}
						label="Subtype"
						options={contentHelper.getProductSubtypesAsDropdownItems()}
					/>
				</div>
				<div className="col-2">
					<Dropdown
						name="phEur"
						value={state.phEur.value}
						error={state.phEur.error}
						onChange={onFieldChange}
						label="Ph. Euro"
						options={contentHelper.getProductPhEurAsDropdownItems()}
					/>
				</div>
			</FormRow>
			<FormRow>
				<div className="col-12">
					<Textarea
						name="description"
						value={state.description.value}
						error={state.description.error}
						onChange={onFieldChange}
						label="Description"
						placeholder="Type in product description here..."
					/>
				</div>
			</FormRow>
			<FormRow>
				<div className="col-2">
					<TextInput
						name="activeIngredient"
						value={state.activeIngredient.value}
						error={state.activeIngredient.error}
						onChange={onFieldChange}
						label="Active ingredient"
						placeholder="e.g. CBD"
					/>
				</div>
				<div className="col-2">
					<NumberInput
						name="weight"
						value={state.weight.value}
						error={state.weight.error}
						onChange={onFieldChange}
						label="Product Weight"
						placeholder="e.g. 12 (g)"
					/>
				</div>
				<div className="col-2">
					<Dropdown
						name="productUnit"
						value={state.productUnit.value}
						error={state.productUnit.error}
						onChange={onFieldChange}
						label="Unit"
						options={[
							{ text: "None", value: 0 },
							{ text: "mg", value: 1 },
							{ text: "g", value: 2 },
							{ text: "ml", value: 3 },
						]}
					/>
				</div>
				<div className="col-2">
					<NumberInput
						name="containerWeight"
						value={state.containerWeight.value}
						error={state.containerWeight.error}
						onChange={onFieldChange}
						label="Product & Container Weight"
						placeholder="e.g. 12 (g)"
					/>
				</div>
				<div className="col-2">
					<Dropdown
						name="irradiatedType"
						value={state.irradiatedType.value}
						error={state.irradiatedType.error}
						onChange={onFieldChange}
						label="Is Irradiated"
						options={[
							{ text: "Non Irradiated ", value: 0 },
							{ text: "Beta Irradiated", value: 1 },
							{ text: "Gamma Irradiated", value: 2 },
						]}
					/>
				</div>
				<div className="col-2">
					{+state.type.value === ProductTypes.Flower &&
						(
							<Dropdown
								name="isPotContainer"
								value={state.isPotContainer.value}
								error={state.isPotContainer.error}
								onChange={onFieldChange}
								label="Container Type"
								options={[
									{ text: "Pouch", value: false },
									{ text: "Pot", value: true },
								]}
							/>
						)}
				</div>
			</FormRow>
			<FormRow>
				<div className="col-6">
					<Checkbox
						name="thcLessThen"
						value={state.thcLessThen.value}
						error={state.thc.error}
						onChange={onFieldChange}
						label={`<`}
						right={true}
					/>
					<NumberInput
						name="thc"
						value={state.thc.value}
						error={state.thc.error}
						onChange={onFieldChange}
						label={`THC (${fieldHeaderSign})`}
						placeholder="e.g. 4"
					/>
				</div>
				<div className="col-6">
					<Checkbox
						name="cbdLessThen"
						value={state.cbdLessThen.value}
						error={state.thc.error}
						onChange={onFieldChange}
						label={`<`}
						right={true}
					/>
					<NumberInput
						name="cbd"
						value={state.cbd.value}
						error={state.cbd.error}
						onChange={onFieldChange}
						label={`CBD (${fieldHeaderSign})`}
						placeholder="e.g. 22"
					/>
				</div>
			</FormRow>
			<FormRow>
				<div className="col-6">
					<NumberInput
						name="retailPrice"
						value={state.retailPrice.value}
						error={state.retailPrice.error}
						onChange={onFieldChange}
						label="Retail price (£)"
						placeholder="e.g. 18.99"
					/>
				</div>
				<div className="col-6">
					<NumberInput
						name="wholesalePrice"
						value={state.wholesalePrice.value}
						error={state.wholesalePrice.error}
						onChange={onFieldChange}
						label="Wholesale price (£)"
						placeholder="e.g. 18.99"
					/>
				</div>
			</FormRow>

			<FormRow>
				<div className="col-2">
					<Dropdown
						name="isT21Eligible"
						value={state.isT21Eligible.value}
						error={state.isT21Eligible.error}
						onChange={onFieldChange}
						label="Is T21 Eligble"
						options={[
							{ text: "No", value: false },
							{ text: "Yes", value: true },
						]}
						disabled={state.isSubsidyEligible.value}
					/>
				</div>
				<div className="col-2">
					<Dropdown
						name="isSubsidyEligible"
						value={state.isSubsidyEligible.value}
						error={state.isSubsidyEligible.error}
						onChange={onFieldChange}
						label="Subsidy Eligible"
						options={[
							{ text: "No", value: false },
							{ text: "Yes", value: true },
						]}
						disabled={state.isT21Eligible.value}
					/>
				</div>
				<div className="col-2">
					{state.isT21Eligible.value && (
						<Dropdown
							name="isMixAndMatchEligible"
							value={state.isMixAndMatchEligible.value}
							error={state.isMixAndMatchEligible.error}
							onChange={onFieldChange}
							label="Is MixAndMatch Eligible"
							options={[
								{ text: "No", value: false },
								{ text: "Yes", value: true },
							]}
						/>
					)}
				</div>
				<div className="col-2">
					{state.isT21Eligible.value && (
						<NumberInput
							name="t21BasePrice"
							value={state.t21BasePrice.value}
							error={state.t21BasePrice.error}
							onChange={onFieldChange}
							label="T21 Base Price"
							placeholder="e.g 65"
						/>
					)}
				</div>
				<div className="col-2">
					{state.isT21Eligible.value && (
						<NumberInput
							name="t21FirstQuantityCap"
							value={state.t21FirstQuantityCap.value}
							error={state.t21FirstQuantityCap.error}
							onChange={onFieldChange}
							label="T21 First Quantity Cap"
							placeholder="e.g 2"
						/>
					)}
				</div>
				<div className="col-2">
					{state.isT21Eligible.value && (
						<NumberInput
							name="t21FirstQuantityCapPrice"
							value={state.t21FirstQuantityCapPrice.value}
							error={state.t21FirstQuantityCapPrice.error}
							onChange={onFieldChange}
							label="T21 First Quantity Cap Price"
							placeholder="e.g 120"
						/>
					)}
				</div>
			</FormRow>
			{state.isSubsidyEligible.value && (
				<FormRow>
					<div className="col-4">
						<NumberInput
							name="t21SubsidyPrice"
							value={state.t21SubsidyPrice.value}
							error={state.t21SubsidyPrice.error}
							onChange={onFieldChange}
							label="T21 Subsidy Price (£)"
							placeholder="e.g 3"
						/>
					</div>
				</FormRow>
			)}
			{state.isT21Eligible.value && (
				<FormRow>
					<div className="col-4">
						<NumberInput
							name="t21SecondQuantityCap"
							value={state.t21SecondQuantityCap.value}
							error={state.t21SecondQuantityCap.error}
							onChange={onFieldChange}
							label="T21 Second Quantity Cap"
							placeholder="e.g 3"
						/>
					</div>
					<div className="col-4">
						<NumberInput
							name="t21SecondQuantityCapPrice"
							value={state.t21SecondQuantityCapPrice.value}
							error={state.t21SecondQuantityCapPrice.error}
							onChange={onFieldChange}
							label="T21 Second Quantity Cap Price"
							placeholder="e.g 150"
						/>
					</div>
					<div className="col-4">
						<NumberInput
							name="t21AdditionalAmountPrice"
							value={state.t21AdditionalAmountPrice.value}
							error={state.t21AdditionalAmountPrice.error}
							onChange={onFieldChange}
							label="T21 Additional Amount Price"
							placeholder="e.g 150"
						/>
					</div>
				</FormRow>
			)}
			<FormRow>
				<h2 className="h6">Subscription pricing</h2>
			</FormRow>
			<FormRow>
				<div className="col-3">
					<NumberInput
						name="allPatientsT1Price"
						value={state.allPatientsT1Price.value}
						error={state.allPatientsT1Price.error}
						onChange={onFieldChange}
						label="All patients Tier 1 Price (£)"
						placeholder="e.g. 18.99"
					/>
				</div>
				<div className="col-3">
					<NumberInput
						name="allPatientsT2Price"
						value={state.allPatientsT2Price.value}
						error={state.allPatientsT2Price.error}
						onChange={onFieldChange}
						label="All patients Tier 2 Price (£)"
						placeholder="e.g. 18.99"
					/>
				</div>
				<div className="col-3">
					<NumberInput
						name="accessSchemeT1Price"
						value={state.accessSchemeT1Price.value}
						error={state.accessSchemeT1Price.error}
						onChange={onFieldChange}
						label="Access scheme patients Tier 1 Price (£)"
						placeholder="e.g. 18.99"
					/>
				</div>
				<div className="col-3">
					<NumberInput
						name="accessSchemeT2Price"
						value={state.accessSchemeT2Price.value}
						error={state.accessSchemeT2Price.error}
						onChange={onFieldChange}
						label="Access scheme patients Tier 2 Price (£)"
						placeholder="e.g. 18.99"
					/>
				</div>
			</FormRow>
			<FormRow>
				<h2 className="h6">Terpene profile</h2>
			</FormRow>
			<FormRow>
				<div className="col-6">
					<div style={{ marginRight: "50px" }}>
						<Checkbox
							name="percent"
							value={isPercentageSelected}
							onChange={() => { setIsPercentageSelected(true); setIsMajorMinorSelected(false); container.setProductTerpeneProfileType(0) }}
							label={`%`}
							right={false}
						/></div>
					<Checkbox
						name="major_minor"
						value={isMajorMinorSelected}
						onChange={() => { setIsPercentageSelected(false); setIsMajorMinorSelected(true); container.setProductTerpeneProfileType(1) }}
						label={`Major/Minor`}
						right={false}
					/>
				</div>
			</FormRow>

			{state.terpeneIds.value.map((terpene, index) => {
				return <FormRow className="j-c-s" key={container.state.terpenes.length + index}>
					<div className="col-4">
						<CustomSelect
							label="Select terpene"
							name="terpeneSelect"
							values={[]}
							options={container.state.terpenes}
							onChange={(event) => { onTerpeneSelectChange(event, index) }}
							placeholder={"Search and select..."}
						/>
					</div>
					{isMajorMinorSelected && <div style={{ marginLeft: "20px" }}>
						<CustomSelect
							name="majorMinor"
							value={[terpene.majorMinor.value]}
							onChange={(e) => container.onTerpineMajorMinorChange(e, index)}
							label="Value"
							options={[
								{ label: "Minor", value: 0 },
								{ label: "Major", value: 1 },
							]}
						/>
					</div>}
					{isPercentageSelected && <div style={{ marginLeft: "20px", width: "9%" }}>
						<NumberInput
							name="percentage"
							value={terpene.percentage.value}
							error={terpene.percentage.error}
							onChange={(e) => { container.onTerpinePercentageChange(e, index) }}
							label="Value %"
							placeholder="e.g."
						/>
					</div>}
					{state.terpeneIds.value.length > 1 &&
						<Button
							className="remove_list_item"
							onClick={() => { onRemoveTerpene(index) }}
						>
							<RemoveLinkedOrganisationIcon></RemoveLinkedOrganisationIcon>
						</Button>
					}
				</FormRow>
			}
			)}
			{state.terpeneIds.value.length < 15 &&
				<FormRow>
					<div onClick={() => { container.addTerpene() }} className="add_list_element_button">
						<i className="icon-plus" />
						<p className="uppercase m-l-8">ADD TERPENE</p>
					</div>
				</FormRow>
			}

			<FormRow>
				<h2 className="h6">Availability</h2>
			</FormRow>

			<FormRow className="j-c-s" style={{ alignItems: "center" }}>
				<div className="col-2">
					<Dropdown
						name="availability"
						value={state.availability.value}
						error={state.availability.error}
						onChange={onFieldChange}
						label="supplier status"
						options={[
							{ text: "Available", value: 0 },
							{ text: "Unavailable", value: 1 },
							{ text: "Discontinued", value: 2 },
						]}
					/>
				</div>
				<div className="col-2">
					<DateInput
						value={state.expiryDate?.value}
						error={state.expiryDate?.error}
						onChange={onFieldChange}
						name="expiryDate"
						label="Expiry Date"
						placeholder="e.g. 10/09/2020">
					</DateInput>
				</div>
			</FormRow>
			<FormRow>
				<h2 className="h6">Manufacturing information</h2>
			</FormRow>
			<FormRow>
				<div className="col-4">
					<Dropdown
						name="supplier"
						value={state.supplier.value}
						error={state.supplier.error}
						onChange={onFieldChange}
						label="supplier"
						options={suppliers}
					/>
				</div>
			</FormRow>
			<FormRow>
				<div className="col-3">
					<TextInput
						name="manufacturer"
						value={state.manufacturer.value}
						error={state.manufacturer.error}
						onChange={onFieldChange}
						label="Manufacturer"
						placeholder="e.g. Some manufacturer"
					/>
				</div>
			</FormRow>
			<FormRow>
				<div className="col-2">
					<TextInput
						name="country"
						value={state.country.value}
						error={state.country.error}
						onChange={onFieldChange}
						label="Country"
						placeholder="e.g. GB"
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Product photos</h2>
			</FormRow>

			<FormRow>
				<ImageDragAndDrop onItemDelete={(index) => onDeleteImage(index)} images={images} setImages={setImages} />
			</FormRow>

			<FormRow>
				<h2 className="h6">Documentation</h2>
			</FormRow>

			<FormRow>
				<DocumentDragAndDrop onItemDelete={(index) => onDeleteDocument(index)} documents={documents} setDocuments={setDocuments} />
			</FormRow>

			<FormRow>
				<Button onClick={createProduct} disabled={!container.isFormValid(formKey) || container.state.creatingProduct} >
					{container.state.creatingProduct ? <SpinnerIcon /> : "Create Product"}
				</Button>
			</FormRow>
		</div>
	);
}

function SubscribedNewProduct(props) {
	return <Subscribe to={[ProductContainer]}>{(container) => <NewProduct {...props} container={container} />}</Subscribe>;
}

export default SubscribedNewProduct;
