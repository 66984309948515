import React, { useLayoutEffect, useRef } from "react";

function Textarea({
    value = '',
    name = '',
    label = '',
    disabled = false,
    placeholder = 'Textarea',
    error = false,
    onKeyUp = () => null,
    onSubmit = () => null,
    onChange = () => null,
    maxLength = null,
}) {
    function onKeyUpHandler(e) {
        onKeyUp(e);

        if (e.key === 'Enter')
            onSubmit();
    }

    const input = useRef();
    const previous = useRef(null);

    const onChangeHandler = (e) => {
        onChange({ name, value: e.target.value });
        previous.current = e.target.selectionStart;
    }

    useLayoutEffect(() => {
        if (previous.current) {
            input.current.setSelectionRange(previous.current, previous.current);
        }
    }, [value])

    const isAboveCharacterLimit = maxLength < value.length;
    return (
        <div style={{ width: '100%' }} className="d-flex flex-col " >
            <div className="textarea-container">
                <textarea
                    ref={input}
                    className={error ? "error" : ""}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    onKeyUp={onKeyUpHandler}
                    onChange={e => onChangeHandler(e)}
                />
                <label className="component-label" >{error ? error : label}</label>
                <div className="animated-border" />
            </div>
            {maxLength &&
                <div
                    className={`textarea-counter ${isAboveCharacterLimit ? "textarea-counter--error" : ""}`}>
                    {isAboveCharacterLimit && "Character limit reached!"} {value.length}/{maxLength}
                </div>
            }
        </div>
    );
}

export default Textarea;