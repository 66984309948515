import {
	AccountType,
	OrderState,
	OrderStatuses,
	OrderStatusToProductLabelMapper,
	ProductAvailability,
	ProductUnitMapper,
	ShippingStatus,
	StatusStringsToOrderStatusesMapper,
	StockCheckStatus,
} from "../enums";
import { formatDate, formatDateForPresenting } from "../util/dates";
import moment from "moment";

//#region Multiple prescriptions

export function prescriptionsApiToClient(prescriptions) {
	const result = [];
	for (let prescription of prescriptions) {
		result.push(convertPrescription(prescription));
	}

	return result;
}

function convertPrescription(prescription) {

	const result = {
		id: prescription.id,
		number: prescription.paperPrescriptionId,
		patient: prescription.patient,
		submittedBy: AccountType[prescription.submitterAccountType],
		isPaperPrescriptionReceived: prescription.isPaperPrescriptionReceived,
		archivedReason: prescription.archivedReason,
		flaggedReason: prescription.flaggedReason,
		rewriteReason: prescription.rewriteReason,
		archivedDateTime: prescription.archivedDateTime,
		flaggedDateTime: prescription.flaggedDateTime,
		flaggedByAdminId: prescription.flaggedByAdminId,
		adminFlaggedBy: prescription.adminFlaggedBy,
		isFlagged: prescription.isFlagged,
		isUrgent: prescription.isUrgent,
		awaitingRewrite: prescription.awaitingRewrite,
		rewriteChildPrescriptionId: prescription.rewriteChildPrescriptionId,
		rewriteParentPrescriptionId: prescription.rewriteParentPrescriptionId,
		rewriteDateTime: prescription.rewriteDateTime,
		expiryDate: formatDate(prescription.expirationDateTime, false),
		createdAt: formatDate(prescription.createdAt),
		dateReceived: formatDate(prescription.dateReceived, false),
		isPaymentRequested: isPaymentRequested(prescription.orders),
		dateCompleted: prescription.dateCompleted,
		adminOwner: prescription.adminOwner,
		organisation: prescription.organisation,
		orders: prescription.orders
	};

	if (!prescription.orders.length && prescription.isArchived) {
		result.orderStatuses = [OrderStatuses.Archived];
	} else {
		result.orderStatuses = getOrderStatusesInPrescription(prescription);
	}

	const prescriber = getPrescriberFromPrescription(prescription.organisationEmployee, prescription.prescriber);
	if (prescriber) result.prescriber = prescriber;

	const carer = getCarerFromPrescription(prescription.patient);
	if (carer) result.carer = carer;

	return result;
}

/**
 * Checks if a payment has been requested for any of
 * the orders in the prescription.
 *
 * @param {*} orders
 */
function isPaymentRequested(orders) {
	const doesAtLeastOneOrderHavePaymentRequest = orders.find((order) => order.isPaymentRequested);

	return doesAtLeastOneOrderHavePaymentRequest ? true : false;
}

function getOrderStatusesInPrescription(prescription) {
	const doesPrescriptionHaveOrders = prescription.orders.length > 0;
	const isExpired = isPrescriptionExpired(prescription);

	let orderStatuses = [];

	if (doesPrescriptionHaveOrders) {
		orderStatuses = prescription.orders.map((order) => getOrderStatus(prescription, order));
	} else if (!doesPrescriptionHaveOrders && isExpired) {
		orderStatuses = [OrderStatuses.Void];
	} else if (!doesPrescriptionHaveOrders && !isExpired) {
		orderStatuses = [OrderStatuses.NotProcessed];
	}

	return orderStatuses;
}

function getPrescriberFromPrescription(organisationEmployee, prescriber) {
	if (organisationEmployee)
		return {
			id: organisationEmployee.id,
			firstName: organisationEmployee.firstName,
			lastName: organisationEmployee.lastName,
			prescriberId: prescriber?.id
		};
}

function getCarerFromPrescription(patient) {
	if (patient?.carer)
		return {
			id: patient.carer.id,
			firstName: patient.carer.firstName,
			lastName: patient.carer.lastName,
		};
}

//#endregion

//#region Unassigned prescriptions
export const unassignedPrescriptionsApiToClient = (serverData) => {
	const sortedPrescriptionsNewestToOldest = serverData.sort((a, b) => {
		return moment(b.createdAt) - moment(a.createdAt)
	})
	return sortedPrescriptionsNewestToOldest.map(prescription => {
		return {
			trackingNumber: prescription.envelopeTrackingCode,
			createdAt: moment(prescription.createdAt).format("DD/MM/YY"),
			id: prescription.id,
			filePath: prescription.filePath,
			prescriber: prescription.organisationEmployee.firstName + ' ' + prescription.organisationEmployee.lastName,
			organisationName: prescription.organisation.name,
			prescriberId: prescription.organisationEmployee.id,
			patient: prescription.patient,
			archivedReason: prescription.archivedReason,
			flaggedByAdminId: prescription.flaggedByAdminId,
			rewriteReason: prescription.rewriteReason,
			archivedDateTime: prescription.archivedDateTime,
			rewriteDateTime: prescription.rewriteDateTime,
			awaitingRewrite: prescription.awaitingRewrite,
			rewriteChildPrescriptionId: prescription.rewriteChildPrescriptionId,
			isFlagged: prescription.isFlagged,
			isUrgent: prescription.isUrgent,
			flaggedDateTime: prescription.flaggedDateTime,
			flaggedReason: prescription.flaggedReason,
			adminFlaggedBy: prescription.adminFlaggedBy,
		}
	});
}
//#endregion

// #region Awaiting rewrite prescriptions
export const awaitingRewritePrescriptionsApiToClient = (serverData) => {
	const prescriptions = serverData.map(prescription => {
		return {
			trackingNumber: prescription.envelopeTrackingCode,
			createdAt: moment(prescription.createdAt).format("DD/MM/YY"),
			id: prescription.id,
			filePath: prescription.filePath,
			prescriber: prescription.organisationEmployee.firstName + ' ' + prescription.organisationEmployee.lastName,
			organisationName: prescription.organisation.name,
			prescriberId: prescription.organisationEmployee.id,
			patient: prescription.patient,
			archivedReason: prescription.archivedReason,
			rewriteReason: prescription.rewriteReason,
			archivedDateTime: prescription.archivedDateTime,
			rewriteDateTime: prescription.rewriteDateTime,
			awaitingRewrite: prescription.awaitingRewrite,
			rewriteChildPrescriptionId: prescription.rewriteChildPrescriptionId,
			isFlagged: prescription.isFlagged,
			isUrgent: prescription.isUrgent,
			flaggedDateTime: prescription.flaggedDateTime,
			flaggedReason: prescription.flaggedReason,
			adminFlaggedBy: prescription.adminFlaggedBy,
			paperPrescriptionId: prescription.paperPrescriptionId,
		}
	})
	prescriptions.sort((a, b) => {
		return moment(a.rewriteDateTime || "11/10/1970") - moment(b.rewriteDateTime || "11/10/1970")
	});

	return prescriptions;
}
//#endregion

export const futureDatedScriptsApiToClient = (serverData) => {
	const prescriptions = serverData.map(prescription => {
		return {
			trackingNumber: prescription.envelopeTrackingCode,
			createdAt: moment(prescription.createdAt).format("DD/MM/YY"),
			id: prescription.id,
			filePath: prescription.filePath,
			prescriber: prescription.organisationEmployee.firstName + ' ' + prescription.organisationEmployee.lastName,
			organisationName: prescription.organisation.name,
			prescriberId: prescription.organisationEmployee.id,
			patient: prescription.patient,
			archivedReason: prescription.archivedReason,
			rewriteReason: prescription.rewriteReason,
			archivedDateTime: prescription.archivedDateTime,
			rewriteDateTime: prescription.rewriteDateTime,
			awaitingRewrite: prescription.awaitingRewrite,
			rewriteChildPrescriptionId: prescription.rewriteChildPrescriptionId,
			isFlagged: prescription.isFlagged,
			flaggedDateTime: prescription.flaggedDateTime,
			flaggedReason: prescription.flaggedReason,
			isUrgent: prescription.isUrgent,
			adminFlaggedBy: prescription.adminFlaggedBy,
			paperPrescriptionId: prescription.paperPrescriptionId,
			prescribedDateTime: prescription.prescribedDateTime,
		}
	})
	prescriptions.sort((a, b) => {
		return moment(a.prescribedDateTime || "11/10/1970") - moment(b.prescribedDateTime || "11/10/1970")
	});

	return prescriptions;
}

//#region Single prescription

export function prescriptionApiToClient(prescription) {

	const result = {
		id: prescription.id,
		prescriptionNumber: prescription.paperPrescriptionId,
		imageUrl: prescription.filePath,
		envelopeTrackingCode: prescription.envelopeTrackingCode,

		patient: {
			...prescription.patient,
			dateOfBirth: formatDate(prescription.patient?.dateOfBirth, false),
		},

		adminFlaggedBy: {
			...prescription.adminFlaggedBy
		},

		submittedBy: AccountType[prescription.submitterAccountType],

		createdAt: formatDateForPresenting(prescription.createdAt, false),
		expiryDate: formatDate(prescription.expirationDateTime, false),
		prescribedDateTime: formatDate(prescription.prescribedDateTime, false),
		dateReceived: formatDate(prescription.dateReceived, false),
		dateCompleted: formatDate(prescription.dateCompleted, false),
		archivedDateTime: formatDate(prescription.archivedDateTime, false),

		isPaperCopyReceived: prescription.isPaperPrescriptionReceived,
		isT21Patient: prescription.isT21Patient,
		isUrgent: prescription.isUrgent,
		isProcessed: prescription.isProcessed,
		isArchived: prescription.isArchived,
		isFlagged: prescription.isFlagged,
		isCompleted: prescription.isCompleted,

		archivedReason: prescription.archivedReason,
		flaggedReason: prescription.flaggedReason,
		flaggedByAdminId: prescription.flaggedByAdminId,
		flaggedDateTime: prescription.flaggedDateTime,
		rewriteReason: prescription.rewriteReason,
		awaitingRewrite: prescription.awaitingRewrite,
		rewriteDateTime: prescription.rewriteDateTime,
		rewriteChildPrescriptionId: prescription.rewriteChildPrescriptionId,
		rewriteParentPrescriptionId: prescription.rewriteParentPrescriptionId,

		doesPrescriberHaveAccount: prescription.organisationEmployee?.user?.email ? true : false,
		doesPatientHaveAccount: prescription.patient?.user?.email ? true : false,
		doesCarerHaveAccount: prescription.patient?.carerId ? true : false,
		organisation: prescription.organisation,
		adminOwner: prescription.adminOwner,

		tempPatient: prescription.tempPatient,

		orders: [],
	};

	const prescriber = getPrescriberFromPrescription(prescription.organisationEmployee, prescription.prescriber);
	if (prescriber) result.prescriber = prescriber;

	result.orders = getOrdersFromPrescription(prescription);

	result.canAddProducts = prescription.canAddProducts;

	return result;
}

export function prescriptionsToSearchList(prescriptions) {

	if (!prescriptions) prescriptions = {
		prescriptionNumbers: [],
		prescriptionIds: [],
		prescriptionOrdersIds: [],
		patientClinicIds: [],
		patientIds: [],
		patients: [],
		patientTelephoneNumbers: [],
	};

	const prescriptionNumbers = prescriptions.prescriptionNumbers.map(p => {
		return {
			text: p.paperPrescriptionId,
			parameter: p.id
		}
	});

	const prescriptionIds = prescriptions.prescriptionIds.map(pId => {
		return {
			text: pId,
			parameter: pId
		}
	});

	let prescriptionOrdersIds = []

	prescriptions.prescriptionOrdersIds.forEach(p => {
		prescriptionOrdersIds.push({
			text: p.id,
			parameter: p.prescriptionId
		})
	})

	const patientClinicIds = prescriptions.patientClinicIds.map(p => {
		return {
			text: `${p.clinicIdNumber} | ${p.firstName} ${p.lastName}`,
			parameter: p.id
		}
	})

	const patientIds = prescriptions.patientIds.map(id => {
		return {
			text: id,
			parameter: id
		}
	})

	const patients = prescriptions.patients.map(p => {
		return {
			text: `${p.firstName} ${p.lastName} | ${formatDate(p.dateOfBirth, false, undefined, "DD/MM/YYYY")}`,
			parameter: p.id
		}
	})

	const patientTelephoneNumber = prescriptions.patientTelephoneNumbers.map(p => {
		return {
			text: `${p.firstName} ${p.lastName} | ${p.telephoneNo}`,
			parameter: p.id
		}
	})

	return {
		prescriptionNumbers: prescriptionNumbers.filter(pN => pN.text),
		prescriptionIds,
		prescriptionOrdersIds,
		patientClinicIds,
		patientIds,
		patients,
		patientTelephoneNumber
	}
}

function getOrdersFromPrescription(prescription) {
	let result = [];

	// if there are no orders you can add orders
	let canAddProduct = prescription.orders.length === 0 && prescription.isProcessed === true;

	for (let order of prescription.orders) {
		const orderStatus = getOrderStatus(prescription, order);

		if (![OrderStatuses.Archived, OrderStatuses.Completed, OrderStatuses.Void].includes(orderStatus)) {
			canAddProduct = true;
		}

		const refunded = order.transactions ? order.transactions.some(t => t.type === 1) : false;
		const chargedPrice = order.transactions.length ? order.transactions.filter(t => t.type === 0)[0].amount : 0;

		const paymentTransactionData = order.transactions.find(t => t.type === 0);
		result.push({
			id: order.id,
			status: orderStatus,
			createdAt: order.createdAt,
			externalTrackingCode: order.externalTrackingCode,
			refunded: refunded,
			chargedPrice: chargedPrice,
			invoiceName: order.transaction?.invoiceNumber,
			invoiceUrl: order.transaction?.invoiceFilePath,

			orderedProducts: getOrderedProductsFromOrder(orderStatus, order.orderedProducts, prescription, order),

			shipmentCost: order.shipmentCost,
			tax: order.tax,
			paymentTransactionData: paymentTransactionData,

			isSplit: order.isSplit
		});

	}
	prescription.canAddProducts = canAddProduct;

	result = result.sort((a, b) => {
		return new Date(b.createdAt) - new Date(a.createdAt);
	})

	return result;
}

function getOrderedProductsFromOrder(orderStatus, orderedProducts, prescription, order) {
	return orderedProducts.map((orderedProduct) => {
		let statusLabel =
			orderStatus === OrderStatuses.NotProcessed
				? getProductStatusLabelForNotProcessed(prescription, order, orderedProduct.isCancelled)
				: getProductStatusLabel(orderStatus, orderedProduct.isCancelled);

		return {
			id: orderedProduct.id,
			name: orderedProduct.product.name,
			THC: orderedProduct.product.thc,
			CBD: orderedProduct.product.cbd,
			activeIngredient: orderedProduct.product.activeIngredient,
			productUnit: ProductUnitMapper[orderedProduct.product.productUnit],
			amount: orderedProduct.amount,
			product: getProductWithStatus(orderedProduct.product, orderedProduct.amount),
			fullPrice: orderedProduct.fullPrice,
			t21Price: orderedProduct.product.isSubsidyEligible ? orderedProduct.product.t21SubsidyPrice : orderedProduct.product.t21BasePrice,
			t21PriceReason: orderedProduct.product.isSubsidyEligible ? " (Subsidy)" : " (Base)",
			retailPrice: orderedProduct.retailPrice,
			isCancelled: orderedProduct.isCancelled,
			statusLabel,
		};
	});
}

export function getProductWithStatus(product, orderedProductAmount) {
	const status = getProductStatus(product.availability, product.stockQuantity, orderedProductAmount);

	return { ...product, status: status };
}

function getProductStatus(availability, stockQuantity, orderedProductAmount) {
	if (availability === ProductAvailability.Discontinued)
		return StockCheckStatus.Discontinued;

	if (stockQuantity > orderedProductAmount) {
		if (availability === ProductAvailability.Available)
			return StockCheckStatus.AvailableInStock;

		return StockCheckStatus.UnavailableInStock;
	}

	if (availability === ProductAvailability.Available)
		return StockCheckStatus.AvailableNotInStock;

	return StockCheckStatus.UnavailableNotInStock;
}

export function getOrderStatus(prescription, order) {
	let status = checkIfVoid(prescription, order);
	if (status) return status;

	if (prescription.isCompleted ||
		order.isForceCompleted ||
		order.shippingStatus === ShippingStatus.ReceivedByCustomer
	) {
		return OrderStatuses.Completed;
	}

	if (prescription.isArchived) return OrderStatuses.Archived;

	if (!prescription.isProcessed) return OrderStatuses.NotProcessed;
	if (order.status === OrderState.Cancelled) return OrderStatuses.Cancelled;

	if (order.status === OrderState.Unavailable) return OrderStatuses.OutOfStock;

	status = checkIfPaymentExpired(order);
	if (status) return status;

	const orderStatusString = getOrderStatusString(prescription, order);

	return StatusStringsToOrderStatusesMapper[orderStatusString];
}

function checkIfVoid(prescription, order) {
	if (prescription.isArchived) return OrderStatuses.Archived;

	const isExpired = isPrescriptionExpired(prescription);

	if (!isExpired && prescription.isCompleted) return OrderStatuses.Complete;

	if (isExpired && prescription.isCompleted) return OrderStatuses.CompletedAndVoid;

	if (isExpired && !prescription.isCompleted && order.shippingStatus === ShippingStatus.SentFromPharmacy) return;

	if (isExpired && !prescription.isCompleted) return OrderStatuses.Void;
}

function checkIfPaymentExpired(order) {
	const currentDate = moment().startOf("day");

	const paymentExpirationDate = moment(order.paymentExpirationDate).startOf("day");
	const isPaymentDeadlineMissed = !order.isPaymentReceived && paymentExpirationDate.isBefore(currentDate);

	if (isPaymentDeadlineMissed && order.status !== OrderState.Cancelled) return OrderStatuses.PaymentDeadlineMissed;
}

function isPrescriptionExpired(prescription) {
	const currentDate = moment().startOf("day");
	const expirationDate = moment(prescription.expirationDateTime).startOf("day");

	return expirationDate.isBefore(currentDate);
}

function getOrderStatusString(prescription, order) {
	const { isPaperPrescriptionReceived } = prescription;
	const { isStockReserved, isPaymentReceived, isForceCompleted, shippingStatus } = order;

	return (
		// order and prescription flags
		`${+isStockReserved}` +
		`${+isPaymentReceived}` +
		`${+isPaperPrescriptionReceived}` +
		`${+isForceCompleted}` +
		` ` +
		// shipping status
		`${+(shippingStatus === ShippingStatus.NotShipped)}` +
		`${+(shippingStatus === ShippingStatus.SentFromWarehouse)}` +
		`${+(shippingStatus === ShippingStatus.ReceivedByPharmacy)}` +
		`${+(shippingStatus === ShippingStatus.SentFromPharmacy)}` +
		`${+(shippingStatus === ShippingStatus.ReceivedByCustomer)}`
	);
}

function getProductStatusLabelForNotProcessed(prescription, order, isProductCanceled) {
	const orderStatusString = getOrderStatusString(prescription, order);
	const orderStatus = StatusStringsToOrderStatusesMapper[orderStatusString];

	return getProductStatusLabel(orderStatus, isProductCanceled);
}

function getProductStatusLabel(orderStatus, isProductCanceled) {
	// we don't have an order status when product is cancelled
	if (isProductCanceled) return { text: "Cancelled", color: "canceled-status-grey" };

	return OrderStatusToProductLabelMapper[orderStatus];
}



//#endregion
