// List of default validation lambas, dependant on 1 (always value) or more parameters (e.g. maxCharacters).
import moment from "moment";

export default {
	isEnum: function (enumType) {
		return function (value) {
			return enumType.isDefined(parseInt(value));
		};
	},
	isPositive: function (value) {
		return value !== null && value !== undefined && typeof value == "number" && value > 0;
	},
	isPositiveWithStringConversion: function (value) {
		value = Number(value);
		return value !== null && value !== undefined && typeof value == "number" && value > 0;
	},
	required: function (value) {
		return value !== null && value !== undefined && (typeof value == "string" ? !!(value.length && value.trim().length) : true);
	},
	isTrue: function (value) {
		return value === true;
	},
	notNull: function (value) {
		return value !== null;
	},
	notUndefined: function (value) {
		return value !== undefined;
	},
	maxValue: function (maximum) {
		return function (value) {
			return value <= maximum;
		};
	},
	minValue: function (minimum) {
		return function (value) {
			return value >= minimum;
		};
	},
	maxLength: function (maximumCharacters) {
		return function (value) {
			return value ? value.length <= maximumCharacters : true;
		};
	},
	minLength: function (minimumCharacters) {
		return function (value) {
			return value ? value.length >= minimumCharacters : true;
		};
	},
	isDateWithFourDigitYear: function (value) {
		if (!value) {
			return true;
		}
		const partsOfTheFormat = value.split('-');
		const year = partsOfTheFormat[0];
		if (year.length > 4) {
			return false;
		}
		return true;
	},
	doesPasswordContainCapital(password) {
		if (password.match(/^.*[A-Z].*$/)) return true;

		return false;

	},
	doesPasswordContainLowercase(password) {
		if (password.match(/^.*[a-z].*$/)) return true;

		return false;

	},
	doesPasswordContainNumber(password) {
		if (password.match(/^.*[0-9].*$/)) return true;

		return false;
	},
	doesPasswordContainSymbol(password) {
		// eslint-disable-next-line
		const symbolRegex = /(.*\W.*)/;
		if (password.match(symbolRegex)) return true;

		return false;
	},
	isOver18: function (value) {
		if (!value) {
			return true;
		}

		if (moment(value, "DD/MM/YYYY").diff(moment(), 'years') <= -18) {
			return true
		}
		return false;
	},
	isValue: function (values) {
		return function (value) {
			return values.indexOf(value) !== -1;
		};
	},
	isEmail: function (value) {
		if (!value) return true;

		return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value); //eslint-disable-line
	},
	isNumeric: function (value) {
		return value && !isNaN(value);
	},
	isTime: function (value) {
		return /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value); //eslint-disable-line
	},
	isLettersAndSpaces: function (value) {
		return /^[a-zA-Z\s]*$/.test(value); //eslint-disable-line
	},
	isLettersSpacesAndHyphens: function (value) {
		return /^[-\sa-zA-Z]+$/.test(value);
	},
	isDate: function (value) {
		return /^(0?[1-9]|[12][0-9]|3[01])[\/\-\.](0?[1-9]|1[012])[\/\-\.]\d{4}\.$/.test(value); //eslint-disable-line
	},
	isUrl: function (value) {
		return new RegExp(
			"^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$",
			"i"
		).test(value); //eslint-disable-line
	},
	isNotJerseyGuernsey: function (value) {
		const lowerCaseValue = value.toLowerCase();
		if (lowerCaseValue.startsWith("je")) return false;
		if (lowerCaseValue.startsWith("gy")) return false;
		return true;
	},
	customRegex: function (regex) {
		return function (value) {
			return value && !!new RegExp(regex, "mg").test(value);
		};
	},
};
