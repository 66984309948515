// List of default error messages, dependant on 0 or more parameters (e.g. maxCharacters).
export default {
	isEnum: function () {
		return "This field is required";
	},
	isPositive: function () {
		return "Value needs to be positive";
	},
	required: function () {
		return "Field is required";
	},
	notNull: function () {
		return "Field cannot be null";
	},
	notUndefined: function () {
		return "Field cannot be undefined";
	},
	maxValue: function (maximum) {
		return "Value cannot be greater than " + maximum;
	},
	minValue: function (minimum) {
		return "Value must be greater than " + minimum;
	},
	maxLength: function (maximumCharacters) {
		return "Maximum length is " + maximumCharacters + " characters";
	},
	minLength: function (minimumCharacters) {
		return "Minimum length is " + minimumCharacters + " characters";
	},
	isValue: function (values) {
		return "Value must be one of the following: " + values.join(", ");
	},
	isEmail: function () {
		return "INVALID EMAIL FORMAT";
	},
	doesPasswordContainCapital: function () {
		return "Password must contain a capital letter";
	},
	doesPasswordContainNumber: function () {
		return "Password must contain a number";
	},
	doesPasswordContainSymbol: function () {
		return "One special character";
	},
	isLettersAndSpaces: function () {
		return "Value must be only letters";
	},
	isLettersSpacesAndHyphens: function () {
		return "Value must be only letters";
	},
	isDateWithfourDigitYear: function () {
		return "Year must have four digits";
	},
	isNumeric: function () {
		return "Value must be numeric";
	},
	isTime: function () {
		return "Time must be in a valid format: [hh:mm]";
	},
	isDate: function () {
		return "MUST BE DD/MM/YYYY";
	},
	isUrl: function () {
		return "Value must be a valid URL";
	},
	isPhoneNumber: function () {
		return "Phone number can only have numbers or + sign";
	},
	isPostCode: function () {
		return "Only numbers , letters and one space";
	},
	customRegex: function () {
		return "Invalid format";
	},
	isNotJerseyGuernsey: function () {
		return "Jersey/Guernsey patients cannot be processed";
	},
	isOver18: function () {
		return "Patient must be 18+";
	},
};
