import React from 'react'
import AccountTypeIcon from '../../assets/icons/AccountTypeIcon'
import { PatientAccountTypes, PatientAccountTypesColors } from '../../enums'
import GenericToolTip from '../SharedComponents/GenericToolTip'

const TableAccountTypeIcon = ({ patientAccountType }) => {

    const resolveIconColor = () => {
        return iconColorMapper[patientAccountType]
    }
    return (
        <div className='table_account_type_icon'>
            <div className='table_account_type_icon__icon'>
                <GenericToolTip text={toolTipTextMapper[patientAccountType]} />
                <AccountTypeIcon color={resolveIconColor()} />
            </div>
        </div>
    )
}

export default TableAccountTypeIcon

const iconColorMapper = {
    [PatientAccountTypes.isSignedUp]: PatientAccountTypesColors.isSignedUp,
    [PatientAccountTypes.isPartiallySignedUp]: PatientAccountTypesColors.isPartiallySignedUp,
    [PatientAccountTypes.isNotSignedUp]: PatientAccountTypesColors.isNotSignedUp,
}
const toolTipTextMapper = {
    [PatientAccountTypes.isSignedUp]: "Signed up",
    [PatientAccountTypes.isPartiallySignedUp]: "Partially signed up",
    [PatientAccountTypes.isNotSignedUp]: "Not signed up",
}
