// noinspection JSIgnoredPromiseFromCall

import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Subscribe } from "unstated";
import StepProgressBar from "react-step-progress";
import Button from "../../components/Form/Button";
import Dropdown from "../../components/Form/Dropdown";
import TextInput from "../../components/Form/TextInput";
import NumberInput from "../../components/Form/NumberInput";
import DateInput from "../../components/Form/DateInput";
import Textarea from "../../components/Form/Textarea";
import Checkbox from "../../components/Form/Checkbox";
import CustomSelect from "../../components/Form/CustomSelect";
import FormRow from "../../components/Form/FormRow";

import PrescriptionPreview from "../../components/PrescriptionPreview/PrescriptionPreview";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";
import Prompt from "../../components/Modal/Prompt";
import Separator from "../../components/Separator/Separator";

import PrescriptionContainer from "../../state/PrescriptionContainer";
import contentHelper from "../../util/helpers/contentHelper";
import tableHeaders from "../../util/constants/tableHeaders";
import {
	AddressTypesToUseForLabelGeneration,
	AdminType,
	BannerTypes,
	GenerateLabelNoteTypes,
	OrderStatuses,
	OrderStatusesToStepMapper,
	PatientAccountTypes,
	RefundTypes,
	ShippingStatus,
	StockCheckStatus,
	genericTagSubscriptionTextMapper,
	genericTagSubscriptionClassnameMapper,
	SubscriptionTypes,
} from "../../enums";

import { ReactComponent as ReceivedByPharmacy } from "../../assets/icons/Received by pharmacy.svg";
import { ReactComponent as NotReceivedByPharmacy } from "../../assets/icons/Not received by pharmacy.svg";
import { ReactComponent as GenerateLabelIcon } from "../../assets/icons/Generate label.svg";
import { ReactComponent as ForceCompleteIcon } from "../../assets/icons/Force complete.svg";
import { ReactComponent as RefundIcon } from "../../assets/icons/Refund icon.svg";
import { ReactComponent as UrgentIcon } from "../../assets/icons/UrgentIcon.svg";

import AuthenticationContainer from "../../state/AuthenticationContainer";
import CloseModalButton from "../../components/Modal/CloseModalButton";
import LoadingCard from "../../components/SharedComponents/LoadingCard";
import { EnvelopeIcon, ExclamationMarkTriangle, SendPaymentLinkIcon, SpinnerIcon, UnlinkIcon, ManualSplitOrderIcon, ExclamationMarkRed, NoteIcon } from "../../assets/icons";
import CustomBanner from "../../components/Banner/CustomBanner";
import ArchiveBox from "../../assets/icons/ArchiveBox";
import UnarchiveBox from "../../assets/icons/UnarchiveBox";
import FlagIcon from "../../assets/icons/FlagIcon";
import FlagIconRed from "../../assets/icons/FlagIconRed";
import SendToOrganisationModal from "../../components/SharedComponents/SendToOrganisationModal";
import moment from "moment";
import BannerButton from "../../components/Form/BannerButton";
import XIconSlim from "../../assets/icons/XIconSlim";
import UnlinkPrescriptionModal from "../../components/SharedComponents/UnlinkPrescriptionModal";
import RemoveAwaitingReWriteStatusModal from "../../components/SharedComponents/RemoveAwaitingReWriteStatusModal";
import RadioSelector from "../../components/Form/RadioSelector";
import Banner from "../../components/Banner/Banner";
import RenderForAdminTypes from "../../components/SharedComponents/RenderForAdminTypes";
import SquareCheckbox from "../../components/Form/SquareCheckbox";
import EditFlagIcon from "../../assets/icons/EditFlagIcon";
import EditFlagButton from "../../components/Form/EditFlagButton";
import RWIcon from "../../assets/icons/RWIcon";
import InfoIconGray from "../../assets/icons/InfoIconGray";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import { ModalInfoTable } from "../../components/StockModalTable/ModalInfoTable";
import InfoIcon from "../../assets/icons/InfoIcon";
import ExpiredBannerIcon from "../../assets/icons/ExpiredBannerIcon";
import GenericTag from "../../components/SharedComponents/GenericTag";
import RemoveLinkedOrganisationIcon from "../../assets/icons/RemoveLinkedOrganisationIcon";

function EditPrescription({ container, currentAdministrator }) {
	// State-related data
	const commentFormKey = "createComment";
	const prescriptionFormKey = "updatePrescription";
	const tempOrderedProductsFormKey = "temporaryOrderedProducts";
	const contactFormKey = "contact";
	const rejectPrescriptionStateFormKey = "rejectPrescription";
	const regenerateLabelStateFormKey = "regenerateLabelState";
	const resubmitOrderFormKey = "resubmitOrder";
	const splitOrderFormKey = "splitOrder";

	const shippingPrice = container.state.totalPriceForOrderedProducts.shippingCost
	const price = container.state.totalPriceForOrderedProducts.priceForOrderedProducts

	const prescription = container.state[prescriptionFormKey];
	const comment = container.state[commentFormKey];

	const tempOrderedProducts = container.state[tempOrderedProductsFormKey];
	const orderedProducts = container.state["orderedProductsTableData"];
	const paymentDetails = container.state["paymentDetails"];
	const products = container.state["productsForDropdown"];
	const contactForm = container.state[contactFormKey];
	const orderForUpdate = container.state["orderForUpdate"];
	const refundOrderState = container.state["refundOrderState"];
	const resubmitOrderState = container.state[resubmitOrderFormKey];
	const splitOrderState = container.state[splitOrderFormKey];
	const regenerateLabelState = container.state[regenerateLabelStateFormKey];
	const removeShippingReasonState = container.state["removeShippingReason"];

	const areCustomAddressFieldsForRegenerateLabelDisabled = !(AddressTypesToUseForLabelGeneration.CustomAddress === regenerateLabelState.addressToUseType.value);

	const isGenerateLabelButtonDisabled = () => {
		return AddressTypesToUseForLabelGeneration.PatientProfileAddress === regenerateLabelState.addressToUseType.value
			? (container.state.generatingLabel || !regenerateLabelState.note.value || regenerateLabelState.note.error)
			: !container.isFormValid(regenerateLabelStateFormKey) || container.state.generatingLabel;
	};

	const isSplitOrderDisabled = () => {
		const numberOfSelected = Object.values(splitOrderState.orderedProductsToSplit).filter(op => {
			return op.isSelected
		}).length
		if (numberOfSelected > 0 && numberOfSelected < Object.values(splitOrderState.orderedProductsToSplit).length && splitOrderState.splitOrderReason.value !== '' && splitOrderState.splitOrderReason.value.length < 255)
			return false;
		else
			return true;
	}

	const onFieldChange = (event) => container.onFormChange(event, prescriptionFormKey);
	const onCommentChange = (event) => container.onFormChange(event, commentFormKey);
	const onFieldChangePaymentDetails = (event) => container.onFormChange(event, "paymentDetails");
	const onFieldChangeContact = (event) => container.onFormChange(event, contactFormKey);
	const onFieldChangeForceComplete = (event) => container.onFormChange(event, "forceCompletedReason");
	const onRefundFormChange = (event) => container.onFormChange(event, "refundOrderState");
	const onRegenerateLabelFieldChange = (event) => container.onFormChange(event, regenerateLabelStateFormKey);
	const onResubmitCheckboxChange = (event) => container.onFormChange(event, resubmitOrderFormKey);

	const onRemoveShippingFormChange = (event) => container.onFormChange(event, "removeShippingReason");
	const onSplitOrderFormChange = (event) => container.onFormChange(event, splitOrderFormKey);


	const onRefundTypeChange = (refundType, refundAmount) => {
		const eventForType = {
			name: "refundType",
			value: refundType
		}
		const eventForAmount = {
			name: "refundAmount",
			value: refundAmount
		}
		onRefundFormChange(eventForType);
		onRefundFormChange(eventForAmount);
	}

	const onOwnerChange = (e) => {
		if (e.value[0].value) {
			container.assignAnOwner(id, e.value[0])
		}
	}

	const onUrgentChange = () => {
		setShowUrgentPopup(true);
	}

	const onRegenerateLabelAddressTypeChange = (type) => {
		const event = {
			name: "addressToUseType",
			value: type
		}
		onRegenerateLabelFieldChange(event)
	}

	const onRegenerateLabelNoteTypeChange = (type, noteValue = "") => {

		const eventForNoteType = {
			name: "noteType",
			value: type
		}
		const eventForNote = {
			name: "note",
			value: noteValue
		}
		onRegenerateLabelFieldChange(eventForNoteType);
		onRegenerateLabelFieldChange(eventForNote);
	}

	const onSelectChangePrescription = (event) => container.onSelectChange(event, prescriptionFormKey);

	const onTempOrderedProductChange = async (e, index) => {
		container.onTempOrderedProductChange(e, index, tempOrderedProductsFormKey)
		if (e.value.length > 0) {
			await container.checkStockStatusForProduct(index);
		}
	}

	const onFieldChangeOrderProduct = async (e, index) => {
		container.onFormChangeOrderedProduct(e, tempOrderedProductsFormKey, index)
		if (tempOrderedProducts[index].productId.value !== undefined && tempOrderedProducts[index].productId.value !== '' && e.name != "fullPrice") {
			await container.checkStockStatusForProduct(index);
		}
	};

	const onSelectChangePrescriptionPatientField = (event) => {
		onSelectChangePrescription(event);
		container.onSelectChange(event, rejectPrescriptionStateFormKey);
	};

	const onSelectKeyDownPrescriptionPatientField = async (event) => {
		await container.onSelectKeyDownPrescriptionPatientField(event);
	};

	const onPaperPrescriptionIdChange = (event) => {
		onFieldChange(event);

		const eventForRejectPrescriptionState = {
			name: "paperPrescriptionId",
			value: event.value
		}
		container.onFormChange(eventForRejectPrescriptionState, rejectPrescriptionStateFormKey);
	}

	const onRemoveTemporaryOrderedProduct = (index) => {
		container.onRemoveTemporaryOrderedProduct(index);
	};

	const handleOpenContactModal = async () => {
		await container.resetAllFieldsExcept(contactFormKey, [])
		toggleContactModal(true)
		const event = {
			name: 'accountTypes',
			value: prescription.doesPatientHaveACarer.value ? [1] : [0]
		}
		container.onFormChange(event, contactFormKey)
	}

	// Hook-related data
	const { id } = useParams();
	const [showRemoveProductPrompt, setRemoveProductPromptVisibility] = useState(false);
	const [contactModalVisible, toggleContactModal] = useState(false);
	const [paymentModalVisible, togglePaymentModal] = useState(false);
	const [sendPaymentLinkModalVisible, toggleSendPaymentLinkModal] = useState(false);
	const [splitOrderModalVisible, toggleSplitOrderModal] = useState(false);
	const [addOrderedProductsModalVisible, toggleAddOrderedProductsModal] = useState(false);
	const [showReadyForPatient, setShowReadyForPatient] = useState(false);
	const [showNotReadyForPatient, setShowNotReadyForPatient] = useState(false);
	const [showArchivePopup, setShowArchivePopup] = useState(false);
	const [showUnarchivePopup, setShowUnarchivePopup] = useState(false);
	const [showFlagPopup, setShowFlagPopup] = useState(false);
	const [showUrgentPopup, setShowUrgentPopup] = useState(false);
	const [showForceCompleteOrderPopup, setShowForceCompleteOrderPopup] = useState(false);
	const [showRefundPopup, setShowRefundPopup] = useState(false);
	const [createOrderModalVisible, setCreateOrderModalVisible] = useState(false);
	const [rejectModalVisible, setRejectModalVisible] = useState(false);
	const [showRefundModal, setShowRefundModal] = useState(false);
	const [unlinkModalVisible, setUnlinkModalVisible] = useState(false);
	const [removeAwaitingRewriteStatusVisible, setRemoveAwaitingRewriteStatusVisible] = useState(false);
	const [regenerateLabelModalVisible, setRegenerateLabelModalVisible] = useState(false);
	const [resubmitOrderModalVisible, setResubmitOrderModalVisible] = useState(false);

	const [orderIdToSendPaymentLinkTo, setOrderIdToSendPaymentLinkTo] = useState(null);

	const [showExistingOrderModal, setShowExistingOrderModal] = useState(false);
	const [isExistingOrderModalCheckboxChecked, setIsExistingOrderModalCheckboxChecked] = useState(false)
	const [openEditFlagDropdown, setOpenEditFlagDropdown] = useState(false);

	const [isShippingModalOpen, setIsShippingModalOpen] = useState(false)
	const [isChargeShippingChecked, setIsChargeShippingChecked] = useState(true)
	const [totalPrice, setTotalPrice] = useState(price)

	const [createOrderCheckboxValues, setCreateOrderCheckboxValues] = useState({
		isT21Checked: false,
		isDatePrescribedCorrectCheck: false,
		isPatientAddressCorrectCheck: false
	})

	const changeCheckboxValue = (key) => {
		createOrderCheckboxValues[key] = !createOrderCheckboxValues[key];
		setCreateOrderCheckboxValues({ ...createOrderCheckboxValues });
	}

	const isCreateTransactionButtonDisabled = () => {
		return paymentDetails?.billingDetailsAreTheSameAsShippingDetails?.value ?
			(!paymentDetails.customerFirstName.value ||
				!paymentDetails.customerLastName.value ||
				!paymentDetails.customerPhone.value ||
				!paymentDetails.customerEmail.value ||
				!paymentDetails.address1.value ||
				!paymentDetails.postalCode.value ||
				!paymentDetails.city.value ||
				!paymentDetails.country.value ||
				!paymentDetails.description.value ||
				container.state.creatingTransaction
			)
			:
			(!paymentDetails.billingAddress1.value ||
				!paymentDetails.billingCity.value ||
				!paymentDetails.billingPostalCode.value ||
				!paymentDetails.description.value ||
				!paymentDetails.customerFirstName.value ||
				!paymentDetails.customerLastName.value ||
				!paymentDetails.customerPhone.value ||
				!paymentDetails.customerEmail.value ||
				container.state.creatingTransaction
			);
	}

	//Click outside hook
	const ref = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpenEditFlagDropdown(false)
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [setOpenEditFlagDropdown]);

	useEffect(() => {
		container.setForUpdate(id);
		container.setCreateCommentState(id);
		container.getAllAdminsPartial()

		return () => container.resetPrescriptionState();
	}, [id, container]);

	useEffect(() => {
		container.getProducts();
	}, [container]);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	//Calculating Price hook
	useEffect(() => {
		isChargeShippingChecked ? setTotalPrice(price + shippingPrice) : setTotalPrice(price)
	}, [isChargeShippingChecked, container.state.totalPriceForOrderedProducts])

	//Charge shipping
	useEffect(() => {
		container.state.chargeShipping.value = (prescription.isT21.value || prescription.patient?.value?.subscriptionType !== SubscriptionTypes.None) ? false : isChargeShippingChecked; // shipping isnt charged for t21 or for subscribers (any tier and type)
		if (!isChargeShippingChecked) {
			setIsShippingModalOpen(true)
		}
	}, [isChargeShippingChecked, prescription.isT21.value, prescription.patient]);

	// Functions
	function openPaymentModal(order) {
		// noinspection JSIgnoredPromiseFromCall
		container.initPayment(order);

		togglePaymentModal(true);
	}

	async function confirmTemporaryOrderedProducts() {
		await container.confirmTemporaryOrderedProducts();
		toggleAddOrderedProductsModal(false);
	}

	function onCloseOrderedProductModal() {
		toggleAddOrderedProductsModal(false)
		container.resetStateOfProductModal()
	}

	function openPaymentLinkModal(orderId) {
		setOrderIdToSendPaymentLinkTo(orderId);
		toggleSendPaymentLinkModal(true);
	}

	function closePaymentLinkModal() {
		toggleSendPaymentLinkModal(false);
		setOrderIdToSendPaymentLinkTo(null);
	}

	function sendPaymentLink() {
		container.sendPaymentLink(orderIdToSendPaymentLinkTo);
		closePaymentLinkModal()
	}

	function createComment() {
		container.createComment(currentAdministrator.name);
	}

	const isSubmitForAddOrderedProductDisabled = () => {
		if (tempOrderedProducts.every(product => product.productId.value != undefined && product.productId.value != '') === false) {
			return true;
		}

		if (tempOrderedProducts.every(product => product.amount.value > 0) === false) {
			return true;
		}

		if (tempOrderedProducts.some(product => product.loadingStock)) {
			return true;
		}

		if (tempOrderedProducts.some(product => product.stockStatus.value > 2 && product.isBannerChecked.value === false)) {
			return true;
		}

		return false;
	}

	const handleRefundOrder = async () => {
		await container.refundOrder();
		setShowRefundModal(false);
	}

	const handleShowRemoveRewriteRequiredStatusModal = () => {
		setRemoveAwaitingRewriteStatusVisible(true)
		container.setForRemovingRewriteStatus(id);
	}

	const handleRemoveAwaitingRewriteStatus = () => {
		container.removeAwaitingRewirteStatus();
		setRemoveAwaitingRewriteStatusVisible(false);
	}

	const generateOrRegenerateLabel = async () => {
		if (regenerateLabelState.addressToUseType.value === AddressTypesToUseForLabelGeneration.PatientProfileAddress) {
			await container.generateLabelForOrder(regenerateLabelState.orderId.value, regenerateLabelState.note.value);
			setRegenerateLabelModalVisible(false);
			return;
		}

		await container.regenerateLabelForOrder();
		setRegenerateLabelModalVisible(false);
	}

	const handleOpenRegenerateLabelModal = async (orderId) => {
		await container.resetRegenerateLabelState();
		setRegenerateLabelModalVisible(true)
		const event = {
			name: "orderId",
			value: orderId
		}
		onRegenerateLabelFieldChange(event)
	}

	const onOpenResubmitOrderModal = async (order) => {
		await container.resetAllFieldsExcept(resubmitOrderFormKey, [])
		setResubmitOrderModalVisible(true)
		const event = {
			name: "orderToResubmit",
			value: order
		}

		container.onFormChange(event, resubmitOrderFormKey)
	}

	const onResubmitOrder = () => {
		container.resubmit(resubmitOrderState.orderToResubmit.value.id, id)
		setResubmitOrderModalVisible(false)
	}

	const onOpenManualSplitModal = async (order) => {
		await container.resetAllFieldsExcept(splitOrderFormKey, [])
		const event = {
			name: "orderToSplit",
			value: order
		}

		container.onFormChange(event, splitOrderFormKey)

		await container.setOrderedProductsForSplit();
		toggleSplitOrderModal(true)
	}

	function closeManualSplitModal() {
		toggleSplitOrderModal(false);
	}

	const onManuallySplitOrder = () => {
		container.splitOrder()
		closeManualSplitModal()
	}

	const onAddProductsToOrder = () => {
		let doesActiveOrderExist = false

		if (prescription.orders.value.length > 0) {
			const activeOrders = prescription.orders.value.filter((orderItem) => orderItem.status < 11);
			// check if order status is active all statuses in OrderStatuses below 11 are active orders

			if (activeOrders.length > 0) doesActiveOrderExist = true
		}

		if (orderedProducts?.length > 0) doesActiveOrderExist = false

		if (doesActiveOrderExist) {
			setShowExistingOrderModal(true)
		}
		else {
			container.resetStateOfProductModal();
			container.filterOutDropdownProducts();
			toggleAddOrderedProductsModal(true);
		}
	}

	const existingOrderModalConfirm = () => {
		setShowExistingOrderModal(false)
		container.resetStateOfProductModal();
		container.filterOutDropdownProducts();
		toggleAddOrderedProductsModal(true)
	}

	const handleOpenEditFlagDropdown = () => {
		setOpenEditFlagDropdown(!openEditFlagDropdown)
	}

	const handleremoveFlagButton = () => {
		container.unflag()
		setOpenEditFlagDropdown(false)
	}

	const handleEditFlagButton = () => {
		setShowFlagPopup(true)
		setOpenEditFlagDropdown(false)
	}

	const handleOnClickShippingModalConfirm = () => {
		setIsShippingModalOpen(false);
	}

	const handleOnClickShippingModalCancel = () => {
		setIsChargeShippingChecked(true);
		setIsShippingModalOpen(false);
	}

	const handleClickCreateOrderButton = () => {
		container.createOrder();
		setCreateOrderModalVisible(false);
		setIsChargeShippingChecked(true);
		removeShippingReasonState.removeShippingReason.value = ""
	}

	function renderUnorderedProductsPrice(price, shippingCost) {
		return (
			<div style={{ display: "flex", alignItems: "flex-end", flexDirection: "column", textAlign: "end" }}>
				<SquareCheckbox
					name="chargeShipping"
					disabled={prescription.isT21.value || prescription.patient?.value?.subscriptionType != SubscriptionTypes.None}
					value={(prescription.isT21.value || prescription.patient?.value?.subscriptionType != SubscriptionTypes.None) ? false : isChargeShippingChecked}
					onChange={() => { setIsChargeShippingChecked(!isChargeShippingChecked) }}
					label={`Charge shipping`}
					className="remove_shipping--checkbox"
				/>

				{prescription.isT21.value &&
					<CustomBanner
						className="banner--info"
						icon={<InfoIcon />}
						text={"Shipping fee not charged if T21"}
						style={{
							width: '350px',
							height: '50px',
							margin: '10px 0'
						}}
					/>
				}

				{prescription.patient?.value?.subscriptionType != SubscriptionTypes.None &&
					<CustomBanner
						className="banner--info"
						icon={<InfoIcon />}
						text={"Shipping fee not charged if patient is subscribed"}
						style={{
							width: '350px',
							height: '50px',
							margin: '10px 0'
						}}
					/>
				}

				<p style={{ fontSize: "20px", marginBottom: "15px", color: "#808080" }}>
					Price with discounts: £{price}
				</p>

				{container.state.chargeShipping.value && <p style={{ fontSize: "20px", marginBottom: "15px", color: "#808080" }}>
					Shipping cost: £{shippingCost}
				</p>}

				<p style={{ fontSize: "20px", marginBottom: "15px", color: "#000000", fontWeight: "700" }}>
					Total: £{(prescription.isT21.value || prescription.patient?.value?.subscriptionType != SubscriptionTypes.None) ? price.toFixed(2) : totalPrice.toFixed(2)}
				</p>
			</div>
		)
	}

	function renderHeadingInformation(order) {
		return (
			<div className="tracking_code">
				{order.chargedPrice > 0 && (
					<p>
						Charged price: £{order.chargedPrice}
					</p>
				)}

				{(order.chargedPrice > 0 && order.externalTrackingCode) &&
					<p className="dot_p">·</p>
				}

				{order.externalTrackingCode && (
					<span>
						Tracking code:
						<a href="https://www.royalmail.com/track-your-item#/">{order.externalTrackingCode}</a>
					</span>
				)}
			</div>
		);
	}

	function renderOrderButtons(order) {
		return (
			<div className="filter-container">
				{order.status === OrderStatuses.OutOfStock && order.orderedProducts.length > 1 && (
					<Button className="badge highlighted m-r-s" title="Manually split order" onClick={() => onOpenManualSplitModal(order)}>
						<ManualSplitOrderIcon />
					</Button>
				)}

				{order.status === OrderStatuses.PaymentDeadlineMissed && (
					<div style={{ display: "block" }} className="col-6">
						<div style={{ marginLeft: "auto", width: "200px", border: "#FFFFFF" }} >
							<Button className="m-r-xs btn--bordered--no_radius"
								style={{ whiteSpace: "nowrap" }}
								onClick={() =>
									onOpenResubmitOrderModal(order)
								}
							>
								<b>RE-SUBMIT ORDER</b>
							</Button>
						</div>
					</div>
				)}

				{currentAdministrator?.type !== AdminType.Eastone && order.invoiceUrl && (
					<a className="rounded-info-span m-r-s" href={order.invoiceUrl}>
						Invoice
					</a>
				)}

				{[OrderStatuses.NotPaidAndNoPaper, OrderStatuses.NotPaidAndPaper].includes(order.status) && (
					<Button className="badge highlighted m-r-s" title="Email patient with payment link" onClick={() => openPaymentLinkModal(order.id)}>
						<SendPaymentLinkIcon />
					</Button>
				)}

				{[OrderStatuses.NotPaidAndNoPaper, OrderStatuses.NotPaidAndPaper].includes(order.status) && (
					<Button className="badge highlighted m-r-s" title="Perform payment" onClick={() => openPaymentModal(order)}>
						<i className="icon-wallet" />
					</Button>
				)}


				{order.status === OrderStatuses.PaidAndPaper && (
					<Button
						disabled={container.state.updatingShippingStatus || container.state.cancelingProduct}
						className="badge highlighted m-r-s"
						title="Ready to be shipped"
						onClick={() => {
							container.setState({ orderForUpdate: order });
							setShowReadyForPatient(true);
						}}
					>
						<ReceivedByPharmacy />
					</Button>
				)}

				{order.status === OrderStatuses.ReceivedByPharmacy && (
					<>
						<Button
							className="badge highlighted m-r-s"
							title="Not ready for patient"
							disabled={container.state.updatingShippingStatus || container.state.cancelingProduct}
							onClick={() => {
								container.setState({ orderForUpdate: order });
								setShowNotReadyForPatient(true);
							}}
						>
							<NotReceivedByPharmacy />
						</Button>

						<Button
							className="badge highlighted m-r-s"
							title={"Generate label"}
							disabled={container.state.updatingShippingStatus || container.state.cancelingProduct}
							onClick={() => handleOpenRegenerateLabelModal(order.id)}
						>
							<GenerateLabelIcon />
						</Button>
					</>
				)}

				{order.status === OrderStatuses.SentFromPharmacy && (
					<Button
						className="badge highlighted m-r-s"
						title="Download label"
						disabled={container.state.updatingShippingStatus || container.state.cancelingProduct}
						onClick={() => handleOpenRegenerateLabelModal(order.id)}
					>
						<GenerateLabelIcon />
					</Button>
				)}

				{
					[
						OrderStatuses.Completed,
						OrderStatuses.PaidAndNoPaper,
						OrderStatuses.PaidAndPaper,
						OrderStatuses.ReceivedByPatient,
						OrderStatuses.ReceivedByPharmacy,
						OrderStatuses.SentFromPharmacy,
						OrderStatuses.SentFromWarehouse,
						OrderStatuses.Completed,
						OrderStatuses.Void,
						OrderStatuses.Archived,
						OrderStatuses.Cancelled,
					].includes(order.status) && currentAdministrator?.type === AdminType.Super && !order.refunded && order.paymentTransactionData && (
						<Button
							className="badge highlighted m-r-s"
							title="Refund order"
							onClick={() => {
								setShowRefundModal(true)
								container.setInitialRefundState(order);
								container.setState({ orderForUpdate: order });
							}}
						>
							<RefundIcon width='30px' />
						</Button>
					)}

				{![OrderStatuses.Completed, OrderStatuses.OutOfStock, OrderStatuses.PaymentDeadlineMissed].includes(order.status) && (
					<Button
						className="badge highlighted m-r-s"
						title="Force complete order"
						onClick={() => {
							setShowForceCompleteOrderPopup(true);

							container.setState({ orderForUpdate: order });
						}}
					>
						<ForceCompleteIcon />
					</Button>
				)}
			</div>
		);
	}

	function renderProgressBar(orderStatus) {
		return (
			<StepProgressBar
				startingStep={OrderStatusesToStepMapper[orderStatus]}
				wrapperClass="progress-bar-container"
				progressClass="progress-line"
				labelClass="step-label"
				buttonWrapperClass="progress-button-container"
				onSubmit={() => null}
				steps={contentHelper.getPrescriptionProgressBarSteps()}
			/>
		);
	}

	const handleUnlinkPrescription = async () => {
		await container.unlinkPrescription();
		setUnlinkModalVisible(false);
	}

	const renderUnlinkModal = () => {
		return <UnlinkPrescriptionModal
			unlinkModalVisible={unlinkModalVisible}
			setUnlinkModalVisible={setUnlinkModalVisible}
			handleUnlinkPrescription={handleUnlinkPrescription}
		/>
	}

	const renderRemoveAwaitingRewriteStatusModal = () => {
		return <RemoveAwaitingReWriteStatusModal
			show={removeAwaitingRewriteStatusVisible}
			onClose={() => setRemoveAwaitingRewriteStatusVisible(false)}
			handleRemoveAwaitingRewriteStatus={() => handleRemoveAwaitingRewriteStatus()}
		/>
	}

	const flaggedBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', position: "relative" }} >
			<div className="m-r-s" >
				<b>{prescription.adminFlaggedBy?.value.name}:</b> {prescription.flaggedReason.value}
			</div>
			<EditFlagButton>

				<EditFlagIcon onClick={handleOpenEditFlagDropdown} />

				<div ref={ref} className={`edit-flag-button--dropdown ${openEditFlagDropdown ? "edit-flag-button--dropdown--expand" : ""}`}>
					<div onClick={handleEditFlagButton}>Edit</div>
					<div onClick={handleremoveFlagButton}>Remove</div>
				</div>
			</EditFlagButton>
		</div>
	}

	const contentForDependantBanner = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'flexStart', alignItems: 'center' }} >
			<div className="m-r-s">
				This patient is a dependant of: &nbsp;
				<Link
					to={`/carers/edit/${prescription?.patientData?.carer?.id}`}
					className='link '
					target="_blank"
					rel="noopener noreferrer"
				>
					{prescription?.patientData?.carer?.firstName} {prescription?.patientData?.carer?.lastName}
				</Link>
			</div>
		</div>
	}

	const renderAwaitingReWriteBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} >
			<div className="m-r-s" >
				Awaiting re-write from organisation due to : {prescription.rewriteReason.value}
			</div>
			<BannerButton
				onClick={() => handleShowRemoveRewriteRequiredStatusModal()}
			>
				<XIconSlim /> &nbsp; <span>Remove</span>
			</BannerButton>
		</div>
	}

	const handleOpenUnlinkPrescriptionModal = (unlinkFrom) => {
		setUnlinkModalVisible(true);
		const payload = {
			prescriptionId: prescription.id.value,
			rewritePrescriptionId: unlinkFrom,
		}

		container.setForUnlinking(payload);
	}

	const rewriteOfBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} >
			<div className="m-r-s">
				Re-write of: &nbsp;
				<Link
					to={`/prescriptions/edit/${prescription.rewriteChildPrescriptionId.value}`}
					className='link bold'
					target="_blank"
					rel="noopener noreferrer"
				>
					Prescription ID {prescription.rewriteChildPrescriptionId.value}
				</Link>
			</div>
			<BannerButton
				onClick={() => handleOpenUnlinkPrescriptionModal(prescription.rewriteChildPrescriptionId.value)}
				className={"banner_button--blue"}
			>
				<UnlinkIcon /> &nbsp; <span>Unlink</span>
			</BannerButton>
		</div>
	}

	const renderRewriteWasRequiredBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} >
			<div className="m-r-s">
				Re-write was required due to: {prescription.rewriteReason.value} -  &nbsp;
				<Link
					to={`/prescriptions/edit/${prescription.rewriteParentPrescriptionId.value}`}
					className='link bold'
					target="_blank"
					rel="noopener noreferrer"
				>
					Re-write: {prescription.rewriteParentPrescriptionId.value}
				</Link>
			</div>
			<BannerButton
				onClick={() => handleOpenUnlinkPrescriptionModal(prescription.rewriteParentPrescriptionId.value)}
				className={"banner_button--blue"}
			>
				<UnlinkIcon /> &nbsp; <span>Unlink</span>
			</BannerButton>
		</div>
	}

	const patientNoteBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', position: "relative" }} >
			<div className="m-r-s" >
				<b>{prescription.patientData?.noteAdmin?.name}</b>: {prescription.patientData?.patientNote}
			</div>
			<div style={{ fontWeight: "400", fontStyle: "italic", opacity: "80%" }}>
				{`Patient note - ${moment(prescription.patientData?.adminNoteDateTime).format('DD/MM')}`}
			</div>
		</div>
	}

	const renderBanners = () => {
		const isExpired = (moment().format("YYYY-MM-DD") > prescription.expiryDate.value && prescription.expiryDate.value !== "" && prescription.isArchived.value === false)

		const formatedExpieryDate = moment(prescription.expiryDate.value).format("DD-MM-YYYY")
		const formatedArchivedDate = moment(prescription.archivedDateTime.value).format("DD-MM-YYYY")

		return <>
			{/*Delete banner in the future*/}
			{prescription.orders.value.length != 0 && <FormRow >
				<div >
					<CustomBanner
						style={{
							paddingLeft: '0px',
							paddingRight: '0px',
						}}
						className="banner--gray "
						text={<div style={{ display: 'flex', width: '100%', justifyContent: 'flexStart', alignItems: 'center' }} >
							<div className="m-r-s">
								Orders have been moved below the prescription information section ▼
							</div>
						</div>}
					/>
				</div>
			</FormRow>}
			{prescription?.patientData?.carer &&
				<FormRow >
					<div >
						<CustomBanner
							style={{
								paddingLeft: '0px',
								paddingRight: '0px',
							}}
							className="banner--gray "
							text={contentForDependantBanner()}
						/>
					</div>
				</FormRow>
			}
			{prescription.isFlagged.value &&
				<FormRow >
					<CustomBanner
						className="banner--red"
						icon={<FlagIconRed />}
						text={flaggedBannerContent()}
					/>
				</FormRow>
			}

			{prescription.awaitingRewrite.value &&
				<FormRow >
					<CustomBanner
						className="banner--blue"
						icon={<RWIcon />}
						text={renderAwaitingReWriteBannerContent()}
					/>
				</FormRow>
			}

			{prescription.isArchived.value &&
				<CustomBanner
					className="banner--gray banner--fixed"
					icon={<ArchiveBox />}
					text={prescription.archivedDateTime.value !== "" ? `PRESCRIPTION WAS ARCHIVED ON: ${formatedArchivedDate}` : "Prescription archived"}
				/>
			}

			{isExpired &&
				<CustomBanner
					className="banner--expired banner--fixed"
					icon={<ExpiredBannerIcon />}
					text={prescription.expiryDate.value !== "" ? `PRESCRIPTION EXPIRED ON: ${formatedExpieryDate}` : "Prescription expired"}
				/>
			}

			{prescription.rewriteChildPrescriptionId.value &&
				<FormRow >
					<CustomBanner
						className="banner--blue"
						icon={<RWIcon />}
					>
						{rewriteOfBannerContent()}
					</CustomBanner>
				</FormRow>
			}

			{prescription.rewriteParentPrescriptionId.value &&
				<FormRow >
					<CustomBanner
						className="banner--blue"
						icon={<RWIcon />}
						text={renderRewriteWasRequiredBannerContent()
						}
					/>
				</FormRow>
			}

			{prescription?.patientData?.noteAdmin &&
				<FormRow >
					<CustomBanner
						className="banner--blue"
						icon={<NoteIcon />}
						text={patientNoteBannerContent()
						}
					/>
				</FormRow>}
		</>
	}

	function renderPrompts() {
		return (
			<div>
				{/* Ready for patient */}
				<Prompt
					isOpen={showReadyForPatient}
					title="Order ready for patient"
					description="Are you sure?"
					onYes={() => {
						container.updateShipmentStatus(orderForUpdate.id, ShippingStatus.ReceivedByPharmacy);

						setShowReadyForPatient(false);
					}}
					onNo={() => setShowReadyForPatient(false)}
					onClose={() => setShowReadyForPatient(false)}
				/>
				{/* Not ready for patient */}
				<Prompt
					isOpen={showNotReadyForPatient}
					title="Order not ready for patient"
					description="Are you sure?"
					onYes={() => {
						container.updateShipmentStatus(orderForUpdate.id, ShippingStatus.NotShipped);

						setShowNotReadyForPatient(false);
					}}
					onNo={() => setShowNotReadyForPatient(false)}
					onClose={() => setShowNotReadyForPatient(false)}
				/>
				{/* Cancel product */}
				<Prompt
					isOpen={showRemoveProductPrompt}
					title="Product cancellation"
					description="Please provide a reason for cancelling this product"
					onYes={() => {
						container.cancelProductInOrder();

						setRemoveProductPromptVisibility(false);
					}}
					onNo={() => setRemoveProductPromptVisibility(false)}
					onClose={() => setRemoveProductPromptVisibility(false)}
					yesDisabled={!prescription.cancelProductReason?.value || prescription.cancelProductReason?.value.length > 255}
				>
					<Textarea
						name="cancelProductReason"
						placeholder="Add a reason..."
						value={prescription.cancelProductReason?.value || ""}
						error={prescription.cancelProductReason?.error}
						onChange={onFieldChange}
						maxLength={255}
					/>
				</Prompt>
				{/* Archive presciprtion */}
				<Prompt
					isOpen={showArchivePopup}
					title="Archive prescription"
					description="Please confirm you would like to archive this prescription by providing a reason."
					onYes={() => {
						container.archive(prescription.id);

						setShowArchivePopup(false);
					}}
					onNo={() => setShowArchivePopup(false)}
					onClose={() => setShowArchivePopup(false)}
					yesDisabled={!prescription.archivedReason.value || prescription.archivedReason.value.length > 255}
				>
					<div style={{ width: "100%" }}>
						<FormRow>
							<Banner type={BannerTypes.Info}>
								Any active orders will be cancelled and stock
								will be no longer reserved for the patient
							</Banner>
						</FormRow>

						<FormRow>
							<Textarea
								name="archivedReason"
								label="Reason for archiving"
								placeholder="Provide a reason for archiving this prescription"
								value={prescription.archivedReason.value || ""}
								error={prescription.archivedReason.error}
								onChange={onFieldChange}
								maxLength={255}
							/>
						</FormRow>
					</div>
				</Prompt>
				{/* Unarchive presciprtion */}
				<Prompt
					isOpen={showUnarchivePopup}
					title="Unarchive prescription?"
					description="Please confirm you would like to unarchive this prescription by providing a reason"
					onYes={() => {
						container.unarchive(prescription.id);

						setShowUnarchivePopup(false);
					}}
					onNo={() => setShowUnarchivePopup(false)}
					onClose={() => setShowUnarchivePopup(false)}
					yesDisabled={!prescription.unarchivedReason.value || prescription.unarchivedReason.value.length > 255}
				>
					<Textarea
						name="unarchivedReason"
						placeholder="Add a reason..."
						value={prescription.unarchivedReason.value || ""}
						error={prescription.unarchivedReason.error}
						onChange={onFieldChange}
						maxLength={255}
					/>
				</Prompt>
				{/* Flag prescription */}
				<Prompt
					isOpen={showFlagPopup}
					title="Flag prescription?"
					description="Please leave a comment describing the reason why this prescription is being flagged"
					onYes={() => {
						container.flag(currentAdministrator.name);

						setShowFlagPopup(false);
					}}
					onNo={() => setShowFlagPopup(false)}
					onClose={() => setShowFlagPopup(false)}
					yesDisabled={!prescription.flaggedReason.value || prescription.flaggedReason.value.length > 255}
				>
					<Textarea
						name="flaggedReason"
						placeholder="Add comment..."
						error={prescription.flaggedReason.error}
						value={prescription.flaggedReason.value || ""}
						onChange={onFieldChange}
						maxLength={255}
					/>
				</Prompt>

				{/* Force complete order  */}
				<Prompt
					isOpen={showForceCompleteOrderPopup}
					title="Reason for force complete"
					description={`Please provide a reason for force completing Order ${container.state.orderForUpdate?.number}`}
					onYes={() => {
						container.forceComplete();

						setShowForceCompleteOrderPopup(false);
					}}
					onNo={() => setShowForceCompleteOrderPopup(false)}
					onClose={() => setShowForceCompleteOrderPopup(false)}
					yesDisabled={
						!container.state.forceCompletedReason.forceCompletedReason.value ||
						container.state.forceCompletedReason.forceCompletedReason.value.length > 255
					}
					customButtonYes="Force complete"
					customButtonNo="Cancel"
				>
					<Textarea
						name="forceCompletedReason"
						placeholder="Add a reason..."
						value={container.state.forceCompletedReason.forceCompletedReason.value || ""}
						error={container.state.forceCompletedReason.forceCompletedReason.error || ""}
						onChange={onFieldChangeForceComplete}
						maxLength={255}
					/>
				</Prompt>

				{/* Refund order  */}
				<Prompt
					isOpen={showRefundPopup}
					title="Issue a refund for this order"
					description="Are you sure you want to issue a refund for this order?"
					onYes={() => {
						container.refundOrder();

						setShowRefundPopup(false);
					}}
					onNo={() => setShowRefundPopup(false)}
					onClose={() => setShowRefundPopup(false)}
				/>

				{/* Set prescription as urgent */}
				<Prompt
					isOpen={showUrgentPopup}
					title={!prescription.isUrgent.value ? "Flag as urgent" : "Remove urgent flag"}
					description={
						!prescription.isUrgent.value ?
							"Please provide a reason for flagging this prescription as urgent. Once flagged remember to 'Update prescription information'."
							:
							"Please provide a reason for removing the urgent status on this prescription."
					}
					onYes={() => {
						container.setUrgent(currentAdministrator.id);

						setShowUrgentPopup(false);
					}}
					onNo={() => { setShowUrgentPopup(false); prescription.urgentReason.value = "" }}
					onClose={() => setShowUrgentPopup(false)}
				>
					<div style={{ width: '100%' }} className="d-flex flex-col ">
						{!prescription.isUrgent.value && (
							<p style={{ fontWeight: '400' }}>
								What qualifies as an urgent script:
								<ul style={{ marginLeft: '20px' }}>
									&nbsp;
									<li>Patient has stated they are vulnerable</li>
									<li>Previous script has had to be re-written</li>
								</ul>
							</p>)}
						&nbsp;
						<Textarea
							name="urgentReason"
							placeholder="Add a reason..."
							error={prescription.urgentReason.error}
							value={prescription.urgentReason.value || ""}
							onChange={onFieldChange}
							maxLength={255}
						/>
					</div>
				</Prompt>
			</div>
		);
	}

	const isRefundButtonDisabled = (!container.isFormValid("refundOrderState") || (refundOrderState.refundAmount.value > orderForUpdate?.paymentTransactionData?.amount))
	function renderModals() {
		return (
			<div>
				<Modal isOpen={contactModalVisible} onClose={() => toggleContactModal(false)}>
					<FormRow>
						<h2 className="h4 m-b-s">Contact patient</h2>
					</FormRow>

					<FormRow>
						<div className="col-12 flex-col align-i-start">
							<p style={{ textAlign: 'start', fontWeight: 'bold' }} className="m-b-s" > Message</p>
							<p style={{ textAlign: 'start', fontWeight: '400', color: 'black' }} className=" m-b-xs"> Hello {prescription.patientData?.firstName}</p>
							<Textarea
								placeholder="Your message...."
								value={contactForm.message.value}
								name="message"
								onChange={onFieldChangeContact}
								maxLength={255}
							/>

						</div>
					</FormRow>

					<FormRow style={{ marinTop: '0px', paddingTop: '0px' }} >
						<div className="col-12 flex-col align-i-start">
							<p style={{ textAlign: 'start', fontWeight: '400', color: 'black' }}> Best wishes,</p>
							<p style={{ textAlign: 'start', fontWeight: '400', color: 'black' }} className=" m-b-xs"> Lyphe Dispensary</p>
						</div>
					</FormRow>

					<FormRow>
						<Button
							text="Send"
							onClick={async () => {
								await container.contact();
								toggleContactModal(false);
							}}
							disabled={!container.isFormValid(contactFormKey)}
						/>
					</FormRow>
				</Modal>

				{/*region create order modal*/}
				<Modal isOpen={createOrderModalVisible} onClose={() => setCreateOrderModalVisible(false)}>
					<>
						<FormRow>
							<h2>Confirm Order</h2>
						</FormRow>
						<FormRow>
							<p>You are about to create a new order for this patient.</p>
						</FormRow>
						<FormRow>
							<p>Please confirm that the amounts and pricing are correct before creating the order. If all stock is available and on hand the patient will be notified that they can make payment immediately.</p>
						</FormRow>
						<FormRow>
							<ModalInfoTable
								tableHeaders={["Product", "Amount", "Price", "Membership Price"]}
								tableBodyData={orderedProducts}
								tableBodyRenderMethod={(op, index) => <tr key={index} className={"table-body"}>
									<td>{op[0].text}</td>
									<td>{op[1].text}</td>
									<td>£{op[2].text}</td>
									<td>{prescription.patient?.value?.subscriptionType != SubscriptionTypes.None ? "£" + op[4].text : "-"}</td>
								</tr>}
							/>
						</FormRow>
						<p style={{ width: "90%", textAlign: "right" }}>
							Total: £{container.state.totalPriceForOrderedProducts.priceForOrderedProducts}
						</p>

						{prescription.orders.value.length === 0 &&
							<>
								<FormRow>
									<p>I confirm to have checked:</p>
								</FormRow>
								<FormRow>
									<SquareCheckbox
										name="isT21Checked"
										value={createOrderCheckboxValues.isT21Checked}
										onChange={() => { changeCheckboxValue("isT21Checked") }}
										label={`If script is meant to be T21 status`}
										right={false}
									/>
								</FormRow>
								<FormRow>
									<SquareCheckbox
										name="isDatePrescribedCorrectCheck"
										value={createOrderCheckboxValues.isDatePrescribedCorrectCheck}
										onChange={() => { changeCheckboxValue("isDatePrescribedCorrectCheck") }}
										label={`Date prescribed is correct`}
										right={false}
									/>
								</FormRow>
								<FormRow>
									<SquareCheckbox
										name="isPatientAddressCorrectCheck"
										value={createOrderCheckboxValues.isPatientAddressCorrectCheck}
										onChange={() => { changeCheckboxValue("isPatientAddressCorrectCheck") }}
										label={`The patient address on prescription is the same as in their patient profile`}
										right={false}
									/>
								</FormRow>
							</>
						}

						<FormRow>
							<Button
								title={"Create order"}
								disabled={
									(createOrderCheckboxValues.isT21Checked === false ||
										createOrderCheckboxValues.isDatePrescribedCorrectCheck === false ||
										createOrderCheckboxValues.isPatientAddressCorrectCheck === false) &&
									prescription.orders.value.length === 0
								}
								onClick={() => { handleClickCreateOrderButton() }}
							>Create order</Button>
							<Button
								title={"Cancel"}
								bordered style={{ marginLeft: "16px" }}
								onClick={() => setCreateOrderModalVisible(false)}
							> Cancel </Button>
						</FormRow>
					</>

				</Modal>
				{/*end region create order modal*/}

				{/*region refund modal*/}
				<Modal isOpen={showRefundModal} onClose={() => setShowRefundModal(false)}>
					<CloseModalButton onClose={() => setShowRefundModal(false)} />
					{orderForUpdate?.paymentTransactionData &&
						<>
							<FormRow>
								<h2 className="h4">Refund payment</h2>
							</FormRow>
							<FormRow>
								<h2 className="h6">Patient paid £{orderForUpdate?.paymentTransactionData?.amount} on  {moment(orderForUpdate?.paymentTransactionData.createdAt).format("DD/MM/YYYY")}</h2>
							</FormRow>

							<FormRow>
								<h3 className="h6">Please select the amount that the patient will be refunded.</h3>
							</FormRow>

							<FormRow>
								<Checkbox
									name="refundType"
									label="Full refund"
									value={refundOrderState.refundType.value === RefundTypes.FullRefund}
									onChange={() => onRefundTypeChange(RefundTypes.FullRefund, orderForUpdate?.paymentTransactionData.amount)}
								/>
							</FormRow>

							{orderForUpdate?.orderedProductsRaw.map((product, index) => {
								return <FormRow key={index} >
									<Checkbox
										name="1"
										label={`Refund only for ${product.name} x ${product.amount}`}
										value={product.product.id === refundOrderState.refundType.value}
										onChange={() => { onRefundTypeChange(product.product.id, product.fullPrice * product.amount) }}
									/>
								</FormRow>
							})}

							<FormRow>
								<div className={"col-1"}>
									<Checkbox
										name="0"
										label=""
										value={refundOrderState.refundType.value === RefundTypes.CustomRefund}
										onChange={() => { onRefundTypeChange(RefundTypes.CustomRefund, "") }}
									/>
								</div>
								<div className={"col-11"}>
									{!(refundOrderState.refundType.value === RefundTypes.CustomRefund) ?
										<TextInput
											className={'input-container--currency'}
											key={`${refundOrderState.refundType.value} ${refundOrderState.resetCustomRefundAmmountField.value}`}
											name={'refundAmount'}
											value={""}
											disabled={true}
											placeholder={" "}
										/>
										:
										<TextInput
											className={'input-container--currency'}
											key={`${refundOrderState.refundType.value} ${refundOrderState.resetCustomRefundAmmountField.value}`}
											name={'refundAmount'} error={refundOrderState.refundAmount.error}
											value={refundOrderState.refundAmount.value}
											disabled={!(refundOrderState.refundType.value === RefundTypes.CustomRefund)}
											onChange={onRefundFormChange}
											placeholder={"Custom amount"}
										/>
									}

								</div>
							</FormRow>
							{refundOrderState.refundAmount.value > orderForUpdate?.paymentTransactionData.amount &&
								<FormRow>
									<p className={'color-red'} >Cannot refund more than the patient paid for!</p>
								</FormRow>
							}
							<FormRow>
								<p>£{(refundOrderState.refundAmount.value && !isNaN(refundOrderState.refundAmount.value)) ? refundOrderState.refundAmount.value : "0"} will be issued to patient</p>
							</FormRow>

							<FormRow>
								<Textarea
									placeholder="Reason for refund"
									label="Reason for refund"
									value={refundOrderState.refundReason.value}
									error={refundOrderState.refundReason.error}
									name="refundReason"
									onChange={onRefundFormChange}
									maxLength={255}
								/>
							</FormRow>
							<FormRow style={{ paddingTop: "5px" }} >
								<p className={'shadow_text'}>Reason will be visible to patient</p>
							</FormRow>

							<FormRow>
								<div className='col-6'>
									<Button
										onClick={() => handleRefundOrder()}
										disabled={isRefundButtonDisabled}
									>
										{container.state.refundingOrder ? <SpinnerIcon /> : "Issue refund"}
									</Button>
								</div>
								<div className='col-6'>
									<Button
										text="Cancel"
										className={'btn--bordered--no_radius'}
										onClick={() => setShowRefundModal(false)}
									/>
								</div>
							</FormRow>
						</>
					}

				</Modal>
				{/*end region  refund modal*/}

				<Modal isOpen={paymentModalVisible} onClose={() => { togglePaymentModal(false) }}>
					<FormRow>
						<h2 className="h4">Perform payment</h2>
					</FormRow>

					{paymentDetails ? (
						<>
							<FormRow>
								<TextInput
									placeholder="Amount to be charged"
									label="Price override"
									name="amount"
									value={paymentDetails.amount.value}
									error={paymentDetails.amount.error}
									onChange={onFieldChangePaymentDetails}
								/>
							</FormRow>

							<FormRow>
								<TextInput
									placeholder="First name"
									label="First name"
									name="customerFirstName"
									value={paymentDetails.customerFirstName.value}
									error={paymentDetails.customerFirstName.error}
									onChange={onFieldChangePaymentDetails}
								/>
							</FormRow>

							<FormRow>
								<TextInput
									placeholder="Last name"
									label="Last name"
									name="customerLastName"
									value={paymentDetails.customerLastName.value}
									error={paymentDetails.customerLastName.error}
									onChange={onFieldChangePaymentDetails}
								/>
							</FormRow>
							<FormRow>
								<TextInput
									placeholder="Email"
									label="Email"
									name="customerEmail"
									value={paymentDetails.customerEmail.value}
									error={paymentDetails.customerEmail.error}
									onChange={onFieldChangePaymentDetails}
									disabled={true}
								/>
							</FormRow>
							<FormRow>
								<TextInput
									placeholder="Phone"
									label="Phone"
									name="customerPhone"
									value={paymentDetails.customerPhone.value}
									error={paymentDetails.customerPhone.error}
									onChange={onFieldChangePaymentDetails}
									disabled={true}
								/>
							</FormRow>

							<FormRow>
								<h3 className="h6">Shipping address</h3>
							</FormRow>
							<Banner
								type={BannerTypes.Info}
								className={"m-b-s"}
								text={"To edit shipping address, you need to change it from\n" +
									"patient’s profile before payment is made. If this is \n" +
									"a temporary address, leave a comment for pharmacy \n" +
									"to direct the order to the new address."} />

							<FormRow>
								<TextInput
									placeholder="e.g. Downing Street 10"
									label="Address 1"
									name="address1"
									value={paymentDetails.address1.value}
									error={paymentDetails.address1.error}
									onChange={onFieldChangePaymentDetails}
									disabled={true}
								/>
							</FormRow>
							<FormRow>
								<TextInput
									placeholder="e.g. London"
									label="City"
									name="city"
									value={paymentDetails.city.value}
									error={paymentDetails.city.error}
									onChange={onFieldChangePaymentDetails}
									disabled={true}
								/>
							</FormRow>
							<FormRow>
								<TextInput
									placeholder="e.g. SW1A 2AA"
									label="Postal code"
									name="postalCode"
									value={paymentDetails.postalCode.value}
									error={paymentDetails.postalCode.error}
									onChange={onFieldChangePaymentDetails}
									disabled={true}
								/>
							</FormRow>
							<FormRow>
								<TextInput
									placeholder="e.g. GB"
									label="Country"
									name="country"
									value={paymentDetails.country.value}
									error={paymentDetails.country.error}
									onChange={onFieldChangePaymentDetails}
									disabled={true}
								/>
							</FormRow>
							<FormRow>
								<Checkbox
									onChange={onFieldChangePaymentDetails}
									name="billingDetailsAreTheSameAsShippingDetails"
									value={paymentDetails.billingDetailsAreTheSameAsShippingDetails.value}
									label="Billing address same as shipping address"
								/>
							</FormRow>

							{!paymentDetails.billingDetailsAreTheSameAsShippingDetails.value && (
								<>
									<FormRow>
										<h3 className="h6">Billing address</h3>
									</FormRow>

									<Banner
										type={BannerTypes.Info}
										className={"m-b-s"}
										text={"Required fields are marked with *"}
									/>

									<FormRow>
										<TextInput
											placeholder="Billing address line 1"
											label="Billing address line 1"
											name="billingAddress1"
											value={paymentDetails.billingAddress1.value}
											error={paymentDetails.billingAddress1.error}
											onChange={onFieldChangePaymentDetails}
											showAsterisk={true}
										/>
									</FormRow>
									<FormRow>
										<TextInput
											placeholder="Billing address line 2"
											label="Billing address line 2"
											name="billingAddress2"
											value={paymentDetails.billingAddress2.value}
											onChange={onFieldChangePaymentDetails}
										/>
									</FormRow>
									<FormRow>
										<TextInput
											placeholder="Billing address line 3"
											label="Billing address line 3"
											name="billingAddress3"
											value={paymentDetails.billingAddress3.value}
											onChange={onFieldChangePaymentDetails}
										/>
									</FormRow>
									<FormRow>
										<TextInput
											placeholder="e.g. London"
											label="Billing city"
											name="billingCity"
											value={paymentDetails.billingCity.value}
											error={paymentDetails.billingCity.error}
											onChange={onFieldChangePaymentDetails}
											showAsterisk={true}
										/>
									</FormRow>
									<FormRow>
										<TextInput
											placeholder="e.g. SW1A 2AA"
											label="Billing post code"
											name="billingPostalCode"
											value={paymentDetails.billingPostalCode.value}
											error={paymentDetails.billingPostalCode.error}
											onChange={onFieldChangePaymentDetails}
											showAsterisk={true}
										/>
									</FormRow>
									<FormRow>
										<TextInput
											placeholder="e.g. GB"
											label="Billing country"
											name="billingCountry"
											value={paymentDetails.billingCountry.value}
											onChange={onFieldChangePaymentDetails}
											disabled={true}
										/>
									</FormRow>
								</>
							)}

							<FormRow>
								<TextInput
									placeholder="Description"
									label="Description"
									name="description"
									value={paymentDetails.description.value}
									error={paymentDetails.description.error}
									onChange={onFieldChangePaymentDetails}
									showAsterisk={true}
								/>
							</FormRow>

							<FormRow>
								<Button
									text="Send"
									onClick={async () => {
										await container.createTransaction();
										togglePaymentModal(false);
									}}
									disabled={isCreateTransactionButtonDisabled()}
								/>
							</FormRow>
						</>
					) : (
						<form className="payment-form">
							<p>Total price including shipping: £ {container.state.orderThatIsBeingPaid?.totalPrice} </p>
							<br />
							<div id="sp-container" />

							<FormRow>
								<Button text="Submit" />
							</FormRow>
						</form>
					)}
				</Modal>

				<Modal large isOpen={addOrderedProductsModalVisible} onClose={() => { onCloseOrderedProductModal(); }}>
					<FormRow>
						<h2 className="h4">Add product(s) to order</h2>
					</FormRow>

					<FormRow>
						<div className={'col-6'} style={{ flexDirection: 'column', alignItems: 'normal' }} >
							{tempOrderedProducts.map((op, index) => {
								return <div className="ordered_products_element">
									{index > 0 && <div style={{ width: '100%' }}><Separator /></div>}
									<FormRow  >
										<div className="col-4">
											<CustomSelect
												label="Product"
												values={[]}
												options={products}
												name="productId"
												onChange={(e) => onTempOrderedProductChange(e, index)}
											/>
										</div>
										<div className="col-4">
											<NumberInput
												placeholder="Amount"
												onChange={(e) => onFieldChangeOrderProduct(e, index)}
												label="Amount"
												name="amount"
												value={tempOrderedProducts[index].amount.value}
												min="1"
												step="1"
											/>
										</div>
										<div className="col-3">
											<TextInput
												placeholder="Price (optional)"
												label="Price Override"
												name="fullPrice"
												value={tempOrderedProducts[index].fullPrice.value}
												onChange={(e) => onFieldChangeOrderProduct(e, index)}
											/>
										</div>
										<div className="col-1">
											{index > 0 && index === tempOrderedProducts.length - 1 && <Button className="remove_list_item"
												onClick={() => { onRemoveTemporaryOrderedProduct(index) }}>
												<RemoveLinkedOrganisationIcon />
											</Button>}
										</div>
									</FormRow>

									{tempOrderedProducts[index].productId && tempOrderedProducts[index].productId?.value?.product &&
										<div className="col-12">
											<Banner type={tempOrderedProducts[index].loadingStock ? BannerTypes.InfoDelay : BannerTypes.Info} text={tempOrderedProducts[index].loadingStock ? "Checking stock for this product..." : `${tempOrderedProducts[index].productId.value.product.stockQuantity} in stock.`} />
										</div>
									}

									{/* Availability Banners */}
									{op.stockStatus.value > 0 && tempOrderedProducts[index].amount.value !== null && tempOrderedProducts[index].productId?.value !== undefined && !tempOrderedProducts[index].loadingStock && <FormRow>
										<div className='col-12' >
											{op.stockStatus.value === StockCheckStatus.AvailableNotInStock && <Banner type={BannerTypes.Info} text="This order will move into Awaiting Stock as there isn't enough of this product in stock to fulfill the order." />}
											{op.stockStatus.value === StockCheckStatus.UnavailableInStock && <Banner type={BannerTypes.Info} text="This product is marked as unavailable but there is enough stock to fulfill this order." />}
											{op.stockStatus.value === StockCheckStatus.UnavailableNotInStock &&
												<div>
													<Banner type={BannerTypes.Warning} text="The selected product is marked as unavailable and there isn't enough stock to fulfill this order. Please send this prescription to the organisation for a re-write." />
													<FormRow>
														<SquareCheckbox
															name="bannerCheckbox"
															value={op.isBannerChecked.value}
															onChange={() => { container.checkTempOrderBanner(index) }}
															label={`I am aware of the above and want to add this product to the order.`}
															right={false}
														/>
													</FormRow>
												</div>
											}
											{op.stockStatus.value === StockCheckStatus.Discontinued &&
												<div>
													<Banner type={BannerTypes.Warning} text="The selected product is flagged as Discontinued." />
													<FormRow>
														<SquareCheckbox
															name="bannerCheckbox"
															value={op.isBannerChecked.value}
															onChange={() => { container.checkTempOrderBanner(index) }}
															label={`I am aware of the above and want to add this product to the order.`}
															right={false}
														/>
													</FormRow>
												</div>
											}
										</div>
									</FormRow>}

									{index === tempOrderedProducts.length - 1 && <FormRow>
										<div className="add_list_element_button" onClick={() => { container.addTemporaryOrderedProduct() }}>
											<i className="icon-plus" />
											<p className="uppercase m-l-8">ADD PRODUCT</p>
										</div>
									</FormRow>
									}
								</div>
									;
							},
							)}
						</div>
						<div className={'col-6'}>
							<PrescriptionPreview showChangeButton={false} className={"prescription-preview--medium"} onChange={container.uploadPrescriptionPhoto} defaultImage={prescription.imageUrl.value} />
						</div>
					</FormRow>
					<FormRow>
						<Button onClick={() => { confirmTemporaryOrderedProducts(); }} disabled={isSubmitForAddOrderedProductDisabled()}>
							Add to order
						</Button>
						<Button onClick={() => { toggleAddOrderedProductsModal(false) }}>
							Cancel
						</Button>
					</FormRow>
				</Modal>

				<Modal isOpen={sendPaymentLinkModalVisible} onClose={() => closePaymentLinkModal()}>
					<FormRow>
						<h2 className="h4">Email payment link?</h2>
					</FormRow>

					<FormRow>
						<div className='bold'>
							Please confirm that you would like to email the payment link to
							{` ${prescription?.patientDetailsForBilling?.firstName} ${prescription?.patientDetailsForBilling?.lastName}`}
						</div>
					</FormRow>
					<FormRow>
						<div className='col-6'>
							<Button text="Confirm and send" onClick={sendPaymentLink} />
						</div>
						<div className='col-6'>
							<Button text="Cancel" onClick={() => closePaymentLinkModal()} />
						</div>
					</FormRow>
				</Modal>

				{/*Manual order split modal*/}
				<Modal isOpen={splitOrderModalVisible} onClose={() => closeManualSplitModal()}>
					<FormRow>
						<h2 className="h4">Split order</h2>
					</FormRow>

					<FormRow>
						<div className='bold'>
							Please select the product(s) you would like to split and move into a new order and provide a reason for doing so.
						</div>
					</FormRow>
					<FormRow>
						<CustomBanner
							className="banner--info"
							icon={<InfoIcon />}
							text={"Once you manually split an order, you will not be able to undo it"}
						/>
					</FormRow>
					{splitOrderState.orderToSplit?.value?.orderedProductsRaw?.map((orderedProduct, key) => {
						return <FormRow key={key}>
							<div key={key}>
								<SquareCheckbox
									value={splitOrderState.orderedProductsToSplit[key]?.isSelected}
									onChange={() => container.changeSelectedOrderedProductForSplit(key)}
									label={orderedProduct.name}
									href={`/products/edit/${orderedProduct.product.id}`}
									className={"orderedProduct-selected-checkbox"}
								/>
							</div>
						</FormRow>
					})}
					<FormRow>
						<Textarea
							name="splitOrderReason"
							placeholder="Please provide a reason for splitting the order"
							error={splitOrderState.splitOrderReason.error}
							value={splitOrderState.splitOrderReason.value || ""}
							onChange={onSplitOrderFormChange}
							maxLength={255}
						/>
					</FormRow>
					{Object.values(splitOrderState.orderedProductsToSplit).filter(op => { return op.isSelected }).length >= Object.values(splitOrderState.orderedProductsToSplit).length && (
						<FormRow style={{ alignItems: 'center', gap: '16px' }}>
							<ExclamationMarkRed />
							<p style={{ fontWeight: '700', color: '#E74C3C', flexGrow: '1' }}>To split an order, all products cannot be selected</p>
						</FormRow>
					)}

					<FormRow>
						<div className='col-6'>
							<Button text="Confirm split" onClick={onManuallySplitOrder} disabled={isSplitOrderDisabled()} />
						</div>
						<div className='col-6'>
							<Button text="Cancel" onClick={() => closeManualSplitModal()} />
						</div>
					</FormRow>
				</Modal>

				<Modal isOpen={regenerateLabelModalVisible} onClose={() => setRegenerateLabelModalVisible(false)}>
					<FormRow>
						<h2 className="h4">Shipping address</h2>
					</FormRow>
					<FormRow>
						<h2 className="h6">Delivery label</h2>
					</FormRow>

					<FormRow>
						<RadioSelector
							text={"Patient profile address"}
							selected={regenerateLabelState.addressToUseType.value === AddressTypesToUseForLabelGeneration.PatientProfileAddress}
							onClick={() => onRegenerateLabelAddressTypeChange(AddressTypesToUseForLabelGeneration.PatientProfileAddress)}
							className={"radio__selector--reversed"}
						/>
					</FormRow>

					<FormRow>
						<RadioSelector
							text={"Use different address"}
							selected={regenerateLabelState.addressToUseType.value === AddressTypesToUseForLabelGeneration.CustomAddress}
							onClick={() => onRegenerateLabelAddressTypeChange(AddressTypesToUseForLabelGeneration.CustomAddress)}
							className={"radio__selector--reversed"}
						/>
					</FormRow>

					<FormRow>
						<TextInput
							placeholder="e.g."
							label="Address line 1"
							name="address1"
							value={regenerateLabelState.address1.value}
							error={regenerateLabelState.address1.error}
							onChange={onRegenerateLabelFieldChange}
							disabled={areCustomAddressFieldsForRegenerateLabelDisabled}
						/>
					</FormRow>
					<FormRow>
						<TextInput
							placeholder="e.g."
							label="Address line 2"
							name="address2"
							value={regenerateLabelState.address2.value}
							error={regenerateLabelState.address2.error}
							onChange={onRegenerateLabelFieldChange}
							disabled={areCustomAddressFieldsForRegenerateLabelDisabled}
						/>
					</FormRow>
					<FormRow>
						<TextInput
							placeholder="e.g."
							label="Address line 3"
							name="address3"
							value={regenerateLabelState.address3.value}
							error={regenerateLabelState.address3.error}
							onChange={onRegenerateLabelFieldChange}
							disabled={areCustomAddressFieldsForRegenerateLabelDisabled}
						/>
					</FormRow>
					<FormRow>
						<TextInput
							placeholder="e.g."
							label="city"
							name="city"
							value={regenerateLabelState.city.value}
							error={regenerateLabelState.city.error}
							onChange={onRegenerateLabelFieldChange}
							disabled={areCustomAddressFieldsForRegenerateLabelDisabled}
						/>
					</FormRow>
					<FormRow>
						<TextInput
							placeholder="e.g."
							label="post code"
							name="zipCode"
							value={regenerateLabelState.zipCode.value}
							error={regenerateLabelState.zipCode.error}
							onChange={onRegenerateLabelFieldChange}
							disabled={areCustomAddressFieldsForRegenerateLabelDisabled}
						/>
					</FormRow>

					<FormRow>
						<Checkbox
							name="noteType"
							label="Must not leave with neighbour"
							value={regenerateLabelState.noteType.value === GenerateLabelNoteTypes.MustNotLeaveWithNeighbor}
							onChange={() => { onRegenerateLabelNoteTypeChange(GenerateLabelNoteTypes.MustNotLeaveWithNeighbor, "Must not leave with neighbour") }}
						/>
					</FormRow>

					<FormRow>
						<div className={"col-1"}>
							<Checkbox
								name="0"
								label=""
								value={regenerateLabelState.noteType.value === GenerateLabelNoteTypes.CustomNote}
								onChange={() => { onRegenerateLabelNoteTypeChange(GenerateLabelNoteTypes.CustomNote, "") }}
							/>
						</div>
						<div className={"col-11"}>
							{!(regenerateLabelState.noteType.value === GenerateLabelNoteTypes.CustomNote) ?
								<TextInput
									key={`${regenerateLabelState.noteType.value}`}
									name={'note'}
									value={""}
									disabled={true}
									placeholder={"Free text"}
								/>
								:
								<TextInput
									key={`${regenerateLabelState.noteType.value}`}
									name={'note'}
									error={regenerateLabelState.note.error}
									value={regenerateLabelState.note.value}
									disabled={!(regenerateLabelState.noteType.value === GenerateLabelNoteTypes.CustomNote)}
									onChange={onRegenerateLabelFieldChange}
									placeholder={"Free text"}
								/>
							}

						</div>
					</FormRow>

					<FormRow>
						<div className="col-6">
							<Button
								className="m-r-xs"
								onClick={() => generateOrRegenerateLabel()}
								disabled={
									isGenerateLabelButtonDisabled()
								}
							>
								{container.state.generatingLabel ? <SpinnerIcon /> : "Generate label"}
							</Button>
						</div>
						<div className="col-6">
							<Button
								className="m-r-xs btn--bordered--no_radius"
								onClick={() => setRegenerateLabelModalVisible(false)}
							>
								Cancel
							</Button>
						</div>
					</FormRow>

				</Modal>

				<Modal isOpen={resubmitOrderModalVisible} onClose={() => setResubmitOrderModalVisible(false)}>
					<FormRow>
						<h2 className="h4">Re-submit expired order</h2>
					</FormRow>
					<FormRow>
						<p className={''} >
							If the payment window has been missed by the patient, you can re-submit the order adding another 14 days for the patient to make payment.
						</p>
					</FormRow>
					<FormRow>
						<p className={''} >
							Before re-submitting, please confirm you have performed the below checks.
						</p>
					</FormRow>

					<FormRow>
						<p className={''} >
							I confirm I have checked
						</p>
					</FormRow>

					<FormRow style={{ marginBottom: "5px" }}>
						<SquareCheckbox
							className={"square-checkbox-container--large_label"}
							name="prescriptionIsInDate"
							value={resubmitOrderState.prescriptionIsInDate.value}
							onChange={onResubmitCheckboxChange}
							label={`Prescription is in date and not too close to the expiry `}
							right={false}
						/>
					</FormRow>
					<FormRow style={{ marginBottom: "5px" }} >
						<SquareCheckbox
							className={"square-checkbox-container--large_label"}
							name="orderHasBeenProcessed"
							value={resubmitOrderState.orderHasBeenProcessed.value}
							onChange={onResubmitCheckboxChange}
							label={`The order has been processed correctly e.g. price is correct`}
							right={false}
						/>
					</FormRow>
					<FormRow style={{ marginBottom: "5px" }} >
						<SquareCheckbox
							className={"square-checkbox-container--large_label"}
							name="productAddedAndInStock"
							value={resubmitOrderState.productAddedAndInStock.value}
							onChange={onResubmitCheckboxChange}
							label={`Products added to the order are still in stock `}
							right={false}
						/>
					</FormRow>
					<FormRow style={{ marginBottom: "5px" }} >
						<SquareCheckbox
							className={"square-checkbox-container--large_label"}
							name="prescriptionReadable"
							value={resubmitOrderState.prescriptionReadable.value}
							onChange={onResubmitCheckboxChange}
							label={`Prescription is readable and no other rewrites are required `}
							right={false}
						/>
					</FormRow>

					<FormRow>
						<div className="col-6">
							<Button
								disabled={!container.isFormValid(resubmitOrderFormKey)}
								onClick={() => { onResubmitOrder() }}
							>
								Re-submit
							</Button>
						</div>
						<div className="col-6">
							<Button
								className="btn--bordered--no_radius"
								onClick={() => { setResubmitOrderModalVisible(false) }}

							>
								Cancel
							</Button>
						</div>
					</FormRow>
				</Modal>

				{/* Existing orders modal */}
				<Modal style={{ maxWidth: "523px" }} isOpen={showExistingOrderModal} onClose={() => { setShowExistingOrderModal(false); setIsExistingOrderModalCheckboxChecked(false) }}>
					<FormRow className='justify-c-c'>
						<ExclamationMarkTriangle size={60} />
					</FormRow>

					<FormRow className='justify-c-c'>
						<h2 className="h4" >Existing active orders</h2>
					</FormRow>
					<FormRow className='justify-c-c'>
						<p>This prescription already has one or more active orders.
							<br />
							<br />
							If any active orders are no longer required, please make sure to cancel them before creating a new one.
						</p>
					</FormRow>
					<FormRow>
						<SquareCheckbox
							style={{ justifyContent: "flex-start" }}
							name="existingOrderModalCheckbox"
							value={isExistingOrderModalCheckboxChecked}
							onChange={() => { setIsExistingOrderModalCheckboxChecked(!isExistingOrderModalCheckboxChecked) }}
							label={`I am aware of the existing active orders and would like to create a new order`}
						/>
					</FormRow>

					<FormRow className='justify-c-sb'>
						<Button disabled={!isExistingOrderModalCheckboxChecked} onClick={() => { existingOrderModalConfirm(); setIsExistingOrderModalCheckboxChecked(false) }} text={"Continue"} />
						<Button className="m-l-xs btn--bordered--no_radius" style={{ maxWidth: "180px" }} disabled={false} onClick={() => { setShowExistingOrderModal(false); setIsExistingOrderModalCheckboxChecked(false) }} text={"Cancel"} />
					</FormRow>
				</Modal>

				{/* Shipping Modal */}
				<Modal isOpen={isShippingModalOpen}>
					<FormRow>
						<h2 className="h4 m-b-s">Remove shipping charges</h2>
					</FormRow>
					<FormRow>
						<h3 className="h5 m-b-s">Please provide a reason for removing the shipping charges on this order</h3>
					</FormRow>

					<FormRow>
						<div className="col-12 flex-col align-i-start">
							<Textarea
								name="removeShippingReason"
								placeholder="Please provide a reason for removing the shipping charges on this order"
								error={removeShippingReasonState.removeShippingReason.error}
								value={removeShippingReasonState.removeShippingReason.value || ""}
								onChange={onRemoveShippingFormChange}
								maxLength={255}
							/>
						</div>
					</FormRow>

					<FormRow>
						<Button
							style={{ marginRight: "10px" }}
							text="Confirm"
							onClick={handleOnClickShippingModalConfirm}
							disabled={!removeShippingReasonState.removeShippingReason.value.length || removeShippingReasonState.removeShippingReason.value.length > 255}
						/>
						<Button
							className="btn  btn--bordered--no_radius"
							text="Cancel"
							onClick={handleOnClickShippingModalCancel}
						/>
					</FormRow>
				</Modal>
			</div>
		);
	}

	return (
		<>
			{(container.state.loadingPrescription || container.state.creatingOrder || container.state.contacting || container.state.cancelingProduct || container.state.resubmitingOrder) ?
				<LoadingCard text={container.state.contacting ? "Sending message..." : "Loading prescription information"} />
				:
				<div className="card p-m">
					{renderPrompts()}

					{renderModals()}

					<FormRow>
						<div className="col-7 flex-col align-i-start">
							<div
								className="shadow_text_2 m-b-xs"
							>
								PATIENT ID: {prescription?.patientData?.id} |
								CLINIC ID: {prescription?.patientData?.clinicIdNumber || "N/A"} |
								CLINIC: {prescription?.organisation?.value?.name || "N/A"}
							</div>
							<h3 className="h3">{prescription.patient?.value?.fullName}</h3>
							{prescription.patient?.value?.fullName && (<div className='col-6'>
								<GenericTag className={`${genericTagClassnameMapper[prescription.patient?.value?.patientAccountType]}`} text={genericTagTextMapper[prescription.patient?.value?.patientAccountType]} />
								<GenericTag className={`m-l-s ${genericTagSubscriptionClassnameMapper[prescription.patient?.value?.subscriptionType]}`} text={`Subscription: ${genericTagSubscriptionTextMapper[prescription.patient?.value?.subscriptionType]} ${prescription.patient?.value?.subscriptionType != 0 ? ("Tier " + prescription.patient?.value?.subscriptionTier) : ""}`} />
							</div>
							)}
						</div>

						{currentAdministrator?.type !== AdminType.Eastone && (
							<div className={"col-5"}>
								<FormRow className={''}>
									<div className="col-2" />
									<div className="col-6">
									</div>
									<div className='col-4'>
										<Button
											className={"btn--bordered--no_radius"}
											onClick={() => handleOpenContactModal()}
											disabled={container.state.loadingPrescription}
										>
											<EnvelopeIcon />
										</Button>
									</div>

								</FormRow>
							</div>
						)}
					</FormRow>

					<FormRow>
						<div className="col-9">
							<h5 className="h5">Prescription {prescription.prescriptionNumber.value}</h5>
							{prescription.isUrgent.value && (
								<div style={{ display: 'flex', padding: '8px', justifyContent: 'space-between', alignItems: 'center' }} >
									<div className="m-r-s" >
										<UrgentIcon />
									</div>
								</div>
							)}
						</div>
						<div className="col-3">
							<CustomSelect
								name={"Owner"}
								label={"Owner"}
								options={container.state.partialAdmins}
								onChange={(e) => onOwnerChange(e)}
								values={[prescription.adminOwner?.value ? { label: prescription.adminOwner?.value?.name, value: prescription.adminOwner?.value?.id } : { label: 'None', value: "" }]}
							/>
						</div>
					</FormRow>


					{renderBanners()}


					{/*Prescription information*/}
					{currentAdministrator?.type !== AdminType.Eastone && (
						<>
							<FormRow>
								<div className="col-6">
									<div style={{ width: "100%" }}>

										<FormRow>
											<h2 className="h6">Prescription information</h2>
										</FormRow>

										<FormRow>
											<div className="col-8">
												<TextInput
													value={prescription.prescriptionNumber.value}
													error={prescription.prescriptionNumber.error}
													onChange={(e) => onPaperPrescriptionIdChange(e)}
													name="prescriptionNumber"
													label="Prescription number"
													placeholder="e.g. 742389479128"
												/>
											</div>
											<div className="col-4">
												<Dropdown
													value={prescription.isT21.value}
													error={prescription.isT21.error}
													onChange={onFieldChange}
													name="isT21"
													label="T21"
													options={contentHelper.getYesNoDropdownItems()}
												/>
											</div>
										</FormRow>
										<FormRow>
											<div className="col-7" id="selectPrescriber">
												<CustomSelect
													label="Organisation"
													name="organisationId"
													values={prescription.organisationId.value ? [prescription.organisationId.value] : []}
													options={container.state.filteredOrganisations}
													onChange={onSelectChangePrescription}
												/>
											</div>
											<RenderForAdminTypes adminTypes={[AdminType.Super]}>
												<div className="col-5">
													{prescription.organisationId.value && (
														<span className="rounded-info-span">
															<Link
																to={`/organisations/edit/${prescription.organisationId.value.value}`}>View</Link>
														</span>
													)}
													<Link to="/organisations/new"
														className="badge--no-border highlighted m-l-xs"
														title="Add a new organisation">
														<i className="icon-plus" />
													</Link>
												</div>
											</RenderForAdminTypes>
										</FormRow>
										<FormRow>
											<div className="col-7" id="selectPrescriber">
												{/* This conditional rendering solves the problem of not seeing prescribers */}
												{!container.state.loadingPrescribers && (
													<CustomSelect
														label="Prescriber"
														name="prescriber"
														values={prescription.prescriber.value ? [prescription.prescriber.value] : []}
														options={container.state.filteredPrescribers}
														onChange={onSelectChangePrescription}
													/>
												)}
											</div>

											<div className="col-5">
												{prescription.prescriber.value && (
													<span className="rounded-info-span">
														<Link
															to={`/prescribers/edit/${prescription.prescriber.value.prescriberId}`}>View</Link>
													</span>
												)}
												<Link to="/prescribers/new" className="badge--no-border highlighted m-l-xs"
													title="Add a new prescriber">
													<i className="icon-plus" />
												</Link>
											</div>
										</FormRow>
										<FormRow>
											<div id="selectPatient" className="col-7">
												<CustomSelect
													label="Patient"
													name="patient"
													values={prescription.patient.value ? [prescription.patient.value] : []}
													options={container.state.filteredPatients}
													onChange={(e) => { onSelectChangePrescriptionPatientField(e) }}
													onKeyDown={(e) => { onSelectKeyDownPrescriptionPatientField(e) }}
													placeholder={"Search patient"}
												/>
											</div>

											<div className="col-5">
												{prescription.patient.value && (
													<span className="rounded-info-span">
														<Link
															to={`/patients/edit/${prescription.patient.value.value}`}>View</Link>
													</span>
												)}
												<GenericTag className={`m-l-s ${genericTagClassnameMapper[prescription.patient?.value?.patientAccountType]}`} text={genericTagTextMapper[prescription.patient?.value?.patientAccountType]} />
											</div>
										</FormRow>
										<FormRow>
											<div className="col-6">
												<DateInput
													value={prescription.datePrescribed.value}
													error={prescription.datePrescribed.error}
													onChange={onFieldChange}
													name="datePrescribed"
													label="Date prescribed"
													placeholder="e.g. 10/09/2020"
												/>
											</div>

											<div className="col-6">
												<DateInput
													value={prescription.dateReceived.value}
													error={prescription.dateReceived.error}
													onChange={onFieldChange}
													name="dateReceived"
													label="Date received"
													placeholder="e.g. 10/09/2020"
												/>
											</div>
										</FormRow>

										<FormRow>
											<h2 className="h6">Flags</h2>
										</FormRow>
										<FormRow>
											<div className="col-4">
												<Dropdown
													value={prescription.isProcessed.value}
													error={prescription.isProcessed.error}
													onChange={onFieldChange}
													name="isProcessed"
													label="Processed"
													options={contentHelper.getYesNoDropdownItems()}
													tooltipText="To be able to mark a prescription as processed, you need to have a date prescribed and received selected"
													disabled={prescription.datePrescribed.value.length === 0 || prescription.dateReceived.value.length === 0}
												/>
											</div>
											<div className="col-3">
												<Dropdown
													value={prescription.isUrgent.value}
													error={prescription.isUrgent.error}
													onChange={onUrgentChange}
													name="isUrgent"
													label="Urgent"
													options={contentHelper.getYesNoDropdownItems()}
												/>
											</div>

											<div className="col-4">
												<Dropdown
													value={prescription.isPaperCopyReceived.value}
													error={prescription.isPaperCopyReceived.error}
													onChange={onFieldChange}
													name="isPaperCopyReceived"
													label="Paper copy received"
													options={contentHelper.getYesNoDropdownItems()}
												/>
											</div>
										</FormRow>

										<FormRow>
											<div className="col-4">
												<Button
													className="m-r-xs"
													onClick={container.update}
													disabled={
														container.state.updatingPrescription ||
														container.state.cancelingProduct ||
														!container.isFormValid("updatePrescription")
													}
												>
													{container.state.updatingPrescription ?
														<SpinnerIcon /> : "Update prescription information"}
												</Button>
											</div>
											<div className="col-4">
												{prescription.canAddProducts && prescription.canAddProducts.value === false &&
													<CustomIconWithTooltip
														style={{ margin: '5px', fontSize: "14px", maxWidth: '300px' }}
														toolTipText={"You can add products to create an order once you have marked the prescription as processed"}
														icon={<InfoIconGray />}
													/>
												}
												<Button
													className="m-r-xs"
													text="Add product(s)"
													onClick={onAddProductsToOrder}
													disabled={
														container.state.updatingPrescription ||
														container.state.cancelingProduct ||
														!prescription.datePrescribed.value ||
														// needs explicit check
														prescription.canAddProducts.value === false
													}
												/>
											</div>
											<div className="col-4">
												<Button
													className="m-r-xs btn--bordered--no_radius"
													onClick={() => {
														setRejectModalVisible(true);
													}}
													disabled={container.state.sendingToOrganisation}
												>
													{container.state.sendingToOrganisation ?
														<SpinnerIcon color={"black"} /> : "Send for Rewrite"}
												</Button>
											</div>
										</FormRow>
									</div>
								</div>

								<div style={{ flexDirection: "column" }} className="col-6">
									<div style={{ marginLeft: "auto", width: "150px" }}>
										{!prescription.isFlagged.value && (
											<Button
												className="m-r-xs btn--bordered--no_radius"
												style={{ border: "#FFFFFF" }}
												onClick={() => setShowFlagPopup(true)}
												disabled={
													prescription.isFlagged === true
												}
											>
												<FlagIcon />
												&nbsp; Add Flag
											</Button>
										)}
										{prescription.isFlagged.value && (
											<Button
												className="m-r-xs btn--bordered--no_radius"
												style={{ border: "#FFFFFF", whiteSpace: "nowrap" }}
												onClick={() => container.unflag()}
												disabled={
													prescription.isFlagged === false
												}
											>
												<FlagIcon />
												&nbsp; Remove Flag
											</Button>
										)}
									</div>
									<PrescriptionPreview onChange={container.uploadPrescriptionPhoto}
										defaultImage={prescription.imageUrl.value} />
								</div>
							</FormRow>
						</>
					)}

					<Separator />

					{/* Order Creation */}
					{orderedProducts?.length !== 0 && (
						<>
							<FormRow>
								<h2 className="h6">Order creation</h2>
							</FormRow>
							<FormRow>
								<Table
									title="Products"
									tableData={{
										head: tableHeaders.orderedProducts,
										body: orderedProducts,
									}}
									adjustable
									small
									onItemDelete={(index) => container.removeOrderedProductFromUnsortedList(index)}
								/>
							</FormRow>

							{renderUnorderedProductsPrice(price, shippingPrice)}

							{orderedProducts?.length !== 0 && (
								<FormRow style={{ justifyContent: "flex-end" }}>
									<Button
										className="m-r-xs"
										onClick={() => setCreateOrderModalVisible(true)}
										disabled={container.state.updatingPrescription || container.state.cancelingProduct}
									>
										{(container.state.updatingPrescription || container.state.cancelingProduct) ?
											<SpinnerIcon /> : "Create order"}
									</Button>
								</FormRow>
							)}
							<Separator />
						</>
					)}

					{/*Orders*/}
					{prescription.orders.value.length !== 0 && (
						<>
							<FormRow>
								<h2 className="h6">Orders</h2>
							</FormRow>

							{prescription.orders.value.map((order, key) => {
								return <div key={key}>
									<FormRow key={key}>
										<Table
											title={order.heading}
											tableHeadingClass={order.headingClass}
											tableHeadingElement={renderHeadingInformation(order)}
											filters={renderOrderButtons(order)}
											tableData={{
												head: tableHeaders.orderedProducts,
												body: order.orderedProducts,
											}}
											splitOrderBadge={prescription.splitOrders[key]}
											adjustable
											small
											bottomContent={renderProgressBar(order.status)}
											onItemDelete={(id) => {
												// There is no need to re-render, so this can be done without setState.
												container.state.cancellation.orderedProductId = id;

												setRemoveProductPromptVisibility(true);
											}}
										/>
									</FormRow>
								</div>;
							},
							)}
						</>
					)}

					{/*Prescription history*/}
					{currentAdministrator?.type !== AdminType.Eastone && (
						<>
							<FormRow>
								<h2 className="h6">Prescription history</h2>
							</FormRow>
							<FormRow>
								<div className="col-12">
									<Table
										title="Support comms"
										tableData={{
											head: tableHeaders.supportComms,
											body: prescription.supportComms,
										}}
										small
										showSpinner={container.state.loadingSupportComms}
									/>
								</div>
							</FormRow>
							<FormRow>
								<div className="col-12">
									<Table
										title="Event history"
										tableData={{
											head: tableHeaders.eventHistory,
											body: prescription.eventHistory,
										}}
										small
										showSpinner={container.state.loadingEventHistory}
									/>
								</div>
							</FormRow>

							<FormRow>
								<Textarea
									name="commentText"
									placeholder="Add a comment to event history"
									value={comment.commentText.value}
									error={comment.commentText.error}
									onChange={onCommentChange}
									maxLength={255}
								/>
							</FormRow>

							<FormRow>
								<Button
									disabled={container.state.creatingComment || !container.isFormValid("createComment")}
									onClick={createComment}>
									{container.state.creatingComment ? <SpinnerIcon /> : "Add Comment"}
								</Button>
							</FormRow>

							<FormRow>
								<h2 className="h6">Metadata</h2>
							</FormRow>
							<FormRow>
								<div className="col-6">
									<TextInput
										value={prescription.createdAt.value}
										name="createdAt"
										label="Created at"
										placeholder="20/10/2020 19:47"
										disabled
									/>
								</div>
								<div className="col-6">
									<TextInput
										value={prescription.updatedAt.value}
										name="updatedAt"
										label="Updated at"
										placeholder="20/10/2020 19:47"
										disabled
									/>
								</div>
							</FormRow>
							<Separator />
							<FormRow>
								<div className="col-4">

								</div>
								{prescription.isArchived.value === false &&
									<div className="col-4 justify-c-c">
										<Button
											className="m-r-xs btn--bordered--no_radius"
											onClick={() => setShowArchivePopup(true)}
											disabled={
												prescription.isArchived.value ||
												container.state.loadingPrescription ||
												container.state.updatingPrescription ||
												container.state.cancelingProduct
											}
										>
											<ArchiveBox /> &nbsp; Archive
										</Button>
									</div>
								}
								{prescription.isArchived.value && [AdminType.Super].includes(currentAdministrator.type) &&
									<div className="col-4">
										<Button
											className="m-r-xs btn--bordered--no_radius"
											onClick={() => setShowUnarchivePopup(true)}
											disabled={
												prescription.isArchived.value === false ||
												container.state.loadingPrescription ||
												container.state.updatingPrescription ||
												container.state.cancelingProduct
											}
										>
											<UnarchiveBox /> &nbsp; Unarchive
										</Button>
									</div>
								}
								<div className="col-4">

								</div>
							</FormRow>
							<SendToOrganisationModal
								rejectModalVisible={rejectModalVisible}
								setRejectModalVisible={setRejectModalVisible}
								imageUrl={prescription.imageUrl.value}
							/>
							{renderUnlinkModal()}
							{renderRemoveAwaitingRewriteStatusModal()}
						</>
					)}
				</div>
			}
		</>

	);
}

function SubscribedEditPrescription(props) {
	return (
		<Subscribe to={[PrescriptionContainer, AuthenticationContainer]}>
			{(container, authContainer) => (
				<EditPrescription {...props} container={container} currentAdministrator={authContainer.state.currentAdministrator} />
			)}
		</Subscribe>
	);
}


const genericTagClassnameMapper = {
	[PatientAccountTypes.isSignedUp]: "b-light-green",
	[PatientAccountTypes.isNotSignedUp]: "b-light-gray",
	[PatientAccountTypes.isPartiallySignedUp]: "b-light-orange",
	undefined: "",
}

const genericTagTextMapper = {
	[PatientAccountTypes.isSignedUp]: "Signed up",
	[PatientAccountTypes.isNotSignedUp]: "Not signed up",
	[PatientAccountTypes.isPartiallySignedUp]: "partial sign up",
	undefined: "",
}

export default SubscribedEditPrescription;
