import React from "react";
import { Subscribe } from "unstated";
import AuthenticationContainer from "../../state/AuthenticationContainer";

function RenderForAdminTypes({ currentAdministrator, children, adminTypes = [] }) {
	return (
		adminTypes.includes(currentAdministrator.type) ?
			<>
				{children}
			</>
			:
			<>
			</>
	);
}

function SubscribedRenderForAdminTypes(props) {
	return <Subscribe to={[AuthenticationContainer]}>{(container) => <RenderForAdminTypes {...props} currentAdministrator={container.state.currentAdministrator} />}</Subscribe>;
}

export default SubscribedRenderForAdminTypes;