import React from 'react'
import { ExclamationMarkTriangle, SpinnerIcon } from '../../assets/icons';
import ExclamationMarkCircle from "../../assets/icons/ExclamationMarkCircle";
import ExclamationMarkCircleEmpty from '../../assets/icons/ExclamationMarkCircleEmpty';
import InfoIcon from '../../assets/icons/InfoIcon';
import NoteIcon from "../../assets/icons/NoteIcon";
import { BannerTypes } from '../../enums'

const Banner = ({ children, className, type = BannerTypes.Warning, text }) => {

  const resolveClass = () => {
    return bannerTypeToClassMapper[type]
  }

  return (
    <div className={`banner ${className} ${resolveClass()}`} >
      <div className='banner__icon'>
        {bannerTypeToIconMapper[type]}
      </div>
      <div className='banner__body'>
        {text} {children}
      </div>
    </div>
  )
}

export default Banner

const bannerTypeToClassMapper = {
  [BannerTypes.Warning]: "banner--warning",
  [BannerTypes.Error]: "banner--error",
  [BannerTypes.Error2]: "banner--error",
  [BannerTypes.Info]: "banner--info",
  [BannerTypes.Note]: "banner--info",
  [BannerTypes.InfoDelay]: "banner--info--delay",
}

const bannerTypeToIconMapper = {
  [BannerTypes.Warning]: <ExclamationMarkTriangle />,
  [BannerTypes.Error]: <ExclamationMarkCircle />,
  [BannerTypes.Error2]: <ExclamationMarkCircleEmpty />,
  [BannerTypes.Info]: <InfoIcon />,
  [BannerTypes.Note]: <NoteIcon />,
  [BannerTypes.InfoDelay]: <SpinnerIcon color='black' width='20' />
}