import React from 'react'

const ArrowIcon = () => {
	return (
		<svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.1998 22.3C1.9998 22.3 1.83314 22.2333 1.6998 22.1L0.499804 21C0.36647 20.8 0.299805 20.6 0.299805 20.4C0.299805 20.2 0.36647 20.0333 0.499804 19.9L9.29981 11.2L0.499804 2.4C0.366471 2.26667 0.299806 2.1 0.299806 1.9C0.299806 1.7 0.366471 1.53333 0.499804 1.4L1.69981 0.199999C1.83314 0.066666 1.99981 -4.93939e-07 2.19981 -4.85196e-07C2.39981 -4.76454e-07 2.56647 0.066666 2.69981 0.199999L13.0998 10.6C13.2331 10.8 13.2998 11 13.2998 11.2C13.2998 11.4 13.2331 11.5667 13.0998 11.7L2.6998 22.1C2.56647 22.2333 2.3998 22.3 2.1998 22.3V22.3Z" fill="black" />
		</svg>
	)
}

export default ArrowIcon
