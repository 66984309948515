import axios from "axios";
import apiClient from "../clients/apiClient";

class ProductsService {
	constructor() {
		this.client = {
			getById: apiClient.getById("products"),
			filter: apiClient.filter("products"),
			create: apiClient.create("products"),
			delete: apiClient.delete("products"),
			update: apiClient.update("products"),
			createComment: apiClient.create("productevents"),
		};
	}

	async getById(id) {
		const { data: product } = await this.client.getById(id);

		return product;
	}

	async filter(queryString, page, pageSize, sortField, sortDirection, cancelToken) {
		const filters = { query: queryString, page: page, pageSize: pageSize, sortField: sortField, sortDirection: sortDirection };

		const { data: products } = await this.client.filter(filters, cancelToken);

		return products;
	}

	async create(productPayload) {
		const { data: newProduct } = await this.client.create(productPayload);

		return newProduct;
	}

	async update(productUpdate) {
		const { data: updatedProduct } = await this.client.update(productUpdate);

		return updatedProduct;
	}

	async delete(id) {
		await this.client.delete({ id });
	}

	async getAllSuppliers() {
		const { data } = await apiClient.getAllSuppliers();
		return data
	}

	async updateStockForProduct(payload) {
		const { data } = await apiClient.updateStockForProduct(payload);
		return data
	}

	async editStockForProduct(payload) {
		const { data } = await apiClient.editStockForProduct(payload)
		return data
	}

	async updateStockForProductManually(payload) {
		const { data } = await apiClient.updateStockForProductManually(payload);
		return data
	}

	async getAllOrdersForProduct(payload) {
		const { data } = await apiClient.getAllOrdersForProduct(payload);
		return data
	}

	async createComment(commentCreate) {
		const { data } = await this.client.createComment(commentCreate);

		return data;
	}

	async addPrescriberNote(payload) {
		const { data } = await apiClient.addPrescriberNote(payload);

		return data;
	}

	async removePrescriberNote(productId) {
		const { data } = await apiClient.removePrescriberNote(productId);

		return data;
	}

	async getAllCommentsByProductId(productId) {
		const { data } = await apiClient.getAllCommentsByProductId(productId);

		return data.sort(function (comm1, comm2) {
			var comm1CreatedAt = new Date(comm1.createdAt);
			var comm2CreatedAt = new Date(comm2.createdAt);

			return comm1CreatedAt - comm2CreatedAt;
		}).reverse();
	}

	//Product Media
	async getAllProductImagesById(productId) {
		const { data } = await apiClient.getAllProductImagesById(productId);
		return data
	}


	async submitProductImage(id, image) {
		const config = { responseType: "blob" };
		const { data: productBlob } = await axios.get(image.mediaUrl, config);
		const product = new File([productBlob], image.file.name);

		const payload = new FormData();
		payload.append("productId", id);
		payload.append("image", product);
		payload.append("mediaAccessLevel", image.mediaAccessLevel);


		const { data } = await apiClient.submitProductImage(payload);

		return data;
	}

	async getAllProductDocumentsById(productId) {
		const { data } = await apiClient.getAllProductDocumentsById(productId);
		return data;
	}

	async submitDocument(id, document) {
		const config = { responseType: "blob" };
		const { data: productBlob } = await axios.get(document.mediaUrl, config);
		const product = new File([productBlob], document.file.name);

		const payload = new FormData();
		payload.append("productId", id);
		payload.append("document", product);
		payload.append("mediaAccessLevel", document.mediaAccessLevel);

		const { data } = await apiClient.submitDocument(payload);

		return data;
	}

	async deleteProductMedia(productMediaId) {
		const { data } = await apiClient.deleteProductMedia(productMediaId);
		return data;
	}

	async getAllTerpenes() {
		const { data } = await apiClient.getAllTerpenes();
		return data
	}

	async getProductStockMetrics() {
		const data = await apiClient.getProductStockMetrics();

		return data;
	}

	async updateProductMediaAccessLevel(index, newAccessLevel) {
		const { data } = await apiClient.updateProductMediaAccessLevel(index, newAccessLevel);

		return data;
	}
}

export default new ProductsService();
