import React from 'react'

const Drawer = ({ buttons, setCurrent, current }) => {
    const handleOnClick = (id) => {
        setCurrent(id)
    }
    const renderClassNames = (button) => {
        let initial = `drawer__button`
        if (button.id === current) {
            initial += ' drawer__button--active'
        }
        return initial
    }
    return (
        <div className='drawer'>
            {buttons.map(b => {
                return (
                    <div key={b.id} onClick={() => { handleOnClick(b.id) }} className={renderClassNames(b)}>{b.text}</div>
                );
            })}
        </div>
    )
}

export default Drawer
