import React from 'react'

const Flag = ({ squareColor = `#EFEFEF`, flagColor = `#808080` }) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="16" height="16" rx="2" fill={squareColor} />
            <path d="M13.1842 3.06109C13.2332 3.0939 13.2734 3.13827 13.3013 3.19029C13.3291 3.24231 13.3437 3.30038 13.3438 3.35938V8.75C13.3437 8.82177 13.3222 8.89189 13.282 8.95133C13.2418 9.01077 13.1847 9.0568 13.1181 9.0835L12.9844 8.75L13.1181 9.0835L13.1159 9.08422L13.1116 9.08638L13.0951 9.09284C13.0006 9.1304 12.9054 9.16634 12.8097 9.20066C12.62 9.26894 12.3562 9.36094 12.0572 9.45222C11.4707 9.63334 10.7067 9.82812 10.1094 9.82812C9.50059 9.82812 8.99675 9.62688 8.55831 9.45078L8.53819 9.44359C8.0825 9.26031 7.69438 9.10938 7.23438 9.10938C6.73125 9.10938 6.05706 9.27469 5.48278 9.45222C5.22565 9.53236 4.97087 9.61984 4.71875 9.71456V14.1406C4.71875 14.2359 4.68089 14.3273 4.61349 14.3947C4.5461 14.4621 4.45469 14.5 4.35938 14.5C4.26406 14.5 4.17265 14.4621 4.10526 14.3947C4.03786 14.3273 4 14.2359 4 14.1406V3.35938C4 3.26406 4.03786 3.17265 4.10526 3.10526C4.17265 3.03786 4.26406 3 4.35938 3C4.45469 3 4.5461 3.03786 4.61349 3.10526C4.68089 3.17265 4.71875 3.26406 4.71875 3.35938V3.56206C4.88119 3.50528 5.07525 3.43988 5.28656 3.37519C5.87306 3.1955 6.63781 3 7.23438 3C7.83812 3 8.32975 3.19909 8.75884 3.37303L8.78975 3.38597C9.23681 3.56638 9.62638 3.71875 10.1094 3.71875C10.6125 3.71875 11.2867 3.55344 11.861 3.37591C12.1882 3.27371 12.5116 3.15959 12.8306 3.03378L12.8442 3.02875L12.8471 3.02731H12.8478" fill={flagColor} />
        </svg>
    )
}

export default Flag
