import React from 'react'

const CustomBanner = ({ children, className, text, icon, boldText, style = {} }) => {

  return (
    <div style={{ ...style }} className={`banner ${className} `} >
      <div className='banner__icon'>
        {icon}
      </div>
      <div className='banner__body'>
        <b>{boldText}</b>{text} {children}
      </div>
    </div>
  )
}

export default CustomBanner
