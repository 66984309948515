import React from "react";

function Button({
  text = "Submit",
  href = null,
  onClick = () => null,
  disabled = false,
  className = "",
  title = "",
  children = null,
  bordered = false,
  style = {}
}) {
  if (href)
    return (
      <a style={style} className={`btn ${bordered ? "btn--bordered" : ""} ${className}`} title={title} onClick={onClick} href={href}>
        {children ? children : text}
      </a>
    );
  else
    return (
      <button style={style} className={`btn ${bordered ? "btn--bordered" : ""} ${className}`} title={title} onClick={onClick} disabled={disabled}>
        {children ? children : text}
      </button>
    );
}

export default Button;
