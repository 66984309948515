export function organisationsApiToClient(organisations) {
	const result = [];

	organisations.forEach(organisation => {
		result.push(organisationApiToClient(organisation))
	})
	return result;
}

export function organisationApiToClient(organisation) {

	return {
		address1: organisation.address1,
		address2: organisation.address2,
		email: organisation.email,
		id: organisation.id,
		name: organisation.name,
		numberOfPrescribers: organisation.numberOfPrescribers,
		numberOfPatients: organisation.numberOfPatients,
		rewritesEmail: organisation.rewritesEmail,
		telephoneNo: organisation.telephoneNo,
		zipCode: organisation.zipCode,
		type: organisation.type,
		referenceNumber: organisation.referenceNumber,
		organisationUsers: organisation.organisationUsers
	}
}

export function organisationNamesApiToClient(organisations) {

	const unique = [...new Set(organisations.map(item => item.locationName))]

	return unique.map((organisation) => {
		return {
			label: organisation,
			value: organisation,
		}
	})
}