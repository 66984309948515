import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";

import carerService from "../services/CarersService";
import patientService from "../services/PatientsService";

import browserHelper from "../util/helpers/browserHelper";
import errorHelper from "../util/helpers/errorHelper";
import { required, isFormValid, isDateInEuroFormat } from "../util/validation/validation";
import { formatDate, formatDateForApi, formatDateForPresenting } from "../util/dates";
import { Throttle } from "../util/Throttle";
import tableHeaders from "../util/constants/tableHeaders";
import { containerSort } from "../util/helpers/sorting";

const updateCarerInitialState = {
	id: { value: "" },
	email: { value: "" },
	isEmailVerified: { value: false },
	isIDVerified: { value: false },
	agreedToTerms: { value: false },
	secretId: { value: "" },
	secretExpiry: { value: "" },
	createdAt: { value: "" },
	updatedAt: { value: "" },
	lastLoggedIn: { value: "" },

	title: { value: "" },
	firstName: { value: "" },
	lastName: { value: "" },
	dateOfBirth: { value: "" },
	telephoneNo: { value: "" },
	patientRelationship: { value: 0 },
	address1: { value: "" },
	address2: { value: "" },
	zipCode: { value: "" },

	patient: { value: null },
};

class CarerContainer extends Container {
	//#region State

	constructor() {
		super();

		this.state = {
			tableHeaders: tableHeaders.carers,
			tableData: [],

			updateCarer: { ...updateCarerInitialState },

			filteredPatients: [],

			// loading flags
			updatingCarer: false,
			loadingCarers: false
		};

		this.validators = {
			newCarer: {
				firstName: [required],
				dateOfBirth: [isDateInEuroFormat]
			},
			updateCarer: {
				firstName: [required],
				dateOfBirth: [isDateInEuroFormat]
			},
		};

		this.throttle = new Throttle();
		this.filterPatientsSelectThrottle = new Throttle();
	}

	resetUpdateCarerState = () => {
		this.setState({ updateCarer: updateCarerInitialState });
	};

	//#endregion

	//#region Util functions for inputs

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}

		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onPatientChange = (e, formKey) => {
		const newField = { value: e.value[0], error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	//#endregion

	//#region Table data

	sort = containerSort.bind(this);

	filter = async (queryString) => {
		this.throttle.setTimeout(() => this._filterCallback(queryString), 300);
	};

	_filterCallback = async (queryString) => {
		try {
			this.setState({ loadingCarers: true });
			const result = await carerService.filter(queryString);

			const newTableData = result.map((carer) => {

				let agreedIcon = "";
				let verifiedIcon = "";

				if (carer.agreedToTerms) agreedIcon = "✔";
				else agreedIcon = "✘";

				if (carer.isEmailVerified) verifiedIcon = "✔";
				else verifiedIcon = "✘";

				return [
					{
						text: carer.id,
						column: 1,
					},
					{
						text: `${carer.firstName} ${carer.lastName}`,
						href: `/carers/edit/${carer.id}`,
						column: 2,
					},
					{
						text: carer.email,
						column: 2,
					},
					{
						text: `${carer.patient.firstName} ${carer.patient.lastName}`,
						href: `/patients/edit/${carer.patient.id}`,
						column: 2,
					},
					{
						text: formatDateForPresenting(carer.createdAt, false),
						column: 1,
						right: true,
						type: "date",
					},
					{
						text: agreedIcon,
						column: 2,
						right: true,
					},
					{
						text: verifiedIcon,
						column: 2,
						right: true,
					},
				];
			});

			this.setState({ tableData: newTableData });
		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ loadingCarers: false });
	};

	filterPatientsSelect = async (queryString) => {
		this.filterPatientsSelectThrottle.setTimeout(() => this._filterPatientsSelectCallback(queryString));
	};

	_filterPatientsSelectCallback = async (queryString) => {
		try {
			const result = await patientService.filterForCarer(queryString);

			const filteredPatients = result.map((patient) => {
				return {
					label: `${patient.firstName} ${patient.lastName} - ${formatDate(patient.dateOfBirth)}`,
					value: patient.id,
				};
			});

			this.setState({ filteredPatients });
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	//#endregion

	//#region CRUD

	setForUpdate = async (id) => {
		try {
			const result = await carerService.getById(id);

			const updateCarer = {
				id: { value: result.id },
				email: { value: result.email },
				isEmailVerified: { value: result.isEmailVerified },
				isIDVerified: { value: result.isIDVerified },
				agreedToTerms: { value: result.agreedToTerms },
				secretId: { value: result.secretId },
				secretExpiry: { value: result.secretExpiry },
				createdAt: { value: result.createdAt },
				updatedAt: { value: result.updatedAt },
				lastLoggedIn: { value: result.lastLoggedIn },

				title: { value: result.title },
				firstName: { value: result.firstName },
				lastName: { value: result.lastName },
				dateOfBirth: { value: result.dateOfBirth },
				telephoneNo: { value: result.telephoneNo },
				address1: { value: result.address1 },
				address2: { value: result.address2 },
				zipCode: { value: result.zipCode },

				patientRelationship: { value: result.patientRelationship },
				patient: {
					value: {
						label: `${result.patient.firstName} ${result.patient.lastName} ${result.patient.dateOfBirth}`,
						value: result.patient.id,
					},
				},
			};

			this.setState({ updateCarer });
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	update = async () => {
		this.setState({ updatingCarer: true });

		try {
			await carerService.update({
				id: this.state.updateCarer.id.value,
				email: this.state.updateCarer.email.value,
				isEmailVerified: this.state.updateCarer.isEmailVerified.value,
				isIDVerified: this.state.updateCarer.isIDVerified.value,
				agreedToTerms: this.state.updateCarer.agreedToTerms.value,

				title: this.state.updateCarer.title.value,
				firstName: this.state.updateCarer.firstName.value,
				lastName: this.state.updateCarer.lastName.value,
				dateOfBirth: formatDateForApi(this.state.updateCarer.dateOfBirth.value, "DD/MM/YYYY"),
				telephoneNo: this.state.updateCarer.telephoneNo.value.trim(),
				address1: this.state.updateCarer.address1.value,
				address2: this.state.updateCarer.address2.value,
				zipCode: this.state.updateCarer.zipCode.value,

				patientId: this.state.updateCarer.patient.value.value,
				patientRelationship: this.state.updateCarer.patientRelationship.value,
			});

			notificationHelper.info("Carer updated!");

			browserHelper.navigate("/carers");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingCarer: false });
	};

	delete = async (id) => {
		try {
			await carerService.delete(id);

			notificationHelper.info("Carer deleted!");

			browserHelper.navigate("/carers");
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	//#endregion
}

export default CarerContainer;
