import React from 'react'

const SuccessIcon = ({ backgroundColor = "white", color = "#16A085", width = "40" }) => {
    return (
        <svg width={width} height={width} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill={backgroundColor} />
            <path d="M27.082 11.1719L17.918 23.2539L12.5 17.8398L10 20.3398L18.332 28.6719L30 13.6719L27.082 11.1719Z" fill={color} />
        </svg>
    )
}

export default SuccessIcon
