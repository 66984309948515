import React from 'react'

const RadioSelector = ({ selected = false, onClick = () => null, text = "", className = "" }) => {
    return (
        <div className={`radio__selector ${className}`} >
            <div className='radio__selector__label' >
                {text}
            </div>
            <div className='radio__selector__select' onClick={() => { onClick() }} >
                {selected && <div className='radio__selector__select__fieller' ></div>}
            </div>
        </div>
    )
}

export default RadioSelector
