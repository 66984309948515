import React from 'react'

const GenericToolTip = ({ text = 'Tooltip text', className = "" }) => {
    return (
        <div className={`generic_tool_tip  ${className}`}>
            <div className='generic_tool_tip__text'>
                {text}
            </div>
            <div className='generic_tool_tip__pointer'></div>
        </div>
    )
}

export default GenericToolTip
