import apiClient from "../clients/apiClient";

import browserHelper from "../util/helpers/browserHelper";

import { adminApiToClient } from "../converters/adminsConverter";

import { tokenKey } from "../config.json";

class AuthenticationService {
	async signin(credentials) {
		try {
			credentials.email = credentials.email.trim().toLowerCase();

			const currentToken = await this.getTokenFromAPI(credentials);

			this.saveTokenToStorage(currentToken);

			const currentAdministrator = await this.getAdministratorDataFromAPI(currentToken);

			return { currentToken, currentAdministrator };
		} catch (error) {
			if (error.response?.data.ErrorCode === 411) {
				return browserHelper.navigate(`/resetpassword/${error.response?.data.Message}/mandatory`);
			}
			if (error.response?.data.ErrorCode === 412) {
				return browserHelper.navigate(`/resetpassword/${error.response?.data.Message}`);
			}
			else {
				return browserHelper.navigate("/login/incorrect");
			}
		}
	}

	async requestPasswordReset(email) {
		email = email.trim().toLowerCase()
		await apiClient.requestPasswordReset(email);
	}

	async resetPassword(payload) {
		await apiClient.resetPassword(payload);
	}

	async getTokenFromAPI(credentials) {
		const { data } = await apiClient.login(credentials);

		return data.token;
	}

	saveTokenToStorage(token) {
		localStorage.setItem(tokenKey, token);
	}

	signout() {
		this.removeTokenFromStorage();

		browserHelper.navigate("/login");
	}

	removeTokenFromStorage() {
		localStorage.removeItem(tokenKey);
	}

	async readAdministratorFromStorage() {
		const currentToken = this.readTokenFromStorage();

		if (currentToken) {
			const currentAdministrator = await this.getAdministratorDataFromAPI();

			return { currentToken, currentAdministrator };
		}
	}

	readTokenFromStorage() {
		return localStorage.getItem(tokenKey);
	}

	async getAdministratorDataFromAPI() {
		const { data: admin } = await apiClient.getAdminData();

		return adminApiToClient(admin);
	}
}

export default new AuthenticationService();
