import React from 'react'

const XIconInACircle = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99979 0.888672C6.59334 0.888672 5.21848 1.30573 4.04906 2.08711C2.87965 2.86849 1.9682 3.97909 1.42998 5.27848C0.891753 6.57786 0.750929 8.00767 1.02531 9.38709C1.2997 10.7665 1.97696 12.0336 2.97147 13.0281C3.96598 14.0226 5.23306 14.6999 6.61248 14.9743C7.9919 15.2486 9.4217 15.1078 10.7211 14.5696C12.0205 14.0314 13.1311 13.1199 13.9125 11.9505C14.6938 10.7811 15.1109 9.40623 15.1109 7.99978C15.1109 6.1138 14.3617 4.30506 13.0281 2.97147C11.6945 1.63788 9.88577 0.888672 7.99979 0.888672ZM11.5553 10.7109C11.6732 10.8288 11.7394 10.9886 11.7394 11.1553C11.7394 11.322 11.6732 11.4819 11.5553 11.5998C11.4375 11.7177 11.2776 11.7839 11.1109 11.7839C10.9442 11.7839 10.7843 11.7177 10.6665 11.5998L7.99979 8.93312L5.33312 11.6087C5.27475 11.667 5.20546 11.7133 5.12921 11.7449C5.05295 11.7765 4.97122 11.7928 4.88868 11.7928C4.80613 11.7928 4.7244 11.7765 4.64814 11.7449C4.57189 11.7133 4.5026 11.667 4.44423 11.6087C4.38587 11.5503 4.33957 11.481 4.30798 11.4048C4.27639 11.3285 4.26014 11.2468 4.26014 11.1642C4.26014 11.0817 4.27639 11 4.30798 10.9237C4.33957 10.8474 4.38587 10.7781 4.44423 10.7198L7.1109 8.03534L4.36867 5.27089C4.2508 5.15302 4.18458 4.99315 4.18458 4.82645C4.18458 4.65975 4.2508 4.49988 4.36867 4.382C4.48655 4.26413 4.64642 4.19791 4.81312 4.19791C4.97982 4.19791 5.13969 4.26413 5.25756 4.382L7.99979 7.15534L10.742 4.41312C10.8004 4.35475 10.8697 4.30845 10.9459 4.27687C11.0222 4.24528 11.1039 4.22902 11.1865 4.22902C11.269 4.22902 11.3507 4.24528 11.427 4.27687C11.5032 4.30845 11.5725 4.35475 11.6309 4.41312C11.6893 4.47148 11.7356 4.54077 11.7671 4.61703C11.7987 4.69329 11.815 4.77502 11.815 4.85756C11.815 4.9401 11.7987 5.02183 11.7671 5.09809C11.7356 5.17435 11.6893 5.24364 11.6309 5.30201L8.88867 8.03534L11.5553 10.7109Z" fill="#B3B3B3" />
        </svg>
    )
}

export default XIconInACircle
