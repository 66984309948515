import React from "react";

function DateInput({
	value = "",
	name = "",
	label = "",
	disabled = false,
	placeholder = "Date input",
	error = false,
	onKeyUp = () => null,
	onSubmit = () => null,
	onChange = () => null,
}) {
	function onKeyUpHandler(e) {
		onKeyUp(e);

		if (e.key === "Enter") onSubmit();
	}

	return (
		<div className="input-container">
			<input
				className={error ? "error" : ""}
				type="date"
				name={name}
				placeholder={placeholder}
				disabled={disabled}
				defaultValue={value}
				onKeyUp={onKeyUpHandler}
				onChange={(e) => onChange({ name, value: e.target.value })}
				max="9999-12-31"
			/>
			<label className="component-label">{error ? error : label}</label>
			<div className="animated-border"></div>
		</div>
	);
}

export default DateInput;
