import { Container } from "unstated";
import errorHelper from "../util/helpers/errorHelper";
import { containerSort } from "../util/helpers/sorting";
import { Throttle } from "../util/Throttle";
import tableHeaders from "../util/constants/tableHeaders";

import prescriptionService from "../services/PrescriptionsService";
import browserHelper from "../util/helpers/browserHelper";
import { isFormValid } from '../util/validation/validation';
import prescribersService from "../services/PrescribersService";
import OrganisationsService from "../services/OrganisationsService";

const assignPrescriptionInitialState = {
	patient: { value: null },
	prescriber: { value: null },
	prescription: { value: null },
	trackingNumber: { value: '' },
	patientsFullName: { value: false },
	patientsFullAddress: { value: false },
	productName: { value: false },
	prescriberSigniture: { value: false },
}

const rejectPrescriptionInitialState = {
	highLevelReason: { value: "", label: 'Select' },
	reason: { value: "", label: 'Select' },
	prescriptionNumber: { value: "" },
	patient: { value: null },
}

const bulkUploadPrescriptionsInitialState = {
	prescriber: { value: null },
	organisationId: { value: null },
	trackingNumber: { value: '' },
	prescriptions: { value: [] }
}

class BulkUploadprescriptionContainer extends Container {
	constructor() {
		super();

		this.state = {
			tableData: [],
			tableHeaders: tableHeaders.toBeAssigned(),

			toBeAssigned: {
				patient: { value: null },
				prescriber: { value: null },
			},
			bulkUpload: { ...bulkUploadPrescriptionsInitialState },
			assignPrescription: { ...assignPrescriptionInitialState },
			rejectPrescription: { ...rejectPrescriptionInitialState },
			filteredOrganisations: [],
			filteredPrescribers: [],
			filteredPrescribersOriginal: [],
			submittingPrescriptions: false,
			loadingToBeAssigned: false
		};

		this.throttle = new Throttle();

		this.validators = {
			bulkUpload: {
				trackingNumber: []
			},
		};
	}

	sort = containerSort.bind(this);

	onSelectChange = (e, formKey) => {
		if (e.value[0] === "true" || e.value[0] === "false") {
			e.value[0] = JSON.parse(e.value[0]);
		}

		const newField = { value: e.value[0], error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}

		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	setPrescriptions = (selectedPrescriptions) => {
		const newField = { value: selectedPrescriptions, error: null };
		this.setState({ bulkUpload: { ...this.state.bulkUpload, prescriptions: newField } });
	}

	setForCreate = async () => {
		this.setState({ loadingPrescription: true });

		try {
			await this._getOrganisations();
			await this._getPrescribersWithoutPatients();

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ loadingPrescription: false });
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	_getPrescribersWithoutPatients = async () => {
		this.setState({ loadingPrescribers: true });

		const orgId = this.state.bulkUpload.organisationId.value ? this.state.bulkUpload.organisationId.value.value : this.state.filteredOrganisations[0].value
		const result = await prescribersService.getAllPrescribersWithoutPatients(orgId);
		const filteredPrescribers = result.map((prescriber) => {
			return {
				label: `${prescriber?.firstName} ${prescriber?.lastName}`,
				value: prescriber?.id,
			};
		});

		const filteredPrescribersOriginal = [...filteredPrescribers];

		this.setState({ filteredPrescribers, filteredPrescribersOriginal, loadingPrescribers: false });
	};

	resetFilterPrescribersToFilterPrescribersOriginal = () => {
		const filteredPrescribersOriginal = this.state.filteredPrescribersOriginal;

		this.setState({ filteredPrescribers: [...filteredPrescribersOriginal] })
	}

	_getPrescribers = async () => {
		this.setState({ loadingPrescribers: true });

		const result = await prescribersService.filter("");

		const filteredPrescribers = result.map((prescriber) => {
			return {
				label: `${prescriber?.firstName} ${prescriber?.lastName}`,
				value: prescriber?.id,
			};
		});

		this.setState({ filteredPrescribers, loadingPrescribers: false });
	};

	_getOrganisations = async () => {
		this.setState({ loadingOrganisations: true });

		const result = await OrganisationsService.filter("");

		const filteredOrganisations = result.map((organisation) => {
			return {
				label: `${organisation?.name}`,
				value: organisation?.id,
			};
		});

		this.setState({ filteredOrganisations, loadingOrganisations: false });
	};


	create = async () => {
		let prescriptions = this.state.bulkUpload.prescriptions.value;
		let prescriber = this.state.bulkUpload.prescriber.value.value;
		let trackingNumber = this.state.bulkUpload.trackingNumber.value;
		let organisationId = this.state.bulkUpload.organisationId.value.value;
		try {
			this.setState({ submittingPrescriptions: true });
			await prescriptionService.bulkUploadPrescriptionImages(prescriptions, prescriber, trackingNumber, organisationId);
			this.resetBulkUploadState();
			browserHelper.navigate('/prescriptions/toBeAssigned');
		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ submittingPrescriptions: false });
	}

	resetBulkUploadState = () => {
		this.setState({ bulkUpload: bulkUploadPrescriptionsInitialState });
	}
}

export default BulkUploadprescriptionContainer;
