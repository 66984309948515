import React from 'react'

const PlusInACircle = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2678 11.1429H13.1964V7.07143C13.1964 6.95358 13.1 6.85715 12.9821 6.85715H11.6964C11.5786 6.85715 11.4821 6.95358 11.4821 7.07143V11.1429H7.4107C7.29284 11.1429 7.19641 11.2393 7.19641 11.3571V12.6429C7.19641 12.7607 7.29284 12.8571 7.4107 12.8571H11.4821V16.9286C11.4821 17.0464 11.5786 17.1429 11.6964 17.1429H12.9821C13.1 17.1429 13.1964 17.0464 13.1964 16.9286V12.8571H17.2678C17.3857 12.8571 17.4821 12.7607 17.4821 12.6429V11.3571C17.4821 11.2393 17.3857 11.1429 17.2678 11.1429Z" fill="black" />
            <path d="M12.5 0C5.87321 0 0.5 5.37321 0.5 12C0.5 18.6268 5.87321 24 12.5 24C19.1268 24 24.5 18.6268 24.5 12C24.5 5.37321 19.1268 0 12.5 0ZM12.5 21.9643C6.99821 21.9643 2.53571 17.5018 2.53571 12C2.53571 6.49821 6.99821 2.03571 12.5 2.03571C18.0018 2.03571 22.4643 6.49821 22.4643 12C22.4643 17.5018 18.0018 21.9643 12.5 21.9643Z" fill="black" />
        </svg>
    )
}

export default PlusInACircle
