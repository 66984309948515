import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Subscribe } from "unstated";

import AuthenticationContainer from "../../state/AuthenticationContainer";

function GuestRoute({ component: Component, permissions, currentToken, ...rest }) {
	function redirectToHome() {
		return <Redirect to={{ pathname: "/" }} />;
	}

	function isAuthenticated() {
		return currentToken ? true : false;
	}

	return <Route {...rest} render={(props) => (!isAuthenticated() ? <Component {...props} /> : redirectToHome())} />;
}

function SubscribedGuestRoute(props) {
	return (
		<Subscribe to={[AuthenticationContainer]}>{(container) => <GuestRoute {...props} currentToken={container.state.currentToken} />}</Subscribe>
	);
}

export default SubscribedGuestRoute;
