import React, { useState } from "react";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import TextInput from "../../components/Form/TextInput";
import PasswordInput from "../../components/Form/PasswordInput";
import FormRow from "../../components/Form/FormRow";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import AuthenticationContainer from "../../state/AuthenticationContainer";
import InputFieldValidationBulletPoints from "../../components/Form/InputFieldValidationBulletPoints";
import { SpinnerIcon } from "../../assets/icons";
import { PasswordRulePoints } from "../../enums";

function ResetPassword({ container }) {
    const [email, setEmail] = useState("");
    const history = useHistory();
    const { secretID, mandatory } = useParams();
    const resetPasswordFormKey = "resetPassword";

    const onFieldChange = (e) => container.onFormChange(e, resetPasswordFormKey)

    const resetPasswordState = container.state[resetPasswordFormKey];

    function requestPasswordReset(email) {
        container.requestPasswordReset(email);
    }

    function resetPassword(secretId, password) {
        container.resetPassword(secretId, password);
    }

    const isButtonEnabled = () => {
        return container.state.loadingRequest ||
            !container.isFormValid(resetPasswordFormKey) ||
            resetPasswordState.password.value !== resetPasswordState.confirmPassword.value
    }

    return (
        <div className="centered-form">
            <div className="card p-m">
                <FormRow>
                    <img src="/assets/images/logo-dark.svg" className="col-7 m-b-s" alt="Lyphe Dispensary logo" />
                </FormRow>
                {!secretID && <div>
                    <FormRow>
                        <TextInput
                            value={email}
                            onChange={(e) => setEmail(e.value)}
                            label="Email"
                            name="email"
                            placeholder="e.g. johndoe@email.com"
                            onSubmit={() => requestPasswordReset({ email })}
                        />
                    </FormRow>

                    <FormRow>
                        <Button style={{ maxWidth: "100%" }} text="Reset password" onClick={() => requestPasswordReset(email)} disabled={container.state.loadingRequest || !email} />
                    </FormRow>
                    <span className="credential login" onClick={() => history.push("/login")} >Back to sign in</span>
                </div>}

                {secretID && <div>

                    {secretID && !mandatory &&
                        <div>
                            <p className="h6" style={{ textAlign: "left" }}>Change password</p>
                            <br />
                            <p className={'m-b-s'} style={{ textAlign: "left", fontWeight: "400" }}>To keep your account secure, please make sure your password has:</p>
                        </div>
                    }

                    {secretID && mandatory &&
                        <div>
                            <p className="h6" style={{ textAlign: "left" }}>Password expired</p>
                            <br />
                            <p className={'m-b-s'} style={{ textAlign: "left", fontWeight: "400" }}>To keep your account secure, your password will expire and a new one will need to be set up. Please make sure your new password has:</p>
                        </div>
                    }

                    <InputFieldValidationBulletPoints
                        value={resetPasswordState.password.value}
                        points={PasswordRulePoints}
                    />
                    <FormRow>
                        <PasswordInput
                            value={resetPasswordState.password.value}
                            error={resetPasswordState.password.error}
                            onChange={onFieldChange}
                            name="password"
                            label="Enter new password"
                            placeholder=""
                            onSubmit={() => { }}
                        />
                    </FormRow>
                    <FormRow>
                        <PasswordInput
                            value={resetPasswordState.confirmPassword.value}
                            error={resetPasswordState.confirmPassword.error}
                            onChange={onFieldChange}
                            name="confirmPassword"
                            label="Re-enter new password"
                            placeholder=""
                            onSubmit={() => { }}
                        />
                    </FormRow>
                    <FormRow>
                        <Button
                            style={{ maxWidth: "100%" }}
                            text="Confirm new password"
                            onClick={() => resetPassword(secretID, resetPasswordState.password.value)}
                            disabled={isButtonEnabled()}
                        >
                            {container.state.loadingRequest ? <SpinnerIcon /> : "Confirm new password"}
                        </Button>
                    </FormRow>
                </div>}
            </div>
            <span className="disclaimer">This page is intended for Lyphe Dispensary personnel only!</span>
        </div>
    );
}

function SubscribedResetPassword(props) {
    return <Subscribe to={[AuthenticationContainer]}>{(container) => <ResetPassword {...props} container={container} />}</Subscribe>
}

export default SubscribedResetPassword;