import React from "react";
import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";
import patientService from "../services/PatientsService";

import browserHelper from "../util/helpers/browserHelper";
import errorHelper from "../util/helpers/errorHelper";
import {
	isDateInEuroFormat,
	isEmail,
	isFormValid,
	isLettersSpacesAndHyphens, isNotJerseyGuernsey,
	isOver18,
	isPhoneNumber,
	isPostCode, maxLength,
	maxLengthPhone,
	minLengthPhone,
	required,
} from "../util/validation/validation";
import tableHeaders from "../util/constants/tableHeaders";
import { Throttle } from "../util/Throttle";
import { formatDate, formatDateForPresenting } from "../util/dates";
import { sortTableDataAndUpdateHeaders } from "../util/helpers/sorting";
import { PatientsTables } from "../enums";
import { NoteIcon, TickedIcon, XIcon } from "../assets/icons";
import TableAccountTypeIcon from "../components/Table/TableAccountTypeIcon";
import OrganisationsService from "../services/OrganisationsService";
import UniversalTooltip from "../components/Tooltips/UniversalTooltip";
import FlaggedPatientTooltipContent from "../components/Tooltips/ContentForTooltips/FlaggedPatientTooltipContent";

const updatePatientInitialState = {
	id: { value: "" },
	userId: { value: "" },
	title: { value: "" },
	firstName: { value: "" },
	lastName: { value: "" },
	dateOfBirth: { value: "" },
	telephoneNo: { value: "" },
	address1: { value: "" },
	address2: { value: "" },
	zipCode: { value: "" },
	createdAt: { value: "" },
	updatedAt: { value: "" },
	clinicIdNumber: { value: "" },
	patientAccountType: { value: "" },
	organisationId: { value: { value: "", label: "Select..." } },

	email: { value: "" },
	areEmailNotificationsEnabled: { value: "" },
	areAftercareNotificationsEnabled: { value: "" },
	isUrgent: { value: "" },
	isEmailVerified: { value: false },
	isIDVerified: { value: false },
	secretId: { value: "" },
	secretExpiry: { value: "" },
	lastLoggedIn: { value: "" },
	agreedToTerms: { value: false },

	prescribers: { value: [] },

	patientNote: { value: "" },
	noteAdmin: { value: {} },

	subscriptionType: { value: "" },
	subscriptionTier: { value: 1 },
	subscriptionCreatedAt: { value: "" },
	subscriptionUpdatedAt: { value: "" },
};

const createPatientInitialState = {
	title: { value: "" },
	firstName: { value: "" },
	lastName: { value: "" },
	dateOfBirth: { value: "" },
	telephoneNo: { value: "" },
	address1: { value: "" },
	address2: { value: "" },
	zipCode: { value: "" },
	email: { value: "" },
	organisationName: { value: "" },
	t21Patient: { value: false },
	clinicPatientId: { value: "" },
	errorMessage: { value: "" },
	organisationId: { value: { value: "", label: "Select..." } },
};

const initialCreateCommentState = {
	patientId: { value: -1 },
	text: { value: "" },
	adminId: { value: "" }
};
const initialUpdateCommentState = {
	id: { value: -1 },
	patientId: { value: -1 },
	text: { value: "" },
	adminId: { value: "" }
};
const initialUrgentCommentState = {
	text: { value: "" }
};
const bulkUploadInitialState = {
	bulkUploadCsvData: [],
	csvDtataToDownload: [],
	tableHeaders: {
		accountsCreated: tableHeaders.accountsCreated,
		accountsWithErrors: tableHeaders.accountsWithErrors,
	},
	tableData: {
		accountsCreated: [],
		accountsWithErrors: [],
	},
	initializeDropZoneRerender: 1
}

class PatientContainer extends Container {
	//#region  State
	constructor() {
		super();

		this.state = {
			tableHeaders: {
				[PatientsTables.AllPatients]: tableHeaders.patientsTableHeaders[PatientsTables.AllPatients](),
				[PatientsTables.AwaitingSignUp]: tableHeaders.patientsTableHeaders[PatientsTables.AwaitingSignUp]()
			},
			tableData: {
				[PatientsTables.AllPatients]: [],
				[PatientsTables.AwaitingSignUp]: []
			},

			updatePatient: { ...updatePatientInitialState },

			filteredOrganisations: [],

			createPatient: { ...JSON.parse(JSON.stringify(createPatientInitialState)) },

			comments: {
				tableData: [],
				value: [],
			},
			createComment: { ...initialCreateCommentState },
			updateComment: { ...initialUpdateCommentState },
			urgentComment: { ...initialUrgentCommentState },

			bulkUpload: { ...bulkUploadInitialState },

			//loaders
			loadingAllPatients: false,
			creatingPatient: false,
			updatingPatient: false,
		};

		this.validators = {
			newPatient: {
				firstName: [required]
			},
			updatePatient: {
				firstName: [required],
				dateOfBirth: [isDateInEuroFormat],
				telephoneNo: [required, isPhoneNumber, maxLengthPhone(12), minLengthPhone(10)],
				clinicIdNumber: [required],
				zipCode: [required, isPostCode, isNotJerseyGuernsey],
				email: [isEmail],
			},
			createPatient: {
				title: [required],
				firstName: [required, isLettersSpacesAndHyphens],
				lastName: [required, isLettersSpacesAndHyphens],
				email: [required, isEmail],
				dateOfBirth: [required, isDateInEuroFormat, isOver18],
				telephoneNo: [required, isPhoneNumber, maxLengthPhone(12), minLengthPhone(10)],
				address1: [required],
				address2: [required],
				zipCode: [required, isPostCode, isNotJerseyGuernsey],
				organisationId: [required],
				clinicPatientId: [required],
			},
			urgentComment: {
				text: [required, maxLength(255)]
			},
			createComment: {
				text: [required, maxLength(255)]
			},
			updateComment: {
				text: [required, maxLength(255)]
			},
		};

		this.throttle = new Throttle();

		this.throttles = {
			accountsCreated: new Throttle(),
			accountsWithErrors: new Throttle(),
		};

		this.tableRowConstructorMapper = {
			[PatientsTables.AllPatients]: this._constructTableRowForAllPatientsTable,
			[PatientsTables.AwaitingSignUp]: this._constructTableRowForAwaitingSignupTable,
		}
	}

	resetUpdateState = () => {
		this.setState({ updatePatient: updatePatientInitialState });
	};

	resetCreateState = () => {
		this.setState({ createPatient: { ...JSON.parse(JSON.stringify(createPatientInitialState)) } });
	}

	fillTempPatient = async (tempPatient) => {
		const labelForOrganisationId = this.state.filteredOrganisations.find(organisation => {
			return organisation.value === tempPatient.value.organisationId
		})

		await this.setState({
			createPatient: {
				...JSON.parse(JSON.stringify(createPatientInitialState)),
				organisationId: { value: { value: tempPatient.value.organisationId, label: labelForOrganisationId?.label } },
				clinicPatientId: { value: tempPatient.value.clinicIdNumber },
				email: { value: tempPatient.value.email },
				telephoneNo: { value: tempPatient.value.telephoneNo },
			},
		});
	}

	setCreateCommentState = (adminId, patientId) => {
		this.setState({
			createComment: {
				adminId: { value: adminId },
				patientId: { value: patientId },
				text: { value: "" },
			},
		});
	};

	setUpdateCommentState = (adminId, patientId, commentId, text) => {
		this.setState({
			updateComment: {
				id: { value: commentId },
				adminId: { value: adminId },
				patientId: { value: patientId },
				text: { value: text },
			},
		});
	};

	//#endregion

	//#region Util

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}


		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onSelectChange = (e, formKey) => {
		if (e.value[0] === "true" || e.value[0] === "false") {
			e.value[0] = JSON.parse(e.value[0]);
		}

		const newField = { value: e.value[0], error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	//#endregion

	//#region Table data

	/* sort = containerSort.bind(this); */

	sort = (columnIndex, phase, sortBySpace) => {
		if ([undefined, null].includes(phase)) return;

		const headers = this.state.tableHeaders[phase];
		const body = this.state.tableData[phase];

		sortTableDataAndUpdateHeaders(headers, body, columnIndex, sortBySpace);

		const tableHeaders = { ...this.state.tableHeaders, [phase]: headers };
		const tableData = { ...this.state.tableData, [phase]: body };

		this.setState({ tableHeaders, tableData });
	};

	filter = (queryString) => {
		this.throttle.setTimeout(() => this._filterCallback(queryString), 300);
	};

	_filterCallback = async (queryString) => {
		this.getAllPatients(queryString)
	};

	_addPatientToTable(patient, tableData, tableEnum) {
		if (((!patient.agreedToTerms || !patient.isIDVerified || !patient.isEmailVerified) && tableEnum === PatientsTables.AwaitingSignUp)) {
			tableData[tableEnum].push(this.tableRowConstructorMapper[tableEnum](patient));
			return;
		}
		if (tableEnum === PatientsTables.AllPatients) {
			tableData[tableEnum].push(this.tableRowConstructorMapper[tableEnum](patient));
		}
	}

	_constructTableRowForAllPatientsTable = (patient) => {
		let agreedIcon = <TableAccountTypeIcon patientAccountType={patient.patientAccountType} />;
		let signedUpColumnValue = `${patient.patientAccountType}`;

		return [
			{
				text: renderFlag(patient),
				value: patient.id,
				column: 1,
			},
			{
				text: patient.clinicIdNumber || 'N/A',
				column: 2,
				value: patient.clinicIdNumber || 1,
			},
			{
				text: `${patient.firstName} ${patient.lastName} ${patient.carer ? "*" : ""}`,
				value: patient.lastName ? patient.lastName : "",
				href: `/patients/edit/${patient.id}`,
				column: 3,
			},
			{
				text: patient.email || "N/A",
				column: 3,
			},
			{
				text: formatDate(patient.dateOfBirth, false, undefined, "DD/MM/YYYY") || "N/A",
				value: {
					value: formatDate(patient.dateOfBirth, false, undefined, "DD/MM/YYYY") || "N/A",
					format: "DD/MM/YYYY"
				},
				column: 1,
				type: "date",
				right: true
			},
			{
				text: formatDate(patient.createdAt, false, undefined, "DD/MM/YYYY"),
				value: formatDateForPresenting(patient.createdAt, false),
				column: 1,
				type: "date",
				right: true
			},
			{
				text: agreedIcon,
				value: signedUpColumnValue,
				column: 12,
				right: true,
			},
		];
	}

	_constructTableRowForAwaitingSignupTable = (patient) => {
		let agreedIcon;
		let termsAcceptedColumnValue;

		let emailVerifiedIcon;
		let emailVerifiedColumnValue;

		let idVerifiedIcon;
		let idCheckColumnValue;

		if (patient.agreedToTerms) {
			agreedIcon = <TickedIcon />;
			termsAcceptedColumnValue = "1";
		} else {
			agreedIcon = <XIcon />;
			termsAcceptedColumnValue = "2";
		}

		if (patient.isEmailVerified) {
			emailVerifiedIcon = <TickedIcon />;
			emailVerifiedColumnValue = "1";

		} else {
			emailVerifiedIcon = <XIcon />;
			emailVerifiedColumnValue = "2";
		}


		if (patient.isIDVerified) {
			idVerifiedIcon = <TickedIcon />;
			idCheckColumnValue = "1";
		} else {
			idVerifiedIcon = <XIcon />;
			idCheckColumnValue = "2";
		}

		return [
			{
				text: patient.id,
				column: 1,
			},
			{
				text: `${patient.firstName} ${patient.lastName} ${patient.carer ? "*" : ""}`,
				href: `/patients/edit/${patient.id}`,
				column: 3,
			},
			{
				text: patient.email || "N/A",
				column: 3,
			},
			{
				text: formatDate(patient.dateOfBirth, false, undefined, "DD/MM/YYYY") || "N/A",
				value: {
					value: formatDate(patient.dateOfBirth, false, undefined, "DD/MM/YYYY") || "N/A",
					format: "DD/MM/YYYY"
				},
				column: 1,
				type: "date",
			},
			{
				text: formatDate(patient.createdAt, false, undefined, "DD/MM/YYYY"),
				value: formatDateForPresenting(patient.createdAt, false),
				column: 1,
				type: "date",
			},
			{
				text: agreedIcon,
				value: termsAcceptedColumnValue,
				column: 1,
				right: true,
			},
			{
				text: emailVerifiedIcon,
				value: emailVerifiedColumnValue,
				column: 1,
				right: true,
			},
			{
				text: idVerifiedIcon,
				value: idCheckColumnValue,
				column: 1,
				right: true,
			},
		];
	}

	getAllPatients = async (query) => {
		try {
			this.setState({ loadingAllPatients: true });
			const result = await patientService.filter(query ? query : '');

			const allPatientsTableData = result.map((patient) => {
				return this._constructTableRowForAllPatientsTable(patient);
			});

			const patientsAwaitingSignup = result.filter(patient => {
				return (!patient.agreedToTerms || !patient.isIDVerified || !patient.isEmailVerified);
			})

			const patientsAwaitingSignupTableData = patientsAwaitingSignup.map((patient) => {
				return this._constructTableRowForAwaitingSignupTable(patient);
			});

			this.setState({ tableData: { ...this.state.tableData, [PatientsTables.AllPatients]: allPatientsTableData, [PatientsTables.AwaitingSignUp]: patientsAwaitingSignupTableData } });
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ loadingAllPatients: false });
	}

	//#endregion

	//#region Table data

	setForUpdate = async (id) => {
		this.setState({ updatingPatient: true });

		try {
			const result = await patientService.getById(id);
			await this._getOrganisations();
			this.getAllCommentsByPatientId(id)

			const updatePatient = {
				id: { value: result.id },
				userId: { value: result.userId },
				title: { value: result.title },
				firstName: { value: result.firstName },
				lastName: { value: result.lastName },
				dateOfBirth: { value: result.dateOfBirth },
				telephoneNo: { value: result.telephoneNo },
				address1: { value: result.address1 },
				address2: { value: result.address2 },
				zipCode: { value: result.zipCode },
				createdAt: { value: result.createdAt },
				updatedAt: { value: result.updatedAt },
				clinicIdNumber: { value: result.clinicIdNumber },
				subscriptionType: { value: result.subscriptionType },
				subscriptionTier: { value: result.subscriptionTier != 0 ? result.subscriptionTier : 1 }, // tier 0 makes no sense, ensure it is 1 at minimum
				subscriptionCreatedAt: { value: result.subscriptionCreatedAt },
				subscriptionUpdatedAt: { value: result.subscriptionUpdatedAt },

				email: { value: result.email },
				areEmailNotificationsEnabled: { value: result.areEmailNotificationsEnabled },
				areAftercareNotificationsEnabled: { value: result.areAftercareNotificationsEnabled },
				isUrgent: { value: result.isUrgent },
				isEmailVerified: { value: result.isEmailVerified },
				isIDVerified: { value: result.isIDVerified },
				agreedToTerms: { value: result.agreedToTerms },
				secretId: { value: result.secretId },
				secretExpiry: { value: result.secretExpiry },
				lastLoggedIn: { value: result.lastLoggedIn },
				patientAccountType: { value: result.patientAccountType },
				organisationId: { value: { value: result?.organisation?.id, label: result.organisation?.name } },

				carer: { value: result.carer },
				// initialize for later
				prescribers: { value: [] },

				patientNote: { value: result.patientNote || "" },
				noteAdmin: { value: result.noteAdmin },
			};

			updatePatient.prescribers.value = result.prescribers.map((prescriber) => {
				return [
					{
						text: prescriber.id,
						column: 1,
					},
					{
						text: prescriber.paperPrescriptionId,
						column: 3,
					},
					{
						text: `${prescriber.firstName} ${prescriber.lastName}`,
						href: `/prescribers/edit/${prescriber.id}`,
						column: 2,
					},
					{
						text: formatDate(prescriber.prescribedAt, false, undefined, "DD/MM/YY"),
						column: 4,
						right: true,
					},
					{
						text: "See prescription",
						href: `/prescriptions/edit/${prescriber.prescriptionId}`,
						column: 2,
						right: true,
					},
				];
			});

			this.setState({ updatePatient });
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPatient: false });
	};

	update = async () => {
		this.setState({ updatingPatient: true });

		try {
			const patientUpdate = {
				id: this.state.updatePatient.id.value,
				title: this.state.updatePatient.title.value,
				firstName: this.state.updatePatient.firstName.value,
				lastName: this.state.updatePatient.lastName.value,
				dateOfBirth: formatDate(this.state.updatePatient.dateOfBirth.value, false, "DD/MM/YYYY", 'MM-DD-YYYY'),
				telephoneNo: this.state.updatePatient.telephoneNo.value.trim(),
				address1: this.state.updatePatient.address1.value,
				address2: this.state.updatePatient.address2.value,
				zipCode: this.state.updatePatient.zipCode.value,
				clinicIdNumber: this.state.updatePatient.clinicIdNumber.value,
				organisationId: this.state.updatePatient.organisationId.value.value,
				areAftercareNotificationsEnabled: this.state.updatePatient.areAftercareNotificationsEnabled.value,
				subscriptionType: this.state.updatePatient.subscriptionType.value,
				subscriptionTier: this.state.updatePatient.subscriptionTier.value,

				// user data
				email: this.state.updatePatient.email.value ? this.state.updatePatient.email.value.toLowerCase().trim() : "",
				isEmailVerified: this.state.updatePatient.isEmailVerified.value,
				isIDVerified: this.state.updatePatient.isIDVerified.value,
				agreedToTerms: this.state.updatePatient.agreedToTerms.value,
			};

			await patientService.update(patientUpdate);
			this.setForUpdate(this.state.updatePatient.id.value)

			notificationHelper.info("Patient updated!");

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPatient: false });
	};

	setForCreate = async () => {
		this._getOrganisations();
	}

	_getOrganisations = async () => {
		this.setState({ loadingOrganisations: true });

		const result = await OrganisationsService.filter("");

		const filteredOrganisations = result.map((organisation) => {
			return {
				label: `${organisation?.name}`,
				value: organisation?.id,
			};
		});

		this.setState({ filteredOrganisations, loadingOrganisations: false });
	};

	create = async (navigateAway = true) => {
		this.setState({ creatingPatient: true });
		let result
		try {
			const patientCreate = {
				title: this.state.createPatient.title.value,
				firstName: this.state.createPatient.firstName.value,
				lastName: this.state.createPatient.lastName.value,
				dateOfBirth: this.state.createPatient.dateOfBirth.value,
				telephoneNo: this.state.createPatient.telephoneNo.value.trim(),
				address1: this.state.createPatient.address1.value,
				address2: this.state.createPatient.address2.value,
				zipCode: this.state.createPatient.zipCode.value,
				email: this.state.createPatient.email.value.toLowerCase().trim(),
				organisationId: this.state.createPatient.organisationId.value.value,
				t21Patient: this.state.createPatient.t21Patient.value,
				clinicPatientId: this.state.createPatient.clinicPatientId.value,
			}

			result = await patientService.create(patientCreate);

			notificationHelper.info(patientCreatedToastContent(`${this.state.createPatient.firstName.value} ${this.state.createPatient.lastName.value}`, result.id));

			this.resetCreateState();
			if (navigateAway) {
				browserHelper.navigate('/prescriptions/add');
			}
		} catch (error) {
			errorHelper.handleError(error);
			if (error.response.data.message === 'User with given email already exists') {
				this.setPatientCreateErrorMessage('Patient not created. The email address entered is already linked to an existing patient.')
			}
		}
		this.setState({ creatingPatient: false });
		return result;
	}

	resetPatientOnboardingLink = async () => {
		try {
			this.setState({ resettingPatientOnboardingLink: true });
			const patientId = this.state.updatePatient.id.value;

			const patient = await patientService.resetPatientOnboardingLink(patientId);
			this.setState({ updatePatient: Object.assign(this.state.updatePatient, { secretExpiry: { value: patient.secretExpiry } }) });

			notificationHelper.info("Sign up link expiry updated");
		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ resettingPatientOnboardingLink: false });
	}

	setAreEmailNotificationsEnabled = async (payload) => {
		try {
			await patientService.setAreEmailNotificationsEnabled(payload)
			this.setState({ updatePatient: { ...this.state.updatePatient, areEmailNotificationsEnabled: { value: payload.areEmailNotificationsEnabled } } });
			notificationHelper.info(payload.areEmailNotificationsEnabled ? "Email notifications successfully enabled" : "Email notifications successfully disabled")
		} catch (error) {
			errorHelper.handleError(error);
			this.setState({ updatePatient: { ...this.state.updatePatient, areEmailNotificationsEnabled: { value: this.state.updatePatient.areEmailNotificationsEnabled.value } } });
		}
	}

	setAreAftercareNotificationsEnabled = async (payload) => {
		try {
			await patientService.setAreAftercareNotificationsEnabled(payload)
			this.setState({ updatePatient: { ...this.state.updatePatient, areAftercareNotificationsEnabled: { value: payload.areAftercareNotificationsEnabled } } });
			notificationHelper.info(payload.areAftercareNotificationsEnabled ? "Aftercare email notifications successfully enabled" : "Aftercare email notifications successfully disabled")
			this.getAllCommentsByPatientId(this.state.updatePatient.id.value);
		} catch (error) {
			errorHelper.handleError(error);
			this.setState({ updatePatient: { ...this.state.updatePatient, areAftercareNotificationsEnabled: { value: this.state.updatePatient.areAftercareNotificationsEnabled.value } } });
		}
	}

	setIsUrgent = async (payload) => {
		try {
			await patientService.setIsUrgent(payload)
			this.setState({ updatePatient: { ...this.state.updatePatient, isUrgent: { value: payload.isUrgent } } });

			notificationHelper.info(payload.isUrgent ? "Patient marked as urgent" : "Patient un-marked as urgent")

			this.setState({ urgentComment: { text: { value: "" }, }, });
			this.getAllCommentsByPatientId(this.state.updatePatient.id.value);
		} catch (error) {
			errorHelper.handleError(error);

			this.setState({ urgentComment: { text: { value: "" }, }, });
			this.setState({ updatePatient: { ...this.state.updatePatient, isUrgent: { value: this.state.updatePatient.isUrgent.value } } });
		}
	}

	setPatientCreateErrorMessage = (message) => {
		this.setState({ createPatient: { ...this.state.createPatient, errorMessage: { value: message } } })
	}

	delete = async (id) => {
		try {
			await patientService.delete(id);

			notificationHelper.info(`Patient ${this.state.updatePatient.firstName.value} ${this.state.updatePatient.lastName.value} was deleted successfully !`);

			browserHelper.navigate("/patients");
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	//#endregion
	//#region Comments
	createComment = async () => {
		try {
			const commentCreate = {
				adminId: this.state.createComment.adminId.value,
				patientId: this.state.createComment.patientId.value,
				text: this.state.createComment.text.value,
			};

			await patientService.createComment(commentCreate);

			this.setCreateCommentState(this.state.createComment.adminId.value, this.state.createComment.patientId.value);
			this.getAllCommentsByPatientId(this.state.createComment.patientId.value);

			notificationHelper.info("Comment added!");
		} catch (error) {
			errorHelper.handleError(error);
			this.setCreateCommentState(this.state.createComment.adminId.value, this.state.createComment.patientId.value);
		}

		/* this.setState({ updatingPrescription: false }); */
	};

	updateComment = async () => {
		try {
			const commentUpdate = {
				id: this.state.updateComment.id.value,
				adminId: this.state.updateComment.adminId.value,
				patientId: this.state.updateComment.patientId.value,
				text: this.state.updateComment.text.value,
			};

			await patientService.updateComment(commentUpdate);
			this.setUpdateCommentState(this.state.updateComment.adminId.value, this.state.updateComment.patientId.value, -1, '');

			this.getAllCommentsByPatientId(this.state.updateComment.patientId.value);

			notificationHelper.info("Comment updated!");
		} catch (error) {
			errorHelper.handleError(error);
		}

	};

	getAllCommentsByPatientId = async (id) => {
		try {
			const comments = await patientService.getAllCommentsByPatientId(id);

			this.setState({ comments: { value: comments } });
			this._setCommentsTableData(comments);
		} catch (error) {
			errorHelper.handleError(error);
		}
	};
	//#endregion

	_setCommentsTableData(comments) {
		let commentsTableRows = [];

		for (let comment of comments) {
			let tableRow = this._getCommentTableRow(comment);
			commentsTableRows.push(tableRow);
		}

		this.setState({ comments: { tableData: commentsTableRows } });
	}

	_getCommentTableRow(comment) {
		return [
			{
				text: comment.text,
				column: 7,
			},
			{
				text: `${comment.admin ? comment.admin.name : "API"}`,
				column: 3,
			},
			{
				text: formatDateForPresenting(comment.createdAt, true, ""),
				column: 2,
				right: true,
				type: "date",
			}
		];
	}

	//#endregion
	//#region Bulkupload
	setBulkUploadCsvData(data) {
		this.setState({ bulkUpload: { ...this.state.bulkUpload, bulkUploadCsvData: data } })
	}

	resetBulkUploadData() {
		this.setState({ bulkUpload: { ...bulkUploadInitialState, initializeDropZoneRerender: this.state.bulkUpload.initializeDropZoneRerender + 1 } })
	}

	bulkUploadCreate = async () => {
		let data = await patientService.bulkCreatePatientAccounts(this.state.bulkUpload.bulkUploadCsvData)
		let emails = data.patientErrors.map((e) => e.patient.email)
		const patientsWithoutErrors = this.state.bulkUpload.bulkUploadCsvData.filter(p => !emails.includes(p.email));
		const patientsWithErrors = this.state.bulkUpload.bulkUploadCsvData.filter(p => emails.includes(p.email));
		const newBulkUpload = {
			...this.state.bulkUpload,
			csvDtataToDownload: patientsWithErrors,
			tableData: {
				...this.state.bulkUpload.tableData,
				accountsWithErrors: this._createAccountsWithErrorsTableData(data.patientErrors),
				accountsCreated: this._createAccountsCreatedTableData(patientsWithoutErrors)
			}
		}
		this.setState({ bulkUpload: newBulkUpload })
		if (patientsWithoutErrors.length) {
			notificationHelper.info(`${patientsWithoutErrors.length} patient account${patientsWithoutErrors.length > 1 ? "s" : ''} created!`);
		}
	}

	_createAccountsWithErrorsTableData(data) {
		return data.map(error => {
			let { patient, errorMessage } = error
			return [
				{
					text: patient.firstName,
					column: 2,
				},
				{
					text: patient.lastName,
					column: 2,
				},
				{
					text: patient.telephoneNo,
					column: 2,

				},
				{
					text: patient.email,
					column: 2,
				},
				{
					text: errorMessage,
					column: 4,
					right: true,
					red: true
				},
			]
		})
	}
	_createAccountsCreatedTableData(data) {
		return data.map(d => {
			return [
				{
					text: d.firstName,
					column: 3,
				},
				{
					text: d.lastName,
					column: 3,
				},
				{
					text: d.telephoneNo,
					column: 3,

				},
				{
					text: d.email,
					column: 3,
					right: true
				},
			]
		})
	}
	//#endregion

	addNoteToPatient = async (noteValue, currentAdministrator) => {
		try {
			const addNote = {
				patientId: this.state.updatePatient.id.value,
				note: noteValue,
			};

			await patientService.addNote(addNote);

			this.setState({
				updatePatient: {
					...this.state.updatePatient, patientNote: { value: noteValue }, noteAdmin: {
						value: {
							name: currentAdministrator.name
						}
					}
				}
			})
			notificationHelper.info(`Patient note added.`);
			this.getAllCommentsByPatientId(this.state.updatePatient.id.value);
		}
		catch (error) {
			errorHelper.handleError(error);
		}
	}

	removeNoteFromPatient = async () => {
		try {
			const patientId = this.state.updatePatient.id.value;

			await patientService.removeNote(patientId);

			this.setState({ updatePatient: { ...this.state.updatePatient, patientNote: { value: "" }, noteAdmin: { value: {} } } })
			this.getAllCommentsByPatientId(this.state.updatePatient.id.value);
		}
		catch (error) {
			errorHelper.handleError(error)
		}
	}


}

export default PatientContainer;


const patientCreatedToastContent = (patientName, patientId) => {
	return <div>
		Patient account created for &nbsp;
		<span className='link' onClick={() => { browserHelper.navigate(`/patients/edit/${patientId}`) }}>
			{patientName}
		</span>
	</div>
}

const renderFlag = (patient) => {
	return <div style={{ display: "flex" }}>
		<div style={{ marginRight: "6px", wordBreak: "initial" }} >
			{patient.id}
		</div>
		{patient.noteAdmin &&
			<div >
				<UniversalTooltip
					hoverableContent={<NoteIcon squareColor={"#FFF5E6"} flagColor={"#F59404"} />}
					left={150}
					contentForTheTooltip={<FlaggedPatientTooltipContent patient={patient} />}
				/>
			</div>}
	</div>
}
