import React, { useEffect } from "react";
import { Subscribe } from "unstated";

import Table from "../../components/Table/Table";
import TextInput from "../../components/Form/TextInput";

import CarerContainer from "../../state/CarerContainer";

function Carers({ container }) {
	// Hook-related data
	useEffect(() => {
		container.filter("");
	}, [container]);

	// Functions
	function renderFilters(container) {
		return (
			<div className="filter-container col-4">
				<div className="col-12">
					<TextInput placeholder="Search..." onChange={(e) => container.filter(e.value)} />
				</div>
			</div>
		);
	}

	return (
		<Table
			title="Carers"
			tableData={{
				head: container.state.tableHeaders,
				body: container.state.tableData,
			}}
			filters={renderFilters(container)}
			onColumnSort={(columnIndex) => container.sort(columnIndex, columnIndex === 3 ? true : false)}
			showSpinner={container.state.loadingCarers}
		/>
	);
}

function SubscribedCarers(props) {
	return <Subscribe to={[CarerContainer]}>{(container) => <Carers {...props} container={container} />}</Subscribe>;
}

export default SubscribedCarers;
