import React, { useState } from "react";
import Modal from "../components/Modal/Modal";

import FormRow from "../components/Form/FormRow";
import MetricsService from "../services/MetricsService";
import Button from "../components/Form/Button";
import DateInput from "../components/Form/DateInput";
import Spinner from "../components/Spinner/Spinner";

/**
 * This is organized in a way where each object in Links has a key that is
 * a title on the page and a controller name on the API.
 *
 * Each of those objects has a key representing the end point in its controller
 * and the value for that key is a name that is presented on the page.
 *
 * These links are then rendered in the component bellow and when you click on any
 * of them a call to the api is made by combining the key like "Referrals" and its
 * subkey like "getmetrics".
 */
const Links = {
	Patients: {
		getmetrics: { label: "All Patients", needsDate: false },
	},

	Carers: {
		getmetrics: { label: "All Carers", needsDate: false },
	},

	Prescribers: {
		getmetrics: { label: "All Prescribers", needsDate: false },
	},

	Prescriptions: {
		getAwaitingRewriteMetrics: { label: "All Awaiting Rewrite", needsDate: false },
		getRewriteMetrics: { label: "All Rewrites Report", needsDate: true, desc: "Select the upload date to filter prescriptions (date→present):" },
	},

	Orders: {
		getmetrics: { label: "All Orders with Prescriptions", needsDate: true, desc: "Select the upload date to filter prescriptions (date→present):" },
	},

	Products: {
		getmetrics: { label: "All Products", needsDate: false },
	},

	OrderedProducts: {
		getmetrics: { label: "SKU history", needsDate: false },
	},

	Admins: {
		getSkuReport: { label: "SKU Report", needsDate: true, desc: "Select the upload date to filter prescriptions (date→present):" },
		getDeliveredReport: { label: "Delivered Report", needsDate: true, desc: "Select the delivered date to filter orders (date→present):" },
		getPatientsWithUploadedScriptsReport: { label: "Patients With Uploaded Scripts Report ", needsDate: true, desc: "Enter the upload date to filter prescriptions (Lyphe only):" },
		getAllTimeMetrics: { label: "All time metrics", needsDate: false },
		GetDailyMetrics: { label: "Daily metrics", needsDate: true, desc: "Select the date to filter metrics:" },
		GetDailySKUMetrics: { label: "Daily SKU metrics", needsDate: true, desc: "Select the date to filter metrics:" },
		GetAdminsReport: { label: "Daily admin processing report", needsDate: true, desc: "Select the date to filter metrics:" }
	},
};

function Metrics() {
	async function onLinkClick(entity, endpoint, name, startDate = null) {

		const url = `/${entity}/${endpoint}`;

		setLoading(true);
		const link = await MetricsService.getMetrics(url, name, startDate);
		setLoading(false);

		if (!link) return;

		document.body.appendChild(link);
		link.click();
	}

	const [loading, setLoading] = useState(false);

	const [modalInfo, setModalInfo] = useState({
		isOpen: false,
		entity: "",
		endpoint: "",
		name: "",

		needsDate: false,
		desc: "",
		startDate: null,
	});

	function openModal(entity, endpoint, name, needsDate, desc) {
		setModalInfo({
			isOpen: true,
			entity,
			endpoint,
			name,

			needsDate: needsDate,
			desc: desc,
		});
	}

	function closeModal() {
		setModalInfo({
			isOpen: false,
			entity: "",
			endpoint: "",
			name: "",

			needsDate: false,
			desc: "",
			startDate: null,
		});
	}

	const renderedLinks = [];

	// render titles
	for (let [entity, endpoints] of Object.entries(Links)) {
		let name = `${entity}`;

		renderedLinks.push(
			<p className="h6" key={name}>
				{name}
			</p>
		);

		renderedLinks.push(<br key={`b${entity}`} />);

		// render each link
		for (let [endpoint, details] of Object.entries(endpoints)) {
			renderedLinks.push(
				<p className="a" key={`${entity}/${endpoint}`} onClick={() => openModal(entity, endpoint, details.label, details.needsDate, details.desc)}>
					{details.label}
				</p>
			);

			renderedLinks.push(<br key={`b${entity}/${endpoint}`} />);
		}

		renderedLinks.push(<br key={`b1${entity}`} />);
	}

	function handleDateChange(data) {
		// Merge the new data (from DateInput) with the existing modalInfo state
		setModalInfo(prevState => ({
			...prevState,
			startDate: data.value
		}));
	}

	return <div>
		{renderedLinks}
		<Modal
			isOpen={modalInfo.isOpen}
			onClose={closeModal}
		>
			<FormRow>
				<h2 className="h4">Report generation:</h2>
			</FormRow>
			{modalInfo.needsDate && (
				<FormRow>
					<DateInput
						value={modalInfo.startDate}
						onChange={handleDateChange}
						name="startDate"
						label={modalInfo.desc}
						placeholder="e.g. 23/09/2023"

					/>
				</FormRow>)}
			<Button
				onClick={() => onLinkClick(modalInfo.entity, modalInfo.endpoint, modalInfo.name, modalInfo.startDate)}
				disabled={(modalInfo.needsDate && !modalInfo.startDate) || loading}
			>
				{loading ? <Spinner /> : `Generate ${modalInfo.name}`}
			</Button>
		</Modal>
	</div >;
}

export default Metrics;
