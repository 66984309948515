import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import CustomSelect from "../../components/Form/CustomSelect";
import FormRow from "../../components/Form/FormRow";

import PrescriptionPreview from "../../components/PrescriptionPreview/PrescriptionPreview";

import PrescriptionContainer from "../../state/PrescriptionContainer";
import { AdminType } from "../../enums";
import AddBulkPrescriptions from "../../pages/Prescriptions/AddBulkPrescriptions";

import AuthenticationContainer from "../../state/AuthenticationContainer";
import { SpinnerIcon } from "../../assets/icons";
import Drawer from "../../components/Drawer/Drawer";
import TextInput from "../../components/Form/TextInput";
import RenderForAdminTypes from "../../components/SharedComponents/RenderForAdminTypes";

function AddPrescription({ container, currentAdministrator }) {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const patientName = query.get('name');
	const patientId = query.get('patientId');

	// State-related data
	const prescriptionFormKey = "createPrescription";

	const prescription = container.state[prescriptionFormKey];
	const onImageChange = (event) => container.onPrescriptionFileChange(event, prescriptionFormKey);
	const onFieldChange = (event) => container.onFormChange(event, prescriptionFormKey);

	const onSelectChangePrescription = (event) => container.onSelectChange(event, prescriptionFormKey);

	const onOrganisationChange = (event) => {
		const organisationId = event?.value[0]?.value;
		container.getPrescribersFromOrg(organisationId);
		prescription.prescriber.value = undefined;

		onSelectChangePrescription(event)
	}

	const onPrescriberChange = (event) => {
		const organisationId = event?.value[0]?.organisationId;

		const orgPrescriberBelongsTo = container.state.filteredOrganisations.find(clinic => {
			return clinic.value === organisationId
		})

		const eventForOrganisationField = {
			name: "organisationId",
			value: orgPrescriberBelongsTo
		}
		if (orgPrescriberBelongsTo) {
			onFieldChange(eventForOrganisationField)
		}

		onSelectChangePrescription(event)
	}

	const isButtonDisabled = () => {
		return (
			!container.isFormValid(prescriptionFormKey) ||
			container.state.creatingPrescription
		)
	}
	useEffect(() => {
		container.setForCreate(patientName, patientId);

		return container.resetUploadPrescriptionState;
		// eslint-disable-next-line
	}, [container]);

	const [current, setCurrent] = useState(drawerEnum.SingleUpload);
	const buttons = [
		{ text: `Single Upload`, id: drawerEnum.SingleUpload },
		{ text: `Bulk Upload`, id: drawerEnum.BulkUpload },
	]

	const renderSingleUpload = () => {
		return <>
			{currentAdministrator?.type !== AdminType.Eastone && (
				<FormRow>
					<div className="col-6">
						<div style={{ width: "100%", marginBottom: "auto" }}>
							<FormRow>
								<h2 className="h6">Prescription information</h2>
							</FormRow>

							<FormRow>
								<div className="col-7" id="selectOrganisation">
									{/* This conditional rendering solves the problem of not seeing prescribers */}
									{!container.state.loadingPrescribers && (
										<CustomSelect
											key={container.state.filteredOrganisations.length}
											label="Organisation"
											name="organisationId"
											values={prescription.organisationId.value ? [prescription.organisationId.value] : []}
											options={container.state.filteredOrganisations}
											onChange={onOrganisationChange}
											clearable={true}
										/>
									)}
								</div>
								<RenderForAdminTypes adminTypes={[AdminType.Super]}>
									<div className="col-5">
										{prescription.organisationId.value && (
											<span className="rounded-info-span">
												<Link to={`/organisations/edit/${prescription.organisationId.value.value}`}>View</Link>
											</span>
										)}
										<Link to="/organisations/new" className="badge--no-border highlighted m-l-xs" title="Add a new organisation">
											<i className="icon-plus"></i>
										</Link>
									</div>
								</RenderForAdminTypes>
							</FormRow>

							<FormRow>
								<div className="col-7" id="selectPrescriber">
									{/* This conditional rendering solves the problem of not seeing prescribers */}
									{!container.state.loadingPrescribers && (
										<CustomSelect
											label="Prescriber"
											name="prescriber"
											values={prescription.prescriber.value ? [prescription.prescriber.value] : []}
											options={container.state.filteredPrescribers}
											key={container.state.filteredPrescribers.length}
											onChange={onPrescriberChange}
											clearable={true}
											disabled={!prescription.organisationId.value}
										/>
									)}
								</div>

								<div className="col-5">
									{prescription.prescriber.value && (
										<span className="rounded-info-span">
											<Link to={`/prescribers/edit/${prescription.prescriber.value.value}`}>View</Link>
										</span>
									)}
									<Link to="/prescribers/new" className="badge--no-border highlighted m-l-xs" title="Add a new prescriber">
										<i className="icon-plus"></i>
									</Link>
								</div>
							</FormRow>
							<FormRow>
								<div className={'col-7'} >
									<TextInput
										value={container.state.createPrescription.envelopeTrackingCode.value}
										key={prescription.prescriber.value?.value ? prescription.prescriber.value?.value : "key"}
										name='envelopeTrackingCode'
										label="Tracking number (optional)"
										placeholder="Tracking number"
										onChange={onFieldChange}
									/>
								</div>
							</FormRow>

						</div>
					</div>
					<div className="col-6">
						<PrescriptionPreview className={"prescription-preview--medium"} displayDropZone={true} onChange={onImageChange} defaultImage={prescription.prescriptionUrl.value} />
					</div>
				</FormRow>

			)}

			<FormRow>
				<div className="col-6">
					<Button
						onClick={container.create}
						disabled={
							isButtonDisabled()
						}
					>
						{container.state.creatingPrescription ? <SpinnerIcon /> : "Upload prescription"}
					</Button>
				</div>
			</FormRow>
		</>
	}

	const renderBulkUpload = () => {
		return <AddBulkPrescriptions />
	}

	const currentToDrawerEnumMapper = {
		[drawerEnum.SingleUpload]: renderSingleUpload,
		[drawerEnum.BulkUpload]: renderBulkUpload,
	}


	return (
		<div className="card p-m tableDrawerContainer">
			<FormRow>
				<div className="col-7">
					<h1 className="h4">Upload Prescriptions</h1>
				</div>
			</FormRow>
			<Drawer buttons={buttons} current={current} setCurrent={setCurrent} />
			{
				currentToDrawerEnumMapper[current]()
			}
		</div>
	);
}

function SubscribedAddPrescription(props) {
	return (
		<Subscribe to={[PrescriptionContainer, AuthenticationContainer]}>
			{(container, authContainer) => (
				<AddPrescription {...props} container={container} currentAdministrator={authContainer.state.currentAdministrator} />
			)}
		</Subscribe>
	);
}

export default SubscribedAddPrescription;

const drawerEnum = {
	SingleUpload: 1,
	BulkUpload: 2,
}

Object.freeze(drawerEnum);
