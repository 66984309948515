import apiClient from "../clients/apiClient";

import { carerApiToClient, carersApiToClient } from "../converters/carersConverter";

class CarersService {
	constructor() {
		this.client = {
			getById: apiClient.getById("carers"),
			filter: apiClient.filter("carers"),
			update: apiClient.update("carers"),
		};
	}

	async getById(id) {
		const { data: carer } = await this.client.getById(id);

		return carerApiToClient(carer);
	}

	async filter(queryString) {
		const filters = { firstName: queryString, lastName: queryString, email: queryString };

		const { data: carers } = await this.client.filter(filters);

		return carersApiToClient(carers);
	}

	async update(carerUpdate) {
		const { data: updatedCarer } = await this.client.update(carerUpdate);

		return updatedCarer;
	}

	async delete(id) { }
}

export default new CarersService();
