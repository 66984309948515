import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Subscribe } from "unstated";

import Table from "../../components/Table/Table";
import Button from "../../components/Form/Button";
import TextInput from "../../components/Form/TextInput";
import FormRow from "../../components/Form/FormRow";

import PrescriberContainer from "../../state/PrescriberContainer";
import { PlusInACircle } from "../../assets/icons";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";

function Prescribers({ prescribersContainer }) {
	const history = useHistory()
	// Hook-related data
	useEffect(() => {
		prescribersContainer.getAllPrescribers();
	}, [prescribersContainer]);

	function renderFilters(prescribersContainer) {
		return (
			<FormRow className="table-row">
				<div className="col-5 table-row">
					<Button onClick={() => history.push('/prescribers/new')} bordered={true} className="m-r-s height-search uppercase" >
						<PlusInACircle /> &nbsp;	<p style={{ fontSize: "13px" }}>New Prescriber</p>
					</Button>
				</div>

				<div className="col-5">
					<TextInput placeholder="Search..." onChange={(e) => prescribersContainer.filter(e.value)}
						clearable={true}
						onClear={() => prescribersContainer.filter("")} />
					<CustomIconWithTooltip className="generic_tool_tip--left"
						style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }}
						toolTipText={"You may search by prescriber name or reference number (GMC)."}
						icon={<InfoIconGray />} />
				</div>
			</FormRow>
		);
	}

	return (
		<Table
			title="Prescribers"
			tableData={{
				head: prescribersContainer.state.tableHeaders,
				body: prescribersContainer.state.tableData,
			}}
			filters={renderFilters(prescribersContainer)}
			onColumnSort={(columnIndex) => prescribersContainer.sort(columnIndex)}
			showSpinner={prescribersContainer.state.loadingPrescribers}
		/>
	);
}

function SubscribedPrescribers(props) {
	return (
		<Subscribe to={[PrescriberContainer]}>
			{(prescribersContainer) => <Prescribers {...props} prescribersContainer={prescribersContainer} />}
		</Subscribe>
	);
}

export default SubscribedPrescribers;
