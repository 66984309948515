import axios from "axios";
import React, { useEffect, useState } from "react";
import Lightbox from "react-awesome-lightbox";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../assets/icons";

function getFileUrl(e) {
	return {
		url: URL.createObjectURL(e.target.files[0]),
		name: e.target.files[0].name,
	};
}

function PrescriptionPreview({ defaultImage = "", onChange = () => null, showChangeButton = true, displayDropZone = false, className = "" }) {
	const [isPDF, setisPDF] = useState(false);
	defaultImage = decodeURIComponent(defaultImage)

	useEffect(() => {
		async function getFileBlob() {
			const config = { responseType: "blob" };
			const { data: prescriptionBlob } = await axios.get(defaultImage, config);
			if (prescriptionBlob.type === 'application/pdf') {
				setisPDF(true);
			} else {
				setisPDF(false);
			}

		}
		if (defaultImage && isDataFromLocalMachine()) {
			getFileBlob()
		}
		// eslint-disable-next-line
	}, [defaultImage])

	const isDataFromLocalMachine = () => {
		return defaultImage.substring(defaultImage.lastIndexOf('/')).lastIndexOf('.') === -1
	}

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/jpeg, image/png, application/pdf',
		multiple: false,
		onDropAccepted: (file, event) => {
			if (event.dataTransfer) {
				const mimicEvent = {
					target: {
						files: event.nativeEvent.dataTransfer.files
					}
				}
				onChange({
					file: getFileUrl(mimicEvent),
					event: mimicEvent,
				})
				return;
			}
			onChange({
				file: getFileUrl(event),
				event: event,
			})
		}
	});


	const resolveDropzoneClass = () => {
		let className = 'prescription-preview__dropzone '

		return className;
	}

	const renderChangeButton = () => {
		return showChangeButton ?
			defaultImage ?
				<label className="badge">
					<input
						type="file"
						accept="image/*,application/pdf"
						onChange={(e) =>
							onChange({
								file: getFileUrl(e),
								event: e,
							})
						}
					/>

					<UploadIcon color={'white'} />
				</label>
				:
				<></>
			:
			<></>
	}

	return (
		<div className={`prescription-preview ${className}`}>
			{defaultImage &&
				isDataFromLocalMachine() &&
				(isPDF ?
					<iframe key={defaultImage} title='prescription preview' className="centered_iframe" src={defaultImage} width="500px" height="500px"
						frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
					:
					<Lightbox image={defaultImage} title="Prescription image" />
				)
			}

			{defaultImage &&
				defaultImage.substring(defaultImage.lastIndexOf('/')).lastIndexOf('.') > -1 &&
				defaultImage.substring(defaultImage.lastIndexOf('.')) !== '.pdf' &&

				<Lightbox image={defaultImage} title="Prescription image" />
			}

			{defaultImage &&
				defaultImage.substring(defaultImage.lastIndexOf('.')) === '.pdf' &&

				<iframe title='prescription preview 2' className="centered_iframe" src={defaultImage} width="500px" height="500px"
					frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
			}

			{(!defaultImage && displayDropZone) ?
				<div {...getRootProps({ className: resolveDropzoneClass() })}>
					<input {...getInputProps()} />
					<div className='prescription-preview__dropzone__icon'>
						<UploadIcon color='white' />
					</div>
					<div className='prescription-preview__dropzone__text'>
						Select or drag and drop a <br />
						prescription image here
					</div>
				</div>
				:
				<span className={`upload-prescription-message ${defaultImage ? "upload-prescription-message--d-none" : ""}`}>Upload a prescription image</span>
			}

			{renderChangeButton()}

		</div>
	);
}

export default PrescriptionPreview;
