import React from "react";
import TableRow from "./TableRow";

import { ReactComponent as ArrowUpIcon } from "../../assets/icons/Arrow up.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/Arrow down.svg";
import { SpinnerIcon } from "../../assets/icons";

function findColumnThatIsSorted(headers) {
	for (let i = 0; i < headers.length; i++) {
		const { ascending } = headers[i];

		if (![null, undefined].includes(ascending)) return { ascending, index: i };
	}
}

function Table({
	title = "Table data",
	tableData = { head: [], body: [] },
	medium = false,
	small = false,
	adjustable = false,
	filters = null,
	tableHeadingClass = "",
	topContent = null,
	bottomContent = null,
	onItemEdit = null,
	onItemDelete = null,
	onInlineAction = null,
	onColumnSort = null,
	tableHeadingElement = null,
	showSpinner = false,
	titleColumnSize = 12,
	splitOrderBadge = "",
	useitemId = false,
}) {
	let sortedColumn = findColumnThatIsSorted(tableData.head);

	function renderTableSizeClass() {
		let classString = "table-size ";

		if (medium) classString += "medium ";
		else if (small) classString += "small ";

		if (!adjustable) classString += "fixed ";

		return classString;
	}

	function renderTableHead() {
		// .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
		const renderRowClass = (item) => `table-col-${item.column * 2} ${item.right ? "right" : ""} ${item.center ? "center" : ""} ${onColumnSort ? "clickable" : ""}`;

		return tableData.head.map((item, index) => (
			<th
				key={index}
				onClick={() => {
					if (onColumnSort) onColumnSort(index);
				}}
				className={renderRowClass(item)}
				title={typeof item.text === 'string' ? item.text : ""}
			>
				{item.text}{" "}{item.right && index === sortedColumn?.index ? renderArrow(sortedColumn.ascending) : ""}
				{!item.right && index === sortedColumn?.index ? renderArrow(sortedColumn.ascending) : ""}
			</th>
		));
	}

	function renderArrow(ascending) {
		return ascending ? <ArrowDownIcon /> : <ArrowUpIcon />;
	}

	// If there is bottomContent (status phases present), order product is cancelled
	// else it is new order, we are talking about so we just remove it from order by index
	function renderTableBody() {
		return tableData.body.map((item, index) => {
			const selectParam = useitemId ? item[0].text : index
			// item[0].text = id of that item

			return (
				<TableRow key={index}
					onItemEdit={onItemEdit ? () => onItemEdit(selectParam) : null}
					onItemDelete={bottomContent ? onItemDelete : () => onItemDelete(selectParam)}
					onInlineAction={onInlineAction}
					rowData={item}
				/>
			)
		}
		);
	}

	const tableDataExists = tableData && tableData.body && tableData.body.length;

	return (
		<div className="card">
			{topContent}

			<div className="heading-filter-container">
				<div className={`col-${titleColumnSize} block`}>
					<h1 className={`table-heading ${tableHeadingClass}`}>{`${title} (${tableData?.body?.length})`}</h1>
					{splitOrderBadge !== "" && <h1 className="split_orded--badge">{`${splitOrderBadge}`}</h1>}
					{tableHeadingElement}
				</div>

				{filters}
			</div>

			<table cellSpacing="0" border="0" width="100%">
				<tbody>
					<tr className="table-header">
						<td>
							<div>
								<table cellSpacing="0" width="100%">
									<thead>
										<tr>{renderTableHead(tableData.head)}</tr>
									</thead>
								</table>
							</div>
						</td>
					</tr>
					{showSpinner ?
						(
							<tr className="empty-table-disclaimer">
								<td>
									<SpinnerIcon color='#B3B3B3' />
								</td>
							</tr>
						) : (
							tableDataExists ? (
								<tr className="table-body">
									<td>
										<div className={renderTableSizeClass()}>
											<table cellSpacing="0" width="100%">
												<tbody>{renderTableBody()}</tbody>
											</table>
										</div>
									</td>
								</tr>
							) : (
								<tr className="empty-table-disclaimer">
									<td>
										<span>Table is empty</span>
									</td>
								</tr>

							)
						)
					}
				</tbody>
			</table>

			{bottomContent}
		</div>
	);
}

export default Table;
