import apiClient from "../clients/apiClient";

class PaymentService {
	merchantSessionKey = async () => {
		const { data } = await apiClient.getMerchantSessionKey();

		return data;
	};
}

export default new PaymentService();
