import apiClient from "../clients/apiClient";
import { adminApiToClient, adminClientToApi, adminsApiToClient } from "../converters/adminsConverter";

class AdminService {
	constructor() {
		this.client = {
			getById: apiClient.getById("admins"),
			filter: apiClient.filter("admins"),
			create: apiClient.create("admins"),
			update: apiClient.update("admins"),
			delete: apiClient.delete("admins"),
		};
	}

	async getById(id) {
		const { data: admin } = await this.client.getById(id);

		return adminApiToClient(admin);
	}

	async filter(queryString) {
		const filters = {};
		if (queryString) {
			filters.filterQuery = queryString;
		}

		const { data: admins } = await this.client.filter(filters);

		return adminsApiToClient(admins);
	}

	async create(adminPayload) {
		adminClientToApi(adminPayload);

		const { data: newAdmin } = await this.client.create(adminPayload);

		return newAdmin;
	}

	async update(adminUpdate) {
		adminUpdate = adminClientToApi(adminUpdate);

		const { data: updatedAdmin } = await this.client.update(adminUpdate);

		return adminApiToClient(updatedAdmin);
	}

	async delete(id) {
		const { data: deletedAdmin } = await this.client.delete({ id });

		return deletedAdmin;
	}

	async getEventHistoryForAdmin(adminId) {
		const { data: eventHistory } = await apiClient.getEventHistoryForAdmin(adminId);

		return eventHistory;
	}
}

export default new AdminService();
