import React from "react";

function Toggler({ isDisabled = false, isChecked = false, onChange = () => null }) {
    return (
        <div>
            <label className="toggler">
                <input disabled={isDisabled} type="checkbox" checked={isChecked} onChange={onChange} />
                <span className="slider round"></span>
            </label>
        </div>
    );
}

export default Toggler;