// noinspection JSIgnoredPromiseFromCall

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Subscribe } from 'unstated';
import { PlusInACircle } from '../../assets/icons';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import Table from '../../components/Table/Table';
import PrescriptionContainer from '../../state/PrescriptionContainer';
import CustomIconWithTooltip from '../../components/SharedComponents/CustomIconWithTooltip';
import InfoIconGray from '../../assets/icons/InfoIconGray';

const ToBeAssigned = ({ container }) => {
	const history = useHistory();
	const loadingToBeAssigned = container.state.loadingToBeAssigned;
	const toBeAssignedFormKey = "toBeAssigned";
	const awaitingRewriteFormKey = "awaitingRewrite";
	const futureDateScriptsFormKey = "futureDatedScripts";

	useEffect(() => {
		container.getAllToBeAssigned();
		container.getAllAwaitingRewrite();
		container.getAllFutureScripts();
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		container.sort(5, awaitingRewriteFormKey, false)
		container.sort(4, futureDateScriptsFormKey, false)
	}, [container.state.tableData.awaitingRewrite, container.state.tableData.futureDatedScripts])

	const filterFunctionsMapper = {
		[toBeAssignedFormKey]: container.setFilterTimeoutAssignPrescription,
		[awaitingRewriteFormKey]: container.setFilterTimeoutAwaitingRewrite,
		[futureDateScriptsFormKey]: container.setFilterTimeOutForFutureDatedScripts,
	}

	const filterDescriptionMapper = {
		[toBeAssignedFormKey]: "You may search by prescriber name or tracking number.",
		[awaitingRewriteFormKey]: "You may search by patient name or prescription number.",
		[futureDateScriptsFormKey]: "You may search by patient name.",
	}

	function renderUploadButton() {
		return (
			<Button style={{ marginRight: "2rem", marginLeft: "auto" }} className='height-search' bordered={true} onClick={() => history.push('/prescriptions/add')}>
				<PlusInACircle /> &nbsp; Upload prescriptions
			</Button>
		);
	}

	function renderFilters(formKey) {
		return (
			<div className={`filter-container ${formKey === toBeAssignedFormKey ? "col-12" : "col-3"}`}>
				{formKey === toBeAssignedFormKey &&
					<>
						{renderUploadButton()}
					</>
				}
				<div className={`${formKey === toBeAssignedFormKey ? "col-5" : "col-12"}`}>
					<TextInput placeholder="Search..." onChange={(e) => filterFunctionsMapper[formKey](e.value)} clearable={true} onClear={() => filterFunctionsMapper[formKey]('')} />
					<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={filterDescriptionMapper[formKey]} icon={<InfoIconGray />} />
				</div>
			</div>
		);
	}

	return (
		<div>
			<Table
				title={`To be assigned`}
				tableData={{
					head: container.state.tableHeaders.toBeAssigned,
					body: container.state.tableData.toBeAssigned,
				}}
				filters={renderFilters(toBeAssignedFormKey)}
				onColumnSort={(columnIndex) => container.sort(columnIndex, toBeAssignedFormKey)}
				showSpinner={loadingToBeAssigned}
				medium
			/>

			<br />

			<Table
				title={`Awaiting re-write`}
				tableData={{
					head: container.state.tableHeaders.awaitingRewrite,
					body: container.state.tableData.awaitingRewrite,
				}}
				filters={renderFilters(awaitingRewriteFormKey)}
				onColumnSort={(columnIndex) => container.sort(columnIndex, awaitingRewriteFormKey, columnIndex === 2 ? true : false)}
				showSpinner={loadingToBeAssigned}
				medium
			/>

			<br />

			<Table
				title={`Future dated scripts`}
				tableData={{
					head: container.state.tableHeaders.futureDatedScripts,
					body: container.state.tableData.futureDatedScripts,
				}}
				filters={renderFilters(futureDateScriptsFormKey)}
				onColumnSort={(columnIndex) => container.sort(columnIndex, futureDateScriptsFormKey, columnIndex === 1 ? true : false)}
				showSpinner={loadingToBeAssigned}
				medium
			/>
		</div>
	)
}

function SubscribedToBeAssigned(props) {
	return (
		<Subscribe to={[PrescriptionContainer]}>
			{(container) => (
				<ToBeAssigned {...props} container={container} />
			)}
		</Subscribe>
	);
}

export default SubscribedToBeAssigned