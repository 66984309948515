import React from 'react'

const SendPaymentLinkIcon = () => {
    return (
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.8513 28.149C32.1345 27.4326 31.1626 27.0302 30.1493 27.0302C29.1359 27.0302 28.164 27.4326 27.4473 28.149L24.7444 30.851C24.0277 31.5678 23.625 32.5399 23.625 33.5535C23.625 34.5671 24.0277 35.5392 24.7444 36.2559C25.4611 36.9726 26.4332 37.3753 27.4468 37.3753C28.4604 37.3753 29.4325 36.9726 30.1493 36.2559L31.5003 34.9049" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.1494 30.851C30.8661 31.5674 31.838 31.9699 32.8514 31.9699C33.8648 31.9699 34.8367 31.5674 35.5534 30.851L38.2563 28.149C38.973 27.4323 39.3757 26.4602 39.3757 25.4466C39.3757 24.433 38.973 23.4609 38.2563 22.7441C37.5396 22.0274 36.5675 21.6248 35.5539 21.6248C34.5402 21.6248 33.5681 22.0274 32.8514 22.7441L31.5004 24.0951" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 14C8 13.337 8.26339 12.7011 8.73223 12.2322C9.20107 11.7634 9.83696 11.5 10.5 11.5H25.5C26.163 11.5 26.7989 11.7634 27.2678 12.2322C27.7366 12.7011 28 13.337 28 14V24C28 24.663 27.7366 25.2989 27.2678 25.7678C26.7989 26.2366 26.163 26.5 25.5 26.5H10.5C9.83696 26.5 9.20107 26.2366 8.73223 25.7678C8.26339 25.2989 8 24.663 8 24V14ZM10.5 12.75C10.1685 12.75 9.85054 12.8817 9.61612 13.1161C9.3817 13.3505 9.25 13.6685 9.25 14V15.25H26.75V14C26.75 13.6685 26.6183 13.3505 26.3839 13.1161C26.1495 12.8817 25.8315 12.75 25.5 12.75H10.5ZM26.75 17.75H9.25V24C9.25 24.3315 9.3817 24.6495 9.61612 24.8839C9.85054 25.1183 10.1685 25.25 10.5 25.25H25.5C25.8315 25.25 26.1495 25.1183 26.3839 24.8839C26.6183 24.6495 26.75 24.3315 26.75 24V17.75Z" fill="black" />
            <path d="M10.5 21.5C10.5 21.1685 10.6317 20.8505 10.8661 20.6161C11.1005 20.3817 11.4185 20.25 11.75 20.25H13C13.3315 20.25 13.6495 20.3817 13.8839 20.6161C14.1183 20.8505 14.25 21.1685 14.25 21.5V22.75C14.25 23.0815 14.1183 23.3995 13.8839 23.6339C13.6495 23.8683 13.3315 24 13 24H11.75C11.4185 24 11.1005 23.8683 10.8661 23.6339C10.6317 23.3995 10.5 23.0815 10.5 22.75V21.5Z" fill="black" />
        </svg>
    )
}

export default SendPaymentLinkIcon
