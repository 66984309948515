import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Subscribe } from "unstated";

import TextInput from "../../components/Form/TextInput";

import PrescriptionContainer from "../../state/PrescriptionContainer";
import { PrescriptionPhases, AdminTypesGroups, AdminTypeDefaultLinks } from "../../enums";
import contentHelper from "../../util/helpers/contentHelper";
import AuthenticationContainer from "../../state/AuthenticationContainer";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import { useState } from "react";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";
import { debounce } from "../../util/helpers/debounce";
import { useCallback } from "react";
import axios from "axios";
import Button from "../../components/Form/Button";
import Spinner from "../../components/Spinner/Spinner";
import { DownloadIcon } from "../../assets/icons";

function checkPermissions(phase, type) {
	const mapper = {
		all: AdminTypesGroups.SuperRegular,
		new: AdminTypesGroups.SuperRegular,
		outofstock: AdminTypesGroups.SuperRegular,
		readyforpayment: AdminTypesGroups.SuperRegular,
		paymentmissed: AdminTypesGroups.SuperRegular,
		requirepapercopy: AdminTypesGroups.SuperRegular,
		readyforpharmacy: AdminTypesGroups.All,
		readyforpatient: AdminTypesGroups.SuperRegular,
		shipped: AdminTypesGroups.SuperRegular,

		completed: AdminTypesGroups.All,
		void: AdminTypesGroups.All,
		archived: AdminTypesGroups.All,
		past: AdminTypesGroups.All,
	};

	return mapper[phase].includes(type);
}

function Prescriptions({ container, authenticationContainer }) {
	// Hook-related data
	const { phase } = useParams();
	let normalizedPhase = contentHelper.normalizePrescriptionPhase(phase);
	const loadingPaginatedPrescriptions = container.state.loadingPaginatedPrescriptions;

	const history = useHistory();

	const prescriptionTables = {
		all: 0,
		new: 1,
		outofstock: 2,
		readyforpayment: 3,
		paymentmissed: 4,
		requirepapercopy: 5,
		readyforpharmacy: 6,
		readyforpatient: 7,
		shipped: 8,
		completed: 9,
		void: 10,
		archived: 11,
		past: 12,
		cancelled: 13,
	};

	const initialPageNumbers = Object.keys(prescriptionTables).reduce((acc, tableName) => {
		acc[tableName] = 1;
		return acc;
	}, {});

	const initialFilterQueries = Object.keys(prescriptionTables).reduce((acc, tableName) => {
		acc[tableName] = "";
		return acc;
	}, {});

	const initialSortColumns = Object.keys(prescriptionTables).reduce((acc, tableName) => {
		acc[tableName] = { columnName: 'id', direction: 1 };
		return acc;
	}, {});


	const [pageNumbers, setPageNumbers] = useState(initialPageNumbers);
	const [pageSize, setPageSize] = useState(20)
	const [sortColumns, setSortColumns] = useState(initialSortColumns);
	const [filterQueries, setFilterQueries] = useState(initialFilterQueries);
	const [cancelTokens, setCancelTokens] = useState({});
	const [isReportDownloadLoading, setIsReportDownloadLoading] = useState(false);

	useEffect(() => {
		if (!authenticationContainer.state.currentAdministrator) return;

		const adminType = authenticationContainer.state.currentAdministrator.type;

		if (!checkPermissions(normalizedPhase, adminType)) history.push(AdminTypeDefaultLinks[adminType]);
		//eslint-disable-next-line
	}, [phase]);

	useEffect(() => {
		getAllPaginated(normalizedPhase, filterQueries[normalizedPhase], 1, pageSize, sortColumns[normalizedPhase].columnName, sortColumns[normalizedPhase].direction);
	}, [container, normalizedPhase, pageSize]);

	useEffect(() => {
		container.getAllAdminsPartial()
		// eslint-disable-next-line
	}, []);

	const getAllPaginated = (tableName, filterQuery, newPageNumber, pageSize, sortColumn, sortDirection) => {
		if (cancelTokens[tableName]) {
			cancelTokens[tableName].cancel('Operation canceled due to new request.');
		}
		const newTokenSource = axios.CancelToken.source();
		setCancelTokens(prevTokens => ({ ...prevTokens, [tableName]: newTokenSource }));

		container.getAllPaginated(tableName, filterQuery, newPageNumber, pageSize, sortColumn, sortDirection, newTokenSource);
	}

	const onPageChange = (newPageNumber, tableName) => {
		setPageNumbers(prevPageNumbers => ({
			...prevPageNumbers,
			[tableName]: newPageNumber,
		}));

		getAllPaginated(tableName, filterQueries[tableName], newPageNumber, pageSize, sortColumns[tableName].columnName, sortColumns[tableName].direction);
	}

	const onColumnSort = (columnName, sortDirection, columnIndex, tableName) => {
		setSortColumns(prevState => ({
			...prevState,
			[tableName]: { columnName, direction: sortDirection }
		}));

		getAllPaginated(tableName, filterQueries[tableName], pageNumbers[tableName], pageSize, columnName, sortDirection);
		container.handleColumnSort(columnIndex, tableName);
	}

	const callGetAllPaginated = useCallback((tableName, value) => {
		getAllPaginated(
			tableName,
			value,
			1,
			pageSize,
			sortColumns[tableName].columnName,
			sortColumns[tableName].direction
		);
	}, [pageSize, sortColumns]);

	const debouncedGetAllPaginated = useCallback(debounce(callGetAllPaginated, 500), [callGetAllPaginated]);

	const onFilterQueryChange = (e, tableName) => {
		setFilterQueries(prevFilterQueries => ({
			...prevFilterQueries,
			[tableName]: e.value,
		}));

		setPageNumbers(prevPageNumbers => ({
			...prevPageNumbers,
			[tableName]: 1,
		}));

		debouncedGetAllPaginated(tableName, e.value);
	}

	const onClearFilter = (tableName) => {
		setFilterQueries(prevFilterQueries => ({
			...prevFilterQueries,
			[tableName]: '',
		}));

		setPageNumbers(prevPageNumbers => ({
			...prevPageNumbers,
			[tableName]: 1,
		}));

		callGetAllPaginated(tableName, '');
	}

	async function DownloadReport(phase) {
		setIsReportDownloadLoading(true)

		await container.getTableReport(phase);

		setIsReportDownloadLoading(false)
	}

	function renderPaginatedFilters(tableName) {
		return (
			<div className="filter-container col-3">
				<div className="col-12">
					<TextInput placeholder="Search..." onChange={(e) => onFilterQueryChange(e, tableName)} clearable={true} onClear={() => onClearFilter(tableName)} />
					<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={"You may search by prescription ID, number, patient or prescriber."} icon={<InfoIconGray />} />
				</div>
			</div>
		);
	}

	function renderBottomContent(tableName, label) {
		return (
			<div style={{ display: "flex", flexDirection: "row-reverse" }}>
				<Button onClick={() => DownloadReport(tableName)} disabled={isReportDownloadLoading} bordered={true} className="m-r-xs btn--mini">
					{isReportDownloadLoading ? <Spinner /> :
						<div style={{ display: "flex", padding: "5px" }}>
							<CustomIconWithTooltip className="generic_tool_tip--up" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={`Export as csv: ${label}`} icon={<DownloadIcon color="#2d495a" />}></CustomIconWithTooltip>
						</div>}
				</Button>
			</div>
		);
	}

	function renderToBeProcessed() {
		return (
			<PaginatedTable
				title="To be processed"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.new],
					body: container.state.tableData[PrescriptionPhases.new],
				}}
				filters={renderPaginatedFilters("new")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.new]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.new]}
				onPageChange={onPageChange}
				tableName="new"
				bottomContent={renderBottomContent("new", "To be processed")}
			/>
		);
	}

	function renderOutOfStock() {
		return (
			<PaginatedTable
				title="Awaiting stock"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.outofstock],
					body: container.state.tableData[PrescriptionPhases.outofstock],
				}}
				filters={renderPaginatedFilters("outofstock")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.outofstock]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.outofstock]}
				onPageChange={onPageChange}
				tableName=""
				bottomContent={renderBottomContent("outofstock", "Awaiting stock")}
			/>
		);
	}

	function renderReadyForPayment() {
		return (
			<PaginatedTable
				title="Ready for payment"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.readyforpayment],
					body: container.state.tableData[PrescriptionPhases.readyforpayment],
				}}
				filters={renderPaginatedFilters("readyforpayment")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.readyforpayment]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.readyforpayment]}
				onPageChange={onPageChange}
				tableName="readyforpayment"
				bottomContent={renderBottomContent("readyforpayment", "Ready for payment")}
			/>
		);
	}

	function renderMissed() {
		return (
			<PaginatedTable
				title="Payment missed"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.paymentmissed],
					body: container.state.tableData[PrescriptionPhases.paymentmissed],
				}}
				filters={renderPaginatedFilters("paymentmissed")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.paymentmissed]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.paymentmissed]}
				onPageChange={onPageChange}
				tableName="paymentmissed"
				bottomContent={renderBottomContent("paymentmissed", "Payment missed")}
			/>
		);
	}

	function renderAwaitingPaperCopy() {
		return (
			<PaginatedTable
				title="Awaiting paper copy"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.requirepapercopy],
					body: container.state.tableData[PrescriptionPhases.requirepapercopy],
				}}
				filters={renderPaginatedFilters("requirepapercopy")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.requirepapercopy]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.requirepapercopy]}
				onPageChange={onPageChange}
				tableName="requirepapercopy"
				bottomContent={renderBottomContent("requirepapercopy", "Awaiting paper copy")}
			/>
		);
	}

	function renderReadyForForPharmacy() {
		return (
			<PaginatedTable
				title="Ready for pharmacy"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.readyforpharmacy],
					body: container.state.tableData[PrescriptionPhases.readyforpharmacy],
				}}
				filters={renderPaginatedFilters("readyforpharmacy")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.readyforpharmacy]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.readyforpharmacy]}
				onPageChange={onPageChange}
				tableName="readyforpharmacy"
				bottomContent={renderBottomContent("readyforpharmacy", "Ready for pharmacy")}
			/>
		);
	}

	function renderReadyToBeShipped() {
		return (
			<PaginatedTable
				title="Ready to be shipped"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.readyforpatient],
					body: container.state.tableData[PrescriptionPhases.readyforpatient],
				}}
				filters={renderPaginatedFilters("readyforpatient")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.readyforpatient]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.readyforpatient]}
				onPageChange={onPageChange}
				tableName="readyforpatient"
				bottomContent={renderBottomContent("readyforpatient", "Ready to be shipped")}
			/>
		);
	}

	function renderShipped() {
		return (
			<PaginatedTable
				title="Shipped"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.shipped],
					body: container.state.tableData[PrescriptionPhases.shipped],
				}}
				filters={renderPaginatedFilters("shipped")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.shipped]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.shipped]}
				onPageChange={onPageChange}
				tableName="shipped"
				bottomContent={renderBottomContent("shipped", "Shipped")}
			/>
		);
	}

	function renderCancelled() {
		return (
			<PaginatedTable
				title="Cancelled"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.cancelled],
					body: container.state.tableData[PrescriptionPhases.cancelled],
				}}
				filters={renderPaginatedFilters("cancelled")}
				onColumnSort={onColumnSort}
				adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.cancelled]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.cancelled]}
				onPageChange={onPageChange}
				tableName="cancelled"
				bottomContent={renderBottomContent("cancelled", "Cancelled")}
			/>
		);
	}

	function renderCompleted() {
		return (
			<PaginatedTable
				title="Completed"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.completed],
					body: container.state.tableData[PrescriptionPhases.completed],
				}}
				filters={renderPaginatedFilters("completed")}
				onColumnSort={onColumnSort} adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.completed]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.completed]}
				onPageChange={onPageChange}
				tableName="completed"
			/>
		);
	}

	function renderVoid() {
		return (
			<PaginatedTable
				title="Expired"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.void],
					body: container.state.tableData[PrescriptionPhases.void],
				}}
				filters={renderPaginatedFilters("void")}
				onColumnSort={onColumnSort} adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.void]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.void]}
				onPageChange={onPageChange}
				tableName="void"
			/>
		);
	}

	function renderArchived() {
		return (
			<PaginatedTable
				title="Archived"
				tableData={{
					head: container.state.tableHeaders[PrescriptionPhases.archived],
					body: container.state.tableData[PrescriptionPhases.archived],
				}}
				filters={renderPaginatedFilters("archived")}
				onColumnSort={onColumnSort} adjustable
				medium
				showSpinner={loadingPaginatedPrescriptions[PrescriptionPhases.archived]}

				paginationData={container.state.tablePaginationData[PrescriptionPhases.archived]}
				onPageChange={onPageChange}
				tableName="archived"
			/>
		);
	}


	function renderStackedTables() {
		return (
			<>
				{renderToBeProcessed()}
				<br />
				{renderOutOfStock()}
				<br />
				{renderReadyForPayment()}
				<br />
				{renderMissed()}
				<br />
				{renderAwaitingPaperCopy()}
				<br />
				{renderReadyForForPharmacy()}
				<br />
				{renderReadyToBeShipped()}
				<br />
				{renderShipped()}
				<br />
				{renderCancelled()}
			</>
		);
	}

	function renderAllPast() {
		return (
			<>
				{renderCompleted()}
				<br />
				{renderVoid()}
				<br />
				{renderArchived()}
			</>
		);
	}

	// choose a table based on link
	const PhaseToTableMapper = {
		all: renderStackedTables,
		new: renderToBeProcessed,
		outofstock: renderOutOfStock,
		readyforpayment: renderReadyForPayment,
		paymentmissed: renderMissed,
		requirepapercopy: renderAwaitingPaperCopy,
		readyforpharmacy: renderReadyForForPharmacy,
		readyforpatient: renderReadyToBeShipped,
		shipped: renderShipped,

		completed: renderCompleted,
		void: renderVoid,
		archived: renderArchived,
		past: renderAllPast,
	};

	return PhaseToTableMapper[normalizedPhase.toLowerCase()]();
}

function SubscribedPrescriptions(props) {
	return (
		<Subscribe to={[PrescriptionContainer, AuthenticationContainer]}>
			{(container, authenticationContainer) => (
				<Prescriptions {...props} container={container} authenticationContainer={authenticationContainer} />
			)}
		</Subscribe>
	);
}

export default SubscribedPrescriptions;
