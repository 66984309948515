import React from "react";

function ProcessPrescriptionTooltipContent({ text }) {

    return (
        <div>
            <div className={'process-prescription-tooltip-content__polygon'}>
                <svg width="29" height="15" viewBox="0 0 29 15" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1718 1.17973C13.9294 0.506804 15.0706 0.506805 15.8282 1.17973L27.4524 11.5047C28.8276 12.7262 27.9636 15 26.1242 15H2.87582C1.03642 15 0.172406 12.7262 1.54764 11.5047L13.1718 1.17973Z" fill="#353534" />
                </svg>
            </div>
            <div className={'process-prescription-tooltip-content'}>
                <div className={'process-prescription-tooltip-content__text'}>
                    <span>{text}</span>
                </div>
            </div>
        </div>
    );
}

export default ProcessPrescriptionTooltipContent;