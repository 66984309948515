import React, { useEffect, useState } from "react";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import PasswordInput from "../../components/Form/PasswordInput";
import TextInput from "../../components/Form/TextInput";
import FormRow from "../../components/Form/FormRow";

import ProfileContainer from "../../state/ProfileContainer";

function Profile({ container }) {
	// State-related data
	const formKey = "administrator";
	const administrator = container.state[formKey];
	const onFieldChange = (event) => container.onFormChange(event, formKey);

	// if passwords are empty this is true
	// if passwords are not empty
	// 		if they match this is true
	//      if they don't match this is false
	const [arePasswordsOk, setArePasswordsOk] = useState(true);

	// Hook-related data
	useEffect(() => {
		container.getAdministrator();
	}, [container]);

	// check if passwords are ok
	useEffect(() => {
		const arePasswordsEmpty = !administrator.password.value.trim() && !administrator.repeatPassword.value.trim();

		if (arePasswordsEmpty) {
			setArePasswordsOk(true);

			return;
		}

		const doPasswordsMatch = administrator.password.value === administrator.repeatPassword.value;

		if (!arePasswordsEmpty && !doPasswordsMatch) {
			setArePasswordsOk(false);

			return;
		}

		setArePasswordsOk(true);
	}, [administrator.password.value, administrator.repeatPassword.value]);

	const isSaveButtonDisabled = !arePasswordsOk || !container.isFormValid(formKey) || !container.state.profileChanged;

	return (
		<div className="card p-m">
			<FormRow>
				<h1 className="h4">Your personal info</h1>
			</FormRow>

			<FormRow>
				<div className="col-6">
					<TextInput label="Full name" placeholder="e.g. John Doe" name="name" value={administrator.name.value} onChange={onFieldChange} />
				</div>
				<div className="col-6">
					<TextInput
						label="Email"
						placeholder="e.g. johndoe@email.com"
						name="email"
						value={administrator.email.value}
						onChange={onFieldChange}
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Password</h2>
			</FormRow>
			<FormRow>
				<div className="col-6">
					<PasswordInput
						label="Password"
						placeholder="Password"
						name="password"
						value={administrator.password.value}
						onChange={onFieldChange}
					/>
				</div>
				<div className="col-6">
					<PasswordInput
						label="Repeat password"
						placeholder="Repeat password"
						name="repeatPassword"
						value={administrator.repeatPassword.value}
						onChange={onFieldChange}
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Metadata</h2>
			</FormRow>
			<FormRow>
				<div className="col-4">
					<TextInput label="Created at" value={administrator.createdAt.value} disabled={true} />
				</div>
				<div className="col-4">
					<TextInput label="Updated at" value={administrator.updatedAt.value} disabled={true} />
				</div>
				<div className="col-4">
					<TextInput label="Last login" value={administrator.lastLogin.value} disabled={true} />
				</div>
			</FormRow>

			<FormRow>
				<Button text="Save" onClick={container.updateAdministrator} disabled={isSaveButtonDisabled || container.state.updatingProfile} />
			</FormRow>
		</div>
	);
}

function SubscribedProfile(props) {
	return <Subscribe to={[ProfileContainer]}>{(container) => <Profile {...props} container={container} />}</Subscribe>;
}

export default SubscribedProfile;
