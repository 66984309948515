import React from "react";

import Modal from "./Modal";
import FormRow from "../Form/FormRow";
import Button from "../Form/Button";

function Prompt({
    title = "Are you sure?",
    description = "Are you sure that you want to perform this action?",
    isOpen = false,
    onYes = () => null,
    onNo = () => null,
    onClose = () => null,
    children,
    yesDisabled = false,
    customButtonYes = null,
    customButtonNo = null,
}) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <FormRow>
                <h2 className="h4" >{title}</h2>
            </FormRow>
            <FormRow>
                <p>{description}</p>
            </FormRow>
            {children &&
                <FormRow>
                    {children}
                </FormRow>
            }
            <FormRow>
                <div className="col-6">
                    <Button disabled={yesDisabled} text={customButtonYes || "Yes"} onClick={onYes} />
                </div>
                <div className="col-6">
                    <Button className="btn--bordered--no_radius" text={customButtonNo || "No"} onClick={onNo} />
                </div>
            </FormRow>
        </Modal>
    );
}

export default Prompt;