import React from "react";
import moment from "moment";

function FlaggedPatientTooltipContent({ patient }) {

	return (
		<div className={'flagged-prescription-tooltip-content'} >
			<div className={'flagged-prescription-tooltip-content__title'}>
				Patient note - {moment(patient.adminNoteDateTime).format('DD/MM/YYYY')}
			</div>
			<div className={'flagged-prescription-tooltip-content__text'}>
				<span className={"bold"}>{patient.noteAdmin?.name || "Deleted Admin"}:</span> {patient.patientNote}
			</div>
		</div>
	);
}

export default FlaggedPatientTooltipContent;