import { useState } from "react";

export default function useCsvParser({ headers = [], excludeFirstRow = true }) {

    const [result, setResult] = useState([])
    const [file, setFile] = useState(null)
    const onFileSelected = async (e) => {
        if (e.dataTransfer) {
            const file = e.dataTransfer.files[0];
            setFile(file);
            setResult(await parse(file));
            return;
        }
        const file = e.target.files[0]
        setFile(file);
        setResult(await parse(file))
    }

    async function parse(csv) {
        if (csv) {
            const text = await csv.text()
            const lines = text.split('\n')
            if (excludeFirstRow) {
                lines.splice(0, 1)
            }
            const arrayOfRows = []

            lines.forEach(line => {
                const obj = {}
                const cells = line.split(/,|;/)
                cells.forEach((cell, cellIndex) => {
                    obj[headers[cellIndex]] = cell.replace(/"/g, '').trim()
                })
                if (Object.entries(obj).length === headers.length) {

                    arrayOfRows.push(obj)
                }
            })

            return arrayOfRows
        }
        return result
    }



    return {
        parse,
        onFileSelected,
        result,
        file,
        setFile
    }
}