import React from "react";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";

import AuthenticationContainer from "../../state/AuthenticationContainer";

import { ActiveRoutes, AdminType } from "../../enums";

import { ReactComponent as ActivePrescriptionsIcon } from "../../assets/icons/Active prescriptions.svg";
import { ReactComponent as AdministratorsIcon } from "../../assets/icons/Administrators.svg";
import { ReactComponent as AllPrescriptionsIcon } from "../../assets/icons/All prescriptions.svg";
import { ReactComponent as AwaitingPaperCopyIcon } from "../../assets/icons/Awaiting paper copy.svg";
import { ReactComponent as CarersIcon } from "../../assets/icons/Carers.svg";
import { ReactComponent as DoctorsIcon } from "../../assets/icons/Doctor.svg";
import { ReactComponent as InTransitIcon } from "../../assets/icons/In transit.svg";
import { ReactComponent as OutOfStockIcon } from "../../assets/icons/Out of stock.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/Patient.svg";
import { ReactComponent as MetricsIcon } from "../../assets/icons/metrics.svg";
import { ReactComponent as ProductsIcon } from "../../assets/icons/Products.svg";
import { ReactComponent as ReadyForPaymentIcon } from "../../assets/icons/Ready for payment.svg";
import { ReactComponent as ReadyForPharmacyIcon } from "../../assets/icons/Ready for pharmacy.svg";
import { ReactComponent as ReadyToBeShippedIcon } from "../../assets/icons/Ready to be shipped.svg";
import { ReactComponent as ToBeProcessedIcon } from "../../assets/icons/To be processed.svg";
import { ReactComponent as ToBeAssignedIcon } from "../../assets/icons/To be assigned.svg";
import { ReactComponent as ClinicsIcon } from "../../assets/icons/ClinicsIcon.svg";
import RenderForAdminTypes from "../SharedComponents/RenderForAdminTypes";

function Aside({ container }) {
	const admin = container.state.currentAdministrator;

	function resolveLinkClassName(activeRoutes) {
		const currentRoute = window.location.pathname.toLowerCase();

		const isRouteActive =
			(activeRoutes.exact && activeRoutes.exact.includes(currentRoute)) ||
			(activeRoutes.prefix && activeRoutes.prefix.filter((e) => currentRoute.startsWith(e.toLowerCase())).length);

		return isRouteActive ? "active" : "";
	}

	return (
		<aside>
			<div className="logo-container">
				<img src="/assets/images/logo.svg" alt="Lyphe Dispensary Logo" />
			</div>
			<nav className="primary-navigation">
				<ul>

					{[AdminType.Super, AdminType.Regular].includes(admin.type) &&
						<li>
							<Link className={resolveLinkClassName(ActiveRoutes.ToBeAssigned)} to="/prescriptions/toBeAssigned">
								<i>
									<ToBeAssignedIcon />
								</i>
								<span>To be assigned</span>
							</Link>

						</li>
					}

					<li>

						<Link className={resolveLinkClassName(ActiveRoutes.ActivePrescriptions)} to="/prescriptions/all">
							<i>
								<ActivePrescriptionsIcon />
							</i>
							<span>Active orders</span>
						</Link>


						{[AdminType.Super, AdminType.Regular].includes(admin.type) && (
							<>
								<nav className="secondary-navigation">
									<ul>
										<li>
											<Link className={resolveLinkClassName(ActiveRoutes.AllPrescriptions)} to="/prescriptions/all">
												<i>
													<AllPrescriptionsIcon />
												</i>
												<span>All</span>
											</Link>
										</li>
										<li>
											<Link className={resolveLinkClassName(ActiveRoutes.NewPrescriptions)} to="/prescriptions/new">
												<i>
													<ToBeProcessedIcon />
												</i>
												<span>To be processed</span>
											</Link>
										</li>

										<li>
											<Link
												className={resolveLinkClassName(ActiveRoutes.OutOfStockPrescriptions)}
												to="/prescriptions/outOfStock"
											>
												<i>
													<OutOfStockIcon />
												</i>
												<span>Awaiting Stock</span>
											</Link>
										</li>
										<li>
											<Link
												className={resolveLinkClassName(ActiveRoutes.ReadyForPaymentPrescriptions)}
												to="/prescriptions/readyForPayment"
											>
												<i>
													<ReadyForPaymentIcon />
												</i>
												<span>Ready for payment</span>
											</Link>
										</li>
										<li>
											<Link className={resolveLinkClassName(ActiveRoutes.PaymentMissed)} to="/prescriptions/paymentmissed">
												<i>
													<ReadyForPaymentIcon />
												</i>
												<span>Payment missed</span>
											</Link>
										</li>
										<li>
											<Link
												className={resolveLinkClassName(ActiveRoutes.RequirePaperCopyPrescriptions)}
												to="/prescriptions/requirepapercopy"
											>
												<i>
													<AwaitingPaperCopyIcon />
												</i>
												<span>Awaiting paper copy</span>
											</Link>
										</li>



										<li>
											<Link
												className={resolveLinkClassName(ActiveRoutes.ReadyForPharmacyPrescriptions)}
												to="/prescriptions/readyForPharmacy"
											>
												<i>
													<ReadyForPharmacyIcon />
												</i>
												<span>Ready for pharmacy</span>
											</Link>
										</li>

										<li>
											<Link
												className={resolveLinkClassName(ActiveRoutes.ReadyForPatientPrescriptions)}
												to="/prescriptions/readyForPatient"
											>
												<i>
													<ReadyToBeShippedIcon />
												</i>
												<span>Ready to be shipped</span>
											</Link>
										</li>

										<li>
											<Link className={resolveLinkClassName(ActiveRoutes.InTransitPrescriptions)} to="/prescriptions/shipped">
												<i>
													<InTransitIcon />
												</i>
												<span>Shipped</span>
											</Link>
										</li>
									</ul>
								</nav>
							</>
						)}
					</li>

					{[AdminType.Regular, AdminType.Super].includes(admin.type) && (
						<>
							<li>
								<Link className={resolveLinkClassName(ActiveRoutes.PastPrescriptions)} to="/prescriptions/past">
									<i className="icon-drawer"></i>
									<span>Past orders</span>
								</Link>

								<nav className="secondary-navigation">
									<ul>
										<li>
											<Link className={resolveLinkClassName(ActiveRoutes.CompletedPrescriptions)} to="/prescriptions/completed">
												<i className="icon-check"></i>
												<span>Completed</span>
											</Link>
										</li>
										<li>
											<Link className={resolveLinkClassName(ActiveRoutes.VoidPrescriptions)} to="/prescriptions/void">
												<i className="icon-clock"></i>
												<span>Expired</span>
											</Link>
										</li>
										<li>
											<Link className={resolveLinkClassName(ActiveRoutes.ArchivedPrescriptions)} to="/prescriptions/archived">
												<i className="icon-folder"></i>
												<span>Archived</span>
											</Link>
										</li>
									</ul>
								</nav>
							</li>

							<li>
								<Link className={resolveLinkClassName(ActiveRoutes.Patients)} to="/patients">
									<i>
										<PatientIcon />
									</i>
									<span>Patients</span>
								</Link>
							</li>
							{/* <li>
								<Link className={resolveLinkClassName(ActiveRoutes.Carers)} to="/carers">
									<i>
										<CarersIcon />
									</i>
									<span>Carers</span>
								</Link>
							</li> */}
							<li>
								<Link className={resolveLinkClassName(ActiveRoutes.Prescribers)} to="/prescribers">
									<i>
										<DoctorsIcon />
									</i>
									<span>Prescribers</span>
								</Link>
							</li>
						</>
					)}
					<li>
						<Link className={resolveLinkClassName(ActiveRoutes.Products)} to="/products">
							<i>
								<ProductsIcon />
							</i>
							<span>Products</span>
						</Link>
					</li>

					<RenderForAdminTypes adminTypes={[AdminType.Super]} >
						<li>
							<Link className={resolveLinkClassName(ActiveRoutes.Organisations)} to="/organisations">
								<i>
									<ClinicsIcon />
								</i>
								<span>Organisations</span>
							</Link>
						</li>
					</RenderForAdminTypes>
					{admin.type === AdminType.Super && (
						<li>
							<Link className={resolveLinkClassName(ActiveRoutes.Administrators)} to="/administrators">
								<i>
									<AdministratorsIcon />
								</i>
								<span>Administrators</span>
							</Link>
						</li>
					)}
					{[AdminType.Super].includes(admin.type) && (
						<li>
							<Link className={resolveLinkClassName(ActiveRoutes.Metrics)} to="/metrics">
								<i>
									<MetricsIcon />
								</i>
								<span>Metrics</span>
							</Link>
						</li>
					)}
				</ul>
			</nav>
		</aside>
	);
}

function SubscribedAside(props) {
	return <Subscribe to={[AuthenticationContainer]}>{(container) => <Aside {...props} container={container} />}</Subscribe>;
}

export default SubscribedAside;
