import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";

import prescriberService from "../services/PrescribersService";

import browserHelper from "../util/helpers/browserHelper";
import errorHelper from "../util/helpers/errorHelper";
import { required, isFormValid, isEmail, maxLength, customRegex } from "../util/validation/validation";
import { Throttle } from "../util/Throttle";
import { formatDateForPresenting } from "../util/dates";
import tableHeaders from "../util/constants/tableHeaders";
import { containerSort, sortTableDataAndUpdateHeaders } from "../util/helpers/sorting";
import OrganisationsService from "../services/OrganisationsService";
import React from "react";
import { OrganisationEmployeeType, OrganisationTypeMapper, PrescriberType } from "../enums";

const updatePrescriberInitialState = {
	id: { value: "" },
	email: { value: "" },
	firstName: { value: "" },
	lastName: { value: "" },
	prescriberIdCode: { value: "" },
	password: { value: "" },
	repeatPassword: { value: "" },
	prescriberType: { value: "" }
};

const newPrescriberInitialState = {
	prescriberType: { value: "" },
	email: { value: "" },
	firstName: { value: "" },
	lastName: { value: "" },
	prescriberIdCode: { value: "" },
	password: { value: "" },
	repeatPassword: { value: "" },
	organisationIds: { value: [0] }
};

const PASSWORD_REGEX = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?!@$%^&*-])[A-Za-z\\d#?!@$%^&*-]{8,}$";

class PrescriberContainer extends Container {
	//#region State

	constructor() {
		super();

		this.state = {
			tableHeaders: tableHeaders.prescribers,
			tableData: [],
			newPrescriber: { ...newPrescriberInitialState },

			filteredOrganisations: [],
			prescriberTypes: Object.values(PrescriberType).map((e, index) => {
				return {
					label: e,
					value: index
				}
			}),

			updatePrescriber: { ...updatePrescriberInitialState },

			// loading flags
			creatingPrescriber: false,
			updatingPrescriber: false,
			checkingGMC: false,
			checkingCQC: false,
			loadingPrescribers: false,
			loadingEventHistory: false,

			loadingLinkedOrganisationTable: false,
			linkedOrganisation: { value: 0 }
		};

		this.validators = {
			newPrescriber: {
				firstName: [required],
				lastName: [required],
				email: [required, isEmail],
				password: [required, customRegex(PASSWORD_REGEX)],
				repeatPassword: [required],
				prescriberType: [required],
				prescriberIdCode: [required, maxLength(15)]
			},

			updatePrescriber: {
				firstName: [required],
				lastName: [required],
				email: [required, isEmail],
				prescriberType: [required],
				prescriberIdCode: [required],
			},
		};

		this.throttle = new Throttle();
	}

	addNewOrganisationId = () => {
		if (this.state.newPrescriber.organisationIds.value && this.state.newPrescriber.organisationIds.value.length < 5)
			this.setState({ newPrescriber: { ...this.state.newPrescriber, organisationIds: { value: [...this.state.newPrescriber.organisationIds.value, 0] } } })
	}

	resetNewPrescriberForm = () => {
		this.setState({ newPrescriber: { ...newPrescriberInitialState, organisationIds: { value: [0] } } });
	};

	resetupdatePrescriberForm = () => {
		this.setState({ updatePrescriber: updatePrescriberInitialState });
	};

	//#endregion

	//#region Util functions for inputs

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}
		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onSelectChange = (e, formKey) => {
		if (e.value[0] === "true" || e.value[0] === "false") {
			e.value[0] = JSON.parse(e.value[0]);
		}

		const newField = { value: e.value[0], error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onAssociatedOrganisationChange = (e, index) => {
		let organisationIds = this.state.newPrescriber.organisationIds.value;
		if (e.value.length > 0) {
			organisationIds[index] = e.value[0];

			this.setState({
				newPrescriber: {
					...this.state.newPrescriber,
					organisationIds: {
						value: organisationIds
					}
				}
			});
		}
	};

	onRemoveOrganisation = (index) => {
		let organisationIds = this.state.newPrescriber.organisationIds.value;

		this.setState({
			newPrescriber: {
				...this.state.newPrescriber,
				organisationIds: {
					value: organisationIds.filter((e, idIndex) => idIndex !== index)
				}
			}
		});
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	//#endregion

	//#region Table data

	sort = containerSort.bind(this);

	sortAssociatedPatients = (columnIndex, sortBySpace) => {
		const headers = this.state.tableHeaders;
		const body = this.state.updatePrescriber.patients.value;

		sortTableDataAndUpdateHeaders(headers, body, columnIndex, sortBySpace);

		const tableData = { ...this.state.tableData, body };

		this.setState({ ...this.state.tableHeaders, tableData });
	};


	filter = (queryString) => {
		this.throttle.setTimeout(() => this._filterlCallback(queryString), 300);
	};

	_filterlCallback = async (queryString) => {
		this.setState({ loadingPrescribers: true });
		try {
			const result = await prescriberService.filter(queryString);

			const newTableData = result.map((prescriber) => {
				return [
					{
						text: `${prescriber.firstName} ${prescriber.lastName}`,
						href: `/prescribers/edit/${prescriber.id}`,
						column: 2.5,
					},
					{
						text: PrescriberType[prescriber.type],
						column: 2
					},
					{
						text: prescriber.prescriberIdCode,
						column: 2.5,
					},
					{
						text: this.formatOrganisationNamesToBeMultiline(prescriber.organisationNames),
						column: 3,
					},
					{
						text: formatDateForPresenting(prescriber.createdAt, false),
						column: 2,
						right: true,
						type: "date",
					},
				];
			});

			this.setState({ tableData: newTableData });
		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ loadingPrescribers: false });
	};

	formatOrganisationNamesToBeMultiline = (organisationNamesString) => {

		const organisationNamesArray = organisationNamesString.split(",");
		return <>
			{organisationNamesArray.map(e => {
				return <> {e} <br /> </>
			})}
		</>
	}

	//#endregion

	getAllPrescribers = async () => {


		await this._filterlCallback("");


	}

	_getOrganisations = async () => {
		this.setState({ loadingOrganisations: true });

		const result = await OrganisationsService.filter("");

		const mappedOrganisations = result.map((clinic) => {
			return {
				label: `${clinic?.name}`,
				value: clinic?.id,
			};
		});

		const filteredOrganisations = mappedOrganisations
			.filter(e => this.state.newPrescriber.organisationIds.value.filter(idObject => idObject.value === e.value).length === 0);

		this.setState({ filteredOrganisations: filteredOrganisations, loadingOrganisations: false });
	};

	//#region CRUD

	create = async () => {
		this.setState({ creatingPrescriber: true });

		try {
			let organisationIds = [...new Set(this.state.newPrescriber.organisationIds.value)]
			await prescriberService.create({
				email: this.state.newPrescriber.email.value.toLowerCase(),

				firstName: this.state.newPrescriber.firstName.value,
				lastName: this.state.newPrescriber.lastName.value,
				prescriberIdCode: this.state.newPrescriber.prescriberIdCode.value,

				prescriberType: this.state.newPrescriber.prescriberType.value.value,
				password: this.state.newPrescriber.password.value,
				confirmPassword: this.state.newPrescriber.repeatPassword.value,
				organisationIds: organisationIds
					.filter(e => e.value > 0)
					.map(e => e.value)
			});

			this.resetNewPrescriberForm();

			notificationHelper.info("Prescriber created!");

			browserHelper.navigate("/prescribers");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ creatingPrescriber: false });
	};

	setForUpdate = async (id) => {
		this.setState({ loadingLinkedOrganisationTable: true })
		this.setState({ updatingPrescriber: true });
		try {
			const result = await prescriberService.getById(id);
			const updatePrescriber = {
				id: { value: result.id },
				organisationEmployeeId: { value: result.organisationEmployeeId },
				email: { value: result.email },

				firstName: { value: result.firstName },
				lastName: { value: result.lastName },
				prescriberIdCode: { value: result.prescriberIdCode },

				patients: { value: [] },
				organisations: { value: [] },

				prescriberType: { value: result.prescriberType },
				password: { value: "" },
				repeatPassword: { value: "" },
			};

			updatePrescriber.patients.value = this._getPatientTableData(result.patients);
			updatePrescriber.organisations.value = this._getAssociatedOrganisationsTableData(result);

			this.setState({ updatePrescriber });

			this.setEventHistory(result.organisationEmployeeId)
		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ loadingLinkedOrganisationTable: false })
		this.setState({ updatingPrescriber: false });
	};

	_getPatientTableData = (patients) => {
		return patients.map((patient) => [
			{
				text: patient.id,
				column: 1,
			},
			{
				text: `${patient.firstName} ${patient.lastName}`,
				href: `/patients/edit/${patient.id}`,
				column: 3,
			},
			{
				text: patient.organisationName,
				href: `/organisations/edit/${patient.organisationId}`,
				column: 3,
			},
			{
				text: "See prescription",
				href: `/prescriptions/edit/${patient.prescriptionId}`,
				column: 5,
				right: true,
			},
		]);
	};

	_getAssociatedOrganisationsTableData = (prescriber) => {
		return prescriber.organisations.map((organisation) => {
			const organisationUser = organisation.organisationUsers.find(e => e.organisationEmployeeId === prescriber.organisationEmployeeId)
			return [
				{
					text: organisation.name,
					href: `/organisations/edit/${organisation.id}`,
					column: 3,
				},
				{
					text: OrganisationTypeMapper[organisation.type],
					column: 3,
				},
				{
					text: OrganisationEmployeeType[organisationUser.employeeType],
					column: 3,
				},
				{
					text: organisationUser.hasAccountAccess ? "Yes" : "No",
					column: 1,
				},
				{
					actions: { id: organisation.id },
					column: 2,
					right: true
				}
			];
		});
	};

	update = async () => {
		this.setState({ updatingPrescriber: true });

		try {
			await prescriberService.update({
				id: this.state.updatePrescriber.id.value,
				email: this.state.updatePrescriber.email.value.toLocaleLowerCase(),
				firstName: this.state.updatePrescriber.firstName.value,
				lastName: this.state.updatePrescriber.lastName.value,
				prescriberIdCode: this.state.updatePrescriber.prescriberIdCode.value,
				password: this.state.updatePrescriber.password.value,
				confirmPassword: this.state.updatePrescriber.repeatPassword.value,
			});

			notificationHelper.info("Prescriber updated!");

			browserHelper.navigate("/prescribers");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPrescriber: false });
	};

	linkOrganisation = async () => {
		try {
			const payload = {
				prescriberId: this.state.updatePrescriber.id.value,
				organisationId: this.state.linkedOrganisation.value
			}

			await prescriberService.linkOrganisation(payload);

			notificationHelper.info("New organisation linked to Prescriber");

			browserHelper.navigate("/prescribers");
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	//#endregion

	setEventHistory = async (id, setLoading = true) => {
		if (setLoading) {
			this.setState({ loadingEventHistory: true });
		}

		try {
			let eventHistory = await this._getEventHistory(id);
			eventHistory = await this._processEventHistoryFromAPI(eventHistory);

			this.setState({
				updatePrescriber: {
					...this.state.updatePrescriber,
					eventHistory: eventHistory,
				}
			});
		} catch (error) {
			errorHelper.handleError(error);
		}

		if (setLoading) {
			this.setState({ loadingEventHistory: false });
		}
	}

	_getEventHistory = async (orgEmployeeId) => {
		const eventHistory = await prescriberService.getEventHistoryForOrganisationEmployee(orgEmployeeId);

		return eventHistory.reverse();
	};

	_processEventHistoryFromAPI = async (eventHistory) => {
		return eventHistory.map((event) => {
			return [
				{
					text: formatText(event.eventContent),
					column: 6,
				},
				{
					text: formatDateForPresenting(event.createdAt, true, ""),
					column: 6,
					right: true,
				},
			];
		});
	};

	setLinkedOrganisation(event) {
		this.setState({ linkedOrganisation: { value: event.value[0].value } })
	}

	resetLinkedOrganisation() {
		this.setState({ linkedOrganisation: { value: 0 } })
	}

}

function formatText(val) {
	if (val.startsWith("{{")) {
		//remove the {{ }} brackets
		let substring = val.replace("{{", "").replace("}}", "")
		let pivot = substring.indexOf(":") + 1
		let adminName = substring.slice(0, pivot)
		substring = substring.slice(pivot)

		return <div>
			<b>{adminName}</b>
			{substring}
		</div>
	}
	return val;
}
export default PrescriberContainer;
