import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Subscribe } from "unstated";

import Header from "../Header/Header";
import Aside from "../Aside/Aside";

import AuthenticationContainer from "../../state/AuthenticationContainer";
import { AdminType, AdminTypeDefaultLinks } from "../../enums";

function AuthenticatedRoute({
	component: Component,
	adminTypes = [AdminType.Super, AdminType.Regular, AdminType.Eastone],
	currentToken,
	currentAdministrator,
	...rest
}) {
	function authenticatedLayout(props) {
		return (
			<div>
				<Header />
				<Aside />

				<main>
					<Component {...props} />
				</main>
			</div>
		);
	}

	function redirectToLogin() {
		return <Redirect to={{ pathname: "/login" }} />;
	}

	function isAuthenticated() {
		const isAuth = isAuthorized();

		// explicit check for true because isAuthorized returns an object
		return currentToken && isAuth === true;
	}

	function isAuthorized() {
		if (currentAdministrator && !adminTypes.includes(currentAdministrator.type))
			return <Redirect to={{ pathname: AdminTypeDefaultLinks[currentAdministrator.type] }} />;

		return true;
	}

	return <Route {...rest} render={(props) => (isAuthenticated() ? authenticatedLayout(props) : redirectToLogin())} />;
}

function SubscribedAuthenticatedRoute(props) {
	return (
		<Subscribe to={[AuthenticationContainer]}>
			{(container) => (
				<AuthenticatedRoute
					{...props}
					currentToken={container.state.currentToken}
					currentAdministrator={container.state.currentAdministrator}
				/>
			)}
		</Subscribe>
	);
}

export default SubscribedAuthenticatedRoute;
