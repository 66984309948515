import React from 'react'
import GenericToolTip from './GenericToolTip'

const CustomIconWithTooltip = ({ icon, toolTipText = "Tooltip text", className = "generic_tool_tip--right", style }) => {

    return (
        <div className={`custom_icon_with_tooltip `} style={style}>
            <div className='custom_icon_with_tooltip__icon'>
                <GenericToolTip className={className} text={toolTipText} />
                {icon}
            </div>
        </div>

    )
}

export default CustomIconWithTooltip
