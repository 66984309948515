import notificationHelper from "./notificationHelper";

class ErrorHelper {
	defaultErrorMessage = "An error ocurred during your request!";

	handleError(error, errorMessage = this.defaultErrorMessage) {
		if (error.response) {
			this._handleServerError(error.response);

			return;
		}

		console.log(error);
		notificationHelper.error(errorMessage);
	}

	_handleServerError(response) {
		if (!response.data) {
			notificationHelper.error(this.defaultErrorMessage);

			return;
		}

		if (response.status >= 400 && response.status < 500) {
			notificationHelper.error(response.data.Message);

			return;
		}
		notificationHelper.error(this.defaultErrorMessage);
	}
}

export default new ErrorHelper();
