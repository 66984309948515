import React, { useEffect } from 'react'
import BulkUploadprescriptionContainer from '../../state/BulkUploadPrescriptionContainer';
import { useDropzone } from 'react-dropzone';
import { Subscribe } from 'unstated';
import { SuccessIcon, UploadIcon } from '../../assets/icons';

const BukImageUploadDropZone = ({ container }) => {
    const numberOfSelectedFiles = container.state.bulkUpload.prescriptions.value.length;
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf'
    });

    const onClear = (e) => {
        e.stopPropagation();
        container.setPrescriptions([]);
    }

    useEffect(() => {
        if (acceptedFiles.length) {
            let prescriptionImages = acceptedFiles.map(file => {
                return (
                    {
                        name: file.name,
                        url: URL.createObjectURL(file),
                    }
                )
            })

            let all = [...container.state.bulkUpload.prescriptions.value, ...prescriptionImages];
            const key = 'name';

            const filesUniqueByName = [...new Map(all.map(item =>
                [item[key], item])).values()];

            container.setPrescriptions([...filesUniqueByName]);
        }
        // eslint-disable-next-line
    }, [acceptedFiles])


    const resolveClass = () => {
        let className = 'drop_zone__zone '
        if (numberOfSelectedFiles) {
            className += 'drop_zone__zone--files_selected '
        }

        return className;
    }
    return (
        <div className='drop_zone'>
            <div {...getRootProps({ className: resolveClass() })}>
                <input {...getInputProps()} />
                {numberOfSelectedFiles === 0 &&
                    <>
                        <UploadIcon
                            color="#555555"
                        />
                        <div className='drop_zone__zone__text' >
                            Drag and drop prescription images <br /> into this field here  or <div className='drop_zone__zone__text--link'>select files</div>
                        </div>
                    </>
                }
                {numberOfSelectedFiles > 0 &&
                    <div className='drop_zone__zone__text' >
                        <div>
                            <SuccessIcon />
                        </div>
                        {numberOfSelectedFiles} prescription image{numberOfSelectedFiles > 1 ? 's' : ''} <br /> ready to be uploaded
                        <div className='drop_zone__zone__text__clear' onClick={(e) => { onClear(e) }} >Clear</div>
                    </div>
                }

            </div>
        </div>
    )
}

function SubscribedBukImageUploadDropZone(props) {
    return <Subscribe to={[BulkUploadprescriptionContainer]}>{(container) => <BukImageUploadDropZone {...props} container={container} />}</Subscribe>;
}

export default SubscribedBukImageUploadDropZone
