import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";

import administratorService from "../services/AdminsService";

import browserHelper from "../util/helpers/browserHelper";
import errorHelper from "../util/helpers/errorHelper";
import { required, isEmail, maxLength, isFormValid } from "../util/validation/validation";
import { Throttle } from "../util/Throttle";
import { formatDateForPresenting } from "../util/dates";
import { containerSort } from "../util/helpers/sorting";
import tableHeaders from "../util/constants/tableHeaders";
import React from "react";

const newAdministratorInitialState = {
	name: { value: "" },
	email: { value: "" },
	password: { value: "" },
	repeatPassword: { value: "" },
	canAccessUserData: { value: false },
	canAccessAdministratorData: { value: false },
};

const updateAdministratorInitialState = {
	id: { value: "" },
	name: { value: "" },
	email: { value: "" },
	password: { value: "" },
	repeatPassword: { value: "" },
	canAccessUserData: { value: false },
	canAccessAdministratorData: { value: false },
	createdAt: { value: "" },
	updatedAt: { value: "" },
	lastLoggedIn: { value: "" },

	eventHistory: { value: [] }
};

class AdministratorContainer extends Container {
	//#region State
	constructor() {
		super();

		// Initial state
		this.state = {
			tableHeaders: tableHeaders.administrators,

			tableData: [],
			newAdministrator: { ...newAdministratorInitialState },
			updateAdministrator: { ...updateAdministratorInitialState },
			creatingAdmin: false,
			updatingAdmin: false,
			loadingAdministrators: false,
			loadingEventHistory: false,
		};

		// Validators
		this.validators = {
			newAdministrator: {
				name: [required, maxLength(24)],
				email: [required, isEmail],
				password: [required],
				repeatPassword: [required],
			},
			updateAdministrator: {
				name: [required, maxLength(24)],
				email: [required, isEmail],
			},
		};

		this.throttle = new Throttle();
	}

	resetNewAdministratorState = () => {
		this.setState({ newAdministrator: newAdministratorInitialState });
	};

	resetUpdateAdministratorState = () => {
		this.setState({ updateAdministrator: updateAdministratorInitialState });
	};

	//#endregion

	//#region Util function for inputs

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}

		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({
			[formKey]: Object.assign(this.state[formKey], {
				[e.name]: newField,
			}),
		});
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	//#endregion

	//#region Table data

	sort = containerSort.bind(this);

	filter = async (queryString) => {
		this.throttle.setTimeout(() => this._filterCallback(queryString));
	};

	_filterCallback = async (queryString) => {
		this.setState({ loadingAdministrators: true });

		try {
			const result = await administratorService.filter(queryString);

			const newTableData = result.map((administrator) => {
				return [
					{
						text: administrator.id,
						column: 1,
					},
					{
						text: administrator.name,
						href: `/administrators/edit/${administrator.id}`,
						column: 3,
					},
					{
						text: administrator.email,
						column: 3,
					},
					{
						text: formatDateForPresenting(administrator.createdAt, false),
						column: 5,
						right: true,
						type: "date",
					},
				];
			});

			this.setState({ tableData: newTableData });
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ loadingAdministrators: false });
	};

	//#endregion

	getAllAdministrators = async () => {
		await this._filterCallback("");
	}

	//#region CRUD

	create = async () => {
		if (this.state.newAdministrator.password.value !== this.state.newAdministrator.repeatPassword.value) {
			notificationHelper.error(`Passwords don't match!`);
			return;
		}
		this.setState({ creatingAdmin: true });

		try {
			await administratorService.create({
				name: this.state.newAdministrator.name.value,
				email: this.state.newAdministrator.email.value.toLowerCase(),
				password: this.state.newAdministrator.password.value,
				permissions: {
					canAccessUsers: this.state.newAdministrator.canAccessUserData.value,
					canAccessAdministrators: this.state.newAdministrator.canAccessAdministratorData.value,
				},
			});

			notificationHelper.info("Administrator created!");

			browserHelper.navigate("/administrators");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ creatingAdmin: false });
	};

	setForUpdate = async (id) => {
		this.setState({ updatingAdmin: true });

		try {
			const result = await administratorService.getById(id);

			this.setState({
				updateAdministrator: {
					id: { value: result.id },
					name: { value: result.name },
					email: { value: result.email },
					password: { value: "" },
					repeatPassword: { value: "" },
					canAccessUserData: {
						value: result.permissions.canAccessUsers,
					},
					canAccessAdministratorData: {
						value: result.permissions.canAccessAdministrators,
					},
					createdAt: { value: result.createdAt },
					updatedAt: { value: result.updatedAt },
					lastLoggedIn: { value: result.lastLoggedIn },
				},
			});

			this.setEventHistory(result.id);
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingAdmin: false });
	};

	update = async () => {
		if (this.state.updateAdministrator.password.value !== this.state.updateAdministrator.repeatPassword.value) {
			notificationHelper.error(`Passwords don't match!`);
			return;
		}

		this.setState({ updatingAdmin: true });

		try {
			const adminUpdate = {
				id: this.state.updateAdministrator.id.value,
				name: this.state.updateAdministrator.name.value,
				email: this.state.updateAdministrator.email.value.toLowerCase(),
				password: this.state.updateAdministrator.password.value,
				permissions: {
					canAccessUsers: this.state.updateAdministrator.canAccessUserData.value,
					canAccessAdministrators: this.state.updateAdministrator.canAccessAdministratorData.value,
				},
			};

			await administratorService.update(adminUpdate);

			notificationHelper.info("Administrator updated!");

			browserHelper.navigate("/administrators");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingAdmin: false });
	};

	delete = async (id) => {
		try {
			await administratorService.delete(id);

			notificationHelper.info("Administrator deleted!");

			browserHelper.navigate("/administrators");
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	//#endregion

	setEventHistory = async (id, setLoading = true) => {
		if (setLoading) {
			this.setState({ loadingEventHistory: true });
		}

		try {
			let eventHistory = await this._getEventHistory(id);
			eventHistory = await this._processEventHistoryFromAPI(eventHistory);

			this.setState({
				updateAdministrator: {
					...this.state.updateAdministrator,
					eventHistory: eventHistory,
				}
			});
		} catch (error) {
			errorHelper.handleError(error);
		}

		if (setLoading) {
			this.setState({ loadingEventHistory: false });
		}
	}

	_getEventHistory = async (adminId) => {
		const eventHistory = await administratorService.getEventHistoryForAdmin(adminId);

		return eventHistory.reverse();
	};

	_processEventHistoryFromAPI = async (eventHistory) => {
		const result = eventHistory.map((event) => {
			return [
				{
					text: formatText(event.eventContent),
					column: 6,
				},
				{
					text: formatDateForPresenting(event.createdAt, true, ""),
					column: 6,
					right: true,
				},
			];
		});

		return result;
	};


}

function formatText(val) {
	if (val.startsWith("{{")) {
		//remove the {{ }} brackets
		let substring = val.replace("{{", "").replace("}}", "")
		let pivot = substring.indexOf(":") + 1
		let adminName = substring.slice(0, pivot)
		substring = substring.slice(pivot)

		return <div>
			<b>{adminName}</b>
			{substring}
		</div>
	}
	return val;
}

export default AdministratorContainer;
