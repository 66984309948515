import moment from "moment";

export function formatDate(dateString, time = true, format = "YYYY-MM-DD HH:mm", outputFormat = "YYYY-MM-DD") {
	if (!dateString) return "";

	const date = moment(dateString, format);

	if (!time) {
		return date.format(outputFormat);
	}

	return date.format(`${outputFormat} HH:mm`);
}

export function formatDateForApi(date, format = "YYYY-MM-DD") {
	return moment(date, format).format("MM-DD-YYYY");
}

export function formatDateForPresenting(date, time = true, format = "YYYY-MM-DD HH:mm") {
	if (!date) return "";

	if (time) return moment(date, format).format("DD/MM/YY HH:mm");

	return moment(date, format).format("DD/MM/YY");
}
