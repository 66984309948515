import React, { useState } from "react";
import Dropdown from 'react-dropdown-overflow'
import CustomSelect from "./CustomSelect";

function OverflowSelect(
	{
		value,
		options,
		onChange,
		prescription,

	}) {

	// eslint-disable-next-line
	const [triggerRerender, setTriggerRerender] = useState("")

	const handleOnChange = (e) => {
		onChange(e)
		setTriggerRerender(e.value[0].label)
		Object.assign(value, { label: e.value[0].label })
	}


	return (
		<Dropdown positioning={'left'} className={"overflow_select"} mode={'click'} dropdown={
			<>
				<CustomSelect
					key={prescription.id}
					values={[value]}
					options={options}
					name=""
					onChange={(e) => handleOnChange(e)}
					dropdownPosition={"auto"}
				/>
			</>
		}>
			<div
				className={`overflow_select__button ${value.label.toLowerCase() === "none" ? "overflow_select__button--none" : ""} `}
			>
				{value.label}
			</div>
		</Dropdown>
	);
}

export default OverflowSelect;