import React from 'react';

function NumberInput({
    value = '',
    name = '',
    step = 1,
    min = null,
    max = null,
    label = '',
    disabled = false,
    placeholder = 'Text input',
    error = false,
    onKeyUp = () => null,
    onSubmit = () => null,
    onChange = () => null
}) {
    function onKeyUpHandler(e) {
        onKeyUp(e);

        if (e.key === 'Enter')
            onSubmit();
    }

    return (
        <div className="input-container">
            <input
                className={error ? "error" : ""}
                value={value}
                type="number"
                name={name}
                step={step}
                min={min}
                max={max}
                placeholder={placeholder}
                disabled={disabled}
                defaultValue={value}
                onKeyUp={onKeyUpHandler}
                onChange={e => onChange({ name, value: +e.target.value })} />
            <label className="component-label" >{error ? error : label}</label>
            <div className="animated-border" ></div>
        </div>
    );
}

export default NumberInput;