import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./ImageDragAndDrop.scss";
import removeImage from "../../assets/icons/removeImage.svg";
import upload_icon from "../../assets/icons/upload_icon.svg";
import Dropdown from "../Form/Dropdown";
import contentHelper from "../../util/helpers/contentHelper";


function ImageDragAndDrop({ productId, submitPhoto, onItemDelete, images, setImages, disabled = false }) {

  const onDrop = useCallback((acceptedFiles) => {
    setImages((prevImages) =>
      prevImages.concat(
        acceptedFiles.map((file) => ({
          file,
          mediaUrl: URL.createObjectURL(file),
          isNew: true,
        }))
      ));
    (productId && submitPhoto) && submitPhoto(productId, { name: acceptedFiles[0].name, mediaUrl: URL.createObjectURL(acceptedFiles[0]) })
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ["image/jpeg", "image/png"] });

  return (
    <div className={(images.length === 0 & disabled) ? "container_hidden" : images.length === 0 ? "container_initial" : "container"}>
      <div className="images">
        {images.map(({ file, id, mediaUrl }, index) => (
          <div key={index} className="image-container">

            <a href={mediaUrl} target="_blank">
              <img src={mediaUrl} alt={id} />
            </a>
            {!disabled && <div className="delete-button" onClick={() => onItemDelete(index, true)}>
              <img src={removeImage} alt="remove" />
            </div>}
            <div className="mediaAccessLevel">
              <Dropdown
                value={images[index].mediaAccessLevel}
                onChange={(e) => setImages(images.map((image, i) => i === index ? { ...image, mediaAccessLevel: e.value, accessLevelChanged: true } : image))}
                name="mediaAccessLevel"
                label="Photo visability"
                options={contentHelper.getMediaAccessLevelsAsDropdowns()}
                centerLabel={true}
              />
            </div>
          </div>
        ))}
      </div>
      {(images.length < 3 && !disabled) && <div className={images.length === 0 ? "dropzone" : "dropzone_after"} {...getRootProps()}>
        <input {...getInputProps({ multiple: false })} disabled={disabled} />
        <img src={upload_icon} alt="" />
        <p>Drop here or select</p>
      </div>}
    </div>
  );
}

export default ImageDragAndDrop;