import React from 'react'

const ExclamationMarkTriangle = ({ size = 30 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 24.7978C30 25.4987 29.7529 26.0332 29.2588 26.4016C28.7646 26.77 28.1581 26.9542 27.4394 26.9542H2.56065C1.84187 26.9542 1.2354 26.7655 0.74124 26.3881C0.24708 26.0108 0 25.4807 0 24.7978C0 24.2588 0.161725 23.7107 0.485175 23.1536L12.9111 1.48248C13.504 0.49416 14.2049 0 15.0135 0C15.8221 0 16.5049 0.49416 17.062 1.48248L29.5148 23.1806C29.8383 23.7556 30 24.2947 30 24.7978ZM16.8733 11.0243V7.08895H13.1267V11.0243C13.1267 11.2758 13.1447 11.5139 13.1806 11.7385C13.2165 11.9632 13.2659 12.2147 13.3288 12.4933C13.3917 12.7718 13.4411 13.0099 13.4771 13.2075L14.1779 17.5741H15.7682L16.496 13.2075C16.5319 13.0279 16.5858 12.7942 16.6577 12.5067C16.7296 12.2192 16.7835 11.9632 16.8194 11.7385C16.8553 11.5139 16.8733 11.2758 16.8733 11.0243ZM16.8733 21.4555C16.8733 20.9344 16.6891 20.4942 16.3208 20.1348C15.9524 19.7754 15.5076 19.5957 14.9865 19.5957C14.4834 19.5957 14.0476 19.7754 13.6792 20.1348C13.3109 20.4942 13.1267 20.9344 13.1267 21.4555C13.1267 21.9766 13.3109 22.4214 13.6792 22.7898C14.0476 23.1581 14.4834 23.3423 14.9865 23.3423C15.5076 23.3423 15.9524 23.1581 16.3208 22.7898C16.6891 22.4214 16.8733 21.9766 16.8733 21.4555Z" fill="#F59404" />
        </svg>
    )
}

export default ExclamationMarkTriangle
