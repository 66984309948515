import React from 'react'

const UnarchiveBox = ({ color = "black" }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3333 21.3334H4.66659V14.6667H3.33325V21.3334C3.33325 21.687 3.47373 22.0261 3.72378 22.2762C3.97382 22.5262 4.31296 22.6667 4.66659 22.6667H19.3333C19.6869 22.6667 20.026 22.5262 20.2761 22.2762C20.5261 22.0261 20.6666 21.687 20.6666 21.3334V14.6667H19.3333V21.3334Z" fill="black" />
            <path d="M9.33325 16C9.33325 16.1768 9.40349 16.3464 9.52851 16.4714C9.65354 16.5964 9.82311 16.6666 9.99992 16.6666H13.9999C14.1767 16.6666 14.3463 16.5964 14.4713 16.4714C14.5963 16.3464 14.6666 16.1768 14.6666 16C14.6666 15.8232 14.5963 15.6536 14.4713 15.5286C14.3463 15.4036 14.1767 15.3333 13.9999 15.3333H9.99992C9.82311 15.3333 9.65354 15.4036 9.52851 15.5286C9.40349 15.6536 9.33325 15.8232 9.33325 16Z" fill="black" />
            <path d="M10.0001 12H4.00008V9.33333H10.0001V8H3.66675C3.40153 8 3.14718 8.10536 2.95964 8.29289C2.7721 8.48043 2.66675 8.73478 2.66675 9V13.3333H10.5201C10.1882 12.9682 10.003 12.4934 10.0001 12Z" fill="black" />
            <path d="M20.3333 8H14V9.33333H20V12H14C13.997 12.4934 13.8118 12.9682 13.48 13.3333H21.3333V9C21.3333 8.73478 21.228 8.48043 21.0404 8.29289C20.8529 8.10536 20.5985 8 20.3333 8Z" fill="black" />
            <path d="M8.66676 6.36665L11.3334 3.73331V12C11.3334 12.1768 11.4037 12.3464 11.5287 12.4714C11.6537 12.5964 11.8233 12.6666 12.0001 12.6666C12.1769 12.6666 12.3465 12.5964 12.4715 12.4714C12.5965 12.3464 12.6668 12.1768 12.6668 12V3.73331L15.3334 6.36665C15.3956 6.42837 15.4693 6.47724 15.5503 6.51048C15.6314 6.54371 15.7182 6.56066 15.8058 6.56035C15.8934 6.56004 15.9801 6.54248 16.0609 6.50867C16.1417 6.47486 16.215 6.42547 16.2768 6.36331C16.3385 6.30115 16.3874 6.22745 16.4206 6.1464C16.4538 6.06535 16.4708 5.97855 16.4705 5.89096C16.4702 5.80336 16.4526 5.71668 16.4188 5.63587C16.385 5.55506 16.3356 5.4817 16.2734 5.41998L12.0001 1.18665L7.7401 5.41998C7.63686 5.54703 7.58397 5.70763 7.59153 5.87117C7.59909 6.03471 7.66656 6.18975 7.78108 6.30674C7.8956 6.42373 8.04916 6.4945 8.2125 6.50554C8.37584 6.51659 8.53753 6.46715 8.66676 6.36665Z" fill="black" />
        </svg>

    )
}

export default UnarchiveBox
