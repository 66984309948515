import React, { useEffect } from "react";
import FormRow from "../../components/Form/FormRow";
import TextInput from "../../components/Form/TextInput";
import Button from "../../components/Form/Button";
import { SpinnerIcon } from "../../assets/icons";
import { Subscribe } from "unstated";
import OrganisationsContainer from "../../state/OrganisationsContainer";
import Banner from "../../components/Banner/Banner";
import { BannerTypes } from "../../enums";
import CustomSelect from "../../components/Form/CustomSelect";

const NewOrganisation = ({ container }) => {
	const createOrganisationFormKey = "newOrganisation";
	const createOrganisationState = container.state[createOrganisationFormKey];
	const onFieldChange = (event) => container.onFormChange(event, createOrganisationFormKey);
	const onSelectChange = (e) => container.onSelectChange(e, createOrganisationFormKey);

	useEffect(() => {
		return () => {
			container.resetCreateOrganisationState()
		}
		// eslint-disable-next-line
	}, [])



	const disableCreateButtonConditions = () => {

		const isButtonDisabled =
			!container.isFormValid(createOrganisationState.organisationType.value.value === 0 ? createOrganisationFormKey : "newOrganisationIP", createOrganisationFormKey)
			|| createOrganisationState.employeePassword.value !== createOrganisationState.employeeRepeatPassword.value
			|| createOrganisationState.isButtonDisabled.value

		//if its Clinic 
		if (createOrganisationState.organisationType.value.value === 0) {
			return isButtonDisabled
		}
		//if its IP
		if (createOrganisationState.organisationType.value.value === 1) {
			return isButtonDisabled || container.state.searchButtonClicked
		}
		return true
	}

	return (
		<div className="card p-m">
			<FormRow>
				<h1 className="h4">New Organisation</h1>
			</FormRow>

			<FormRow>
				<div className="col-4">
					<CustomSelect
						label="Type"
						name="organisationType"
						values={createOrganisationState.organisationType.value ? [createOrganisationState.organisationType.value] : []}
						options={container.state.organisationTypes}
						// onChange={(e) => { onSelectChange(e); container.resetCreateOrganisationState() }}
						// NOTE: read TextInput note 
						onChange={(e) => { onSelectChange(e) }}
						placeholder={"Select type"}
					/>
				</div>
				<div className="col-7" />
			</FormRow>

			<FormRow>
				<h2 className="h6">Organisation details</h2>
			</FormRow>

			<FormRow>
				<div className='col-4' >
					<Banner type={BannerTypes.Info} text="All fields are required" />
				</div>
			</FormRow>

			{createOrganisationState.organisationType.value.value === 0 &&
				<>
					<FormRow className="organisation_details">
						<p className="text_input-label">CQC Provider ID</p>
						<p>You can find the CQC Provider ID for a organisation using the <a href="https://www.cqc.org.uk/about-us/transparency/using-cqc-data" target="_blank">CQC care directory CSV</a></p>
					</FormRow>

					<FormRow>
						<div className="col-5">
							<TextInput
								value={createOrganisationState.referenceNumber.value}
								error={createOrganisationState.referenceNumber.error}
								onChange={onFieldChange}
								name="referenceNumber"
								label=""
								disabled={false}
								placeholder="CQC Provider ID"
							/>
						</div>
					</FormRow>
				</>
			}

			{createOrganisationState.organisationType.value.value === 1 && <>
				<FormRow>
					<div className="col-4">
						<CustomSelect
							label="Prescriber type"
							name="prescriberType"
							values={createOrganisationState.prescriberType.value ? [createOrganisationState.prescriberType.value] : []}
							options={container.state.prescriberTypes}
							onChange={(e) => { onSelectChange(e) }}
							placeholder={"Select type"}
						/>
					</div>
					<div className="col-7" />
				</FormRow>

				{createOrganisationState.prescriberType.value.value === 0 &&
					<>
						<FormRow className="organisation_details" style={{ marginBottom: "0" }}>
							<p className="text_input-label">GMC number</p>
							<p>You can find a Doctor's <a href="https://www.gmc-uk.org/registration-and-licensing/the-medical-register" target="_blank">GMC number here</a></p>
						</FormRow>

						<FormRow>
							<div className="col-4">
								<TextInput
									value={createOrganisationState.prescriberIdCode.value}
									error={createOrganisationState.prescriberIdCode.error}
									onChange={onFieldChange}
									name="prescriberIdCode"
									label=""
									disabled={false}
									placeholder="GMC"
								/>
								<Button
									style={{ marginLeft: "20px" }}
									onClick={() => container.serchForPrescribersByGMC()}
									disabled={false}>
									{container.state.searchingForGmcNumber ? <SpinnerIcon /> : "Search"}
								</Button>
							</div>
						</FormRow>

						<Banner className="new_organisation-banner" type={createOrganisationState.bannerType.value} text={createOrganisationState.bannerText.value} />
					</>
				}

				{createOrganisationState.prescriberType.value.value === 1 && <>
					<FormRow className="organisation_details" style={{ marginBottom: "0" }}>
						<p className="text_input-label">GPHC number</p>
						<p>You can find a Pharmacist's <a href="https://www.pharmacyregulation.org/registers/pharmacist" target="_blank">GPHC number here</a></p>
					</FormRow>


					<FormRow>
						<div className="col-4">
							<TextInput
								value={createOrganisationState.prescriberIdCode.value}
								error={createOrganisationState.prescriberIdCode.error}
								onChange={onFieldChange}
								name="prescriberIdCode"
								label=""
								disabled={false}
								placeholder="GPHC"
							/>
							<Button
								style={{ marginLeft: "20px" }}
								onClick={() => container.serchForPrescribersByGMC()}
								disabled={false}>
								{container.state.searchingForGmcNumber ? <SpinnerIcon /> : "Search"}
							</Button>
						</div>
					</FormRow>

					<Banner className="new_organisation-banner" type={createOrganisationState.bannerType.value} text={createOrganisationState.bannerText.value} />
				</>
				}

				{createOrganisationState.prescriberType.value.value === 2 && <>
					<FormRow className="organisation_details" style={{ marginBottom: "0" }}>
						<p className="text_input-label">NMC PIN NUMBER</p>
						<p>Before adding, please check their <a href="https://www.nmc.org.uk/registration/search-the-register/" target="_blank">registration status here</a></p>
					</FormRow>


					<FormRow>
						<div className="col-4">
							<TextInput
								value={createOrganisationState.prescriberIdCode.value}
								error={createOrganisationState.prescriberIdCode.error}
								onChange={onFieldChange}
								name="prescriberIdCode"
								label=""
								disabled={false}
								placeholder="NMC PIN NUMBER"
							/>
							<Button
								style={{ marginLeft: "20px" }}
								onClick={() => container.serchForPrescribersByGMC()}
								disabled={false}>
								{container.state.searchingForGmcNumber ? <SpinnerIcon /> : "Search"}
							</Button>
						</div>
					</FormRow>

					<Banner className="new_organisation-banner" type={createOrganisationState.bannerType.value} text={createOrganisationState.bannerText.value} />
				</>
				}
			</>
			}

			{createOrganisationState.organisationType.value.value === 0 && <FormRow>
				<div className="col-5">
					<TextInput
						value={createOrganisationState.name.value}
						error={createOrganisationState.name.error}
						onChange={onFieldChange}
						name="name"
						label="Clinic Name"
						disabled={false}
						placeholder="Name"
					/>
				</div>
			</FormRow>}

			{createOrganisationState.organisationType.value.value === 1 && <FormRow>
				<div className="col-3">
					<TextInput
						value={createOrganisationState.employeeFirstName.value}
						error={createOrganisationState.employeeFirstName.error}
						onChange={onFieldChange}
						name="employeeFirstName"
						label="First name"
						disabled={createOrganisationState.isFieldDisabled.value}
						placeholder="First name"
					/>
				</div>
				<div className="col-3">
					<TextInput
						value={createOrganisationState.employeeLastName.value}
						error={createOrganisationState.employeeLastName.error}
						onChange={onFieldChange}
						name="employeeLastName"
						label="Last name"
						disabled={createOrganisationState.isFieldDisabled.value}
						placeholder="Last name"
					/>
				</div>
				<div className="col-6" />
			</FormRow>}

			<FormRow>
				<div className="col-6">
					<TextInput
						value={createOrganisationState.email.value}
						error={createOrganisationState.email.error}
						onChange={onFieldChange}
						name="email"
						label="email"
						disabled={createOrganisationState.isFieldDisabled.value}
						placeholder="Organisation email"
					/>
				</div>
				<div className="col-6">
					<TextInput
						value={createOrganisationState.phone.value}
						error={createOrganisationState.phone.error}
						onChange={onFieldChange}
						name="phone"
						label="telephone"
						disabled={false}
						placeholder="Phone number"
					/>
				</div>
			</FormRow>
			<FormRow>
				<div className="col-4">
					<TextInput
						value={createOrganisationState.address1.value}
						error={createOrganisationState.address1.error}
						onChange={onFieldChange}
						name="address1"
						label="address 1"
						disabled={false}
						placeholder="Address 1"
					/>
				</div>
				<div className="col-4">
					<TextInput
						value={createOrganisationState.address2.value}
						error={createOrganisationState.address2.error}
						onChange={onFieldChange}
						name="address2"
						label="address 2"
						disabled={false}
						placeholder="Address 2"
					/>
				</div>
				<div className="col-4">
					<TextInput
						value={createOrganisationState.postCode.value}
						error={createOrganisationState.postCode.error}
						onChange={onFieldChange}
						name="postCode"
						label="post code"
						disabled={false}
						placeholder="Post code"
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Communications</h2>
			</FormRow>

			<FormRow>
				<div className="col-4">
					<TextInput
						value={createOrganisationState.emailRewrite.value}
						error={createOrganisationState.emailRewrite.error}
						onChange={onFieldChange}
						name="emailRewrite"
						label="re-writes email"
						disabled={false}
						placeholder="Email for re-writes"
					/>
				</div>
			</FormRow>

			{!createOrganisationState.isFieldDisabled.value && <>
				<FormRow>
					{/* Says 'user' but actually means org employee */}
					<h2 className="h6">Organisation user account details</h2>
				</FormRow>

				<FormRow>
					<div className="col-8">
						<Banner type={BannerTypes.Info} text="You will be able to add more users once you create the Organisation" />
					</div>
				</FormRow>

				{createOrganisationState.organisationType.value.value === 0 && <FormRow>
					<div className="col-3">
						<TextInput
							value={createOrganisationState.employeeFirstName.value}
							error={createOrganisationState.employeeFirstName.error}
							onChange={onFieldChange}
							name="employeeFirstName"
							label="First name"
							disabled={false}
							placeholder="First name"
						/>
					</div>
					<div className="col-3">
						<TextInput
							value={createOrganisationState.employeeLastName.value}
							error={createOrganisationState.employeeLastName.error}
							onChange={onFieldChange}
							name="employeeLastName"
							label="Last name"
							disabled={false}
							placeholder="Last name"
						/>
					</div>
					<div className="col-6">
						<TextInput
							value={createOrganisationState.employeeEmail.value}
							error={createOrganisationState.employeeEmail.error}
							onChange={onFieldChange}
							name="employeeEmail"
							label="Email"
							disabled={false}
							placeholder="Email"
						/>
					</div>
				</FormRow>}

				<FormRow>
					<div className="col-6">
						<TextInput
							value={createOrganisationState.employeePassword.value}
							error={createOrganisationState.employeePassword.error}
							onChange={onFieldChange}
							name="employeePassword"
							label="Password"
							disabled={false}
							placeholder="Password"
							type={"password"}
						/>
					</div>
					<div className="col-6">
						<TextInput
							value={createOrganisationState.employeeRepeatPassword.value}
							error={createOrganisationState.employeeRepeatPassword.error}
							onChange={onFieldChange}
							name="employeeRepeatPassword"
							label="Repeat password"
							disabled={false}
							placeholder="Repeat password"
							type={"password"}
						/>
					</div>
				</FormRow>
			</>}

			<FormRow>
				<Button
					onClick={() => container.createOrganisation()}
					disabled={disableCreateButtonConditions()}
				>
					{container.state.creatingOrganisation ? <SpinnerIcon /> : "Create new organisation"}
				</Button>
			</FormRow>
		</div>
	);
}

function SubscribedNewOrganisation(props) {
	return <Subscribe to={[OrganisationsContainer]}>
		{(container) => <NewOrganisation {...props} container={container} />}
	</Subscribe>;
}

export default SubscribedNewOrganisation;

