import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscribe } from "unstated";

import Table from "../../components/Table/Table";
import TextInput from "../../components/Form/TextInput";

import PatientContainer from "../../state/PatientContainer";
import Button from "../../components/Form/Button";
import { PatientsTables } from "../../enums";
import { PlusInACircle } from "../../assets/icons";
import Drawer from "../../components/Drawer/Drawer";
import FormRow from "../../components/Form/FormRow";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";

function Patients({ container }) {
	const history = useHistory();
	const loadingAllPatients = container.state.loadingAllPatients;
	const [current, setCurrent] = useState(drawerEnum.AllPatients);
	// Hook-related data
	useEffect(() => {
		setSearchValues({})
		container.getAllPatients("");
	}, [container, current]);

	const buttons = [
		{ text: `All Patients ${container.state.tableData[PatientsTables.AllPatients].length}`, id: drawerEnum.AllPatients },
		{ text: `Awaiting signup ${container.state.tableData[PatientsTables.AwaitingSignUp].length}`, id: drawerEnum.AwaitingSignup },
	]

	const [searchValues, setSearchValues] = useState({
		[PatientsTables.AllPatients]: "",
		[PatientsTables.AwaitingSignUp]: "",
	})

	// Functions
	function renderFilters(container, tableEnum) {
		return (
			<FormRow className={`filter-container `}>
				<div className="col-4" />
				<div className="col-3">
					<Button onClick={() => history.push('/patients/new')} bordered={true} className="m-r-s height-search" >
						<PlusInACircle /> &nbsp;	New patient
					</Button>
				</div>
				<div className={`col-5`}>
					<TextInput key={current} value={searchValues[tableEnum]} placeholder="Search..." onChange={(e) => {
						container.filter(e.value)
						setSearchValues({
							...searchValues,
							[tableEnum]: e.value
						})
					}}
						clearable={true}
						onClear={() => container.filter()} />
					<CustomIconWithTooltip className="generic_tool_tip--left"
						style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }}
						toolTipText={"You may search by patient clinic ID, first name, last name, or email."}
						icon={<InfoIconGray />} />
				</div>
			</FormRow>
		);
	}

	const renderAllPatients = () => {
		return <Table
			title={`All patients`}
			tableData={{
				head: container.state.tableHeaders[PatientsTables.AllPatients],
				body: container.state.tableData[PatientsTables.AllPatients],
			}}
			titleColumnSize={6}
			filters={renderFilters(container, PatientsTables.AllPatients)}
			onColumnSort={(columnIndex) => container.sort(columnIndex, [PatientsTables.AllPatients], columnIndex == 2 ? true : false)}
			showSpinner={loadingAllPatients}
			medium
		/>
	}

	const renderAwaitingSignupPatients = () => {
		return <Table
			title={`Awaiting signup`}
			tableData={{
				head: container.state.tableHeaders[PatientsTables.AwaitingSignUp],
				body: container.state.tableData[PatientsTables.AwaitingSignUp],
			}}
			titleColumnSize={6}
			filters={renderFilters(container, PatientsTables.AwaitingSignUp)}
			onColumnSort={(columnIndex) => container.sort(columnIndex, [PatientsTables.AwaitingSignUp])}
			showSpinner={loadingAllPatients}
			medium
		/>
	}

	const currentToDrawerEnumMapper = {
		[drawerEnum.AllPatients]: renderAllPatients,
		[drawerEnum.AwaitingSignup]: renderAwaitingSignupPatients,
	}

	return (
		<div className='card tableDrawerContainer'>
			<h1 className="h4 m-b-m">Patients</h1>
			<Drawer buttons={buttons} current={current} setCurrent={setCurrent} />
			{
				currentToDrawerEnumMapper[current]()
			}
		</div>
	);
}

function SubscribedPatients(props) {
	return <Subscribe to={[PatientContainer]}>{(container) => <Patients {...props} container={container} />}</Subscribe>;
}

export default SubscribedPatients;

const drawerEnum = {
	AllPatients: 1,
	AwaitingSignup: 2,
}

Object.freeze(drawerEnum);
