import React from 'react'

const Separator = () => {
    return (
        <div>
            <hr className="separator" />
        </div>
    )
}

export default Separator
