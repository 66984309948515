import React from 'react'

const FlagIconRed = () => {
	return (
		<svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 1.9541C0 0.849532 0.895431 -0.0458984 2 -0.0458984H14C15.1046 -0.0458984 16 0.849532 16 1.9541V13.9541C16 15.0587 15.1046 15.9541 14 15.9541H2C0.895431 15.9541 0 15.0587 0 13.9541V1.9541Z" fill="none" />
			<path d="M13.1842 2.5152C13.2332 2.548 13.2734 2.59237 13.3013 2.64439C13.3291 2.69641 13.3437 2.75448 13.3438 2.81348V8.2041C13.3437 8.27587 13.3222 8.34599 13.282 8.40543C13.2418 8.46487 13.1847 8.5109 13.1181 8.5376L12.9844 8.2041L13.1181 8.5376L13.1159 8.53832L13.1116 8.54048L13.0951 8.54695C13.0006 8.5845 12.9054 8.62045 12.8097 8.65476C12.62 8.72304 12.3562 8.81504 12.0572 8.90632C11.4707 9.08745 10.7067 9.28223 10.1094 9.28223C9.50059 9.28223 8.99675 9.08098 8.55831 8.90488L8.53819 8.8977C8.0825 8.71441 7.69438 8.56348 7.23438 8.56348C6.73125 8.56348 6.05706 8.72879 5.48278 8.90632C5.22565 8.98646 4.97087 9.07394 4.71875 9.16866V13.5947C4.71875 13.69 4.68089 13.7814 4.61349 13.8488C4.5461 13.9162 4.45469 13.9541 4.35938 13.9541C4.26406 13.9541 4.17265 13.9162 4.10526 13.8488C4.03786 13.7814 4 13.69 4 13.5947V2.81348C4 2.71816 4.03786 2.62676 4.10526 2.55936C4.17265 2.49196 4.26406 2.4541 4.35938 2.4541C4.45469 2.4541 4.5461 2.49196 4.61349 2.55936C4.68089 2.62676 4.71875 2.71816 4.71875 2.81348V3.01616C4.88119 2.95938 5.07525 2.89398 5.28656 2.82929C5.87306 2.6496 6.63781 2.4541 7.23438 2.4541C7.83812 2.4541 8.32975 2.6532 8.75884 2.82713L8.78975 2.84007C9.23681 3.02048 9.62638 3.17285 10.1094 3.17285C10.6125 3.17285 11.2867 3.00754 11.861 2.83001C12.1882 2.72781 12.5116 2.61369 12.8306 2.48788L12.8442 2.48285L12.8471 2.48141H12.8478" fill="#F59404" />
		</svg>
	)
}

export default FlagIconRed
