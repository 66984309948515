import apiClient from "../clients/apiClient";
import { prescriberApiToClient, prescribersApiToClient } from "../converters/prescribersConverter";
import { trackAnalyticsEvent } from "../util/helpers/analyticsHelper";
import { organisationsApiToClient } from "../converters/organisationsConverter";

class PrescribersService {
	constructor() {
		this.client = {
			getById: apiClient.getById("prescribers"),
			filter: apiClient.filter("prescribers"),
			create: apiClient.create("prescribers"),
			update: apiClient.update("prescribers"),
			getAllPrescribersWithoutPatients: apiClient.getAllPrescribersWithoutPatientsByOrgId,
		};
	}

	async getById(id) {
		const { data: prescriber } = await this.client.getById(id);
		const { data: organisations } = await apiClient.getAllOrganisationsForOrganisationEmployee(id);
		const { data: prescriptions } = await apiClient.getAllPrescriptionsForOrganisationEmployee(id);

		const formattedPrescribers = prescriberApiToClient(prescriber);

		formattedPrescribers.organisations = organisationsApiToClient(organisations);
		formattedPrescribers.patients = prescriptions.map((prescription) => ({
			id: prescription.patient.id,
			firstName: prescription.patient.firstName,
			lastName: prescription.patient.lastName,
			prescriptionId: prescription.id,
			organisationName: prescription.organisation.name,
			organisationId: prescription.organisation.id
		}));

		return formattedPrescribers
	}

	async getAllPrescribersWithoutPatients(orgId) {
		const { data: prescribers } = await this.client.getAllPrescribersWithoutPatients(orgId);

		return prescribersApiToClient(prescribers);
	}

	async filter(queryString) {

		const { data: prescribers } = await this.client.filter({ queryString });

		return prescribersApiToClient(prescribers);
	}

	async create(prescriberPayload) {
		const { data: newDoctor } = await this.client.create(prescriberPayload);

		trackAnalyticsEvent("User", "Register", "Doctor Registered From Admin");

		return newDoctor;
	}

	async update(prescriberPayload) {
		const { data: updatedPrescriber } = await this.client.update(prescriberPayload);

		return updatedPrescriber;
	}

	async getEventHistoryForOrganisationEmployee(orgEmployeeId) {
		const { data: eventHistory } = await apiClient.getEventHistoryForOrganisationEmployee(orgEmployeeId);

		return eventHistory;
	}

	async linkOrganisation(payload) {
		await apiClient.linkPrescriberToOrganisation(payload);
	}
}

export default new PrescribersService();
