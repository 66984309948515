import React, { useEffect } from "react";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import TextInput from "../../components/Form/TextInput";
import FormRow from "../../components/Form/FormRow";

import PrescriberContainer from "../../state/PrescriberContainer";
import { SpinnerIcon } from "../../assets/icons";
import CustomSelect from "../../components/Form/CustomSelect";
import Banner from "../../components/Banner/Banner";
import { BannerTypes, PasswordRulePoints } from "../../enums";
import PasswordInput from "../../components/Form/PasswordInput";
import InputFieldValidationBulletPoints from "../../components/Form/InputFieldValidationBulletPoints";
import RemoveLinkedOrganisationIcon from "../../assets/icons/RemoveLinkedOrganisationIcon";

function NewPrescriber({ container }) {
	// State-related data
	const formKey = "newPrescriber";
	const state = container.state[formKey];
	const onFieldChange = (event) => container.onFormChange(event, formKey);
	const onSelectChange = (event) => container.onSelectChange(event, formKey);
	const onAssociatedOrganisationChange = (event, index) => container.onAssociatedOrganisationChange(event, index);
	const onRemoveOrganisation = (index) => container.onRemoveOrganisation(index);

	useEffect(() => {
		container._getOrganisations();
		return () => {
			container.resetNewPrescriberForm()
		}
		// eslint-disable-next-line
	}, [container.resetNewPrescriberForm]);

	const typeGMC = state.prescriberType.value.value === 0
	const typeGPHC = state.prescriberType.value.value === 1
	const typeNMC = state.prescriberType.value.value === 2

	return (
		<div className="card p-m">
			<FormRow>
				<h1 className="h4">New prescriber</h1>
			</FormRow>

			<FormRow>
				<Banner type={BannerTypes.Info} text="All fields are required" />
			</FormRow>

			<FormRow>
				<h2 className="h6">Personal info</h2>
			</FormRow>

			<FormRow>
				<div className="col-4">
					<CustomSelect
						key={container.state.filteredOrganisations.length}
						label="Select Type"
						name="prescriberType"
						values={state.prescriberType.value ? [state.prescriberType.value] : []}
						options={container.state.prescriberTypes}
						onChange={(e) => { onSelectChange(e) }}
						placeholder={"Type"}
					/>
				</div>
			</FormRow>

			{typeGMC &&
				<div className="col-5">
					<FormRow className="organisation_details">
						<p className="text_input-label">GMC number</p>
						<p style={{ fontWeight: "300", marginBottom: "15px" }}>
							You can find a Doctor’s <a href="https://www.gmc-uk.org/registration-and-licensing/the-medical-register">GMC number here</a>
						</p>
						<TextInput
							value={state.prescriberIdCode.value}
							error={state.prescriberIdCode.error}
							onChange={(e) => { onFieldChange(e) }}
							name="prescriberIdCode"
							placeholder="e.g. 1234567"
						/>
					</FormRow>
				</div>
			}
			{typeGPHC &&
				<div className="col-5">
					<FormRow className="organisation_details">
						<p className="text_input-label">GPHC number</p>
						<p style={{ fontWeight: "300", marginBottom: "15px" }}>You can find a Pharmacist’s <a href="https://www.pharmacyregulation.org/registers/pharmacist">GPHC number here</a></p>
						<TextInput
							value={state.prescriberIdCode.value}
							error={state.prescriberIdCode.error}
							onChange={(e) => { onFieldChange(e) }}
							name="prescriberIdCode"
							placeholder="e.g. 1234567"
						/>
					</FormRow>
				</div>
			}
			{
				typeNMC &&
				<div className="col-5">
					<FormRow className="organisation_details">
						<p className="text_input-label">NMC PIN NUMBER</p>
						<p style={{ fontWeight: "300", marginBottom: "15px" }}>Before adding, please check their <a href="https://www.nmc.org.uk/registration/search-the-register/">registration status here</a></p>
						<TextInput
							value={state.prescriberIdCode.value}
							error={state.prescriberIdCode.error}
							onChange={(e) => { onFieldChange(e) }}
							name="prescriberIdCode"
							placeholder="e.g. 1234567"
						/>
					</FormRow>
				</div>
			}


			<FormRow>
				<div className="col-6">
					<TextInput
						value={state.firstName.value}
						error={state.firstName.error}
						onChange={onFieldChange}
						name="firstName"
						label="First name"
						placeholder="e.g. John"
					/>
				</div>
				<div className="col-6">
					<TextInput
						value={state.lastName.value}
						error={state.lastName.error}
						onChange={onFieldChange}
						name="lastName"
						label="Last name"
						placeholder="e.g. Doe"
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Prescriber account</h2>
			</FormRow>
			<FormRow>
				<div className="col-8">
					<TextInput
						value={state.email.value}
						error={state.email.error}
						onChange={onFieldChange}
						name="email"
						label="Email"
						placeholder="e.g. johndoe@email.com"
					/>
				</div>
				<div className="col-4" />
			</FormRow>

			<FormRow>
				<div>
					<p className="m-l-8 m-b-s normal">Please make sure password has:</p>
					<InputFieldValidationBulletPoints
						value={state.password.value}
						points={PasswordRulePoints}
					/>
				</div>
			</FormRow>

			<FormRow>
				<div className="col-4">
					<PasswordInput
						value={state.password.value}
						error={state.password.error}
						onChange={onFieldChange}
						name="password"
						label="Password"
						placeholder=""
					/>
				</div>
				<div className="col-4">
					<PasswordInput
						value={state.repeatPassword.value}
						error={state.repeatPassword.error}
						onChange={onFieldChange}
						name="repeatPassword"
						label="Confirm Password"
						placeholder=""
					/>
				</div>
				<div className="col-4"></div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Organisations</h2>
			</FormRow>

			{state.organisationIds.value.map((id, index) =>
				<FormRow key={container.state.filteredOrganisations.length + index}>
					<div className="col-4 organisations_select-dropdown">
						<CustomSelect
							label="Select organisation"
							name="organisationsSelect"
							values={id === 0 ? [] : [id]}
							options={container.state.filteredOrganisations}
							onChange={(e) => { onAssociatedOrganisationChange(e, index) }}
							placeholder={"Search ..."}
						/>
						{state.organisationIds.value.length > 1 &&
							<Button
								className="remove_linked_organisation"
								onClick={() => { onRemoveOrganisation(index) }}
							>
								<RemoveLinkedOrganisationIcon></RemoveLinkedOrganisationIcon>
							</Button>
						}
					</div>
				</FormRow>
			)}
			{state.organisationIds.value.length < 5 &&
				<FormRow>
					<div onClick={() => { container.addNewOrganisationId() }} className="add_list_element_button">
						<i className="icon-plus" />
						<p className="uppercase m-l-8">LINK ORGANISATION</p>
					</div>
				</FormRow>
			}
			<FormRow>
				<Button
					onClick={container.create}
					disabled={
						!container.isFormValid(formKey) || container.state.creatingPrescriber ||
						(state.organisationIds.value && state.organisationIds.value.some(e => e <= 0))
					}
				>
					{container.state.creatingPrescriber ? <SpinnerIcon /> : "Create Prescriber"}
				</Button>
			</FormRow>
		</div>
	);
}

function SubscribedNewPrescriber(props) {
	return <Subscribe to={[PrescriberContainer]}>{(container) => <NewPrescriber {...props} container={container} />}</Subscribe>;
}

export default SubscribedNewPrescriber;