import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";

import AdminsService from "../services/AdminsService";

import AuthenticationService from "../services/AuthenticationService";

import { required, isEmail, maxLength, isFormValid } from "../util/validation/validation";

class ProfileContainer extends Container {
	constructor() {
		super();

		// Initial state
		this.state = {
			administrator: {
				id: { value: "" },
				name: { value: "" },
				email: { value: "" },
				password: { value: "" },
				repeatPassword: { value: "" },
				createdAt: { value: "" },
				updatedAt: { value: "" },
				lastLogin: { value: "" },
			},

			// used to determine if use changed something
			// if false "Save" button is disabled
			profileChanged: false,

			// loading flags
			updatingProfile: false,
		};

		// Validators
		this.validators = {
			administrator: {
				name: [required, maxLength(12)],
				email: [required, isEmail],
			},
		};
	}

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}

		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({
			[formKey]: Object.assign(this.state[formKey], {
				[e.name]: newField,
			}),

			profileChanged: true,
		});
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	getAdministrator = async () => {
		try {
			const administrator = await AuthenticationService.getAdministratorDataFromAPI();

			this.setState({
				administrator: {
					id: { value: administrator.id },
					name: { value: administrator.name },
					email: { value: administrator.email },
					password: { value: "" },
					repeatPassword: { value: "" },
					createdAt: { value: administrator.createdAt },
					updatedAt: { value: administrator.updatedAt },
					lastLogin: { value: administrator.lastLogin },
					// this value isn't presented but it is needed
					// when an admin is updating his/hers profile
					permissions: administrator.permissions,
				},
			});
		} catch (error) { }
	};

	updateAdministrator = async () => {
		this.setState({ updatingProfile: true });

		try {
			const administrator = await AdminsService.update({
				id: this.state.administrator.id.value,
				name: this.state.administrator.name.value,
				email: this.state.administrator.email.value,
				password: this.state.administrator.password.value,
				permissions: this.state.administrator.permissions,
			});

			this.setState({
				profileChanged: false,

				administrator: {
					id: { value: administrator.id },
					name: { value: administrator.name },
					email: { value: administrator.email },
					password: { value: "" },
					repeatPassword: { value: "" },
					createdAt: { value: administrator.createdAt },
					updatedAt: { value: administrator.updatedAt },
					lastLogin: { value: administrator.lastLogin },
					// this value isn't presented but it is needed
					// when an admin is updating his/hers profile
					permissions: administrator.permissions,
				},
			});

			notificationHelper.info("Your profile has been updated!");
		} catch (error) { }

		this.setState({ updatingProfile: false });
	};
}

export default ProfileContainer;
