import React from 'react'

const TickedIcon = ({ color = "black" }) => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.082 0.171875L7.91797 12.2539L2.5 6.83984L0 9.33984L8.33203 17.6719L20 2.67188L17.082 0.171875Z" fill={color} />
        </svg>

    )
}

export default TickedIcon
