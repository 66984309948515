import React from 'react'

const CustomCard = ({ className = "", children }) => {
    return (
        <div className={`custom__card ${className} `}>
            {children}
        </div>
    )
}

export default CustomCard
