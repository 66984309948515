import React, { useEffect, useState } from 'react'
import { Subscribe } from 'unstated';
import Drawer from '../../components/Drawer/Drawer';
import DropZone from '../../components/DropZone/DropZone';
import Button from '../../components/Form/Button';
import Dropdown from '../../components/Form/Dropdown';
import FormRow from '../../components/Form/FormRow'
import TextInput from '../../components/Form/TextInput';
import Table from '../../components/Table/Table';
import AuthenticationContainer from '../../state/AuthenticationContainer';
import PatientContainer from '../../state/PatientContainer';
import contentHelper from '../../util/helpers/contentHelper';
import CsvDownload from 'react-json-to-csv'
import { SpinnerIcon } from '../../assets/icons';
import Banner from '../../components/Banner/Banner';
import { BannerTypes } from '../../enums';
import CustomSelect from "../../components/Form/CustomSelect";

const NewPatient = ({ container }) => {
	const formKey = "createPatient";
	const bulkUploadFormKey = "bulkUpload"
	const state = container.state[formKey];
	const bulkUploadState = container.state[bulkUploadFormKey];

	const onFieldChange = (event) => container.onFormChange(event, formKey);
	const onSelectChange = (event) => container.onSelectChange(event, formKey);


	const accountsWithErrorsTableData = bulkUploadState.tableData.accountsWithErrors;
	const accountsCreatedTableData = bulkUploadState.tableData.accountsCreated;
	const areThereAccountsWithErrors = accountsWithErrorsTableData.length > 0;
	const areThereAccountsCreated = accountsCreatedTableData.length > 0;
	const numberOfRowsInTheCsv = bulkUploadState.bulkUploadCsvData.length;

	const [current, setCurrent] = useState(drawerEnum.Individual);
	const buttons = [
		{ text: "Individual", id: drawerEnum.Individual },
		{ text: "Bulk upload", id: drawerEnum.Bulk },
	]

	useEffect(() => {
		container.setForCreate();
		return () => {
			container.resetCreateState();
		}
		// eslint-disable-next-line
	}, [])

	const renderDownloadErrorsCsv = () => {
		return (
			<FormRow>
				<div className='col-4'>
				</div>
				<div className='col-3'>
					<CsvDownload filename="accounts_with_errors.csv" className='btn' data={bulkUploadState.csvDtataToDownload}>Download as CSV</CsvDownload>
				</div>
			</FormRow>
		)
	}

	const renderDownloadCsvWithHeaders = () => {
		return (
			(numberOfRowsInTheCsv < 1) &&
			<FormRow>
				<div className='col-4'>
					<CsvDownload filename="blank.csv" className='btn' data={[headersForBlankCsv]}>Download CSV</CsvDownload>
				</div>
				<div className='col-3'>

				</div>
			</FormRow>
		)
	}

	const renderDoneButton = () => {
		return (
			(areThereAccountsCreated || areThereAccountsWithErrors) &&
			<FormRow>
				<div className='col-4'>
					<Button className='m-l-s' text="Done" onClick={() => { container.resetBulkUploadData() }} />
				</div>
			</FormRow>
		)
	}

	const renderUploadResponseMessages = () => {
		return (
			<div className='m-t-m'>
				{areThereAccountsCreated &&
					<div className='color-green bold'>
						✔ &nbsp; {accountsCreatedTableData.length} patient accounts successfully created
					</div>
				}

				{areThereAccountsWithErrors &&
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div className='color-red bold'>
							✘ &nbsp; {accountsWithErrorsTableData.length} patient with errors </div>
						&nbsp;
						<div> please check if all fields are correct</div>
					</div>
				}
			</div>
		)
	}

	const renderIndividual = () => {
		return (
			<>
				<FormRow>
					<div className='col-3' >
						<Banner type={BannerTypes.Info} text="All fields required" />
					</div>
				</FormRow>
				<FormRow>
					<div className="col-2">
						<Dropdown
							value={state.title?.value}
							error={state.title.error}
							onChange={onFieldChange}
							name="title"
							label="Title"
							optional
							required
							options={contentHelper.getTitlesAsDropdownItems()}
						/>
					</div>
					<div className="col-5">
						<TextInput
							value={state.firstName?.value}
							error={state.firstName.error}
							onChange={onFieldChange}
							name="firstName"
							label="First name"
							placeholder="e.g. John"
							required={true}
						/>
					</div>
					<div className="col-5">
						<TextInput
							value={state.lastName?.value}
							error={state.lastName.error}
							onChange={onFieldChange}
							name="lastName"
							label="Last name"
							placeholder="e.g. Doe"
							required={true}
						/>
					</div>
				</FormRow>
				<FormRow>
					<div className="col-4">
						<TextInput
							value={state.dateOfBirth?.value}
							error={state.dateOfBirth.error}
							onChange={onFieldChange}
							name="dateOfBirth"
							label="Date of birth"
							placeholder="e.g. 25/08/1973"
							required={true}
						/>
					</div>
					<div className="col-4">
						<TextInput
							value={state.email?.value}
							error={state.email.error}
							onChange={onFieldChange}
							name="email"
							label="Email address"
							placeholder="e.g. johndoe@email.com"
							required={true}
						/>
					</div>
					<div className="col-4">
						<TextInput
							value={state.telephoneNo?.value}
							error={state.telephoneNo.error}
							onChange={onFieldChange}
							name="telephoneNo"
							label="Telephone number"
							placeholder="e.g. +00256478921"
							required={true}
						/>
					</div>
				</FormRow>
				<FormRow>
					<div className="col-4">
						<TextInput
							value={state.address1?.value}
							error={state.address1.error}
							onChange={onFieldChange}
							name="address1"
							label="Address 1"
							placeholder="e.g. Avenue Road 134A, Illinois"
							required
						/>
					</div>
					<div className="col-4">
						<TextInput
							value={state.address2?.value}
							error={state.address2.error}
							onChange={onFieldChange}
							name="address2"
							label="Address 2"
							placeholder="e.g. Avenue Road 134B, Illinois"
							required
						/>
					</div>
					<div className="col-4">
						<TextInput
							value={state.zipCode?.value}
							error={state.zipCode.error}
							onChange={onFieldChange}
							name="zipCode"
							label="Postal code"
							placeholder="e.g. 35492 DB"
							required
						/>
					</div>
				</FormRow>
				<FormRow>

					<div className="col-4">
						<Dropdown
							name="t21Patient"
							value={state.t21Patient?.value}
							error={state.t21Patient.error}
							onChange={onFieldChange}
							options={contentHelper.getYesNoDropdownItems()}
							label="T21 patient"
							required
						/>
					</div>
					<div className="col-4">

						<CustomSelect
							key={container.state.filteredOrganisations.length}
							label="Clinic name"
							name="organisationId"
							values={state.organisationId ? [state.organisationId.value] : []}
							options={container.state.filteredOrganisations}
							onChange={(e) => { onSelectChange(e) }}
							placeholder={"Select clinic"}
						/>
					</div>
					<div className="col-4">
						<TextInput
							value={state.clinicPatientId?.value}
							error={state.clinicPatientId.error}
							onChange={onFieldChange}
							name="clinicPatientId"
							label="Clinic patient id"
							placeholder="e.g. 123456"
							required
						/>
					</div>
				</FormRow>
				<FormRow>
					<div className="col-4">

						<Button onClick={container.create} disabled={!container.isFormValid(formKey) || container.state.creatingPatient} >
							{container.state.creatingPatient ? <SpinnerIcon /> : "Save"}
						</Button>
					</div>
					<div className="col-8 " style={{ color: 'red' }}>
						{state.errorMessage.value}
					</div>
				</FormRow>
			</>
		)
	}

	const renderBulk = () => {
		return (
			<>
				{(!areThereAccountsCreated && !areThereAccountsWithErrors) &&
					<>
						<div className='m-t-m m-b-m'>
							<DropZone />
						</div>
						{
							numberOfRowsInTheCsv ?
								<FormRow>
									<p>Create {numberOfRowsInTheCsv} patient account{numberOfRowsInTheCsv > 1 ? 's' : ''} using the uploaded file?</p>
								</FormRow>
								:

								""
						}
						{numberOfRowsInTheCsv >= 1 &&
							<FormRow>
								<div className='col-4'>
									<Button text="Create patients" onClick={container.bulkUploadCreate} disabled={!numberOfRowsInTheCsv} />
									<Button className='m-l-s' text="Cancel" onClick={() => { container.resetBulkUploadData() }} disabled={!numberOfRowsInTheCsv} />
								</div>
							</FormRow>
						}

					</>
				}

				{renderUploadResponseMessages()}

				{areThereAccountsCreated &&
					<div className='m-t-m'>
						<Table
							title="Patient accounts created"
							tableData={{
								head: bulkUploadState.tableHeaders.accountsCreated,
								body: bulkUploadState.tableData.accountsCreated,
							}}
							small
							adjustable
						/>
					</div>
				}
				{areThereAccountsWithErrors &&
					<div className='m-t-m'>
						<Table
							title="Errors"
							tableData={{
								head: bulkUploadState.tableHeaders.accountsWithErrors,
								body: bulkUploadState.tableData.accountsWithErrors,
							}}
							bottomContent={renderDownloadErrorsCsv()}
							small
							adjustable
						/>
					</div>
				}

				{renderDoneButton()}

				{renderDownloadCsvWithHeaders()}
			</>
		)
	}

	const currentToDrawerEnumMapper = {
		[drawerEnum.Individual]: renderIndividual,
		[drawerEnum.Bulk]: renderBulk,
	}

	return (
		<div className="card p-m">
			<FormRow>
				<h1 className="h4">New {current === drawerEnum.Individual ? "patient" : "patients"}</h1>
			</FormRow>
			<Drawer buttons={buttons} current={current} setCurrent={setCurrent} />
			{
				currentToDrawerEnumMapper[current]()
			}
		</div>
	)
}

function SubscribedNewPatient(props) {
	return <Subscribe to={[PatientContainer, AuthenticationContainer]}>{(container, authContainer) => <NewPatient {...props} container={container} currentAdministrator={authContainer.state.currentAdministrator} />}</Subscribe>;
}

export default SubscribedNewPatient;

const drawerEnum = {
	Individual: 1,
	Bulk: 2,
}

Object.freeze(drawerEnum);

const headersForBlankCsv = {
	title: "",
	firstName: "",
	lastName: "",
	dateOfBirth: "",
	address1: "",
	zipCode: "",
	email: "",
	telephoneNo: "",
	clinicName: "",
	patientClinicId: "",
	t21: "",
	mdtStatus: "",
}