import React from "react";

function TextInputWithTooltip({
	value = "",
	name = "",
	label = "",
	disabled = false,
	placeholder = "Text input",
	error = false,
	onKeyUp = () => null,
	onSubmit = () => null,
	onChange = () => null,
	required = false,
	showAsterisk = false,
	className = "",
	type = "text",
	toolTip
}) {
	function onKeyUpHandler(e) {
		onKeyUp(e);

		if (e.key === "Enter") onSubmit();
	}
	const spreadable = {
		autoComplete: "nope"
	}

	if (type === "password") {
		Object.assign(spreadable, { autoComplete: "new-password" })
	}

	return (
		<div className={`input-container ${className} `}>
			<label className="component-label">{error ? error : typeof label === "string" ? label + (showAsterisk ? " *" : '') : label} <span style={{ fontSize: "14px", textTransform: "none" }}>{toolTip}</span></label>
			<input
				className={error ? "error" : ""}
				type={type}
				name={name}
				placeholder={placeholder}
				disabled={disabled}
				defaultValue={value}
				onKeyUp={onKeyUpHandler}
				onChange={(e) => onChange({ name, value: e.target.value })}

				onBlur={(e) => {
					if (!required) return;
					onChange({ name, value: e.target.value });
				}}
				{...spreadable}
			/>
			<div className="animated-border"></div>
		</div>
	);
}

export default TextInputWithTooltip;
