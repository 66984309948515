import React, { useState } from "react";
import Portal from "../Portals/Portal";
import TooltipContainer from "./TooltipContainer";

function UniversalTooltip({ hoverableContent = "hoverable", contentForTheTooltip = "Content for the tooltip", left = 0, top = 0 }) {
	const [coords, setCoords] = useState({}); // takes current button coordinates
	const [isOn, setOn] = useState(false); // toggles button visibility

	const handleOnMouseEnter = (e) => {
		const rect = e.target.getBoundingClientRect();

		setCoords({
			left: rect.x + rect.width / 2 + left,
			top: rect.y + window.scrollY + top
		});
		setOn(!isOn); // [ 3 ]
	}

	return <div style={{ position: 'relative' }}>
		<div
			onMouseEnter={e => { handleOnMouseEnter(e) }}
			onMouseLeave={() => { setOn(false) }}
		>
			{hoverableContent}
		</div>
		{
			isOn &&
			<Portal>
				<TooltipContainer coords={coords}>
					{contentForTheTooltip}
				</TooltipContainer>
			</Portal>
		}
	</div>
}

export default UniversalTooltip;