import React from 'react'

const SpinnerIcon = ({ color = "white", width = "37" }) => {
    return (
        <svg className='spinner_icon' width={width} height={width} viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.933 8.5673L45.3375 13.1628C41.616 9.44089 36.7191 7.1245 31.4811 6.60833C26.2432 6.09215 20.9883 7.40812 16.6118 10.332C12.2354 13.2559 9.00816 17.6068 7.48 22.6434C5.95185 27.68 6.21733 33.0906 8.23121 37.9534C10.2451 42.8162 13.8828 46.8303 18.5244 49.3117C23.1661 51.7931 28.5246 52.5883 33.6868 51.5618C38.8491 50.5353 43.4957 47.7507 46.835 43.6823C50.1743 39.614 51.9996 34.5136 52 29.2503H58.5C58.4999 36.0174 56.1535 42.5752 51.8604 47.8062C47.5673 53.0373 41.5933 56.6179 34.9562 57.9381C28.319 59.2582 21.4295 58.2362 15.4615 55.0461C9.49339 51.8561 4.81607 46.6954 2.22645 40.4433C-0.363177 34.1913 -0.70488 27.2348 1.25956 20.759C3.224 14.2833 7.37305 8.68903 12.9997 4.92945C18.6264 1.16987 25.3826 -0.522428 32.1172 0.140911C38.8517 0.80425 45.1479 3.78218 49.933 8.5673Z" fill={color} />
        </svg>

    )
}

export default SpinnerIcon
