import React from 'react'

const InfoIconGray = (props) => {
    let size = props.size ? props.size : 20
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8.57143 11.8571C8.57143 11.9357 8.50714 12 8.42857 12H7.57143C7.49286 12 7.42857 11.9357 7.42857 11.8571V7C7.42857 6.92143 7.49286 6.85714 7.57143 6.85714H8.42857C8.50714 6.85714 8.57143 6.92143 8.57143 7V11.8571ZM8 5.71429C7.7757 5.70971 7.56213 5.61739 7.40513 5.45714C7.24812 5.2969 7.16018 5.08149 7.16018 4.85714C7.16018 4.6328 7.24812 4.41739 7.40513 4.25714C7.56213 4.0969 7.7757 4.00458 8 4C8.2243 4.00458 8.43787 4.0969 8.59488 4.25714C8.75189 4.41739 8.83982 4.6328 8.83982 4.85714C8.83982 5.08149 8.75189 5.2969 8.59488 5.45714C8.43787 5.61739 8.2243 5.70971 8 5.71429Z" fill="#B3B3B3" />
        </svg>
    )
}

export default InfoIconGray
