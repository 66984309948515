import React, { useEffect } from "react";

function Modal({
    isOpen = false,
    onClose = () => null,
    children = null,
    large = null,
    className = "",
    style = {}
}) {

    useEffect(() => {
        if (!isOpen) {
            document.body.classList.remove("modal-open");
        }

        if (isOpen) {
            document.body.classList.add("modal-open");
        }
        return () => { document.body.classList.remove("modal-open") };
    }, [isOpen])

    return isOpen ? (
        <div>
            <div className="curtain" onClick={onClose} />
            <div style={style} className={`modal ${large ? "modal--large" : ""} ${className}`} >
                {children}
            </div>
        </div>
    ) : null;
}

export default Modal;