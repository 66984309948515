import React from 'react'

const NoteIcon = () => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6667 2.13341C14.6667 1.32675 14.0067 0.666748 13.2 0.666748H1.46667C0.66 0.666748 0 1.32675 0 2.13341V10.9334C0 11.7401 0.66 12.4001 1.46667 12.4001H11.7333L14.6667 15.3334V2.13341Z" fill="#2F3EFB" />
        </svg>

    )
}

export default NoteIcon

