import React from "react";

export function ModalInfoTable({ tableHeaders, tableBodyData, tableBodyRenderMethod, centered = false }) {
	return <table className={`stock-table ${centered && "centered"}`} style={{ margin: "10px" }}>
		<thead>
			<tr className={"table-header"}>
				{
					tableHeaders.map((header, index) => {
						return <th key={index}>{header}</th>;
					})
				}
			</tr>
		</thead>
		<tbody>
			{
				tableBodyData.map(tableBodyRenderMethod)
			}
		</tbody>
	</table>;
}