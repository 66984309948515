import React from "react";
import { Provider, Subscribe } from "unstated";

// Library styles
import "react-awesome-lightbox/build/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-step-progress/dist/index.css";

import MainRouter from "./routers/MainRouter";

import AuthenticationContainer from "./state/AuthenticationContainer";

// Styles
import "./scss/main.scss";

import ReactGA from 'react-ga';
import { ToastContainer } from "react-toastify";

function App() {
	ReactGA.initialize('UA-184803219-1');
	return (
		<div id="app">
			<ToastContainer />
			<Provider>
				<Subscribe to={[AuthenticationContainer]}>
					{(container) => <MainRouter container={container} administratorChecked={container.state.administratorChecked} />}
				</Subscribe>
			</Provider>
		</div>

	);
}

export default App;
