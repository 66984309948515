import moment from "moment";
import apiClient from "../clients/apiClient";


import { patientApiToClient, patientsApiToClient, patientClientToApi } from "../converters/patientsConverter";

class PatientsService {
	constructor() {
		this.client = {
			getById: apiClient.getById("patients"),
			filter: apiClient.filter("patients"),
			filterForCarer: apiClient.patientsFilterForCarer,
			patientSearch: apiClient.patientSearch,
			update: apiClient.update("patients"),
			delete: apiClient.delete("patients"),
			createComment: apiClient.create("comments"),
			createPatient: apiClient.createUsingFromData("patients/registerfromadmin"),
			updateComment: apiClient.update("comments/updateComment"),
			setAreEmailNotificationsEnabled: apiClient.setAreEmailNotificationsEnabled,
			setAreAftercareNotificationsEnabled: apiClient.setAreAftercareNotificationsEnabled,
			setIsUrgent: apiClient.setIsPatientUrgent
		};
	}

	async getById(id) {
		const { data: patient } = await this.client.getById(id);

		return patientApiToClient(patient);
	}

	async filter(queryString) {
		let date = moment(queryString, 'DD/MM/YY', true);

		const filters = {
			filterQuery: queryString,
		};

		const { data: patients } = await this.client.filter(filters);

		return patientsApiToClient(patients);
	}

	async filterForCarer(queryString) {
		const filters = queryString ? { firstName: queryString, lastName: queryString } : {};

		const { data: patients } = await this.client.filterForCarer(filters);

		return patients;
	}

	async patientSearch(queryString, cancelToken) {
		const filters = queryString ? { fullName: queryString } : {};

		const { data: patients } = await this.client.patientSearch(filters, cancelToken);

		return patients;
	}

	async create(patientCreate) {
		const payload = new FormData()
		const processed = patientClientToApi(patientCreate)

		for (var key in processed) {
			payload.append(key, processed[key]);
		}

		const { data: newPatient } = await this.client.createPatient(payload);

		return newPatient;
	}

	async update(patientUpdate) {

		const { data: updatedPatient } = await this.client.update(patientUpdate);

		return updatedPatient;
	}

	async delete(patientId) {
		await this.client.delete({ patientId });
	}

	async createComment(commentCreate) {
		const { data } = await this.client.createComment(commentCreate);

		return data;
	}
	async updateComment(commentUpdate) {
		const { data } = await this.client.updateComment(commentUpdate);

		return data;
	}

	async getAllCommentsByPatientId(patientId) {
		const { data } = await apiClient.getAllCommentsByPatientId(patientId);

		return data.sort(function (comm1, comm2) {
			var comm1CreatedAt = new Date(comm1.createdAt);
			var comm2CreatedAt = new Date(comm2.createdAt);

			return comm1CreatedAt - comm2CreatedAt;
		}).reverse();
	}

	async bulkCreatePatientAccounts(payload) {
		const { data } = await apiClient.bulkCreatePatientAccounts(payload);
		return data;
	}

	async setAreEmailNotificationsEnabled(payload) {
		const { data } = await this.client.setAreEmailNotificationsEnabled(payload);
		return data;
	}

	async setAreAftercareNotificationsEnabled(payload) {
		const { data } = await this.client.setAreAftercareNotificationsEnabled(payload);
		return data;
	}

	async setIsUrgent(payload) {
		const { data } = await this.client.setIsUrgent(payload);
		return data;
	}

	async resetPatientOnboardingLink(patientId) {
		const { data } = await apiClient.resetPatientOnboardingLink(patientId);
		return patientApiToClient(data);
	}

	async addNote(payload) {
		const { data } = await apiClient.addPatientNote(payload);

		return data;
	}

	async removeNote(patientId) {
		const { data } = await apiClient.removePatientNote(patientId);

		return data;
	}
}

export default new PatientsService();
