import React from "react";
import Modal from "../Modal/Modal";
import FormRow from "../Form/FormRow";
import Textarea from "../Form/Textarea";
import Button from "../Form/Button";
import Spinner from "../Spinner/Spinner";
import { Subscribe } from "unstated";
import PrescriptionContainer from "../../state/PrescriptionContainer";

function UnlinkPrescriptionModal({ container, unlinkModalVisible, setUnlinkModalVisible, handleUnlinkPrescription }) {
	const unlinkPrescriptionStateFormKey = "unlinkPrescription";
	const unlinkPrescriptionState = container.state[unlinkPrescriptionStateFormKey];
	const onUnlinkPrescriptionFieldChange = (event) => container.onFormChange(event, unlinkPrescriptionStateFormKey);
	const isButtonDisabled = !container.isFormValid(unlinkPrescriptionStateFormKey) || container.state.unlinkingPrescription;

	return (
		<Modal className='modal--assign' isOpen={unlinkModalVisible} onClose={() => setUnlinkModalVisible(false)}>
			<FormRow>
				<h1 className="h4">Unlink prescriptions</h1>
			</FormRow>
			<FormRow>
				<div className="bold">Please confirm you would like to unlink the two prescriptions and provide a reason for doing so.</div>
			</FormRow>
			<FormRow>
				<div className="bold">The original prescription will go back to awaiting re-write.</div>
			</FormRow>
			<Textarea
				name="reason"
				placeholder="Provide a reason"
				value={unlinkPrescriptionState.reason.value}
				error={unlinkPrescriptionState.reason.value?.length > 255 ? "Max character limit reached" : ""}
				onChange={onUnlinkPrescriptionFieldChange}
				maxLength={255}
			/>
			<FormRow>
				<Button
					className="m-r-xs "
					onClick={() => { handleUnlinkPrescription() }}
					disabled={isButtonDisabled}
				>
					{container.state.unlinkingPrescription ? <Spinner /> : "Unlink"}
				</Button>
				<Button
					className="m-r-xs btn--bordered--no_radius btn--bg-white"
					onClick={() => { setUnlinkModalVisible(false) }}
				>
					Cancel
				</Button>
			</FormRow>
		</Modal>

	);
}

function SubscribedUnlinkPrescriptionModal(props) {
	return (
		<Subscribe to={[PrescriptionContainer]}>
			{(container) => (
				<UnlinkPrescriptionModal {...props} container={container} />
			)}
		</Subscribe>
	);
}

export default SubscribedUnlinkPrescriptionModal;