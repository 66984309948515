import React from 'react'

const MagnifyingGlass = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6781 12.9287C15.8884 11.2771 16.4305 9.22941 16.1959 7.19531C15.9613 5.16122 14.9673 3.2907 13.4128 1.95799C11.8583 0.625277 9.85794 -0.0713438 7.81187 0.00749472C5.76581 0.0863332 3.82496 0.934817 2.37762 2.38319C0.930277 3.83157 0.0831823 5.77303 0.00580795 7.81915C-0.0715664 9.86527 0.626485 11.8652 1.96031 13.4187C3.29413 14.9722 5.16536 15.9649 7.19963 16.198C9.23389 16.4312 11.2812 15.8876 12.9319 14.6761H12.9306C12.9681 14.7261 13.0081 14.7736 13.0531 14.8198L17.8654 19.6322C18.0998 19.8667 18.4178 19.9985 18.7493 19.9986C19.0809 19.9988 19.399 19.8672 19.6335 19.6328C19.8681 19.3984 19.9999 19.0805 20 18.7489C20.0001 18.4173 19.8685 18.0993 19.6341 17.8647L14.8218 13.0524C14.7771 13.0072 14.7291 12.9654 14.6781 12.9274V12.9287ZM15.0006 8.12384C15.0006 9.02665 14.8227 9.92061 14.4773 10.7547C14.1318 11.5888 13.6254 12.3466 12.987 12.985C12.3486 13.6234 11.5907 14.1298 10.7567 14.4753C9.92258 14.8208 9.02862 14.9986 8.12582 14.9986C7.22301 14.9986 6.32905 14.8208 5.49497 14.4753C4.66089 14.1298 3.90302 13.6234 3.26464 12.985C2.62626 12.3466 2.11987 11.5888 1.77438 10.7547C1.4289 9.92061 1.25108 9.02665 1.25108 8.12384C1.25108 6.30055 1.97538 4.55193 3.26464 3.26267C4.55391 1.9734 6.30252 1.2491 8.12582 1.2491C9.94911 1.2491 11.6977 1.9734 12.987 3.26267C14.2763 4.55193 15.0006 6.30055 15.0006 8.12384Z" fill="#B3B3B3" />
        </svg>
    )
}

export default MagnifyingGlass
