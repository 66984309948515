import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import TextInput from "../../components/Form/TextInput";
import FormRow from "../../components/Form/FormRow";

import Table from "../../components/Table/Table";
import Prompt from "../../components/Modal/Prompt";

import tableHeaders from "../../util/constants/tableHeaders";
import PrescriberContainer from "../../state/PrescriberContainer";
import { PlusInACircle, SpinnerIcon } from "../../assets/icons";
import CustomSelect from "../../components/Form/CustomSelect";
import PasswordInput from "../../components/Form/PasswordInput";
import { BannerTypes, OrganisationEmployeeTypeForEditModal, PrescriberType } from "../../enums";

import Banner from "../../components/Banner/Banner"
import Modal from "../../components/Modal/Modal";
import Toggler from "../../components/Form/Toggler";
import OrganisationsContainer from "../../state/OrganisationsContainer";
import LoadingCard from "../../components/SharedComponents/LoadingCard";


function EditPrescriber({ container, organisationsContainer }) {
	// State-related data
	const formKey = "updatePrescriber";
	const state = container.state[formKey];
	const onFieldChange = (event) => container.onFormChange(event, formKey);

	const typeGMC = state.prescriberType.value === null || state.prescriberType.value === PrescriberType[0]

	// Hook-related data
	const { id } = useParams();
	const [openLinkOrganisationModal, setOpenLinkOrganisationModal] = useState(false);

	const [showDeleteEmployeeForOrganisationModal, setShowDeleteEmployeeForOrganisationModal] = useState(false);
	const [showSelectedOrganisationName, setShowSelectedOrganisationName] = useState("");
	const [showEditEmployeeForOrganisationModal, setShowEditEmployeeForOrganisationModal] = useState(organisationsContainer.state.setShowEditEmployeeForOrganisationModal);

	const deleteEmployeeForOrganisationKey = "deleteEmployeeForOrganisation";
	const deleteEmployeeForOrganisationState = organisationsContainer.state[deleteEmployeeForOrganisationKey];

	const [isAccountAccessToggledEdit, setIsAccountAccessToggledEdit] = useState(organisationsContainer.state.editEmployeeForOrganisation.hasAccountAccess.value);
	const [selectedRoleForUpdate, setSelectedRoleForUpdate] = useState(organisationsContainer.state.editEmployeeForOrganisation.role.value || 0);

	const loadingPrescriberInfo = container.state.updatingPrescriber

	useEffect(() => {
		setIsAccountAccessToggledEdit(organisationsContainer.state.editEmployeeForOrganisation.hasAccountAccess.value);
	}, [organisationsContainer.state.editEmployeeForOrganisation.hasAccountAccess.value]);

	useEffect(() => {
		setShowEditEmployeeForOrganisationModal(organisationsContainer.state.setShowEditEmployeeForOrganisationModal);
	}, [organisationsContainer.state.setShowEditEmployeeForOrganisationModal]);

	const openEditOrganisationEmployeeModal = (employeeIndex) => {
		organisationsContainer.setOrganisationEmployeeForUpdate(
			state.organisations.value.find((e, index) => index === employeeIndex)[4].actions.id,
			state.organisationEmployeeId.value);
		setShowEditEmployeeForOrganisationModal(true);
	};

	const openDeleteOrganisationEmployeeModal = (employeeIndex) => {
		organisationsContainer.setOrganisationEmployeeForDelete(state.organisations.value.find((e, index) => index === employeeIndex)[4].actions.id,
			state.organisationEmployeeId.value);

		setShowSelectedOrganisationName(state.organisations.value.find((e, index) => index === employeeIndex)[0].text)
		setShowDeleteEmployeeForOrganisationModal(true);
	};

	const confirmEditOrganisationEmployee = async () => {
		await organisationsContainer.updateForOrganisationEmployees(organisationsContainer.state.editEmployeeForOrganisation.id.value, selectedRoleForUpdate, isAccountAccessToggledEdit);
		container.setForUpdate(id);
		setShowEditEmployeeForOrganisationModal(false);
		organisationsContainer.resetOrganisationEmployeeState();
	};

	const deleteEmployeeFromOrganisation = async () => {
		await organisationsContainer.deleteEmployeeFromOrganisation(deleteEmployeeForOrganisationState.id.value, id);
		await container.setForUpdate(id);
		container.resetLinkedOrganisation();
		setShowDeleteEmployeeForOrganisationModal(false);
	};

	useEffect(() => {
		container.setForUpdate(id);
		container._getOrganisations();

		return container.resetupdatePrescriberForm;
	}, [id, container]);

	function renderFilters() {
		return (
			<FormRow className="table-row">
				<div className="col-5 table-row"></div>
				<Button onClick={() => { setOpenLinkOrganisationModal(true) }} bordered={true} className="height-search uppercase" >
					<PlusInACircle /> &nbsp;	Link Organisation
				</Button>
			</FormRow>
		);
	}



	return (
		<>
			{loadingPrescriberInfo ?
				<LoadingCard text={"Loading prescriber information"} />
				:
				<div className="card p-m">
					<Prompt
						isOpen={openLinkOrganisationModal}
						title="Link an Organisation"
						description=""
						children={
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div className="col-5 link_organisation-dropdown">
									<CustomSelect
										key={container.state.filteredOrganisations.length}
										label="Select organisation"
										name="organisationsSelect"
										values={[]}
										options={container.state.filteredOrganisations}
										onChange={(e) => container.setLinkedOrganisation(e)}
										placeholder={"Search ..."}
									/>
								</div>
								<div className="link-organisation_banner">
									<Banner type={BannerTypes.Info} text="By default, the Prescriber will not have access to an Organisation's account. To enable access for this Prescriber, please go to the Organisation’s page." />
								</div>
							</div>
						}
						customButtonYes="Confirm"
						customButtonNo="Cancel"
						onYes={() => {
							container.linkOrganisation();
							container.resetLinkedOrganisation();
							setOpenLinkOrganisationModal(false);
						}}
						yesDisabled={container.state.linkedOrganisation.value === 0}
						onNo={() => setOpenLinkOrganisationModal(false)}
						onClose={() => setOpenLinkOrganisationModal(false)}
					/>

					<FormRow>
						<h1 className="h4">{state.firstName.value} {state.lastName.value}</h1>
					</FormRow>
					<FormRow>
						<div className="col-2">
							<TextInput
								value={state.prescriberType.value}
								error={state.prescriberType.error}
								onChange={onFieldChange}
								name="type"
								label="TYPE"
								disabled
							/>
						</div>
						{typeGMC ?
							<div className="col-6">
								<TextInput
									value={state.prescriberIdCode.value}
									error={state.prescriberIdCode.error}
									onChange={onFieldChange}
									name="prescriberIdCode"
									label="GMC NUMBER"
									disabled
								/>
							</div>
							:
							<div className="col-6">
								<TextInput
									value={state.prescriberIdCode.value}
									error={state.prescriberIdCode.error}
									onChange={onFieldChange}
									name="prescriberIdCode"
									label="GPHC NUMBER"
									disabled
								/>
							</div>
						}
						<div className="col-4"></div>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<TextInput
								value={state.firstName.value}
								error={state.firstName.error}
								onChange={onFieldChange}
								name="firstName"
								label="First name"
								placeholder="e.g. John"
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.lastName.value}
								error={state.lastName.error}
								onChange={onFieldChange}
								name="lastName"
								label="Last name"
								placeholder="e.g. Doe"
							/>
						</div>
						<div className="col-4"></div>
					</FormRow>

					<FormRow>
						<h2 className="h6">Prescriber account</h2>
					</FormRow>
					<FormRow>
						<div className="col-8">
							<TextInput
								value={state.email.value}
								error={state.email.error}
								onChange={onFieldChange}
								name="email"
								label="Email"
								placeholder="e.g. johndoe@email.com"
							/>
						</div>
						<div className="col-4"></div>
					</FormRow>

					<FormRow>
						<div className="col-4">
							<PasswordInput
								value={state.password.value}
								error={state.password.error}
								onChange={onFieldChange}
								name="password"
								label="Password"
								placeholder=""
							/>
						</div>
						<div className="col-4">
							<PasswordInput
								value={state.repeatPassword.value}
								error={state.repeatPassword.error}
								onChange={onFieldChange}
								name="repeatPassword"
								label="Password"
								placeholder=""
							/>
						</div>
						<div className="col-4"></div>
					</FormRow>

					{state.organisations && (
						<FormRow>
							<div className="col-12">
								<Table
									title="Linked organisations"
									tableData={{
										head: tableHeaders.associatedOrganisations,
										body: state.organisations.value,
									}}
									filters={renderFilters()}
									small
									showSpinner={container.state.loadingLinkedOrganisationTable}
									onItemDelete={(employeeIndex) => openDeleteOrganisationEmployeeModal(employeeIndex)}
									onItemEdit={(employeeIndex) => openEditOrganisationEmployeeModal(employeeIndex)}
								/>
							</div>
						</FormRow>
					)}

					{state.patients && (
						<FormRow>
							<Table
								small
								title="Associated patients"
								tableData={{
									head: tableHeaders.associatedPatients,
									body: state.patients.value,
								}}
								onColumnSort={(columnIndex) => container.sortAssociatedPatients(columnIndex, columnIndex === 1 ? true : false)}
								adjustable
							/>
						</FormRow>
					)}

					<FormRow>
						<div className="col-12">
							<Table
								title="Event history"
								tableData={{
									head: tableHeaders.eventHistory,
									body: state.eventHistory,
								}}
								small
								showSpinner={container.state.loadingEventHistory}
							/>
						</div>
					</FormRow>

					<FormRow>
						<Button text="Save" onClick={container.update} disabled={!container.isFormValid(formKey) || container.state.updatingPrescriber} />
					</FormRow>

					{/* Delete employee form organisation modal */}
					<Modal
						isOpen={showDeleteEmployeeForOrganisationModal}
						onClose={() => {
							setShowDeleteEmployeeForOrganisationModal(false);
						}}
					>
						<FormRow>
							<h2 className="h6">Unlink Prescriber?</h2>
						</FormRow>
						<FormRow>
							<p>
								Please confirm you would like to unlink {state.firstName.value} {state.lastName.value} from{" "}
								{showSelectedOrganisationName}.<br></br>
								<br></br>
								Once unlinked, you will not be able to assign prescriptions to this Prescriber when selecting this Organisation.
							</p>
						</FormRow>
						<FormRow>
							<Button
								className="m-r-xs "
								onClick={() => {
									deleteEmployeeFromOrganisation();
								}}
							>
								Delete
							</Button>
							<Button
								className="m-r-xs btn--bordered--no_radius btn--bg-white"
								onClick={() => {
									setShowDeleteEmployeeForOrganisationModal(false);
								}}
							>
								{" "}
								Cancel
							</Button>
						</FormRow>
					</Modal>

					{/* Edit employee for organisation modal */}
					<Modal
						className="edit_employee-modal"
						isOpen={showEditEmployeeForOrganisationModal}
						onClose={() => {
							setShowEditEmployeeForOrganisationModal(false);
							organisationsContainer.resetOrganisationEmployeeState();
						}}
					>
						<FormRow>
							<h2 className="h6">Edit user account</h2>
						</FormRow>

						<FormRow>
							<div className="col-8">
								<CustomSelect
									label="ROLE"
									name="role"
									values={organisationsContainer.state.editEmployeeForOrganisation.role ? [organisationsContainer.state.editEmployeeForOrganisation.role] : []}
									options={organisationsContainer.state.organisationEmployeeTypesForEditModal}
									onChange={(e) => {
										setSelectedRoleForUpdate(e.value[0]);
									}}
									placeholder={"Type"}

									disabled={organisationsContainer.state.editEmployeeForOrganisation.role.value === 1 ? true : false}
								/>
							</div>
						</FormRow>

						{organisationsContainer.state.editEmployeeForOrganisation.role.value !== 1 && (
							<FormRow className="j-c-s" style={{ alignItems: "center" }}>
								<p>Account Access</p>
								<Toggler
									isChecked={isAccountAccessToggledEdit}
									onChange={() => {
										setIsAccountAccessToggledEdit(!isAccountAccessToggledEdit);
									}}
								/>
							</FormRow>
						)}

						{isAccountAccessToggledEdit && (
							<>
								{selectedRoleForUpdate.label === OrganisationEmployeeTypeForEditModal[2] && (
									<FormRow>
										<div className="new_employee_account_modal-disclaimer">
											<p>Organisation Admin Prescriber accounts can:</p>
											<br></br>
											<p>• Upload prescription images (image only area)</p>
											<p>• Select which Prescriber the prescription belongs to</p>
											<p>• Upload prescriptions to the DG platform with patient details</p>
											<p>• View all prescriptions associated with Organisation</p>
										</div>
									</FormRow>
								)}

								{selectedRoleForUpdate.label === OrganisationEmployeeTypeForEditModal[0] && (
									<FormRow>
										<div className="new_employee_account_modal-disclaimer">
											<p>Organisation Prescriber accounts with account access will be able to:</p>
											<br></br>
											<p>• Upload prescription images (image only area)</p>
											<p>• Upload prescriptions auto-assigned to them</p>
											<p>• View prescriptions associated to them within the clinic</p>
											<p>• View patients associated to them</p>
										</div>
									</FormRow>
								)}
							</>
						)}

						<FormRow className="j-c-s">
							<Button
								className="m-r-xs "
								onClick={() => confirmEditOrganisationEmployee()}

							>
								{organisationsContainer.state.updatingEmployeeForOrganisation ? <SpinnerIcon /> : "Confirm"}
							</Button>
							<Button
								className="m-r-xs btn--bordered--no_radius btn--bg-white"
								onClick={() => {
									setShowEditEmployeeForOrganisationModal(false);
									organisationsContainer.resetOrganisationEmployeeState();
								}}
							>
								{" "}
								Cancel
							</Button>
						</FormRow>
					</Modal>
				</div>}
		</>
	);
}

function SubscribedEditPrescriber(props) {
	return <Subscribe to={[PrescriberContainer, OrganisationsContainer]}>{(container, organisationsContainer) => <EditPrescriber {...props} container={container} organisationsContainer={organisationsContainer} />}</Subscribe>;
}

export default SubscribedEditPrescriber;
