import moment from "moment";

import apiClient from "../clients/apiClient";
import errorHelper from "../util/helpers/errorHelper";
import notificationHelper from "../util/helpers/notificationHelper";

class MetricsService {
	getMetrics = async (url, name, date = null) => {
		try {
			let response = {}
			if (date !== null) {
				response = await apiClient.getMetricsWithDate(url, date);
			} else {
				response = await apiClient.getMetrics(url);
			}
			return this._handleDownloadCSV(response, name);
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	_handleDownloadCSV = (response, name) => {
		name = `${name} ${moment().format("DD MM YYYY HH mm")}`;
		const blob = new Blob([response.data], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = name;
		link.click();

		// Clean up after download
		window.URL.revokeObjectURL(url);
		notificationHelper.info(`Downloaded ${name}!`)
	};
}

export default new MetricsService();