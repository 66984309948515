import React from 'react'

const CloseModalButton = ({ onClose }) => {
    return (
        <div className='close_modal'>
            <div className='close_modal__button' onClick={() => onClose(false)}>
                <i className='icon-close' ></i>
            </div>
        </div>
    )
}

export default CloseModalButton
