import React, { useRef } from "react";
import { XIconInACircle } from "../../assets/icons";

function TextInput({
	value = "",
	name = "",
	label = "",
	disabled = false,
	placeholder = "Text input",
	error = false,
	onKeyUp = () => null,
	onSubmit = () => null,
	onChange = () => null,
	required = false,
	showAsterisk = false,
	className = "",
	type = "text",
	clearable = false,
	onClear = () => null,
}) {
	function onKeyUpHandler(e) {
		onKeyUp(e);

		if (e.key === "Enter") onSubmit();
	}
	const spreadable = {
		autoComplete: "nope"
	}

	if (type === "password") {
		Object.assign(spreadable, { autoComplete: "new-password" })
	}

	const inputRef = useRef(null);

	const resetInputField = () => {
		inputRef.current.value = '';
	};

	return (
		<div className={`input-container ${className} `}>
			<input
				ref={inputRef}
				className={error ? "error" : ""}
				type={type}
				name={name}
				placeholder={placeholder}
				disabled={disabled}
				defaultValue={value}
				// NOTE: Changing the value of defaultValue attribute after a component has mounted will not cause any update of the value in the DOM therefore reseting state without exiting page is impossible 
				// value={value}
				onKeyUp={onKeyUpHandler}
				onChange={(e) => onChange({ name, value: e.target.value })}
				onBlur={(e) => {
					if (!required) return;
					onChange({ name, value: e.target.value });
				}}
				{...spreadable}
			/>
			<label className="component-label">{error ? error : typeof label === "string" ? label + (showAsterisk ? " *" : '') : label}</label>
			{clearable && inputRef.current?.value && (
				<div className="clear-icon" onClick={() => {
					resetInputField()
					onClear();
				}}>
					<XIconInACircle></XIconInACircle>
				</div >
			)}
			<div className="animated-border"></div>
		</div>
	);
}

export default TextInput;
