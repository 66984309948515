import React, { useEffect } from "react";
import { Subscribe } from "unstated";

import Table from "../../components/Table/Table";

import { PrescriptionPhases } from "../../enums";
import AuthenticationContainer from "../../state/AuthenticationContainer";
import TextInput from "../../components/Form/TextInput";
import PrescriptionEastoneContainer from "../../state/PrescriptionEastoneContainer";

function PrescriptionsEastone({ container }) {
	useEffect(() => {
		container.filterCallback();
	}, [container]);

	function renderFilters() {
		return (
			<div className="filter-container col-3">
				<div className="col-12">
					<TextInput placeholder="Search..." onChange={(e) => container.setFilterTimeout(e.value)} />
				</div>
			</div>
		);
	}

	return (
		<Table
			title="Ready for pharmacy"
			tableData={{
				head: container.state.tableHeaders,
				body: container.state.tableData,
			}}
			filters={renderFilters("readyforpharmacy")}
			onColumnSort={(columnIndex) => container.sort(columnIndex, PrescriptionPhases.readyforpharmacy)}
			adjustable
			medium
		/>
	);
}

function SubscribedPrescriptions(props) {
	return (
		<Subscribe to={[PrescriptionEastoneContainer, AuthenticationContainer]}>
			{(container, authenticationContainer) => (
				<PrescriptionsEastone {...props} container={container} authenticationContainer={authenticationContainer} />
			)}
		</Subscribe>
	);
}

export default SubscribedPrescriptions;
