import React from 'react'

const FlaggedProductIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="2" fill="#F0F1FF" />
            <path d="M18.6667 7.13268C18.6667 6.32602 18.0067 5.66602 17.2 5.66602H5.46667C4.66 5.66602 4 6.32602 4 7.13268V15.9327C4 16.7393 4.66 17.3993 5.46667 17.3993H15.7333L18.6667 20.3327V7.13268Z" fill="#2F3EFB" />
        </svg>
    )
}

export default FlaggedProductIcon