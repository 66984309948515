import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";

import Table from "../../components/Table/Table";
import TextInput from "../../components/Form/TextInput";

import AdministratorContainer from "../../state/AdministratorContainer";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";
import Button from "../../components/Form/Button";
import { PlusInACircle } from "../../assets/icons";
import FormRow from "../../components/Form/FormRow";

function Administrators({ container }) {
	const history = useHistory()
	// Hook-related data
	useEffect(() => {
		container.getAllAdministrators();
	}, [container]);

	// Functions
	function renderFilters(container) {
		return (
			<FormRow className="table-row">
				<Button onClick={() => history.push('/administrators/new')} bordered={true} className="m-r-s height-search uppercase" >
					<PlusInACircle /> &nbsp;	<p style={{ fontSize: "13px" }}>New Administrator</p>
				</Button>
				<div className="col-5">
					<TextInput placeholder="Search..." onChange={(e) => container.filter(e.value)}
						clearable={true}
						onClear={() => container.filter("")} />
					<CustomIconWithTooltip className="generic_tool_tip--left"
						style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }}
						toolTipText={"You may search by administrator name or email."}
						icon={<InfoIconGray />} />
				</div>
			</FormRow>
		);
	}

	return (
		<Table
			title="Administrators"
			tableData={{
				head: container.state.tableHeaders,
				body: container.state.tableData,
			}}
			filters={renderFilters(container)}
			onColumnSort={(columnIndex) => container.sort(columnIndex)}
			showSpinner={container.state.loadingAdministrators}
		/>
	);
}

function SubscribedAdministrators(props) {
	return <Subscribe to={[AdministratorContainer]}>{(container) => <Administrators {...props} container={container} />}</Subscribe>;
}

export default SubscribedAdministrators;
