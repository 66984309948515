import React from "react";
import { ArrowIcon } from "../../assets/icons";
import RadioInput from "../Form/RadioInput";

export function AlgorithmOption(
	{
		title,
		text,
		clickable = false,
		selected = false,
		onSelectChange,
		onClick = () => { }
	}) {
	return (
		<div
			className={clickable ?
				"algorithm-option-container__clickable algorithm-option-container" :
				"algorithm-option-container"
			}
			onClick={clickable ? (e) => onClick(e) : null}>

			<div className={"algorithm-option-text"}>
				<p>{title}</p>
				<span>{text}</span>
			</div>
			<div className={"algorithm-option-option"}>
				{clickable ?
					<ArrowIcon></ArrowIcon>
					:
					<RadioInput value={selected} onChange={onSelectChange} options={[{ value: selected }]}></RadioInput>
				}
			</div>
		</div>
	);
}
