import moment from "moment";
import { PatientAccountTypes } from "../enums";
import { formatDate } from "../util/dates";

const sortPatientsOldestToNewest = (patients) => {

	const sortFunction = (a, b) => {
		let aDate = moment(a.createdAt);
		let bDate = moment(b.createdAt);
		return bDate.diff(aDate);
	}

	return patients.sort(sortFunction);
};

export function patientsApiToClient(patients) {
	const result = [];

	for (let patient of sortPatientsOldestToNewest(patients)) {
		let agreedToTerms = patient.carer ? patient.carer?.user?.agreedToTerms : patient.agreedToTerms;
		let isEmailVerified = patient.carer ? patient.carer?.user?.isEmailVerified : patient.isEmailVerified;
		let isIDVerified = patient.carer ? patient.carer?.user?.isIDVerified : patient.isIDVerified;

		let isSignedUp = agreedToTerms && isEmailVerified && isIDVerified;
		let isPartiallySignedUp = !isSignedUp && (agreedToTerms || isEmailVerified || isIDVerified);
		let isNotSignedUp = !agreedToTerms && !isEmailVerified && !isIDVerified;

		result.push({
			id: patient.id,
			firstName: patient.firstName,
			lastName: patient.lastName,
			dateOfBirth: formatDate(patient.dateOfBirth, false),
			email: patient.email,
			carer: patient.carer,
			agreedToTerms,
			isEmailVerified,
			isIDVerified,
			createdAt: formatDate(patient.createdAt),
			isSignedUp,
			isPartiallySignedUp,
			isNotSignedUp,
			patientAccountType: determinePatientAccountType(isSignedUp, isPartiallySignedUp, isNotSignedUp),
			clinicIdNumber: patient.clinicIdNumber,


			patientNote: patient.patientNote,
			noteAdmin: patient.noteAdmin,
			adminNoteDateTime: patient.patientNoteDateTime,
		});
	}

	return result;
}

export function patientApiToClient(patient) {
	let agreedToTerms = patient.carer ? patient.carer?.user?.agreedToTerms : patient.user.agreedToTerms;
	let isEmailVerified = patient.carer ? patient.carer?.user?.isEmailVerified : patient.user.isEmailVerified;
	let isIDVerified = patient.carer ? patient.carer?.user?.isIDVerified : patient.user.isIDVerified;

	let isSignedUp = agreedToTerms && isEmailVerified && isIDVerified;
	let isPartiallySignedUp = !isSignedUp && (agreedToTerms || isEmailVerified || isIDVerified);
	let isNotSignedUp = !agreedToTerms && !isEmailVerified && !isIDVerified;

	const result = {
		id: patient.id,
		title: patient.title,
		firstName: patient.firstName,
		lastName: patient.lastName,
		dateOfBirth: formatDate(patient.dateOfBirth, false, undefined, "DD/MM/YYYY"),
		telephoneNo: patient.telephoneNo,
		address1: patient.address1,
		address2: patient.address2,
		zipCode: patient.zipCode,
		clinicIdNumber: patient.clinicIdNumber,
		organisation: patient.organisation,

		patientNote: patient.patientNote,
		noteAdmin: patient.noteAdmin,
		adminNoteDateTime: patient.patientNoteDateTime,

		subscriptionType: patient.subscriptionType,
		subscriptionTier: patient.subscriptionTier,
		subscriptionCreatedAt: formatDate(patient.subscriptionCreatedAt),
		subscriptionUpdatedAt: formatDate(patient.subscriptionUpdatedAt),

		// user data
		email: patient.user?.email,
		secretId: patient.user?.secretId,
		secretExpiry: formatDate(patient.user?.secretIdExpiryDateTime),
		isEmailVerified: patient.user?.isEmailVerified,
		isIDVerified: patient.user?.isIDVerified,
		agreedToTerms: patient.user?.agreedToTerms,

		carer: patient.carer,

		createdAt: formatDate(patient.createdAt),
		updatedAt: formatDate(patient.updatedAt),
		lastLoggedIn: formatDate(patient.user?.lastLoginDateTime),
		userId: patient?.user?.id,
		areEmailNotificationsEnabled: patient?.user?.areEmailNotificationsEnabled,
		areAftercareNotificationsEnabled: patient?.areAftercareNotificationsEnabled,
		isUrgent: patient?.isUrgent,
		isSignedUp,
		isPartiallySignedUp,
		isNotSignedUp,
		patientAccountType: determinePatientAccountType(isSignedUp, isPartiallySignedUp, isNotSignedUp)
	};

	// get associated doctors
	const prescriptions = patient.prescriptions.filter((prescription) => prescription.organisationEmployee);

	let prescribers = prescriptions.map((prescription) => ({
		id: prescription.organisationEmployee.id,
		paperPrescriptionId: prescription.paperPrescriptionId,
		firstName: prescription.organisationEmployee.firstName,
		lastName: prescription.organisationEmployee.lastName,
		prescriptionId: prescription.id,
		prescribedAt: formatDate(prescription.prescribedDateTime)
	}))

	result.prescribers = prescribers.reverse();

	return result;
}

export function patientClientToApi(patient) {
	return {
		id: patient.id,
		email: patient.email,
		password: patient.password,

		title: patient.title,
		firstName: patient.firstName,
		lastName: patient.lastName,
		dateOfBirth: formatDate(patient.dateOfBirth, false, "DD/MM/YYYY", 'MM-DD-YYYY'),
		telephoneNo: patient.telephoneNo,
		address1: patient.address1,
		address2: patient.address2,

		agreedToTerms: patient.agreedToTerms,
		isEmailVerified: patient.isEmailVerified,
		isIDVerified: patient.isIDVerified,

		clinicIdNumber: patient.clinicPatientId,
		t21Patient: patient.t21Patient,
		organisationName: patient.organisationName,
		zipCode: patient.zipCode,
		organisationId: patient.organisationId,

		subscriptionType: patient.subscriptionType,
		subscriptionTier: patient.subscriptionTier,
	};
}

function determinePatientAccountType(isSignedUp, isPartiallySignedUp, isNotSignedUp) {
	if (isSignedUp) {
		return PatientAccountTypes.isSignedUp;
	}
	if (isPartiallySignedUp) {
		return PatientAccountTypes.isPartiallySignedUp;
	}
	if (isNotSignedUp) {
		return PatientAccountTypes.isNotSignedUp;
	}
}
