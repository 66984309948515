import React from "react";

function DownloadIcon({ color = "black" }) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 9.182 0 C 9.619 0 9.91 0.291 9.91 0.727 L 9.91 9.891 L 10.855 8.945 C 11.146 8.655 11.655 8.727 11.873 9.018 C 12.091 9.309 12.091 9.673 11.873 9.964 L 9.691 12.145 C 9.4 12.436 8.964 12.436 8.673 12.145 L 6.491 9.964 C 6.2 9.673 6.2 9.236 6.491 8.945 C 6.782 8.655 7.219 8.655 7.51 8.945 L 8.455 9.891 L 8.455 0.727 C 8.455 0.291 8.746 0 9.182 0 Z"
        fill={color}
      />
      <path
        d="M17.1824 8.72705C16.746 8.72705 16.4551 9.01796 16.4551 9.45432V13.818C16.4551 14.2543 16.1642 14.5452 15.7278 14.5452H2.6369C2.20053 14.5452 1.90962 14.2543 1.90962 13.818V9.45432C1.90962 9.01796 1.61871 8.72705 1.18235 8.72705C0.745987 8.72705 0.455078 9.01796 0.455078 9.45432V13.818C0.455078 15.0543 1.40053 15.9998 2.6369 15.9998H15.7278C16.9642 15.9998 17.9096 15.0543 17.9096 13.818V9.45432C17.9096 9.09069 17.6187 8.72705 17.1824 8.72705Z"
        fill={color}
      />
    </svg>
  );
}

export default DownloadIcon;
