import React from 'react'

const FlagIcon = () => {
    return (
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.778 0.085C12.8462 0.130637 12.9022 0.192369 12.9409 0.264746C12.9796 0.337123 12.9999 0.417918 13 0.5V8C13 8.09985 12.9701 8.19741 12.9141 8.28011C12.8581 8.36281 12.7787 8.42685 12.686 8.464L12.5 8L12.686 8.464L12.683 8.465L12.677 8.468L12.654 8.477C12.5225 8.52925 12.3902 8.57926 12.257 8.627C11.993 8.722 11.626 8.85 11.21 8.977C10.394 9.229 9.331 9.5 8.5 9.5C7.653 9.5 6.952 9.22 6.342 8.975L6.314 8.965C5.68 8.71 5.14 8.5 4.5 8.5C3.8 8.5 2.862 8.73 2.063 8.977C1.70526 9.08849 1.35078 9.21021 1 9.342V15.5C1 15.6326 0.947321 15.7598 0.853553 15.8536C0.759785 15.9473 0.632608 16 0.5 16C0.367392 16 0.240215 15.9473 0.146447 15.8536C0.0526785 15.7598 0 15.6326 0 15.5V0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526784 0.367392 0 0.5 0C0.632608 0 0.759785 0.0526784 0.853553 0.146447C0.947321 0.240215 1 0.367392 1 0.5V0.782C1.226 0.703 1.496 0.612 1.79 0.522C2.606 0.272 3.67 0 4.5 0C5.34 0 6.024 0.277 6.621 0.519L6.664 0.537C7.286 0.788 7.828 1 8.5 1C9.2 1 10.138 0.77 10.937 0.523C11.3923 0.380812 11.8423 0.22204 12.286 0.047L12.305 0.04L12.309 0.038H12.31" fill="#333333" />
        </svg>

    )
}

export default FlagIcon
