import React from "react";
import { OrganisationsTables, PatientsTables } from "../../enums";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";

const QNTRequired = () => {
	return <div style={{ display: 'flex' }}>
		<span style={{ fontSize: "14px" }}>Qnt. required</span>
		<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={"Quantity of this product that is required for orders that are awaiting stock"} icon={<InfoIconGray />} />
	</div>
}

const QNTReserved = () => {
	return <div style={{ display: 'flex' }}>
		<span style={{ fontSize: "14px" }}>QNT. reserved</span>
		<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={"Quantity that is reserved for orders that have not yet been shipped to the patient"} icon={<InfoIconGray />} />
	</div>
}

const QNTAvailable = () => {
	return <div style={{ display: 'flex' }}>
		<span style={{ fontSize: "14px" }}>QNT. available</span>
		<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={"Quantity available on hand that has not been reserved for any orders yet"} icon={<InfoIconGray />} />
	</div>
}

const supplierStatusHeader = () => {
	return <div style={{ display: 'flex' }}>
		<span style={{ fontSize: "14px" }}>Supplier status</span>
		<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={"Product availability at Supplier level"} icon={<InfoIconGray />} />
	</div>
}

const stockStatusHeader = () => {
	return <div style={{ display: 'flex' }}>
		<span style={{ fontSize: "14px" }}>Stock status</span>
		<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={"The product's stock status based on availability at the supplier as well as quantity available at pharmacy"} icon={<InfoIconGray />} />
	</div>
}
export default {
	administrators: [
		{
			text: "Id",
			column: 1,
		},
		{
			text: "Name",
			column: 3,
		},
		{
			text: "Email",
			column: 3,
		},
		{
			text: "Created",
			column: 5,
			right: true,
		},
	],
	carers: [
		{
			text: "Car Id",
			column: 1,
		},
		{
			text: "Name",
			column: 2,
		},
		{
			text: "Email",
			column: 2,
		},
		{
			text: "Patient",
			column: 2,
		},
		{
			text: "Created",
			column: 1,
		},
		{
			text: "Terms Accepted",
			column: 2,
			right: true,
		},
		{
			text: "Verified",
			column: 2,
			right: true,
		},
	],
	prescribers: [
		{
			text: "Name",
			column: 2.5,
		},
		{
			text: "Type",
			column: 2,
		},
		{
			text: "Ref number",
			column: 2.5,
		},
		{
			text: "Organisations",
			column: 3,
		},
		{
			text: "Created",
			column: 2,
			right: true,
		},
	],

	organisationsTableHeaders: {
		[OrganisationsTables.AllOrganisations]: () => [
			{
				text: "Name",
				column: 5,
			},
			{
				text: "Type",
				column: 2,
			},
			{
				text: "prescribers",
				column: 2,
				right: true,
			},
			{
				text: "patients",
				column: 3,
				right: true,
			},
		]
	},

	clinicsEmployeesTableHeaders: {
		[OrganisationsTables.AllOrganisationEmployees]: () => [
			{
				text: "id",
				column: 1,
			},
			{
				text: "name",
				column: 3,
			},
			{
				text: "email",
				column: 3,
			},
			{
				text: "role",
				column: 2,
			},
			{
				text: "Account access",
				column: 1.5,
			},
			{
				text: "actions",
				column: 1.5,
				right: true,
			},
		]
	},

	patientsTableHeaders: {
		[PatientsTables.AllPatients]: () => [
			{
				text: "Pat Id",
				column: 1,
			},
			{
				text: "Clinic Id",
				column: 2,
			},
			{
				text: "Patient",
				column: 3,
			},
			{
				text: "Email",
				column: 3,
			},
			{
				text: "Date of birth",
				column: 1,
				right: true
			},
			{
				text: "Created",
				column: 1,
				right: true
			},
			{
				text: "Account type",
				column: 1,
				right: true,
			},
		],
		[PatientsTables.AwaitingSignUp]: () => [
			{
				text: "Pat Id",
				column: 1,
			},
			{
				text: "Name",
				column: 3,
			},
			{
				text: "Email",
				column: 3,
			},
			{
				text: "Date of birth",
				column: 1,
			},
			{
				text: "Created",
				column: 1,
			},
			{
				text: "Terms accepted",
				column: 1,
				right: true,
			},
			{
				text: "Email verified",
				column: 1,
				right: true,
			},
			{
				text: "Id check passed",
				column: 1,
				right: true,
			},
		],
	},

	accountsWithErrors: [
		{
			text: "First name",
			column: 2,
		},
		{
			text: "Last name",
			column: 2,
		},
		{
			text: "Telephone number",
			column: 2,
		},
		{
			text: "Email",
			column: 2,
		},
		{
			text: "Error type",
			column: 4,
			right: true
		},
	],

	accountsCreated: [
		{
			text: "First name",
			column: 3,
		},
		{
			text: "Last name",
			column: 3,
		},
		{
			text: "Telephone number",
			column: 3,
		},
		{
			text: "Email",
			column: 3,
			right: true
		},
	],

	prescriptionsProcessing: () => [
		{
			text: "Pres ID",
			name: "id",
			column: 1.5,
		},
		{
			text: "Prescription number",
			name: "paperPrescriptionId",
			column: 2,
		},
		{
			text: "Patient",
			name: "patient",
			column: 2,
		},
		{
			text: "Owner",
			name: "owner",
			column: 1.5,
		},
		{
			text: "Prescriber",
			name: "prescriber",
			column: 2,
		},
		{
			text: "Organisation",
			name: "organisation",
			column: 2,
		},
		{
			text: "Submission date",
			name: "submissionDate",
			column: 1,
			right: true,
		},
	],

	prescriptionsReadyForPayment: () => [
		{
			text: "Pres ID",
			name: "id",
			column: 1,
		},
		{
			text: "Prescription number",
			name: "paperPrescriptionId",
			column: 1.5,
		},
		{
			text: "Patient",
			name: "patient",
			column: 1.5,
		},
		{
			text: "Owner",
			name: "owner",
			column: 1.5,
		},
		{
			text: "Prescriber",
			name: "prescriber",
			column: 1.5,
		},
		{
			text: "Organisation",
			name: "organisation",
			column: 2,
		},
		{
			text: "Submission date",
			name: "submissionDate",
			column: 1,
			right: true,
		},
		{
			text: "Expiry date",
			name: "expiryDate",
			column: 1,
			right: true,
		},
		{
			text: "Payment call requested",
			disableSort: true,
			column: 1,
			right: true,
		},
	],

	toBeAssigned: () => [
		{
			text: "Prescription ID",
			column: 2,
		},
		{
			text: "Prescriber",
			column: 2.5,
		},
		{
			text: "Organisation",
			column: 2.5,
		},
		{
			text: "Tracking number",
			column: 2.5,
		},
		{
			text: "Submission date",
			column: 2.5,
			right: true
		},
	],

	awaitingRewrite: () => [
		{
			text: "Prescription ID",
			column: 2,
		},
		{
			text: "Prescription number",
			column: 2,
		},
		{
			text: "Patient",
			column: 2,
		},
		{
			text: "Organisation",
			column: 2,
		},
		{
			text: "Reason",
			column: 2,
		},
		{
			text: "Submitted to clinic",
			column: 2,
			right: true
		},
	],


	futureDatedScripts: () => [
		{
			text: "Prescription ID",
			column: 2,
		},
		{
			text: "Patient",
			column: 3,
		},
		{
			text: "Organisation",
			column: 3,
		},
		{
			text: "Submission date",
			column: 2,
		},
		{
			text: "Date prescribed",
			column: 2,
			right: true
		},
	],

	prescriptions: () => [
		{
			text: "Pres ID",
			name: "id",
			column: 1,
		},
		{
			text: "Prescription number",
			name: "paperPrescriptionId",
			column: 2,
		},
		{
			text: "Patient",
			name: "patient",
			column: 2,
		},

		{
			text: "Owner",
			name: "owner",
			column: 1.5,
		},
		{
			text: "Prescriber",
			name: "prescriber",
			column: 1.5,
		},
		{
			text: "Organisation",
			name: "organisation",
			column: 2,
		},

		{
			text: "Expiry date",
			name: "expiryDate",
			column: 1,
			right: true,
		},
		{
			text: "Submission date",
			name: "submissionDate",
			column: 1,
			right: true,
		}
	],

	awaitingStock: () => [
		{
			text: "Pres ID",
			name: "id",
			column: 1,
		},
		{
			text: "Prescription number",
			name: "paperPrescriptionId",
			column: 2,
		},
		{
			text: "Patient",
			name: "patient",
			column: 2,
		},
		{
			text: "Owner",
			name: "owner",
			column: 2,
		},
		{
			text: "Organisation",
			name: "organisation",
			column: 2,
		},
		{
			text: "Expiry date",
			name: "expiryDate",
			column: 1.5,
			right: true,
		},
		{
			text: "All products available",
			disableSort: true,
			column: 1.5,
			right: true,
		},
	],

	completed: () => [
		{
			text: "Pres ID",
			name: "id",
			column: 1,
		},

		{
			text: "Prescription no",
			name: "paperPrescriptionId",
			column: 1.5,
		},

		{
			text: "Patient",
			name: "patient",
			column: 1.5,
		},

		{
			text: "Owner",
			name: "owner",
			column: 1.5,
		},

		{
			text: "Prescriber",
			name: "prescriber",
			column: 1.5,
		},
		{
			text: "Organisation",
			name: "organisation",
			column: 2,
		},
		{
			text: "Submission date",
			name: "submissionDate",
			column: 1,
			right: true,
		},

		{
			text: "Expiry date",
			name: "expiryDate",
			column: 1,
			right: true,
		},

		{
			text: "Completed date",
			name: "completedDate",
			column: 1,
			right: true,
		},
	],

	archived: () => [
		{
			text: "Pres ID",
			name: "id",
			column: 1,
		},
		{
			text: "Patient",
			name: "patient",
			column: 2,
		},

		{
			text: "Prescriber",
			name: "prescriber",
			column: 2,
		},
		{
			text: "Organisation",
			name: "organisation",
			column: 2,
		},
		{
			text: "Reason",
			name: "reason",
			column: 2,
			left: true,
		},
		{
			text: "Submission date",
			name: "submissionDate",
			column: 1.5,
			right: true,
		},
		{
			text: "Archive date",
			name: "archiveDate",
			column: 1.5,
			right: true,
		},
	],

	prescriptionsEastone: () => [
		{
			text: "Pres ID",
			column: 2,
		},
		{
			text: "Prescription number",
			column: 2,
		},
		{
			text: "Submitted by",
			column: 2,
			right: true,
		},
		{
			text: "Submission date",
			column: 2,
			right: true,
		},
		{
			text: "Expiry date",
			column: 2,
			right: true,
		},
		{
			text: "Paper copy",
			column: 2,
			right: true,
		},
	],
	products: [
		{
			text: "Name",
			name: "name",
			column: 2.5,
		},
		{
			text: "sku",
			name: "sku",
			column: 1.5,
		},
		{
			text: supplierStatusHeader(),
			name: "supplierStatus",
			column: 1.5,
		},
		{
			text: stockStatusHeader(),
			name: "stockStatus",
			column: 2,
		},
		{
			text: QNTRequired(),
			name: "qntRequired",
			column: 1.5,
		},
		{
			text: QNTReserved(),
			name: "qntReserved",
			column: 1.5,
		},
		{
			text: QNTAvailable(),
			name: "qntAvailable",
			column: 1.5,
		},
	],
	productsActiveOrders: [
		{
			text: "Order Created",
			column: 2,
		},
		{
			text: "Patient",
			column: 3,
		},
		{
			text: "Order ID",
			column: 2,
		},
		{
			text: "Status",
			column: 2,
		},
		{
			text: "Amount",
			column: 1
		},
		{
			text: "Prescription",
			column: 2,
			right: true,
		},
	],
	warehouses: [
		{
			text: "Id",
			column: 2,
		},
		{
			text: "Name",
			column: 3,
		},
		{
			text: "Address",
			column: 7,
			right: true,
		},
	],
	associatedPrescribers: [
		{
			text: "Id",
			column: 1,
		},
		{
			text: "Prescription number",
			column: 3,
		},
		{
			text: "Name",
			column: 2,
		},
		{
			text: "Date prescribed",
			column: 4,
			right: true,
		},
		{
			text: "Prescription",
			column: 2,
			right: true,
		},
	],
	patientComments: [
		{
			text: "Text",
			column: 7,
		},
		{
			text: "Admin",
			column: 3,
		},
		{
			text: "Created at",
			column: 2,
			right: true,
		},
	],
	associatedPatients: [
		{
			text: "Id",
			column: 1,
		},
		{
			text: "Name",
			column: 3,
		},
		{
			text: "Organisation",
			column: 3,
		},
		{
			text: "Prescription",
			column: 5,
			right: true,
		},
	],
	associatedOrganisations: [
		{
			text: "Organisation Name",
			column: 3,
		},
		{
			text: "Organisation Type",
			column: 3,
		},
		{
			text: "Role",
			column: 3,
		},
		{
			text: "Account Access",
			column: 1,
		},
		{
			text: "Actions",
			column: 2,
			right: true
		}

	],
	supportComms: [
		{
			text: "Comment",
			column: 3,
		},
		{
			text: "Agent",
			column: 3,
		},
		{
			text: "Type",
			column: 2,
			right: true,
		},
		{
			text: "Status",
			column: 2,
			right: true,
		},
		{
			text: "Time created",
			column: 2,
			right: true,
		},
	],
	eventHistory: [
		{
			text: "Event",
			column: 6,
		},
		{
			text: "Time",
			column: 6,
			right: true,
		},
	],
	orderedProducts: [
		{
			text: "Product",
			column: 3,
		},
		{
			text: "Amount",
			column: 1,
			right: true,
		},
		{
			text: "Price (£)",
			column: 1.5,
			right: true,
		},
		{
			text: "Price T21 (£)",
			column: 1.5,
			right: true,
		},
		{
			text: "Membership Price (£)",
			column: 1.5,
			right: true,
		},
		{
			text: "Status",
			column: 2,
			right: true,
		},
		{
			text: "Actions",
			column: 1.5,
			right: true,
		},
	],
};
