import React, { useState } from 'react'
import MagnifyingGlass from '../../assets/icons/MagnifyingGlass';
import XIconInACircle from '../../assets/icons/XIconInACircle';
import Spinner from '../Spinner/Spinner';
import CustomIconWithTooltip from '../SharedComponents/CustomIconWithTooltip';
import InfoIconGray from '../../assets/icons/InfoIconGray';

const GlobalSearchField = ({
    onChange = () => null,
    Options = () => <></>,
    placeholder = "Quick search",
    onKeyChange = () => null,
    loading = false,
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const handleOnChange = (e) => {
        onChange(e);
        setInputValue(e.target.value);
        setShowOptions(true);
    }

    const handleOpen = () => {
        setShowOptions(true)
        setFocused(true);
    }

    const handleClose = () => {
        setFocused(false);
        setTimeout(() => {
            setShowOptions(false);
        }, 300);
    }

    const handleClear = () => {
        setInputValue("");
        setFocused(true);
    }

    return (
        <div className='global_search_field__container'>
            <div onBlur={() => handleClose()} className={`global_search_field ${focused ? "global_search_field--focused" : ""}`}>
                <label>
                    <div className="global_search_field__icon">
                        <MagnifyingGlass />
                    </div>
                    <input
                        placeholder={placeholder}
                        value={inputValue}
                        onFocus={() => handleOpen()}
                        /*                    onBlur={() => handleClose()}*/
                        onKeyDown={(value) => onKeyChange(value)}
                        className="global_search_field__input"
                        type="text"
                        onChange={(e) => handleOnChange(e)}
                    />
                    {inputValue &&
                        <div className="global_search_field__icon" onClick={() => { handleClear() }} >
                            <XIconInACircle />
                        </div>
                    }
                </label>
                {(showOptions && inputValue.length >= 1) &&
                    <div onClick={() => setShowOptions(false)} className="global_search_field__options">
                        {loading ?
                            <div className='global_search_field__options__option' >
                                <Spinner className='loader--gray m-auto' />
                            </div>
                            :
                            <Options />
                        }
                    </div>
                }
            </div>
            <CustomIconWithTooltip className="generic_tool_tip--left"
                style={{ marginLeft: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left", alignself: "center" }}
                toolTipText={`You may search for: \n\nPrescriptions by Prescription ID or Number \n\nOrders by Order ID \n\nPatients by Patient ID, Clinic ID, Telephone Number or Date of Birth (DD/MM/YYYY)`}
                icon={<InfoIconGray />} />
        </div>
    )
}

export default GlobalSearchField
