import { Container } from "unstated";

import errorHelper from "../util/helpers/errorHelper";
import { containerSort } from "../util/helpers/sorting";
import { Throttle } from "../util/Throttle";
import { formatDateForPresenting } from "../util/dates";
import tableHeaders from "../util/constants/tableHeaders";

import prescriptionService from "../services/PrescriptionsService";
import { OrderStatusesToPhaseMapper, PrescriptionPhases } from "../enums";

class PrescriptionEastoneContainer extends Container {
	constructor() {
		super();

		this.state = {
			tableHeaders: tableHeaders.prescriptionsEastone(),

			tableData: [],
		};

		this.throttle = new Throttle();
	}

	sort = containerSort.bind(this);

	setFilterTimeout = async (queryString) => {
		this.throttle.setTimeout(() => this.filterCallback(queryString), 300);
	};

	filterCallback = async (queryString) => {
		try {
			const result = await prescriptionService.filter(queryString, "readyforpharmacy");

			const tableData = [];

			for (let prescription of result) {
				for (let orderStatus of prescription.orderStatuses) {

					const phases = OrderStatusesToPhaseMapper[orderStatus];

					for (let phase of phases) {

						if (phase === PrescriptionPhases.readyforpharmacy) {
							const row = this._getPrescriptionTableRow(prescription);

							tableData.push(row);
						}
					}

				}

			}

			this.setState({ tableData });
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	_getPrescriptionTableRow(prescription) {
		return [
			{
				text: prescription.id,
				href: `/prescriptions/edit/${prescription.id}`,
				column: 2,
			},
			{
				text: prescription.number || "N/A",
				href: `/prescriptions/edit/${prescription.id}`,
				column: 2,
			},
			{
				text: prescription.submittedBy,
				column: 2,
				right: true,
			},
			{
				text: formatDateForPresenting(prescription.createdAt, false),
				column: 2,
				right: true,
				type: "date",
			},
			{
				text: prescription.expiryDate ? formatDateForPresenting(prescription.expiryDate, false) : "N/A",
				column: 2,
				right: true,
				type: "date",
			},
			{
				text: prescription.isPaperPrescriptionReceived ? "✔" : "",
				column: 2,
				right: true,
			},
		];
	}
}

export default PrescriptionEastoneContainer;
