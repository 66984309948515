import React from 'react'

const ManualSplitOrderIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6673 26.6667V16.5333L8.00065 9.86668V13.3333H5.33398V5.33334H13.334V8.00001H9.86732L17.334 15.4667V26.6667H14.6673ZM19.8007 14.1333L17.8673 12.2L22.134 8.00001H18.6673V5.33334H26.6673V13.3333H24.0007V9.86668L19.8007 14.1333Z" fill="black" />
        </svg>
    )
}

export default ManualSplitOrderIcon
