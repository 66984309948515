import React from "react";
import moment from "moment";

function FlaggedPrescriptionTooltipContent({ prescription }) {

	return (
		<div className={'flagged-prescription-tooltip-content'} >
			<div className={'flagged-prescription-tooltip-content__title'}>
				Flagged - {moment(prescription.flaggedDateTime).format('DD/MM/YYYY')}
			</div>
			<div className={'flagged-prescription-tooltip-content__text'}>
				<span className={"bold"}>{prescription.adminFlaggedBy?.name || "Deleted Admin"}:</span> {prescription.flaggedReason}
			</div>
		</div>
	);
}

export default FlaggedPrescriptionTooltipContent;