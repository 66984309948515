import React from "react";
import TableRow from "../Table/TableRow";
import { SpinnerIcon } from "../../assets/icons";

function findColumnThatIsSorted(headers) {
    for (let i = 0; i < headers.length; i++) {
        const { ascending } = headers[i];

        if (![null, undefined].includes(ascending)) return { ascending, index: i };
    }
}

function PaginatedTable({
    title = "Table data",
    tableData = { head: [], body: [] },
    medium = false,
    small = false,
    adjustable = false,
    filters = null,
    tableHeadingClass = "",
    topContent = null,
    bottomContent = null,
    onItemEdit = null,
    onItemDelete = null,
    onInlineAction = null,
    onColumnSort = null,
    tableHeadingElement = null,
    showSpinner = false,
    titleColumnSize = 12,
    splitOrderBadge = "",
    useitemId = false,

    paginationData = {},
    onPageChange = () => { },
    tableName = "",
}) {
    let sortedColumn = findColumnThatIsSorted(tableData.head);

    function renderTableSizeClass() {
        let classString = "table-size ";

        if (medium) classString += "medium ";
        else if (small) classString += "small ";

        if (!adjustable) classString += "fixed ";

        return classString;
    }

    function renderTableHead() {
        // .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
        const renderRowClass = (item) => `table-col-${item.column * 2} ${item.right ? "right" : ""} ${item.center ? "center" : ""} ${onColumnSort && !item.disableSort ? "clickable" : ""}`;

        return tableData.head.map((item, index) => (
            <th
                key={index}
                onClick={() => {
                    if (onColumnSort && !item.disableSort) onColumnSort(item.name ? item.name : "undefinedTableHeaderName", (sortedColumn?.index === index && sortedColumn?.ascending) ? 0 : 1, index, tableName); //0 stands for asc and 1 for desc sort
                }}
                className={renderRowClass(item)}
                title={typeof item.text === 'string' ? item.text : ""}
            >
                {item.text}{" "}
                {item.right && index === sortedColumn?.index ? (
                    sortedColumn.ascending ? <span>▼</span> : <span>▲</span>
                ) : ""}
                {!item.right && index === sortedColumn?.index ? (
                    sortedColumn.ascending ? <span>▼</span> : <span>▲</span>
                ) : ""}
            </th>
        ));
    }

    // If there is bottomContent (status phases present), order product is cancelled
    // else it is new order, we are talking about so we just remove it from order by index
    function renderTableBody() {
        return tableData.body.map((item, index) => {
            const selectParam = useitemId ? item[0].text : index
            // item[0].text = id of that item

            return (
                <TableRow key={index}
                    onItemEdit={onItemEdit ? () => onItemEdit(selectParam) : null}
                    onItemDelete={bottomContent ? onItemDelete : () => onItemDelete(selectParam)}
                    onInlineAction={onInlineAction}
                    rowData={item}
                />
            )
        }
        );
    }

    function renderPaginationControls() {
        const {
            currentPage,
            hasNextPage,
            hasPreviousPage,
            totalPages
        } = paginationData;

        const pageNeighbors = 2; // determines the number of pages shown around the current page
        const totalNumbers = (pageNeighbors * 2) + 3; // total page numbers shown in the control
        const totalBlocks = totalNumbers + 2; // totalNumbers + 2 to cover for the left(<) and right(>) controls

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbors);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);
            let pages = Array.from({ length: (endPage - startPage) + 1 }, (_, idx) => startPage + idx);

            // Add the first and last page number to the list
            pages = [1, ...pages, totalPages];

            // Add dots for the skipped pages
            const dots = '...';
            if (pages[1] !== 2) {
                pages = [1, dots, ...pages.slice(1)];
            }
            if (pages[pages.length - 2] !== totalPages - 1) {
                pages = [...pages.slice(0, -1), dots, totalPages];
            }

            return (
                <div className="table-pagination-controls">
                    {hasPreviousPage && <button className="button" onClick={() => onPageChange(currentPage - 1, tableName)}>&lt;</button>}
                    {pages.map((page, idx) => (
                        page === dots
                            ? <span key={idx}>{dots}</span>
                            : <button key={idx} className={page === currentPage ? "button button-active" : "button"} onClick={() => onPageChange(page, tableName)}>{page}</button>
                    ))}
                    {hasNextPage && <button className="button" onClick={() => onPageChange(currentPage + 1, tableName)}>&gt;</button>}
                </div>
            );
        } else {
            return (
                <div className="table-pagination-controls">
                    {hasPreviousPage && <button className="button" onClick={() => onPageChange(currentPage - 1, tableName)}>&lt;</button>}
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={(index + 1) === currentPage ? "button button-active" : "button"}
                            onClick={() => onPageChange(index + 1, tableName)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    {hasNextPage && <button className="button" onClick={() => onPageChange(currentPage + 1, tableName)}>&gt;</button>}
                </div>
            );
        }
    }


    const tableDataExists = tableData && tableData.body && tableData.body.length;

    return (
        <div className="card">
            {topContent}

            <div className="heading-filter-container">
                <div className={`col-${titleColumnSize} block`}>
                    <h1 className={`table-heading ${tableHeadingClass}`}>{`${title} (${paginationData.totalCount ? paginationData.totalCount : 0})`}

                    </h1>
                    {splitOrderBadge !== "" && <h1 className="split_orded--badge">{`${splitOrderBadge}`}</h1>}
                    {tableHeadingElement}
                </div>

                {filters}
            </div>

            {paginationData.totalCount > paginationData.pageSize && renderPaginationControls()}
            <table cellSpacing="0" border="0" width="100%">
                <tbody>
                    <tr className="table-header">
                        <td>
                            <div>
                                <table cellSpacing="0" width="100%">
                                    <thead>
                                        <tr>{renderTableHead(tableData.head)}</tr>
                                    </thead>
                                </table>
                            </div>
                        </td>
                    </tr>
                    {showSpinner ?
                        (
                            <tr className="empty-table-disclaimer">
                                <td>
                                    <SpinnerIcon color='#B3B3B3' />
                                </td>
                            </tr>
                        ) : (
                            tableDataExists ? (
                                <tr className="table-body">
                                    <td>
                                        <div className={renderTableSizeClass()}>
                                            <table cellSpacing="0" width="100%">
                                                <tbody>{renderTableBody()}</tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                <tr className="empty-table-disclaimer">
                                    <td>
                                        <span>Table is empty</span>
                                    </td>
                                </tr>

                            )
                        )
                    }
                </tbody>
            </table>

            {bottomContent}
        </div>
    );
}

export default PaginatedTable;
