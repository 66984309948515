import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import PasswordInput from "../../components/Form/PasswordInput";
import TextInput from "../../components/Form/TextInput";
import FormRow from "../../components/Form/FormRow";

import AuthenticationContainer from "../../state/AuthenticationContainer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Login({ container }) {
	// Hook-related data
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const { status } = useParams();
	const history = useHistory();

	return (
		<div className="centered-form">
			<div className="card p-m">
				<FormRow>
					<img src="/assets/images/logo-dark.svg" className="col-7 m-b-s" alt="Lyphe Dispensary logo" />
				</FormRow>
				<FormRow>
					<TextInput
						value={email}
						onChange={(e) => setEmail(e.value)}
						label="Email"
						name="email"
						placeholder="e.g. johndoe@email.com"
						onSubmit={() => container.signin({ email, password })}
					/>
				</FormRow>
				<FormRow>
					<PasswordInput
						value={password}
						onChange={(e) => setPassword(e.value)}
						name="password"
						label="Password"
						placeholder="Your password"
						onSubmit={() => container.signin({ email, password })}
					/>
				</FormRow>

				<span className="credential reset" onClick={() => history.push("/resetpassword")} >Forgot password?</span>
				<br />

				{status && <span className="disclaimer error">Credentials incorrect</span>}

				<FormRow>
					<Button style={{ maxWidth: "100%" }} text="Sign in" onClick={() => container.signin({ email, password })} disabled={container.state.loadingLogin} />
				</FormRow>
			</div>

			<span className="disclaimer">This page is intended for Lyphe Dispensary personnel only!</span>
		</div>
	);
}

function SubscribedLogin(props) {
	return <Subscribe to={[AuthenticationContainer]}>{(container) => <Login {...props} container={container} />}</Subscribe>;
}

export default SubscribedLogin;
