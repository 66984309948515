import React from 'react'

const ExclamationMarkCircle = ({ color = "#E74C3C" }) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5598 1C7.53166 1 1 7.56238 1 15.6286C1 23.6948 7.56238 30.2571 15.6286 30.2571C23.6948 30.2571 30.2571 23.6948 30.2571 15.6286C30.2571 7.56238 23.664 1 15.5598 1ZM17.0914 22.9429H14.1657V20.0171H17.0914V22.9429ZM17.0914 17.0914H14.1657V8.31429H17.0914V17.0914Z" fill={color} />
        </svg>

    )
}

export default ExclamationMarkCircle
