import React from "react";
import Modal from "../Modal/Modal";
import FormRow from "../Form/FormRow";
import Textarea from "../Form/Textarea";
import Button from "../Form/Button";
import Spinner from "../Spinner/Spinner";
import { Subscribe } from "unstated";
import PrescriptionContainer from "../../state/PrescriptionContainer";

function RemoveAwaitingReWriteStatusModal({ container, show, onClose = () => null, handleRemoveAwaitingRewriteStatus = () => null }) {
	const removeAwaitingRewriteStatusStateFormKey = "removeRewriteRequiredStatus";
	const removeAwaitingRewriteStatusState = container.state[removeAwaitingRewriteStatusStateFormKey];
	const onRemoveAwaitingRewriteStatusChange = (event) => container.onFormChange(event, removeAwaitingRewriteStatusStateFormKey);

	const isButtonDisabled = !container.isFormValid(removeAwaitingRewriteStatusStateFormKey) || container.state.removingAwaitingRewriteStatus;

	return (
		<Modal className="modal--assign" isOpen={show} onClose={() => onClose()}>
			<FormRow>
				<h1 className="h4">Remove re-write required status</h1>
			</FormRow>
			<FormRow>
				<div className="bold">Please confirm you would like to remove the re-write required status from this
					prescription and provide a reason.
				</div>
			</FormRow>
			<FormRow>
				<div className="bold">Doing this will remove the prescription from awaiting re-write table.</div>
			</FormRow>
			<FormRow>
				<div className="bold">If this prescription was sent to the clinic by mistake, please let the clinic know.</div>
			</FormRow>
			<Textarea
				name="reason"
				placeholder="Provide a reason"
				value={removeAwaitingRewriteStatusState.reason.value}
				error={removeAwaitingRewriteStatusState.reason.value?.length > 255 ? "Max character limit reached" : ""}
				onChange={onRemoveAwaitingRewriteStatusChange}
				maxLength={255}
			/>
			<FormRow>
				<Button
					className="m-r-xs "
					onClick={() => {
						handleRemoveAwaitingRewriteStatus();
					}}
					disabled={isButtonDisabled}
				>
					{container.state.removingAwaitingRewriteStatus ? <Spinner /> : "Remove"}
				</Button>
				<Button
					className="m-r-xs btn--bordered--no_radius btn--bg-white"
					onClick={() => {
						onClose(false);
					}}
				>
					Cancel
				</Button>
			</FormRow>
		</Modal>
	);
}

function SubscribedRemoveAwaitingReWriteStatusModal(props) {
	return (
		<Subscribe to={[PrescriptionContainer]}>
			{(container) => (
				<RemoveAwaitingReWriteStatusModal {...props} container={container} />
			)}
		</Subscribe>
	);
}

export default SubscribedRemoveAwaitingReWriteStatusModal;