import messages from "./messages";
import lambdas from "./lambdas";

// Facade for everything that will be exported by the module.
const facade = {
	isEnum: function (enumType) {
		return function (value) {
			return {
				valid: lambdas.isEnum(enumType)(value),
				message: messages.isEnum(),
			};
		};
	},
	isPositive: function (value) {
		return {
			valid: lambdas.isPositive(value),
			message: messages.isPositive(),
		};
	},

	isPositiveWithStringConversion: function (value) {
		return {
			valid: lambdas.isPositiveWithStringConversion(value),
			message: messages.isPositive(),
		};
	},

	isTrue: function (value) {
		return {
			valid: lambdas.isTrue(value),
			message: messages.isPositive(),
		};
	},

	isPrescriptionNumber: function (value) {
		return {
			valid: lambdas.isPrescriptionNumber(value),
			message: messages.isPrescriptionNumber(),
		};
	},
	/**
	 * Checks whether value is Falsy, or, if value is a string, whether it's Falsy when trimmed.
	 */
	required: function (value) {
		return {
			valid: lambdas.required(value),
			message: messages.required(),
		};
	},



	/**
	 * Checks whether value is explicitly null.
	 */
	notNull: function (value) {
		return {
			valid: lambdas.notNull(value),
			message: messages.notNull(),
		};
	},

	/**
	 * Checks whether value is explicitly undefined.
	 */
	notUndefined: function (value) {
		return {
			valid: lambdas.notUndefined(value),
			message: messages.notUndefined(),
		};
	},

	/**
	 * Checks whether value is greater than the provided maximum.
	 */
	maxValue: function (maximum) {
		return function (value) {
			return {
				valid: lambdas.maxValue(maximum)(value),
				message: messages.maxValue(maximum),
			};
		};
	},

	/**
	 * Checks whether value is lower than the provided minimum.
	 */
	minValue: function (minimum) {
		return function (value) {
			return {
				valid: lambdas.minValue(minimum)(value),
				message: messages.minValue(minimum),
			};
		};
	},

	/**
	 * Checks whether value is shorter in length than provided maximum.
	 */
	maxLength: function (maximumCharacters) {
		return function (value) {
			return {
				valid: lambdas.maxLength(maximumCharacters)(value),
				message: messages.maxLength(maximumCharacters),
			};
		};
	},

	/**
	 * Checks whether phone value is shorter in length than provided maximum but removes the + sign.
	 */
	maxLengthPhone: function (maximumCharacters) {
		return function (value) {
			let newValue = value;

			if (value.includes('+')) {
				newValue = value.slice(3);
			}

			return {
				valid: lambdas.maxLength(maximumCharacters)(newValue),
				message: messages.maxLength(maximumCharacters),
			};
		};
	},

	/**
	 * Checks whether value is longer in length than provided minimum.
	 */
	minLength: function (minimumCharacters) {
		return function (value) {
			return {
				valid: lambdas.minLength(minimumCharacters)(value),
				message: messages.minLength(minimumCharacters),
			};
		};
	},
	/**
	 * Checks whether phone value is longer in length than provided minimum but removes the + sign.
	 */
	minLengthPhone: function (minimumCharacters) {
		return function (value) {
			let newValue = value;

			if (value.includes('+')) {
				newValue = value.slice(3);
			}

			return {
				valid: lambdas.minLength(minimumCharacters)(newValue),
				message: messages.minLength(minimumCharacters),
			};
		};
	},

	/**
	 * Checks whether value is a member of the provided values list.
	 */
	isValue: function (values) {
		return function (value) {
			return {
				valid: lambdas.isValue(values)(value),
				message: messages.isValue(values),
			};
		};
	},

	/**
	 * Checks whether value is a valid e-mail address.
	 */
	isEmail: function (value) {
		return {
			valid: lambdas.isEmail(value),
			message: messages.isEmail(),
		};
	},

	doesPasswordContainCapital: function (value) {
		return {
			valid: lambdas.doesPasswordContainCapital(value),
			message: messages.doesPasswordContainCapital(),
		};
	},
	doesPasswordContainNumber: function (value) {
		return {
			valid: lambdas.doesPasswordContainNumber(value),
			message: messages.doesPasswordContainNumber(),
		};
	},
	doesPasswordContainSymbol: function (value) {
		return {
			valid: lambdas.doesPasswordContainSymbol(value),
			message: messages.doesPasswordContainSymbol(),
		};
	},

	/**
	 * Checks whether value is only letters with only spaces allowed as other characters
	 */
	isLettersAndSpaces: function (value) {
		return {
			valid: lambdas.isLettersAndSpaces(value),
			message: messages.isLettersAndSpaces(),
		};
	},

	/**
	 * Checks whether value is only letters with only spaces allowed as other characters
	 */
	isLettersSpacesAndHyphens: function (value) {
		return {
			valid: lambdas.isLettersSpacesAndHyphens(value),
			message: messages.isLettersSpacesAndHyphens(),
		};
	},
	/**
	 * Checks if the date provided has a four digit year format
	 */
	isDateWithfourDigitYear: function (value) {
		return {
			valid: lambdas.isDateWithFourDigitYear(value),
			message: messages.isDateWithfourDigitYear(),
		};
	},

	/**
	 * Checks whether value is explicitly numeric.
	 */
	isNumeric: function (value) {
		if (!value) return {
			valid: true,
		};

		return {
			valid: lambdas.isNumeric(value),
			message: messages.isNumeric(),
		};
	},

	/**
	 * Checks whether value is in a valid time format.
	 */
	isTime: function (value) {
		return {
			valid: lambdas.isTime(value),
			message: messages.isTime(),
		};
	},

	/**
	 * Checks whether value is in a valid date format.
	 */
	isDate: function (value) {
		return {
			valid: lambdas.isDate(value),
			message: messages.isDate(),
		};
	},

	/**
	 * Check if date is in DD/MM/YYY format.
	 */
	isDateInEuroFormat: function (value) {
		return {
			// eslint-disable-next-line 
			valid: lambdas.customRegex(/(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/)(value),
			message: messages.isDate(),
		};
	},

	/**
	 * Check if phone number is the correct format.
	 */

	/**
	 * Check if provided date is over 18 years old, date must be in "DD/MM/YYY" format
	 */
	isOver18: function (value) {
		return {
			// eslint-disable-next-line
			valid: lambdas.isOver18(value),
			message: messages.isOver18(),
		};
	},

	/**
	 * Check if phone number is the correct format.
	 */
	isPhoneNumber: function (value) {
		return {
			valid: lambdas.customRegex(/^[\d+]+$/)(value),
			message: messages.isPhoneNumber(),
		};
	},

	/**
	 * Check if post code is the correct format.
	 */
	isPostCode: function (value) {
		return {
			valid: lambdas.customRegex(/^[A-Za-z0-9]+( [A-Za-z0-9]+)*$/)(value),
			message: messages.isPostCode(),
		};
	},

	/**
	 * Check if post code is the correct format.
	 */
	isNotJerseyGuernsey: function (value) {
		return {
			valid: lambdas.isNotJerseyGuernsey(value),
			message: messages.isNotJerseyGuernsey(),
		};
	},

	/**
	 * Checks whether value is lower than the provided minimum.
	 */
	isUrl: function (value) {
		return {
			valid: lambdas.isUrl(value),
			message: messages.isUrl(),
		};
	},

	/**
	 * Checks whether value matches the provided regex.
	 */
	customRegex: function (regex) {
		return function (value) {
			return {
				valid: lambdas.customRegex(regex)(value),
				message: messages.customRegex(),
			};
		};
	},

	/**
	 * Utility function that checks whether the provided form is valid.
	 */
	isFormValid: function (form, validators = []) {
		let isValid = true;

		for (const key in form) {
			if (form[key].error) {
				isValid = false;

				break;
			} else {
				for (let i in validators[key]) {
					const result = validators[key][i](form[key].value);

					if (!result.valid) {
						isValid = false;

						break;
					}
				}
			}
		}

		return isValid;
	},
};

// Validation methods export.
export const {
	isEnum,
	isPositive,
	isPositiveWithStringConversion,
	required,
	notNull,
	notUndefined,
	maxValue,
	minValue,
	maxLength,
	minLength,
	isValue,
	isEmail,
	isNumeric,
	isTime,
	isDate,
	isUrl,
	customRegex,
	isFormValid,
	isDateInEuroFormat,
	isPhoneNumber,
	isPostCode,
	isLettersAndSpaces,
	isLettersSpacesAndHyphens,
	maxLengthPhone,
	minLengthPhone,
	isTrue,
	isNotJerseyGuernsey,
	isDateWithfourDigitYear,
	isOver18,
	doesPasswordContainCapital,
	doesPasswordContainNumber,
	doesPasswordContainSymbol,
} = facade;

// Default export to supress warnings.
export default {
	required: facade.required,
	notNull: facade.notNull,
	notUndefined: facade.notUndefined,
	maxValue: facade.maxValue,
	minValue: facade.minValue,
	maxLength: facade.maxLength,
	minLength: facade.minLength,
	isValue: facade.isValue,
	isEmail: facade.isEmail,
	isNumeric: facade.isNumeric,
	isTime: facade.isTime,
	isDate: facade.isDate,
	isUrl: facade.isUrl,
	customRegex: facade.customRegex,
	isFormValid: facade.isFormValid,
	isDateInEuroFormat: facade.isDateInEuroFormat,
	isPhoneNumber: facade.isPhoneNumber,
	isPostCode: facade.isPostCode,
	isLettersAndSpaces: facade.isLettersAndSpaces,
	maxLengthPhone: facade.maxLengthPhone,
	minLengthPhone: facade.minLengthPhone,
	isTrue: facade.isTrue,
	isLettersSpacesAndHyphens: facade.isLettersSpacesAndHyphens,
	isNotJerseyGuernsey: facade.isNotJerseyGuernsey,
	isOver18: facade.isOver18,
	doesPasswordContainCapital: facade.doesPasswordContainCapital,
	doesPasswordContainNumber: facade.doesPasswordContainNumber,
	doesPasswordContainSymbol: facade.doesPasswordContainSymbol,
};

// Export for imported packages (used for custom lambdas and messages).
export { default as messages } from "./messages";
export { default as lambdas } from "./lambdas";
