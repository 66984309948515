import React, { useEffect } from "react";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import Checkbox from "../../components/Form/Checkbox";
import PasswordInput from "../../components/Form/PasswordInput";
import TextInput from "../../components/Form/TextInput";
import FormRow from "../../components/Form/FormRow";

import AdministratorContainer from "../../state/AdministratorContainer";
import SquareCheckbox from "../../components/Form/SquareCheckbox";

function NewAdministrator({ container }) {
	// State-related data
	const formKey = "newAdministrator";
	const state = container.state[formKey];
	const onFieldChange = (event) => container.onFormChange(event, formKey);

	useEffect(() => container.resetNewAdministratorState, [container.resetNewAdministratorState]);

	return (
		<div className="card p-m">
			<FormRow>
				<h1 className="h4">Personal info</h1>
			</FormRow>

			<FormRow>
				<div className="col-6">
					<TextInput
						value={state.name.value}
						error={state.name.error}
						onChange={onFieldChange}
						name="name"
						label="Full name"
						placeholder="e.g. John Doe"
					/>
				</div>
				<div className="col-6">
					<TextInput
						value={state.email.value}
						error={state.email.error}
						onChange={onFieldChange}
						name="email"
						label="Email"
						placeholder="e.g. johndoe@email.com"
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Password</h2>
			</FormRow>
			<FormRow>
				<div className="col-6">
					<PasswordInput
						value={state.password.value}
						error={state.password.error}
						onChange={onFieldChange}
						name="password"
						label="Password"
						placeholder="Password"
					/>
				</div>
				<div className="col-6">
					<PasswordInput
						value={state.repeatPassword.value}
						error={state.repeatPassword.error}
						onChange={onFieldChange}
						name="repeatPassword"
						label="Repeat password"
						placeholder="Repeat password"
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Permissions</h2>
			</FormRow>
			<FormRow>
				<SquareCheckbox
					value={state.canAccessUserData.value}
					onChange={onFieldChange}
					name="canAccessUserData"
					label="Can access User data (patients, carers, doctors)"
					className="bold_text"
				/>
			</FormRow>
			<FormRow>
				<SquareCheckbox
					value={state.canAccessAdministratorData.value}
					onChange={onFieldChange}
					name="canAccessAdministratorData"
					label="Can access Administrator data and edit Product data"
					className="bold_text"
				/>
			</FormRow>

			<FormRow>
				<Button text="Save" onClick={container.create} disabled={!container.isFormValid(formKey) || container.state.creatingAdmin} />
			</FormRow>
		</div>
	);
}

function SubscribedNewAdministrator(props) {
	return <Subscribe to={[AdministratorContainer]}>{(container) => <NewAdministrator {...props} container={container} />}</Subscribe>;
}

export default SubscribedNewAdministrator;
