import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";
import browserHelper from "../util/helpers/browserHelper";
import errorHelper from "../util/helpers/errorHelper";
import { required, isFormValid, isEmail } from "../util/validation/validation";
import { Throttle } from "../util/Throttle";
import tableHeaders from "../util/constants/tableHeaders";
import { sortTableDataAndUpdateHeaders } from "../util/helpers/sorting";
import { OrganisationsTables, OrganisationType, OrganisationEmployeeType, OrganisationTypeMapper, OrganisationEmployeeTypeForEditModal, OrganisationEmployeeTypeForNewIPModal, PrescriberType, BannerTypes, BannerValues } from "../enums";
import organisationsService from '../services/OrganisationsService'
import axios from "axios";
import PrescribersService from "../services/PrescribersService";

const newOrganisationInitialState = {
	name: { value: "" },
	referenceNumber: { value: "" },
	email: { value: "" },
	phone: { value: "" },
	address1: { value: "" },
	postCode: { value: "" },
	address2: { value: "" },
	country: { value: "" },
	emailRewrite: { value: "" },
	employeeFirstName: { value: "" },
	employeeLastName: { value: "" },
	employeeEmail: { value: "" },
	employeePassword: { value: "" },
	employeeRepeatPassword: { value: "" },
	organisationSearchString: { value: "" },
	organisationType: { value: "" },

	//IP State
	prescriberType: { value: "" },
	prescriberIdCode: { value: "" },
	bannerType: { value: "" },
	bannerText: { value: "" },

	isFieldDisabled: { value: false },
	isButtonDisabled: { value: false }
};

const updateOrganisationInitialState = {
	name: { value: "" },
	email: { value: "" },
	phone: { value: "" },
	address1: { value: "" },
	postCode: { value: "" },
	address2: { value: "" },
	country: { value: "" },
	emailRewrite: { value: "" },
	employeeFirstName: { value: "" },
	employeeLastName: { value: "" },
	employeeEmail: { value: "" },
	employeePassword: { value: "" },
	employeeRepeatPassword: { value: "" },
	type: { value: "" },
	referenceNumber: { value: "" }
};


const newEmployeeForOrganisationState = {
	firstName: { value: "" },
	lastName: { value: "" },
	email: { value: "" },
	password: { value: "" },
	repeatPassword: { value: "" },
	organisationId: { value: "" },
	role: [{ label: "", value: "" }],

	prescriberIdCode: { value: "" },
	hasAccountAccess: { value: false }
}

const updateEmployeeForOrganisationState = {
	firstName: { value: "" },
	lastName: { value: "" },
	email: { value: "" },
	password: { value: "" },
	repeatPassword: { value: "" },
	organisationId: { value: "" },
	id: { value: "" },
	hasAccountAccess: { value: false },

	role: Object.values(OrganisationEmployeeType).map((e, index) => {
		return {
			label: e,
			value: index
		}
	}),
}

const deleteEmployeeForOrganisationState = {
	name: { value: "" },
	id: { value: "" },
	role: { value: "" }

}

class OrganisationsContainer extends Container {
	//#region State

	constructor() {
		super();

		this.state = {
			tableHeaders: {
				[OrganisationsTables.AllOrganisations]: tableHeaders.organisationsTableHeaders[OrganisationsTables.AllOrganisations](),
				[OrganisationsTables.AllOrganisationEmployees]: tableHeaders.clinicsEmployeesTableHeaders[OrganisationsTables.AllOrganisationEmployees](),
			},

			tableData: {
				[OrganisationsTables.AllOrganisations]: []
			},
			newOrganisation: { ...newOrganisationInitialState },
			updateOrganisation: { ...updateOrganisationInitialState },
			organisationTypes: Object.keys(OrganisationType).map((e, index) => {
				return {
					label: OrganisationTypeMapper[index],
					value: index
				}
			}),

			prescriberTypes: Object.values(PrescriberType).map((e, index) => {
				return {
					label: e,
					value: index
				}
			}),

			organisationEmployeeTypes: Object.values(OrganisationEmployeeType).map((e, index) => {
				return {
					label: e,
					value: index
				}
			}),

			organisationEmployeeTypesForEditModal: Object.values(OrganisationEmployeeTypeForEditModal).map((e, index) => {
				return {
					label: e,
					value: index
				}
			}),

			organisationEmployeeTypesForNewIPModal: Object.values(OrganisationEmployeeTypeForNewIPModal).map((e, index) => {
				return {
					label: e,
					value: index
				}
			}),

			newEmployeeForOrganisation: { ...newEmployeeForOrganisationState },
			editEmployeeForOrganisation: { ...updateEmployeeForOrganisationState },
			deleteEmployeeForOrganisation: { ...deleteEmployeeForOrganisationState },

			// loading flags
			loadingAllOrganisations: false,
			creatingOrganisation: false,
			updatingOrganisation: false,
			deletingOrganisation: false,
			loadingOrganisation: false,
			loadingOrganisationNames: false,
			creatingNewEmployeeForOrganisation: false,
			updatingEmployeeForOrganisation: false,
			deletingOrganisationEmployee: false,

			searchingForGmcNumber: false,
			setShowEditEmployeeForOrganisationModal: false,

			searchButtonClicked: true
		};

		this.validators = {
			newOrganisation: {
				name: [required],
				email: [required, isEmail],
				phone: [required],
				address1: [required],
				postCode: [required],
				address2: [required],
				emailRewrite: [required, isEmail],
				employeeFirstName: [required],
				employeeLastName: { required },
				employeeEmail: [required, isEmail],
				employeePassword: [required],
				employeeRepeatPassword: [required],
				organisationType: [required]
			},
			newOrganisationIP: {
				email: [required, isEmail],
				phone: [required],
				address1: [required],
				postCode: [required],
				address2: [required],
				emailRewrite: [required, isEmail],
				employeeFirstName: [required],
				employeeLastName: { required },
				organisationType: [required]
			},
			updateOrganisation: {
				name: [required],
				email: [required, isEmail],
				phone: [required],
				address1: [required],
				postCode: [required],
				address2: [required],
				emailRewrite: [required, isEmail],
				employeeFirstName: [required],
				employeeLastName: { required },
				employeeEmail: [required, isEmail],
			},
			newEmployeeForOrganisation: {
				name: [required],
				email: [required, isEmail],
				password: [required],
				repeatPassword: [required],
			},

			editEmployeeForOrganisation: {
				firstName: [required],
				lastName: [required],
				email: [required, isEmail],
				password: [required],
				repeatPassword: [required],
			}

		};

		this.throttle = new Throttle();
	}



	//#endregion

	//#region Util functions for inputs

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}

		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}
		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onSelectChange = (e, formKey) => {
		if (e.value[0] === "true" || e.value[0] === "false") {
			e.value[0] = JSON.parse(e.value[0]);
		}

		const newField = { value: e.value[0], error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	isFormValid = (formKey, stateFormKey) => {
		if (stateFormKey) {
			return isFormValid(this.state[stateFormKey], this.validators[formKey]);
		}

		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	//#endregion

	//#region Table data

	sort = (columnIndex, tableEnum) => {
		if ([undefined, null].includes(tableEnum)) return;

		const headers = this.state.tableHeaders[tableEnum];
		const body = this.state.tableData[tableEnum];

		sortTableDataAndUpdateHeaders(headers, body, columnIndex);

		const tableHeaders = { ...this.state.tableHeaders, [tableEnum]: headers };
		const tableData = { ...this.state.tableData, [tableEnum]: body };

		this.setState({ tableHeaders, tableData });
	};

	getAllOrganisations = async () => {
		this.setState({ loadingAllOrganisations: true });
		try {
			const organisations = await organisationsService.filter("");
			const allOrganisationTableRows = organisations.map((organisation) => {
				return this._constructTableRowForAllOrganisationTable(organisation);
			})

			this.setState({
				tableData: Object.assign(this.state.tableData, { [OrganisationsTables.AllOrganisations]: allOrganisationTableRows })
			})

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ loadingAllOrganisations: false });
	}

	_constructTableRowForAllOrganisationTable = (organisation) => {
		return [
			{
				text: organisation.name,
				href: `/organisations/edit/${organisation.id}`,
				column: 5,
			},
			{
				text: OrganisationTypeMapper[organisation.type],
				column: 2,
			},
			{
				text: organisation.numberOfPrescribers,
				column: 2,
				right: true,
			},
			{
				text: organisation.numberOfPatients,
				column: 3,
				right: true,
			},
		]
	}

	getAllEmployeesFromOrganisation = async (id) => {
		this.setState({ loadingAllOrganisationEmployees: true });
		try {
			const organisationEmployees = await organisationsService.getAllOrganisationEmployeesFromOrganisation(id);
			const prescribers = await PrescribersService.filter('');

			const allOrganisationEmployeesTableRows = organisationEmployees.map((employee) => {
				return this._constructTableRowForAllOrganisationEmployeesTable(employee);
			})
			const organisationEmployeesForDropdownSearch = prescribers.map((employee) => {
				return {
					label: `${employee.firstName} ${employee.lastName}`,
					value: employee.organisationEmployee.id,
					email: employee.organisationEmployee.user.email,
					firstName: employee.organisationEmployee.firstName,
					lastName: employee.organisationEmployee.lastName,
					password: employee.organisationEmployee.user.password,
					repeatPassword: employee.organisationEmployee.user.password,
					prescriberIdCode: employee.prescriberIdCode ? employee.prescriberIdCode : 'not-a-prescriber',
					hasAccountAccess: employee.hasAccountAccess
				}
			})

			this.setState({
				tableData: Object.assign(this.state.tableData, { [OrganisationsTables.AllOrganisationEmployees]: allOrganisationEmployeesTableRows }),
				organisationEmployeesForDropdownSearch: organisationEmployeesForDropdownSearch
			})

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ loadingAllOrganisationEmployees: false });
	}

	_constructTableRowForAllOrganisationEmployeesTable = (organisationEmployee) => {
		return [
			{
				text: organisationEmployee.id,
				column: 1,
			},
			{
				text: `${organisationEmployee.firstName} ${organisationEmployee.lastName}`,
				column: 3,
			},
			{
				text: organisationEmployee.user.email,
				column: 3,
			},
			{

				text: OrganisationEmployeeType[organisationEmployee.organisationUser.employeeType],
				column: 2,
			},
			{
				text: organisationEmployee.organisationUser.hasAccountAccess === false ? "No" : "Yes",
				column: 1.5,
			},
			{
				column: 1.5,
				right: true,
				actions: true,
			},
		]
	}

	createOrganisation = async () => {
		this.setState({ creatingOrganisation: true });
		const createOrganisationState = this.state.newOrganisation;
		const payload = {
			name: createOrganisationState.organisationType.value.value === 0 ? createOrganisationState.name.value : createOrganisationState.employeeFirstName.value + ' ' + createOrganisationState.employeeLastName.value,
			telephone: createOrganisationState.phone.value,
			address1: createOrganisationState.address1.value,
			address2: createOrganisationState.address2.value,
			postCode: createOrganisationState.postCode.value,
			email: createOrganisationState.email.value,
			rewritesEmail: createOrganisationState.emailRewrite.value,
			accountFirstName: createOrganisationState.employeeFirstName.value,
			accountLastName: createOrganisationState.employeeLastName.value,
			accountEmail: createOrganisationState.organisationType.value.value === 0 ? createOrganisationState.employeeEmail.value : createOrganisationState.email.value,
			password: createOrganisationState.employeePassword.value,
			type: createOrganisationState.organisationType.value.value,
			prescriberType: createOrganisationState.prescriberType.value.value,

			prescriberIdCode: createOrganisationState.prescriberIdCode.value,
			referenceNumber: createOrganisationState.organisationType.value.value === 0 ? createOrganisationState.referenceNumber.value : createOrganisationState.prescriberIdCode.value,

		}

		try {
			const organisation = await organisationsService.createOrganisation(payload);
			notificationHelper.info("Organisation created successfully")
			browserHelper.navigate(`cinics/edit/${organisation.id}`)

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ creatingOrganisation: false });
	}

	filter = (queryString, tableEnum) => {
		this.throttle.setTimeout(() => this._filterCallback(queryString, tableEnum), 300);
	};

	_filterCallback = async (queryString = "") => {
		this.setState({ loadingAllOrganisations: true });
		try {
			const organisations = await organisationsService.filter(queryString);
			const allOrganisationTableRows = organisations.map((organisation) => {
				return this._constructTableRowForAllOrganisationTable(organisation);
			})

			this.setState({
				tableData: Object.assign(this.state.tableData, { [OrganisationsTables.AllOrganisations]: allOrganisationTableRows })
			})
		} catch (error) {
			errorHelper.handleError(error);
		}

		finally {
			this.setState({ loadingAllOrganisations: false });
		}

	};

	serchForPrescribersByGMC = async () => {
		this.setState({ searchingForGmcNumber: true });
		try {
			const searchResults = await organisationsService.getPrescribersByGMC(this.state.newOrganisation.prescriberIdCode.value)
			this.setState({
				newOrganisation: {
					...this.state.newOrganisation,
					email: { value: searchResults.organisationEmployee.user.email || '' },
					phone: { value: searchResults.organisationEmployee.user.phone || '' },
					address1: { value: searchResults.organisationEmployee.user.address1 || '' },
					postCode: { value: searchResults.organisationEmployee.user.postCode || '' },
					address2: { value: searchResults.organisationEmployee.user.address2 || '' },
					employeeFirstName: { value: searchResults.organisationEmployee.firstName || '' },
					employeeLastName: { value: searchResults.organisationEmployee.lastName || '' },
					bannerText: { value: `The number matches an existing Prescriber ${searchResults.organisationEmployee.firstName + searchResults.organisationEmployee.lastName}. Any scripts uploaded by this user from their account will be assigned to them.` },
					bannerType: { value: BannerTypes.Info },

					name: { value: searchResults.organisationEmployee.firstName + searchResults.organisationEmployee.lastName },
					referenceNumber: { value: searchResults.prescriberIdCode },
					employeeEmail: { value: searchResults.organisationEmployee.user.email || '' },
					isFieldDisabled: { value: true },
					isButtonDisabled: { value: false }
				}
			})
		} catch (error) {
			let stateWithoutFieldDisabled = {
				...this.state.newOrganisation,
				bannerText: { value: BannerValues[error.response.status].text },
				bannerType: { value: BannerValues[error.response.status].type },
				organisationType: this.state.newOrganisation.organisationType,
				prescriberType: this.state.newOrganisation.prescriberType,
				prescriberIdCode: this.state.newOrganisation.prescriberIdCode,
			}
			if (error.response.status === 400) {
				this.setState({
					newOrganisation: {
						...stateWithoutFieldDisabled,
						isFieldDisabled: { value: false },
						isButtonDisabled: { value: true }
					}
				})
			} else {
				this.setState({
					newOrganisation: {
						...stateWithoutFieldDisabled,
						isFieldDisabled: { value: false },
						isButtonDisabled: { value: false }

					}
				})
			}

		} finally {
			this.setState({ searchingForGmcNumber: false });
			this.setState({ searchButtonClicked: false })
		}
	}

	updateOrganisation = async () => {
		this.setState({ updatingOrganisation: true });
		try {
			const payload = {
				id: this.state.updateOrganisation.id.value,
				name: this.state.updateOrganisation.name.value,
				telephone: this.state.updateOrganisation.phone.value,
				address1: this.state.updateOrganisation.address1.value,
				address2: this.state.updateOrganisation.address2.value,
				postCode: this.state.updateOrganisation.postCode.value,
				email: this.state.updateOrganisation.email.value,
				rewritesEmail: this.state.updateOrganisation.emailRewrite.value,
				type: this.state.updateOrganisation.type.value
			}

			await organisationsService.updateOrganisation(payload);
			notificationHelper.info("Organisation updated successfully");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingOrganisation: false });
	}

	setForUpdate = async (id) => {
		this.setState({ loadingOrganisation: true });
		try {
			const organisation = await organisationsService.getOrganisationById(id);
			const newUpdateOrganisationState = {
				name: { value: organisation.name },
				email: { value: organisation.email },
				phone: { value: organisation.telephoneNo },
				address1: { value: organisation.address1 },
				address2: { value: organisation.address2 },
				postCode: { value: organisation.zipCode },
				country: { value: "" },
				emailRewrite: { value: organisation.rewritesEmail },
				employeePassword: { value: "" },
				employeeRepeatPassword: { value: "" },
				id: { value: organisation.id },
				type: { value: organisation.type },
				referenceNumber: { value: organisation.referenceNumber }
			}
			this.setState({ updateOrganisation: newUpdateOrganisationState })

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ loadingOrganisation: false });
	}

	resetCreateOrganisationState = () => {
		this.setState({
			newOrganisation: {
				...newOrganisationInitialState,
				organisationType: { value: this.state.newOrganisation.organisationType.value },
				prescriberType: { value: this.state.newOrganisation.prescriberType.value }
			},
		});
	}

	resetUpdateOrganisationState = () => {
		this.setState({ updateOrganisation: { ...updateOrganisationInitialState } });
	}

	getOrganisationNames = async (queryString = "") => {
		try {
			if (queryString.length > 4) {
				const cancelToken = axios.CancelToken.source();
				if (this.state.cancelToken) {
					this.state.cancelToken.cancel("Cancelling this request");
				}
				this.setState({ cancelToken: cancelToken })

				this.setState({ loadingOrganisationNames: true })


				const allOrganisationNames = await organisationsService.getOrganisationNames(queryString, cancelToken);

				this.setState({ allOrganisationNames })
				if (allOrganisationNames.length) {
					notificationHelper.info("Organisations found. Please select the Organisation")
				} else {
					notificationHelper.error("No organisations found")
				}

			}
		} catch (e) {

		}

		this.setState({ loadingOrganisationNames: false })

	}

	createNewEmployeeForOrganisation = async (hasAccountAccess) => {
		this.setState({ creatingNewEmployeeForOrganisation: true });

		const createNewEmployeeForOrganisationState = this.state.newEmployeeForOrganisation;
		const payload = {
			firstName: createNewEmployeeForOrganisationState.firstName.value,
			lastName: createNewEmployeeForOrganisationState.lastName.value,
			email: createNewEmployeeForOrganisationState.email.value,
			password: createNewEmployeeForOrganisationState.password.value,
			repeatPassword: createNewEmployeeForOrganisationState.repeatPassword.value,
			organisationId: createNewEmployeeForOrganisationState.organisationId.value,
			role: createNewEmployeeForOrganisationState.role.value ? createNewEmployeeForOrganisationState.role.value.value : 1,
			//value of 1 is organisation admin role
			prescriberIdCode: createNewEmployeeForOrganisationState.prescriberIdCode.value,
			hasAccountAccess
		}

		try {
			await organisationsService.createOrganisationEmployee(payload);
			await this.getAllEmployeesFromOrganisation(payload.organisationId)
			notificationHelper.info("New organisation employee added")

		} catch (error) {
			errorHelper.handleError(error);
		}
		finally {
			this.state.newEmployeeForOrganisation.firstName.value = ""
			this.state.newEmployeeForOrganisation.lastName.value = ""
			this.state.newEmployeeForOrganisation.email.value = ""
			this.state.newEmployeeForOrganisation.password.value = ""
			this.state.newEmployeeForOrganisation.repeatPassword.value = ""
			this.setState({ creatingNewEmployeeForOrganisation: false });
		}
	}

	updateForOrganisationEmployees = async (id, role, hasAccountAccess) => {
		this.setState({ updatingEmployeeForOrganisation: true });
		this.setState({ setShowEditEmployeeForOrganisationModal: true });
		try {

			const index = Object.values(OrganisationEmployeeType).indexOf(role.label);

			const key = Object.keys(OrganisationEmployeeType)[index];
			const payload = {
				firstName: this.state.editEmployeeForOrganisation.firstName.value,
				lastName: this.state.editEmployeeForOrganisation.lastName.value,
				email: this.state.editEmployeeForOrganisation.email.value,
				password: this.state.editEmployeeForOrganisation.password.value,
				organisationId: this.state.editEmployeeForOrganisation.organisationId.value,
				employeeType: ~~key,
				id: id,
				hasAccountAccess: hasAccountAccess
			}

			await organisationsService.updateOrganisationEmployee(payload);
			await this.getAllEmployeesFromOrganisation(payload.organisationId)
			notificationHelper.info("Organisation employee details updated");

			this.setState({ setShowEditEmployeeForOrganisationModal: false });
		} catch (error) {
			errorHelper.handleError(error);
		}
		finally {
			this.resetOrganisationEmployeeState();
			this.setState({ updatingEmployeeForOrganisation: false });
		}
	}

	resetOrganisationEmployeeState = () => {
		this.state.editEmployeeForOrganisation.firstName.value = "";
		this.state.editEmployeeForOrganisation.lastName.value = "";
		this.state.editEmployeeForOrganisation.email.value = "";
		this.state.editEmployeeForOrganisation.password.value = "";
		this.state.editEmployeeForOrganisation.repeatPassword.value = "";
		this.state.editEmployeeForOrganisation.organisationId.value = "";
		this.setState({ editEmployeeForOrganisation: { ...this.state.editEmployeeForOrganisation, hasAccountAccess: false } });
	}

	setOrganisationEmployeeForUpdate = async (id, employeeId = -1) => {

		this.setState({ updatingEmployeeForOrganisation: true });
		try {
			const organisationEmployees = await organisationsService.getAllOrganisationEmployeesFromOrganisation(id);

			let organisationEmployee;

			organisationEmployee = organisationEmployees.find(e => e.id === employeeId);

			const mapOrganisationEmployee = {
				firstName: { value: organisationEmployee.firstName },
				lastName: { value: organisationEmployee.lastName },
				email: { value: organisationEmployee.user.email },
				password: { value: organisationEmployee.user.password },
				repeatPassword: { value: organisationEmployee.user.password },
				id: { value: organisationEmployee.id },
				organisationId: { value: id },
				role: { label: OrganisationEmployeeType[organisationEmployee.organisationUser.employeeType], value: organisationEmployee.organisationUser.employeeType },
				hasAccountAccess: { value: organisationEmployee.organisationUser.hasAccountAccess },

				prescriberId: { value: organisationEmployee.prescriber?.id }
			}
			this.setState({ editEmployeeForOrganisation: mapOrganisationEmployee });

		} catch (error) {
			errorHelper.handleError(error);
		} finally {
			this.setState({ updatingEmployeeForOrganisation: false });
		}
	}

	deleteEmployeeFromOrganisation = async (id, organisationId) => {
		this.setState({ deletingOrganisationEmployee: true });

		const payload = {
			organisationId: ~~organisationId,
			id: id,
		}

		try {
			await organisationsService.deleteEmployeeFromOrganisation(payload);
			await this.getAllEmployeesFromOrganisation(organisationId)
			notificationHelper.info("Organisation employee user account deleted");
		} catch (error) {
			errorHelper.handleError(error);
		}
		finally {
			this.setState({ deletingOrganisationEmployee: false });
		}
	}

	setOrganisationEmployeeForDelete = async (id, employeeId = -1) => {

		this.setState({ deletingOrganisationEmployee: true });
		try {
			const organisationEmployees = await organisationsService.getAllOrganisationEmployeesFromOrganisation(id);

			let organisationEmployee;

			organisationEmployee = organisationEmployees.find(e => e.id === employeeId);

			const mapOrganisationEmployee = {
				name: { value: organisationEmployee.firstName + " " + organisationEmployee.lastName },
				id: { value: organisationEmployee.id },
				role: { value: organisationEmployee.organisationUser.employeeType }
			}
			this.setState({ deleteEmployeeForOrganisation: mapOrganisationEmployee });

		} catch (error) {
			errorHelper.handleError(error);
		} finally {
			this.setState({ deletingOrganisationEmployee: false });
		}
	}

	setOrganisationEmployeeRole = (organisationType) => {
		organisationType ? this.setState({
			newEmployeeForOrganisation:
			{
				...newEmployeeForOrganisationState,
				role: [{ label: OrganisationEmployeeType[1], value: 1 }]
			}
		})
			:
			this.setState({
				newEmployeeForOrganisation:
				{
					...newEmployeeForOrganisationState,

					role: Object.values(OrganisationEmployeeType).map((e, index) => {

						return {
							label: e,
							value: index
						}
					}),
				}
			})
	}

	setPrepopulatedFieldsForEmployee = (employee) => {
		const prepopulateFields = {
			firstName: { value: employee.value[0].firstName },
			lastName: { value: employee.value[0].lastName },
			email: { value: employee.value[0].email },
			password: { value: employee.value[0].password },
			repeatPassword: { value: employee.value[0].repeatPassword },
			prescriberIdCode: { value: employee.value[0].prescriberIdCode },
			hasAccountAccess: { value: employee.value[0].hasAccountAccess }
		}

		this.setState({
			newEmployeeForOrganisation: {
				...prepopulateFields,
				role: this.state.newEmployeeForOrganisation.role,
				organisationId: this.state.newEmployeeForOrganisation.organisationId,
			}
		})
	}

	resetPrepopulatedFieldsForEmployee = () => {
		this.setState({ newEmployeeForOrganisation: { ...newEmployeeForOrganisationState, hasAccountAccess: false } });
	}
}

export default OrganisationsContainer;
