import apiClient from "../clients/apiClient";
import axios from "axios";
import {
	awaitingRewritePrescriptionsApiToClient, futureDatedScriptsApiToClient,
	prescriptionApiToClient,
	prescriptionsApiToClient,
	prescriptionsToSearchList,
	unassignedPrescriptionsApiToClient,
} from "../converters/prescriptionsConverter";
import { PrescriptionFilters } from "../enums";

import contentHelper from "../util/helpers/contentHelper";
import { trackAnalyticsEvent } from "../util/helpers/analyticsHelper";
import { partialAdminsApiToClient } from "../converters/adminsConverter";

class PrescriptionsService {
	constructor() {
		this.client = {
			getActiveById: apiClient.getById("prescriptions"),
			filterActive: apiClient.filterActive,

			getPastById: apiClient.getById("prescriptions/past"),
			filterPast: apiClient.filter("prescriptions/past"),
			createPast: apiClient.create("prescriptions/past"),
			createComment: apiClient.create("prescriptionevents"),
			updatePast: apiClient.update("prescriptions/past")
		};
	}

	async getById(id) {
		let { data: prescription } = await this.client.getActiveById(id);
		prescription = prescriptionApiToClient(prescription);

		prescription.eventHistory = [];
		prescription.supportComms = [];

		return prescription;
	}

	async filter(queryString = "", phase) {
		phase = contentHelper.normalizePrescriptionPhase(phase);

		// Resolve prescription status array from prescription phase.
		const filteredStatuses = {
			...PrescriptionFilters[phase],
			paperPrescriptionId: queryString,
			patientFullName: queryString,
			prescriberFullName: queryString,
		};

		const { data: activePrescriptions } = await this.phasesToApiCallMapper[phase.toLowerCase()](filteredStatuses);

		return prescriptionsApiToClient(activePrescriptions);
	}

	async filterPaginated(queryString = "", phase, page, pageSize, sortField, sortDirection, cancelToken) {
		phase = contentHelper.normalizePrescriptionPhase(phase);

		const filters = { ...PrescriptionFilters[phase], query: queryString, page: page, pageSize: pageSize, sortField: sortField, sortDirection: sortDirection };

		const { data: activePrescriptions } = await this.phasesToApiCallMapper[phase.toLowerCase()](filters, cancelToken);

		return { ...activePrescriptions, items: prescriptionsApiToClient(activePrescriptions.items) };
	}

	async getTableReport(phase) {
		phase = contentHelper.normalizePrescriptionPhase(phase);

		const filters = { ...PrescriptionFilters[phase], phase };

		const data = await apiClient.getPrescriptionPhaseReport(filters);

		return data;
	}

	async filterUnasigned(queryString) {
		const filters = { queryFilter: queryString };

		const { data } = await apiClient.filterUnassigned(filters);

		return unassignedPrescriptionsApiToClient(data);
	}

	async getAllAdminsPartial() {
		const { data } = await apiClient.getAllAdminsPartial();
		return partialAdminsApiToClient(data)
	}
	async assignOwner(prescriptionId, adminId) {
		const payload = {
			prescriptionId,
			adminId
		}
		await apiClient.assignOwner(payload);
	}

	async filterAwaitingRewrite(queryString) {
		const filters = { queryFilter: queryString };

		const { data } = await apiClient.filterAwaitingRewrite(filters);

		return awaitingRewritePrescriptionsApiToClient(data);
	}

	async filterFutureDatedScripts(queryString) {
		const filters = { queryFilter: queryString };

		const { data } = await apiClient.filterFutureDatedScripts(filters);

		return futureDatedScriptsApiToClient(data);
	}

	async quickSearch(queryString = "", cancelToken) {
		const { data } = await apiClient.quickSearch({ query: queryString }, cancelToken);

		return prescriptionsToSearchList(data);
	}

	async assignPrescriptionImage(payload) {
		const { data } = await apiClient.assignPrescriptionImage(payload);

		return data;
	}
	async checkAssignPatientToPrescription(payload) {
		const { data } = await apiClient.checkAssignPatientToPrescription(payload);

		return data;
	}

	phasesToApiCallMapper = {
		all: apiClient.filterActive,
		new: apiClient.filterActive,
		outofstock: apiClient.filterActive,
		readyforpayment: apiClient.filterActive,
		paymentmissed: apiClient.filterActive,
		requirepapercopy: apiClient.filterActive,
		readyforpharmacy: apiClient.filterActive,
		readyforpatient: apiClient.filterActive,
		shipped: apiClient.filterActive,
		cancelled: apiClient.filterActive,

		completed: apiClient.filterCompleted,
		void: apiClient.filterVoid,
		archived: apiClient.filterArchived,
	};

	async uploadPrescriptionPhoto(id, file) {
		const config = { responseType: "blob" };
		const { data: prescriptionBlob } = await axios.get(file.url, config);

		const prescription = new File([prescriptionBlob], file.name);

		const payload = new FormData();
		payload.append("id", id);
		payload.append("prescription", prescription);

		const { data } = await apiClient.uploadPrescriptionPhoto(payload);

		return data.filePath;
	}

	async create(prescriptionCreate) {

		const payload = new FormData();
		payload.append("patientId", prescriptionCreate.patientId);
		payload.append("prescriberId", prescriptionCreate.prescriberId);
		payload.append("prescription", prescriptionCreate.prescription);
		payload.append("confirmedConsent", prescriptionCreate.confirmedConsent);
		payload.append("envelopeTrackingCode", prescriptionCreate.envelopeTrackingCode);
		payload.append("organisationId", prescriptionCreate.organisationId);

		const { data } = await apiClient.uploadPrescription(payload);

		trackAnalyticsEvent("Prescription", "Upload", `From Admin`);

		return data;
	}

	async update(prescriptionUpdate) {
		const { data } = await apiClient.processPrescription(prescriptionUpdate);

		return prescriptionApiToClient(data);
	}

	async addProductsToPrescription(prescriptionId, patientId, orderedProducts, removeShippingCostReason, RemoveShippingCost) {
		const { data } = await apiClient.addProductsToPrescription({ prescriptionId, patientId, orderedProducts, removeShippingCostReason, RemoveShippingCost });

		return data;
	}

	async getProducts() {
		const { data: products } = await apiClient.getAllProductPartials();

		return products;
	}

	async performStockCheck(orderId) {
		const { data } = await apiClient.performStockCheck(orderId);

		return prescriptionApiToClient(data);
	}

	async checkStockStatusForProduct(productId, amount) {
		const { data: status } = await apiClient.checkStockStatusForProduct({ productId, amount });

		return status;
	}

	async getOrdersAwaitingStock(productId, amount) {
		const { data: status } = await apiClient.getOrdersAwaitingStock({ productId, amount });

		return status;
	}

	async cancelProductInOrder(orderedProductId, cancelProductReason) {
		const { data } = await apiClient.cancelProductInOrder(orderedProductId, cancelProductReason);

		return prescriptionApiToClient(data);
	}

	async getEventHistoryForPrescription(id) {
		const { data } = await apiClient.getEventHistoryForPrescription(id);

		return data;
	}

	async getSupportComms(paperPrescriptionId) {
		const { data } = await apiClient.getSupportComms(paperPrescriptionId);

		return data;
	}

	async payForOrder(transaction) {
		const { data } = await apiClient.payForOrder(transaction);

		return prescriptionApiToClient(data);
	}

	async updateShipmentStatus(orderId, newStatus, prescriptionId) {
		const { data } = await apiClient.updateShipmentStatus({ orderId, newStatus, prescriptionId });

		return prescriptionApiToClient(data);
	}

	async updateOrderNumber(orderId) {
		await apiClient.updateOrderNumber(orderId);
	}

	async contact(payload) {
		await apiClient.contact(payload);
	}

	async archive(id, reason = "") {
		await apiClient.archive({ id, reason });
	}

	async unarchive(id, reason = "") {
		await apiClient.unarchive({ id, reason });
	}

	async flag(id, reason = "") {
		await apiClient.flag({ id, reason });
	}

	async unflag(id) {
		await apiClient.unflag({ id });
	}

	async setIsUrgent(payload) {
		await apiClient.setIsPrescriptionUrgent(payload);
	}

	async resubmit(id) {
		await apiClient.resubmit({ id });
	}

	async splitOrder(payload) {
		const { data } = await apiClient.splitOrder(payload);

		return data;
	}

	async sendToClinic(payload) {
		await apiClient.sendToClinic(payload);
	}

	async removeAwaitingRewirteStatus(payload) {
		await apiClient.removeAwaitingRewirteStatus(payload);
	}

	async sendToOrganisationWithProducts(payload) {
		await apiClient.sendToOrganisationWithProducts(payload);
	}

	async generateLabelForOrder(id, comments) {
		const { data: urlToLabel } = await apiClient.generateLabelForOrder(id, comments);
		window.open(urlToLabel, "_blank");
	}

	async regenerateLabelForOrder(payload) {
		const { data: urlToLabel } = await apiClient.regenerateLabelForOrder(payload);
		window.open(urlToLabel, "_blank");
	}

	async forceCompleteOrder(orderId, reason) {
		const { data: prescription } = await apiClient.forceCompleteOrder({ id: orderId, reason: reason });

		return prescriptionApiToClient(prescription);
	}

	async refundOrder(payload) {
		const { data: prescription } = await apiClient.refundOrder(payload);

		return prescriptionApiToClient(prescription);
	}

	async createComment(commentCreate) {
		const { data } = await this.client.createComment(commentCreate);

		return data;
	}

	async getTotalPriceForOrderedProducts(payload) {
		const { data } = await apiClient.getTotalPriceForOrderedProducts(payload);

		return data;
	}

	async linkForRewrite(payload) {
		const { data } = await apiClient.linkForRewrite(payload);

		return data;
	}

	async unlinkPrescription(payload) {
		const { data } = await apiClient.unlinkPrescription(payload);

		return data;
	}

	async bulkUploadPrescriptionImages(prescriptions, prescriberId, envelopeTrackingCode, organisationId) {
		const prescriptionsBlobs = [];

		for (const prescription of prescriptions) {

			const config = { responseType: "blob" };
			const { data: prescriptionBlob } = await axios.get(prescription.url, config);

			const prescriptionFile = new File([prescriptionBlob], prescription.name);

			prescriptionsBlobs.push(prescriptionFile);
		}

		const payload = new FormData();

		prescriptionsBlobs.forEach(b => {
			payload.append("prescriptions", b);
		})
		payload.append("confirmedConsent", true);
		payload.append("prescriberId", prescriberId);
		payload.append("organisationId", organisationId);
		payload.append("envelopeTrackingCode", envelopeTrackingCode);
		const response = await apiClient.bulkUploadPrescriptionImages(payload);

		return response;
	}

	async sendPaymentLink(orderId) {
		await apiClient.sendPaymentLink(orderId);
	}

	async getAllAwaitingRewriteByPatientId(patientId) {
		const { data } = await apiClient.getAllAwaitingRewriteByPatientId(patientId);
		return data;
	}
}

export default new PrescriptionsService();
