import { formatDate } from "../util/dates";
import { PrescriberType } from "../enums";

export function prescribersApiToClient(prescribers) {
	const result = [];
	for (let prescriber of prescribers) {
		result.push({
			id: prescriber.id,
			email: prescriber.organisationEmployee.user.email,
			type: prescriber.prescriberType,

			firstName: prescriber.organisationEmployee.firstName,
			lastName: prescriber.organisationEmployee.lastName,
			prescriberIdCode: prescriber.prescriberIdCode,

			organisationNames: prescriber.organisations ? prescriber.organisations.map(org => org.name).join(', ') : undefined,

			patients: prescriber.patients,

			createdAt: formatDate(prescriber.createdAt),
			organisationEmployee: prescriber.organisationEmployee,
		});
	}
	return result;
}

export function prescriberApiToClient(prescriber) {
	return {
		id: prescriber.id,
		organisationEmployeeId: prescriber.organisationEmployee.id,
		email: prescriber.organisationEmployee.user?.email,
		createdAt: formatDate(prescriber.createdAt),
		updatedAt: formatDate(prescriber.updatedAt),

		firstName: prescriber.organisationEmployee.firstName,
		lastName: prescriber.organisationEmployee.lastName,
		prescriberIdCode: prescriber.prescriberIdCode,
		prescriberType: PrescriberType[prescriber.prescriberType]
	};
}

export function prescriberClientToApi(prescriber) {
	return {
		firstName: prescriber.firstName,
		lastName: prescriber.lastName,
		organisationName: prescriber.organisationName,
		organisationAddress1: prescriber.organisationAddress1,
		organisationAddress2: prescriber.organisationAddress2,
		organisationZIPCode: prescriber.organisationZIPCode,
		telephoneNo: prescriber.telephoneNo,
		gmc: prescriber.GMC,

		email: prescriber.email,
		password: prescriber.password,

		patientsIds: prescriber.patientIds,

		isIDVerified: prescriber.isEmailVerified,
		isEmailVerified: prescriber.isEmailVerified,
	};
}