import apiClient from "../clients/apiClient";
import {
	organisationApiToClient,
	organisationNamesApiToClient,
	organisationsApiToClient,
} from "../converters/organisationsConverter";

class OrganisationsService {
	constructor() {
		this.client = {
			getById: apiClient.getById("organisations"),
			filter: apiClient.filter("organisations"),
			update: apiClient.update("organisations"),
			delete: apiClient.delete("organisations"),
			create: apiClient.create("organisations"),

			createNewEmployee: apiClient.create("organisationemployees"),
			editOrganisationEmployee: apiClient.update("organisationemployees"),

		};
	}

	async createOrganisation(payload) {
		const { data } = await this.client.create(payload);

		return organisationApiToClient(data);
	}

	async updateOrganisation(payload) {
		const { data } = await this.client.update(payload);
		return data
	}

	async filter(queryString = "") {
		const filters = {
			filterQuery: queryString,
		};
		const { data } = await this.client.filter(filters);
		return organisationsApiToClient(data);
	}

	async getOrganisationById(id) {
		const { data } = await this.client.getById(id);

		return organisationApiToClient(data);
	}

	async getOrganisationNames(queryString, cancelToken) {
		const { data } = await apiClient.getOrganisationNames({ filter: queryString }, cancelToken);

		return organisationNamesApiToClient(data);

	}

	async getAllOrganisationEmployeesFromOrganisation(id) {
		const { data } = await apiClient.getAllOrganisationEmployeesFromOrganisation(id);

		return data
	}

	async createOrganisationEmployee(payload) {
		const { data } = await this.client.createNewEmployee(payload);

		return data;
	}

	async updateOrganisationEmployee(payload) {
		const { data } = await this.client.editOrganisationEmployee(payload);

		return data;
	}

	async deleteEmployeeFromOrganisation(payload) {
		const { data } = await apiClient.deleteOrganisationEmployeeOrganisationAccount(payload);

		return data;
	}

	async getPrescribersByGMC(prescriberIdCode) {
		const { data } = await apiClient.getByGmc(prescriberIdCode);

		return data;
	}
}

export default new OrganisationsService();