import React from 'react'

const TickedIconInCircle = ({ color = "#57CBCD" }) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill={color} />
            <path d="M23.082 7.17188L13.918 19.2539L8.5 13.8398L6 16.3398L14.332 24.6719L26 9.67188L23.082 7.17188Z" fill="white" />
        </svg>
    )
}

export default TickedIconInCircle
