export class Throttle {
	constructor() {
		this.timeout = null;
	}

	setTimeout(callback, milliseconds = 300) {
		if (this.timeout) clearTimeout(this.timeout);

		this.timeout = setTimeout(callback, milliseconds);
	}
}
