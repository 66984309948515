import { Container } from "unstated";

import AuthenticationService from "../services/AuthenticationService";
import {
	doesPasswordContainCapital, doesPasswordContainNumber, doesPasswordContainSymbol, isFormValid,
	minLength,
	required,
} from "../util/validation/validation";
import notificationHelper from "../util/helpers/notificationHelper";
import errorHelper from "../util/helpers/errorHelper";
import browserHelper from "../util/helpers/browserHelper";

const initialResetPasswordState = {
	password: { value: "" },
	confirmPassword: { value: "" },
}

class AuthenticationContainer extends Container {
	constructor() {
		super();

		this.state = {
			currentToken: null,
			currentAdministrator: null,
			resetPassword: { ...initialResetPasswordState },

			/**
			 * This is used to know if the api call for administrator data has been made
			 * Nothing will be rendered until this is set to true by signin or readAdministratorFromStorage
			 */
			administratorChecked: false,

			loadingLogin: false,
			loadingRequest: false
		};

		this.validators = {
			resetPassword: {
				password: [required, minLength(8), doesPasswordContainCapital, doesPasswordContainNumber, doesPasswordContainSymbol],
				confirmPassword: [required],
			},
		}
	}

	onFormChange = (e, formKey) => {
		let validators = [];

		if (this.validators[formKey]) {
			validators = this.validators[formKey][e.name];
		}
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}


		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	signin = async (credentials) => {
		this.setState({ loadingLogin: true });

		try {
			const { currentAdministrator, currentToken } = await AuthenticationService.signin(credentials);

			this.setState({ currentAdministrator, currentToken, administratorChecked: true });
		} catch (error) { }

		this.setState({ loadingLogin: false });
	};

	signout = async () => {
		AuthenticationService.signout();

		this.setState({ currentToken: null, currentAdministrator: null });
	};

	readAdministratorFromStorage = async () => {
		try {
			const { currentAdministrator, currentToken } = await AuthenticationService.readAdministratorFromStorage();

			this.setState({ currentAdministrator, currentToken, administratorChecked: true });

			return currentToken;
		} catch (error) {
			this.setState({ administratorChecked: true });
		}
	};

	requestPasswordReset = async (email) => {
		try {
			this.setState({ loadingRequest: true })

			await AuthenticationService.requestPasswordReset(email);
			browserHelper.navigate("/resetEmailSent");
		} catch (e) {
			errorHelper.handleError(e);
		}
		this.setState({ loadingRequest: false })
	}

	resetPassword = async (secretId, password) => {
		try {
			this.setState({ loadingRequest: true })

			await AuthenticationService.resetPassword({ secretId, password });
			notificationHelper.info("Your password has been updated");
			browserHelper.navigate("/login");
		} catch (e) {
			errorHelper.handleError(e);
		}


		this.setState({ loadingRequest: false })
	}
}

export default AuthenticationContainer;
