import React from "react";
import UniversalTooltip from "../Tooltips/UniversalTooltip";
import ProcessPrescriptionTooltipContent from "../Tooltips/ContentForTooltips/ProcessPrescriptionTooltipContent";

function renderOptions(options) {
	return options.map((option, index) => (
		<option key={index} value={option.value}>
			{option.text}
		</option>
	));
}

function Dropdown({
	value = "",
	name = "",
	optional = false,
	label = "Choose",
	disabled = false,
	options = [],
	error = false,
	onChange = () => null,
	required = false,
	showAsterisk = false,
	tooltipText = "",
	centerLabel = false,
}) {
	return (
		<div className="dropdown-container">
			<select
				className={error ? "error" : ""}
				name={name}
				value={value}
				disabled={disabled}
				onChange={(e) => onChange({ name, value: e.target.value })}
				onBlur={(e) => {
					if (!required) return;
					onChange({ name, value: e.target.value });
				}}
			>
				{optional && <option value="">Select</option>}
				{renderOptions(options, value)}
			</select>
			<div className={"component-label" + (centerLabel ? " centered" : "")}>
				{tooltipText ?
					<UniversalTooltip
						hoverableContent={<label >{error ? error : label + (showAsterisk ? " *" : '')}</label>}
						contentForTheTooltip={<ProcessPrescriptionTooltipContent
							text={tooltipText}
						/>}
						left={55}
						top={35}
					/> :
					<label>{error ? error : label + (showAsterisk ? " *" : '')}</label>
				}
			</div>
		</div>
	);
}

export default Dropdown;
