import React, { useEffect, useRef } from 'react'
import { Subscribe } from 'unstated';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_icon.svg'
import useCsvParser from '../../hooks/useCsvParser';
import PatientContainer from '../../state/PatientContainer';
const DropZone = ({ container }) => {
    const headers = ["title", "firstName", "lastName", "dateOfBirth", "address1", "zipCode", 'email', 'telephoneNo', 'clinicName', 'patientClinicId', 't21', 'mdtStatus'];
    const { onFileSelected, result, file, setFile } = useCsvParser({ headers, excludeFirstRow: true });
    const inputRef = useRef();
    const bulkUploadFormKey = "bulkUpload"

    const bulkUploadState = container.state[bulkUploadFormKey];

    const handleClick = () => {
        inputRef.current.click()
    }
    useEffect(() => {
        container.setBulkUploadCsvData(result)
        // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        setFile(null)
        inputRef.current.value = null
        // eslint-disable-next-line
    }, [bulkUploadState.initializeDropZoneRerender])

    const onChange = (e) => {
        onFileSelected(e)
    }

    const handleOnDrop = (e) => {
        e.preventDefault()
        onFileSelected(e)
    }

    return (
        <div className={`dropzone }`}>
            <div className={`dropzone__area ${file ? 'dropzone__area--selected' : ''}`} onDragOver={(e) => { e.preventDefault() }} onDrop={handleOnDrop} >

                {file ?
                    <div className='dropzone__text'>{file.name}<br /> uploaded</div>
                    :
                    <>
                        <UploadIcon />
                        <div className='dropzone__text'>Drop patient list CSV into <br /> this field or</div>
                        <div onClick={() => { handleClick() }} className='dropzone__select_file'>select a file</div>
                    </>
                }
                <input onChange={(e) => onChange(e)} ref={inputRef} hidden={true} type='file'></input>
            </div>
        </div>
    )
}

function SubscribedNewPatient(props) {
    return <Subscribe to={[PatientContainer]}>{(container) => <DropZone {...props} container={container} />}</Subscribe>;
}

export default SubscribedNewPatient
