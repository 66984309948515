import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import Dropdown from "../../components/Form/Dropdown";
import TextInput from "../../components/Form/TextInput";
import CustomSelect from "../../components/Form/CustomSelect";
import FormRow from "../../components/Form/FormRow";

import Prompt from "../../components/Modal/Prompt";

import contentHelper from "../../util/helpers/contentHelper";
import CarerContainer from "../../state/CarerContainer";
import { SpinnerIcon } from "../../assets/icons";

function EditCarer({ container }) {
	// State-related data
	const formKey = "updateCarer";
	const state = container.state[formKey];
	const onFieldChange = (event) => container.onFormChange(event, formKey);
	const onPatientChange = (event) => container.onPatientChange(event, formKey);

	// Hook-related data
	const { id } = useParams();
	const [showPrompt, setPromptVisibility] = useState(false);

	useEffect(() => {
		container.setForUpdate(id);

		return container.resetUpdateCarerState;
	}, [id, container]);

	return (
		<div className="card p-m">
			<Prompt
				isOpen={showPrompt}
				title="Remove carer"
				description="Are you sure? All the data related to this entity will be removed."
				onYes={() => {
					container.delete(id);
					setPromptVisibility(false);
				}}
				onNo={() => setPromptVisibility(false)}
				onClose={() => setPromptVisibility(false)}
			/>

			<FormRow>
				<h1 className="h4">Personal info</h1>
			</FormRow>

			<FormRow>
				<div className="col-2">
					<Dropdown
						value={state.title.value}
						error={state.title.error}
						onChange={onFieldChange}
						name="title"
						label="Title"
						options={contentHelper.getTitlesAsDropdownItems()}
					/>
				</div>
				<div className="col-5">
					<TextInput
						value={state.firstName.value}
						error={state.firstName.error}
						onChange={onFieldChange}
						name="firstName"
						label="First name"
						placeholder="e.g. John"
					/>
				</div>
				<div className="col-5">
					<TextInput
						value={state.lastName.value}
						error={state.lastName.error}
						onChange={onFieldChange}
						name="lastName"
						label="Last name"
						placeholder="e.g. Doe"
					/>
				</div>
			</FormRow>

			<FormRow>
				<div className="col-4">
					<TextInput
						value={state.address1.value}
						error={state.address1.error}
						onChange={onFieldChange}
						name="address1"
						label="Address 1"
						placeholder="e.g. Avenue Road 134A, Illinois"
					/>
				</div>
				<div className="col-4">
					<TextInput
						value={state.address2.value}
						error={state.address2.error}
						onChange={onFieldChange}
						name="address2"
						label="Address 2"
						placeholder="e.g. Avenue Road 134B, Illinois"
					/>
				</div>
				<div className="col-4">
					<TextInput
						value={state.zipCode.value}
						error={state.zipCode.error}
						onChange={onFieldChange}
						name="zipCode"
						label="Postal code"
						placeholder="e.g. 35492 DB"
					/>
				</div>
			</FormRow>

			<FormRow>
				<div className="col-6">
					<TextInput
						value={state.dateOfBirth.value}
						error={state.dateOfBirth.error}
						onChange={onFieldChange}
						name="dateOfBirth"
						label="Date of birth"
						placeholder="e.g. 25/08/1995"
					/>
				</div>
				<div className="col-6">
					<TextInput
						value={state.telephoneNo.value}
						error={state.telephoneNo.error}
						onChange={onFieldChange}
						name="telephoneNo"
						label="Telephone number"
						placeholder="e.g. +00256478921"
					/>
				</div>
			</FormRow>

			{state.patient.value && (
				<>
					<FormRow>
						<h2 className="h6">Patient info</h2>
					</FormRow>

					<FormRow>
						<div className="col-6">
							<CustomSelect
								label="Patient"
								name="patient"
								values={[state.patient.value]}
								options={container.state.filteredPatients}
								onKeyDown={(e) => container.filterPatientsSelect({ queryString: e.event.target.value })}
								onChange={onPatientChange}
								showDropdownOnlyOnSearch={true}
								placeholder={"Search patient"}
							/>
						</div>
						<div className="col-6">
							<Dropdown
								value={state.patientRelationship.value}
								onChange={onFieldChange}
								options={contentHelper.getPatientRelationshipAsDropdownItems()}
								name="patientRelationship"
								label="Relationship with the patient"
							/>
						</div>
					</FormRow>

					<FormRow>
						<span className="rounded-info-span">
							Patient: <Link to={`/patients/edit/${state.patient.value.value}`}>{state.patient.value.label}</Link>
						</span>
					</FormRow>
				</>
			)}

			<FormRow>
				<h2 className="h6">Account</h2>
			</FormRow>

			<FormRow>
				<div className="col-12">
					<TextInput
						value={state.email.value}
						error={state.email.error}
						onChange={onFieldChange}
						name="email"
						label="Email"
						placeholder="e.g. johndoe@email.com"
					/>
				</div>
			</FormRow>
			<FormRow>
				<div className="col-4">
					<Dropdown
						value={state.isEmailVerified.value}
						error={state.isEmailVerified.error}
						onChange={onFieldChange}
						options={contentHelper.getYesNoDropdownItems()}
						name="isEmailVerified"
						label="Email verified"
					/>
				</div>
				<div className="col-4">
					<Dropdown
						value={state.isIDVerified.value}
						error={state.isIDVerified.error}
						onChange={onFieldChange}
						options={contentHelper.getYesNoDropdownItems()}
						name="isIDVerified"
						label="ID verified"
					/>
				</div>
				<div className="col-4">
					<Dropdown
						value={state.agreedToTerms.value}
						error={state.agreedToTerms.error}
						onChange={onFieldChange}
						name="agreedToTerms"
						options={contentHelper.getYesNoDropdownItems()}
						label="Agreed to terms"
					/>
				</div>
			</FormRow>

			<FormRow>
				<h2 className="h6">Metadata</h2>
			</FormRow>
			<FormRow>
				<div className="col-6">
					<TextInput value={state.secretId.value} label="Secret" placeholder="Secret" disabled />
				</div>
				<div className="col-6">
					<TextInput value={state.secretExpiry.value} label="Secret expiry" placeholder="Secret expiry" disabled />
				</div>
			</FormRow>
			<FormRow>
				<div className="col-4">
					<TextInput value={state.createdAt.value} label="Created at" placeholder="Created at" disabled />
				</div>
				<div className="col-4">
					<TextInput value={state.updatedAt.value} label="Updated at" placeholder="Updated at" disabled />
				</div>
				<div className="col-4">
					<TextInput value={state.lastLoggedIn.value} label="Last login" placeholder="Last login" disabled />
				</div>
			</FormRow>

			<FormRow>
				<Button onClick={container.update} disabled={!container.isFormValid(formKey) || container.state.updatingCarer} >
					{container.state.updatingCarer ? <SpinnerIcon /> : "Save"}
				</Button>
			</FormRow>
		</div>
	);
}

function SubscribedEditCarer(props) {
	return <Subscribe to={[CarerContainer]}>{(container) => <EditCarer {...props} container={container} />}</Subscribe>;
}

export default SubscribedEditCarer;
