import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import CustomSelect from "../../components/Form/CustomSelect";
import FormRow from "../../components/Form/FormRow";

import { AdminType } from "../../enums";

import AuthenticationContainer from "../../state/AuthenticationContainer";
import BukImageUploadDropZone from "../../components/DropZone/BukImageUploadDropZone";
import BulkUploadprescriptionContainer from "../../state/BulkUploadPrescriptionContainer";
import TextInput from "../../components/Form/TextInput";
import { SpinnerIcon } from "../../assets/icons";
import RenderForAdminTypes from "../../components/SharedComponents/RenderForAdminTypes";

function AddBulkPrescriptions({ container, currentAdministrator }) {
	// State-related data
	const addPrescriptionsStateFormKey = "bulkUpload";

	const addPrescriptionsState = container.state[addPrescriptionsStateFormKey];
	const numberOfSelectedPrescriptions = addPrescriptionsState.prescriptions.value.length;
	const submittingPrescriptions = container.state.submittingPrescriptions;

	const onSelectChangePrescription = (event) => container.onSelectChange(event, addPrescriptionsStateFormKey);
	const onFieldChange = (event) => container.onFormChange(event, addPrescriptionsStateFormKey);

	const onPrescriberChange = (event) => {
		onSelectChangePrescription(event)
	}

	const onOrganisationChange = (event) => {
		addPrescriptionsState.prescriber.value = null
		container.setForCreate();
		onSelectChangePrescription(event)
	}
	const isButtonEnabled = () => {
		return (
			!addPrescriptionsState.prescriber.value ||
			!addPrescriptionsState.organisationId.value ||
			numberOfSelectedPrescriptions < 1 ||
			submittingPrescriptions
		)
	}
	useEffect(() => {
		container.setForCreate();

	}, [container]);

	return (
		<>
			{currentAdministrator?.type !== AdminType.Eastone && (
				<FormRow>
					<div className="col-6">
						<div style={{ width: "100%" }}>
							<FormRow>
								<h2 className="h6">Prescription information</h2>
							</FormRow>

							<FormRow>
								<div className="col-7" id="selectPrescriber">
									<CustomSelect
										key={container.state.filteredOrganisations.length}
										label="Organisation"
										name="organisationId"
										values={addPrescriptionsState.organisationId.value ? [addPrescriptionsState.organisationId.value] : []}
										options={container.state.filteredOrganisations}
										onChange={onOrganisationChange}
										clearable={true}
									/>
								</div>

								<RenderForAdminTypes adminTypes={[AdminType.Super]}>
									{
										addPrescriptionsState.organisationId.value &&
										<div className="col-5">
											{addPrescriptionsState.organisationId && (
												<span className="rounded-info-span">
													<Link to={`/organisations/edit/${addPrescriptionsState.organisationId.value.value}`}>View</Link>
												</span>
											)}
											<Link to="/organisations/new" className="badge--no-border highlighted m-l-xs" title="Add a new organisation">
												<i className="icon-plus"></i>
											</Link>
										</div>
									}
								</RenderForAdminTypes>
							</FormRow>

							<FormRow>
								<div className="col-7" id="selectPrescriber">
									{/* This conditional rendering solves the problem of not seeing prescribers */}
									{!container.state.loadingPrescribers && (
										<CustomSelect
											label="Prescriber"
											name="prescriber"
											values={addPrescriptionsState.prescriber.value ? [addPrescriptionsState.prescriber.value] : []}
											options={container.state.filteredPrescribers}
											key={container.state.filteredPrescribers?.length}
											onChange={onPrescriberChange}
											clearable={true}
											disabled={!addPrescriptionsState.organisationId.value}
										/>
									)}
								</div>

								<div className="col-5">
									{addPrescriptionsState.prescriber.value && (
										<span className="rounded-info-span">
											<Link to={`/prescribers/edit/${addPrescriptionsState.prescriber.value.value}`}>View</Link>
										</span>
									)}
									<Link to="/prescribers/new" className="badge--no-border highlighted m-l-xs" title="Add a new prescriber">
										<i className="icon-plus"></i>
									</Link>
								</div>
							</FormRow>

							<FormRow>
								<div className='col-7'>
									<TextInput value={addPrescriptionsState.trackingNumber?.value} name='trackingNumber' label="Tracking number (optional)" placeholder="Tracking number" onChange={onFieldChange} />
								</div>
							</FormRow>
						</div>
					</div>
					<div className="col-6">
						<BukImageUploadDropZone />
					</div>
				</FormRow>

			)}
			<FormRow>
				<div className="col-6">
					<Button
						className="m-r-xs"
						onClick={container.create}
						disabled={isButtonEnabled()}
					>
						{submittingPrescriptions ? <SpinnerIcon /> : "Upload images"}
					</Button>
				</div>
			</FormRow>
		</>
	);
}

function SubscribedAddBulkPrescriptions(props) {
	return (
		<Subscribe to={[BulkUploadprescriptionContainer, AuthenticationContainer]}>
			{(container, authContainer) => (
				<AddBulkPrescriptions {...props} container={container} currentAdministrator={authContainer.state.currentAdministrator} />
			)}
		</Subscribe>
	);
}

export default SubscribedAddBulkPrescriptions;
