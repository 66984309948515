import React from 'react'

const RWIcon = () => {
	return (
		<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="16" rx="2" fill="#E6E8FF" />
			<path d="M7.895 12L6.465 9.95C6.405 9.95667 6.315 9.96 6.195 9.96H4.615V12H3.315V5H6.195C6.80167 5 7.32833 5.1 7.775 5.3C8.22833 5.5 8.575 5.78667 8.815 6.16C9.055 6.53333 9.175 6.97667 9.175 7.49C9.175 8.01667 9.045 8.47 8.785 8.85C8.53167 9.23 8.165 9.51333 7.685 9.7L9.295 12H7.895ZM7.865 7.49C7.865 7.04333 7.71833 6.7 7.425 6.46C7.13167 6.22 6.70167 6.1 6.135 6.1H4.615V8.89H6.135C6.70167 8.89 7.13167 8.77 7.425 8.53C7.71833 8.28333 7.865 7.93667 7.865 7.49ZM21.3595 5L19.0395 12H17.6595L15.9295 6.82L14.1695 12H12.7795L10.4595 5H11.8095L13.5495 10.32L15.3595 5H16.5595L18.3295 10.35L20.1195 5H21.3595Z" fill="#2F3EFB" />
		</svg>
	)
}

export default RWIcon
