import React, { useCallback } from "react";

function InputFieldValidationBulletPoints({ className, value, points }) {

	const isPointValid = (point) => {
		return !!(point.validator(value) && !!value)
	}

	const renderPoints = useCallback(() => {
		return points.map((point, i) => {
			return <div
				className={`input_field_validation_bullet_points__point ${isPointValid(point) ? "input_field_validation_bullet_points__point--valid" : ""}`}
				key={i}
			>
				<div className="input_field_validation_bullet_points__point__circle" /> {point.text}
			</div>
		})
		// eslint-disable-next-line
	}, [value])

	return (
		<div className={`input_field_validation_bullet_points  ${className}`} >
			{renderPoints()}
		</div>
	);
}

export default InputFieldValidationBulletPoints;