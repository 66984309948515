import React from 'react'
import Spinner from '../Spinner/Spinner'

const LoadingCard = ({ text = 'Loading' }) => {
    return (
        <div className='loading_card card'>
            <Spinner className='loader--gray' />
            <div className='loading_card__text'>
                {text}
            </div>
        </div>
    )
}

export default LoadingCard
