import React from "react";
import moment from "moment";

function ProductPrescriberNoteTooltipContent({ product }) {
	return (
		<div className={'flagged-product-tooltip-content'} >
			<div className={'flagged-product-tooltip-content__title'}>
				NOTE TO PRESCRIBERS - {moment(product.prescriberNoteDateTime).format('DD/MM/YYYY')}
			</div>
			<div className={'flagged-product-tooltip-content__text'}>
				<span className={"bold"}>{product.prescriberNoteAdminName || "Deleted Admin"}:</span> {product.prescriberNote}
			</div>
		</div>
	);
}

export default ProductPrescriberNoteTooltipContent;