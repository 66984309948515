import { AdminPermissions, AdminType, AdminRole } from "../enums";
import { formatDate } from "../util/dates";

export function adminsApiToClient(admins) {
	const result = [];

	for (let admin of admins) {
		result.push({
			id: admin.id,
			name: admin.name,
			email: admin.email,
			createdAt: formatDate(admin.createdAt),
		});
	}

	return result;
}

export function adminApiToClient(admin) {
	const canAccessUsers = admin.permissions?.includes(AdminPermissions.UserData);
	const canAccessAdministrators = admin.permissions?.includes(AdminPermissions.AdminData);

	const AdminRoleToAdminTypeMapper = {
		[AdminRole.External]: AdminType.Eastone,
		[AdminRole.Regular]: AdminType.Regular,
		[AdminRole.Super]: AdminType.Super,
	};

	const type = AdminRoleToAdminTypeMapper[admin.adminRole];

	return {
		id: admin.id,
		name: admin.name,
		email: admin.email,
		createdAt: formatDate(admin.createdAt),
		updatedAt: formatDate(admin.updatedAt),
		lastLogin: formatDate(admin.lastLoginDateTime),
		permissions: {
			canAccessUsers,
			canAccessAdministrators,
		},
		type,
	};
}

export function adminClientToApi(admin) {
	admin.permissions = getAdminPermissions(admin.permissions);

	return admin;
}

export function partialAdminsApiToClient(admins) {

	const partialAdminsList = admins.map(admin => {
		return {
			label: admin.name,
			value: admin.id,
		}
	})

	const noneOption = {
		label: "None",
		value: "",
	}

	partialAdminsList.unshift(noneOption)

	return partialAdminsList
}

/**
 * Returns a list of enums
 *
 * @param {canAccessAdministrators, canAccessUsers} param0 - permissions object
 */
export function getAdminPermissions({ canAccessUsers, canAccessAdministrators }) {
	const result = [];

	if (canAccessUsers) result.push(AdminPermissions.UserData);
	if (canAccessAdministrators) result.push(AdminPermissions.AdminData);

	return result;
}
