import React from "react";

function XIconSlim({ color = "#E74C3C", dimensions = 20 }) {
	return (
		<svg width={dimensions} height={dimensions} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.884 0.705973L10 8.58997L2.11602 0.705973C1.9264 0.532452 1.67714 0.438781 1.42017 0.444475C1.1632 0.450169 0.918335 0.554788 0.736585 0.736538C0.554835 0.918288 0.450216 1.16315 0.444522 1.42012C0.438828 1.67709 0.532499 1.92635 0.70602 2.11597L8.58602 9.99997L0.70402 17.882C0.607725 17.9737 0.530747 18.0838 0.477612 18.2058C0.424476 18.3277 0.396255 18.4591 0.394608 18.5921C0.39296 18.7251 0.41792 18.8571 0.468019 18.9803C0.518118 19.1035 0.592346 19.2155 0.686339 19.3096C0.780332 19.4038 0.892193 19.4781 1.01535 19.5284C1.1385 19.5787 1.27046 19.6038 1.40348 19.6024C1.53649 19.6009 1.66787 19.5729 1.78989 19.5199C1.91191 19.467 2.02211 19.3901 2.11402 19.294L10 11.412L17.884 19.296C18.0736 19.4695 18.3229 19.5632 18.5799 19.5575C18.8368 19.5518 19.0817 19.4472 19.2635 19.2654C19.4452 19.0837 19.5498 18.8388 19.5555 18.5818C19.5612 18.3249 19.4675 18.0756 19.294 17.886L11.41 10.002L19.294 2.11597C19.3903 2.0242 19.4673 1.91411 19.5204 1.79216C19.5736 1.67022 19.6018 1.53888 19.6034 1.40587C19.6051 1.27286 19.5801 1.14086 19.53 1.01763C19.4799 0.894408 19.4057 0.782442 19.3117 0.688315C19.2177 0.594189 19.1058 0.519803 18.9827 0.469529C18.8595 0.419255 18.7276 0.394109 18.5946 0.395568C18.4616 0.397027 18.3302 0.425061 18.2082 0.478024C18.0861 0.530987 17.9759 0.607808 17.884 0.703973V0.705973Z" fill={color} />
		</svg>
	);
}

export default XIconSlim;