// noinspection JSIgnoredPromiseFromCall

import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Subscribe } from "unstated";
import Button from "../../components/Form/Button";
import CustomSelect from "../../components/Form/CustomSelect";
import Dropdown from "../../components/Form/Dropdown";
import FormRow from "../../components/Form/FormRow";
import TextInput from "../../components/Form/TextInput";
import Modal from "../../components/Modal/Modal";
import Prompt from "../../components/Modal/Prompt";
import PrescriptionPreview from "../../components/PrescriptionPreview/PrescriptionPreview";
import { AdminType, HighLevelRejectReasons } from "../../enums";
import AuthenticationContainer from "../../state/AuthenticationContainer";
import PatientContainer from "../../state/PatientContainer";
import contentHelper from "../../util/helpers/contentHelper";
import SpinnerIcon from "../../assets/icons/SpinnerIcon";
import { formatDateForPresenting } from "../../util/dates";
import PrescriptionChecklist from "../../components/CheckList/Checklist";
import SquareCheckbox from "../../components/Form/SquareCheckbox";
import PrescriptionContainer from "../../state/PrescriptionContainer";
import Table from "../../components/Table/Table";
import tableHeaders from "../../util/constants/tableHeaders";
import ArchiveBox from "../../assets/icons/ArchiveBox";
import UnarchiveBox from "../../assets/icons/UnarchiveBox";
import Textarea from "../../components/Form/Textarea";
import Spinner from "../../components/Spinner/Spinner";
import RadioSelector from "../../components/Form/RadioSelector";
import CustomCard from "../../components/SharedComponents/CustomCard";
import CustomBanner from "../../components/Banner/CustomBanner";
import InfoIcon from "../../assets/icons/InfoIcon";
import LoadingCard from "../../components/SharedComponents/LoadingCard";
import SendToOrganisationModal from "../../components/SharedComponents/SendToOrganisationModal";
import Separator from "../../components/Separator/Separator";
import FlagIcon from "../../assets/icons/FlagIcon";
import FlagIconRed from "../../assets/icons/FlagIconRed";
import BannerButton from "../../components/Form/BannerButton";
import XIconSlim from "../../assets/icons/XIconSlim";
import { ExclamationMarkTriangle, UnlinkIcon } from "../../assets/icons";
import UnlinkPrescriptionModal from "../../components/SharedComponents/UnlinkPrescriptionModal";
import RemoveAwaitingReWriteStatusModal from "../../components/SharedComponents/RemoveAwaitingReWriteStatusModal";
import DateInput from "../../components/Form/DateInput";
import moment from "moment";

const AssignPrescription = ({ container, currentAdministrator, patientContainer }) => {
	const { id } = useParams();
	const [showQuickPatientCreateModal, setShowQuickPatientCreateModal] = useState(false);
	const [showConfrimAssignPrescriptionModal, setShowConfrimAssignPrescriptionModal] = useState(false);
	const [showFlagPopup, setShowFlagPopup] = useState(false);
	const [rejectModalVisible, setRejectModalVisible] = useState(false);
	const [hideShowLinkAsRewriteModal, setHideShowLinkAsRewriteModal] = useState(false);
	const [removeAwaitingRewriteStatusVisible, setRemoveAwaitingRewriteStatusVisible] = useState(false);

	// State-related data
	const assignPrescriptionStateFormKey = "assignPrescription";
	const rejectPrescriptionStateFormKey = "rejectPrescription";
	const formKey = "createPatient";
	const commentFormKey = "createComment";
	const flagFormKey = "flaggedReason";

	const state = patientContainer.state[formKey];
	const comment = container.state[commentFormKey];
	const assignPrescriptionState = container.state[assignPrescriptionStateFormKey];
	const prescription = assignPrescriptionState.prescription;
	const filteredPatients = container.state.filteredPatients;
	const rejectPrescriptionState = container.state[rejectPrescriptionStateFormKey];
	const flag = container.state[flagFormKey];
	const selectedPatientHasActivePrescriptions = assignPrescriptionState.patientsActivePrescriptions.value.length;

	const wasPrescriptionSubmittedByOrganisation = prescription?.value?.submittedBy === "Organisation";
	const onFieldChange = (event) => patientContainer.onFormChange(event, formKey);

	const onAssignPrescriptionFieldChange = (event) => container.onFormChange(event, assignPrescriptionStateFormKey);
	const onCheckboxChange = (event) => container.onFormChange(event, assignPrescriptionStateFormKey);
	const onCommentChange = (event) => container.onFormChange(event, commentFormKey);
	const onFlagReasonChange = (event) => container.onFormChange(event, flagFormKey);
	const onSelectChangePatientContainer = (event) => patientContainer.onSelectChange(event, formKey);
	const onSelectKeyDownPrescriptionPatientField = async (event) => {
		await container.onSelectKeyDownPrescriptionPatientField(event);
	};

	const onSelectChangeRejectPrescription = (event) => container.onSelectChange(event, rejectPrescriptionStateFormKey);

	const onSelectChangePrescriptionPatientField = (event) => {
		const fieldValue = event.value[0];
		if (fieldValue) {
			container.getAllPatientsAwaitingRewritePrescriptions(fieldValue.value);
			if (fieldValue.patientDetails) {
				const { address1, address2, zipCode } = fieldValue.patientDetails;
				const events = [
					{
						name: "address1",
						value: address1,
					},
					{
						name: "address2",
						value: address2,
					},
					{
						name: "zipCode",
						value: zipCode,
					},
				]
				events.forEach(event => {
					onAssignPrescriptionFieldChange(event)
				})
			}
		}
		container.onSelectChange(event, assignPrescriptionStateFormKey);
		container.onSelectChange(event, rejectPrescriptionStateFormKey);

		if (!fieldValue) {
			setHideShowLinkAsRewriteModal(true);
		}

		if (fieldValue) {
			setHideShowLinkAsRewriteModal(false);
		}

	};

	const onPaperPrescriptionIdChange = (event) => {
		container.onFormChange(event, assignPrescriptionStateFormKey);
		container.onFormChange(event, rejectPrescriptionStateFormKey);
	}

	const setRewritePrescriptionIdForLinking = (prescriptionId) => {
		const event = {
			value: prescriptionId,
			name: "rewritePrescriptionIdForLinking"
		}

		onAssignPrescriptionFieldChange(event);
	}

	const onReasonTextChange = (e) => {
		const event = {
			name: e.name,
			value: [{ label: "", value: e.value }]
		}

		container.onSelectChange(event, rejectPrescriptionStateFormKey)
	};

	const isButtonEnabled = !container.isFormValid(assignPrescriptionStateFormKey);
	const isArchiveButtonEnabled = !!rejectPrescriptionState.reason.value.value && !!rejectPrescriptionState.highLevelReason.value.value && !container.state.archivingPrescription && !(rejectPrescriptionState.reason.value.value?.length > 255);
	const isUnarchiveButtonEnabled = !!rejectPrescriptionState.reason.value.value && !container.state.unarchivingPrescription && !(rejectPrescriptionState.reason.value.value?.length > 255);

	const [archiveModalVisible, setArchiveModalVisible] = useState(false);
	const [unarchiveModalVisible, setUnarchiveModalVisible] = useState(false);
	const [unlinkModalVisible, setUnlinkModalVisible] = useState(false);
	const [patientHasActivePrescriptionsModalVisible, setPatientHasActivePrescriptionsModalVisible] = useState(false);

	const toggleArchiveModal = () => {
		setArchiveModalVisible(!archiveModalVisible);
	}

	const toggleUnarchiveModal = () => {
		setUnarchiveModalVisible(!unarchiveModalVisible);
	}

	function createComment() {
		container.createComment(currentAdministrator.name);
	}

	const handleQuickCreatePatient = async () => {
		const patient = await patientContainer.create(false);
		if (!patient) {
			return;
		}
		const createdPatient = {
			label: `${patient.firstName} ${patient.lastName} - ${formatDateForPresenting(patient.dateOfBirth, false)}`,
			value: patient.id,
		};
		filteredPatients.unshift(createdPatient);
		container.onSelectChange({ name: 'patient', value: [createdPatient] }, assignPrescriptionStateFormKey);

		container.onSelectChange({ name: 'address1', value: [`${patient.address1}`] }, assignPrescriptionStateFormKey);
		container.onSelectChange({ name: 'address2', value: [`${patient.address2}`] }, assignPrescriptionStateFormKey);
		container.onSelectChange({ name: 'zipCode', value: [`${patient.zipCode}`] }, assignPrescriptionStateFormKey);

		setShowQuickPatientCreateModal(false);
		patientContainer.resetCreateState();
	}

	const handleOpenQuickCreateModal = async (fillTempPatient = false) => {
		await patientContainer.resetCreateState();
		setShowQuickPatientCreateModal(true);
		if (fillTempPatient) {
			await patientContainer.fillTempPatient(assignPrescriptionState.tempPatient);
		}
		if (wasPrescriptionSubmittedByOrganisation) {
			await patientContainer.fillTempPatient(assignPrescriptionState.tempPatient);
		}

	}

	const isPrescriptionFutureDated = () => {
		if (!assignPrescriptionState?.datePrescribed?.value) {
			return false
		}
		return !(moment(Date.now()) > moment(assignPrescriptionState.datePrescribed.value, 'YYYY-MM-DD'))
	}


	const handleOpenUnlinkPrescriptionModal = () => {
		setUnlinkModalVisible(true);
		const payload = {
			prescriptionId: prescription.value.id,
			rewritePrescriptionId: prescription.value.rewriteChildPrescriptionId,
		}

		container.setForUnlinking(payload)
	}

	const handleArchivePrescription = async () => {
		await container.archiveFromToBeAssigned();
	}

	const handleUnarchivePrescription = async () => {
		await container.UnarchiveFromToBeAssigned();
	}

	const handleCloseArchive = () => {
		toggleArchiveModal(false);
		container.resetAllFieldsExcept('rejectPrescription', ["prescriptionNumber", "patient", "paperPrescriptionId"])
	}

	const handleCloseUnarchive = () => {
		toggleUnarchiveModal(false);
		container.resetAllFieldsExcept('rejectPrescription', ["prescriptionNumber", "patient", "paperPrescriptionId"])
	}

	const handleLinkPrescription = async () => {
		await container.linkForRewrite({ prescriptionId: id, rewritePrescriptionId: assignPrescriptionState.rewritePrescriptionIdForLinking.value });
	}

	const handleUnlinkPrescription = async () => {
		await container.unlinkPrescription();
		setUnlinkModalVisible(false);
	}

	const handleRemoveAwaitingRewriteStatus = () => {
		container.removeAwaitingRewirteStatus();
		setRemoveAwaitingRewriteStatusVisible(false);
	}

	const handleShowRemoveRewriteRequiredStatusModal = () => {
		setRemoveAwaitingRewriteStatusVisible(true)
		container.setForRemovingRewriteStatus(id);
	}

	const onAssignPrescriptionButtonClick = () => {
		if (selectedPatientHasActivePrescriptions && !isPrescriptionFutureDated()) {
			setPatientHasActivePrescriptionsModalVisible(true)
			return
		}
		setShowConfrimAssignPrescriptionModal(true)
	}

	useEffect(() => {
		container.setForAssigning(id);
		patientContainer._getOrganisations();


		return () => container.resetAssignPrescriptionState();
		// eslint-disable-next-line
	}, [container]);

	useEffect(() => {
		if (assignPrescriptionState.patient?.value?.value) {
			container.checkAssignPatientToPrescription(assignPrescriptionState.patient?.value?.value, id);
		}
		// eslint-disable-next-line
	}, [assignPrescriptionState.patient.value]);

	const renderQuickPatientCreateModal = () => {
		return <Modal large isOpen={showQuickPatientCreateModal} >
			<FormRow>
				<div className='col-7' style={{ flexDirection: 'column' }} >
					<FormRow>
						<h1 className="h4">New patient</h1>
					</FormRow>
					<FormRow>
						<div className="col-2">
							<Dropdown
								value={state.title?.value}
								error={state.title.error}
								onChange={onFieldChange}
								name="title"
								label="Title"
								optional
								required
								options={contentHelper.getTitlesAsDropdownItems()}
							/>
						</div>
						<div className="col-5">
							<TextInput
								value={state.firstName?.value}
								error={state.firstName.error}
								onChange={onFieldChange}
								name="firstName"
								label="First name"
								placeholder="e.g. John"
								required={true}
							/>
						</div>
						<div className="col-5">
							<TextInput
								value={state.lastName?.value}
								error={state.lastName.error}
								onChange={onFieldChange}
								name="lastName"
								label="Last name"
								placeholder="e.g. Doe"
								required={true}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<TextInput
								value={state.dateOfBirth?.value}
								error={state.dateOfBirth.error}
								onChange={onFieldChange}
								name="dateOfBirth"
								label="Date of birth"
								placeholder="e.g. 25/08/1973"
								required={true}
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.email?.value}
								error={state.email.error}
								onChange={onFieldChange}
								name="email"
								label="Email address"
								placeholder="e.g. johndoe@email.com"
								required={true}
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.telephoneNo?.value}
								error={state.telephoneNo.error}
								onChange={onFieldChange}
								name="telephoneNo"
								label="Telephone number"
								placeholder="e.g. +00256478921"
								required={true}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<TextInput
								value={state.address1?.value}
								error={state.address1.error}
								onChange={onFieldChange}
								name="address1"
								label="Address 1"
								placeholder="e.g. Avenue Road 134A, Illinois"
								required
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.address2?.value}
								error={state.address2.error}
								onChange={onFieldChange}
								name="address2"
								label="Address 2"
								placeholder="e.g. Avenue Road 134B, Illinois"
								required
							/>
						</div>
						<div className="col-4">
							<TextInput
								value={state.zipCode?.value}
								error={state.zipCode.error}
								onChange={onFieldChange}
								name="zipCode"
								label="Postal code"
								placeholder="e.g. 35492 DB"
								required
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<CustomSelect
								key={patientContainer.state.filteredOrganisations.length}
								label="Organisation name"
								name="organisationId"
								disabled={wasPrescriptionSubmittedByOrganisation}
								values={state.organisationId ? [state.organisationId.value] : []}
								options={patientContainer.state.filteredOrganisations}
								onChange={(e) => { onSelectChangePatientContainer(e) }}
								placeholder={"Select organisation"}
								higherOpacityDisabled
							/>
						</div>
						<div className="col-6">
							<TextInput
								value={state.clinicPatientId?.value}
								error={state.clinicPatientId.error}
								onChange={onFieldChange}
								name="clinicPatientId"
								label="Organisation patient id"
								placeholder="e.g. 123456"
								required
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">

							<Button onClick={() => { handleQuickCreatePatient() }} disabled={!patientContainer.isFormValid(formKey) || patientContainer.state.creatingPatient} >
								{patientContainer.state.creatingPatient ? <SpinnerIcon /> : "Create patient"}
							</Button>
						</div>
						<div className="col-1">

						</div>
						<div className="col-4">

							<Button onClick={() => setShowQuickPatientCreateModal(false)}  >
								Cancel
							</Button>
						</div>
						<div className="col-8 " style={{ color: 'red' }}>

						</div>
					</FormRow>
				</div>
				<div className='col-5' style={{ flexDirection: 'column' }} >
					<FormRow >
						<div className='col-8'>

						</div>
						<div className='col-4'>
							<Link to='/patients/new' >
								Open full patient creator
							</Link>
						</div>
					</FormRow>
					{
						prescription.value && <PrescriptionPreview showChangeButton={false} onChange={container.uploadPrescriptionPhoto} defaultImage={prescription.value.imageUrl} />
					}
				</div>
			</FormRow>


		</Modal>
	}

	const renderConfirmAssignPrescriptionModal = () => {
		return <Modal large isOpen={showConfrimAssignPrescriptionModal} onClose={() => setShowConfrimAssignPrescriptionModal(false)} >
			<FormRow>
				<div className='col-6' style={{ flexDirection: 'column' }} >
					<FormRow>
						<h1 className="h4">Please confirm before assigning</h1>
					</FormRow>
					<FormRow>
						<div className='col-8' >Before assigning the prescription to the patient, please double-check and confirm that the details you have added match the prescription</div>
					</FormRow>
					<FormRow>
						<div id="selectPatient" className="col-7">
							{!container.state.loadingPatients && (
								<CustomSelect
									label="Patient"
									name="patient"
									values={assignPrescriptionState.patient.value ? [assignPrescriptionState.patient.value] : []}
									options={[]}
									disabled
									higherOpacityDisabled={true}
									showDropdownOnlyOnSearch={true}
									placeholder={"Search patient"}
								/>
							)}
						</div>
					</FormRow>

					<FormRow>
						<div className='col-7'>
							<TextInput
								className={'input-container--no_opacity_label'}
								value={assignPrescriptionState.paperPrescriptionId?.value}
								name='paperPrescriptionId'
								label="Prescription Number"
								placeholder="e.g. 780146609790"
								disabled
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className='col-7'>
							<TextInput
								className={'input-container--no_opacity_label'}
								value={assignPrescriptionState.address1?.value}
								label="Address 1"
								placeholder="Address 1"
								disabled
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className='col-7'>
							<TextInput
								className={'input-container--no_opacity_label'}
								value={assignPrescriptionState.address2?.value}
								label="Address 2"
								placeholder="Address 2"
								disabled
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className='col-7'>
							<TextInput
								className={'input-container--no_opacity_label'}
								value={assignPrescriptionState.zipCode?.value}
								label="Post code"
								placeholder="Post code"
								disabled
							/>
						</div>
					</FormRow>

				</div>
				<div className='col-6' style={{ flexDirection: 'column' }} >
					{
						prescription.value && <PrescriptionPreview showChangeButton={false} onChange={container.uploadPrescriptionPhoto} defaultImage={prescription.value.imageUrl} />
					}
				</div>
			</FormRow>
			<FormRow>
				<div className="col-3">
					<Button
						className="m-r-xs"
						text="Confirm and assign"
						onClick={() => {
							setShowConfrimAssignPrescriptionModal();
							container.assignImage();
						}}
						disabled={
							isButtonEnabled
						}
					/>
				</div>
				<div className="col-3">
					<Button
						className="btn--bordered--no_radius"
						onClick={() => setShowConfrimAssignPrescriptionModal(false)}
					>
						Cancel
					</Button>
				</div>
				<div className="col-3" />
				<div className="col-3" />

			</FormRow>

		</Modal>
	}

	const highlevelReasonToreasonOptionsMapper = {
		[HighLevelRejectReasons.PrescriberProductError]: contentHelper.getPrescriberProductErrorRejectReasons,
		[HighLevelRejectReasons.PrescriberError]: contentHelper.getPrescriberErrorRejectReasons,
		[HighLevelRejectReasons.prescriberError]: contentHelper.getprescriberErrorArchiveReasons,
		[HighLevelRejectReasons.AdminError]: contentHelper.getAdminErrorArchiveReasons,
		undefined: () => [{ value: "", label: "select" }],
		null: () => [{ value: "", label: "select" }],
	}

	const renderDescribeReason = () => {
		return [
			HighLevelRejectReasons.Other,

		].includes(rejectPrescriptionState?.highLevelReason?.value?.value) ?
			<div className='m-t-s col-12'>
				<Textarea
					label={"Describe the reason"}
					name="reason"
					placeholder="Add a reason for archiving this prescription image...."
					value={rejectPrescriptionState.reason.value.value}
					error={rejectPrescriptionState.reason.value.value?.length > 255 ? "Max character limit reached" : ""}
					onChange={onReasonTextChange}
					maxLength={255}
				/>
			</div>
			:
			<></>
	}

	const renderReasonInput = useCallback(() => {

		return [
			HighLevelRejectReasons.PrescriberError,
			HighLevelRejectReasons.PrescriberProductError,
			HighLevelRejectReasons.prescriberError,
			HighLevelRejectReasons.AdminError,

		].includes(rejectPrescriptionState?.highLevelReason?.value?.value) ?
			<FormRow>
				<CustomSelect
					label="What was the error"
					key={`${rejectPrescriptionState.highLevelReason.value.value}+${rejectPrescriptionState.reason.value.value}`}
					name="reason"
					values={[!rejectPrescriptionState.reason.value ? { label: "Select", value: "" } : rejectPrescriptionState.reason.value]}
					options={highlevelReasonToreasonOptionsMapper[rejectPrescriptionState.highLevelReason.value.value]()}
					disabled={false}
					onChange={onSelectChangeRejectPrescription}
				/>
			</FormRow>
			:
			<></>

		// eslint-disable-next-line
	}, [rejectPrescriptionState.highLevelReason.value.value, rejectPrescriptionState.reason.value.value])

	const renderArchiveModal = () => {
		return <Modal className='modal--assign' isOpen={archiveModalVisible} onClose={() => handleCloseArchive()}>
			<FormRow>
				<h1 className="h4">Why is this prescription being archived?</h1>
			</FormRow>
			<FormRow>
				<CustomSelect
					label="Reason"
					name="highLevelReason"
					values={[rejectPrescriptionState.highLevelReason]}
					options={contentHelper.getHighLevelArchiveReasonsAsDropDownItems()}
					disabled={false}
					onChange={(e) => { onSelectChangeRejectPrescription(e); container.resetAllFieldsExcept('rejectPrescription', ["highLevelReason", "patient", "paperPrescriptionId"]) }}
				/>
			</FormRow>
			{renderReasonInput()}
			{renderDescribeReason()}
			<FormRow>
				<div className="col-6" >
					<Button
						className="m-r-xs"
						onClick={() => { handleArchivePrescription() }}
						disabled={!isArchiveButtonEnabled}
					>
						{container.state.archivingPrescription ? <SpinnerIcon /> : <><ArchiveBox color={"white"} /> &nbsp; Archive </>}
					</Button>
				</div>
				<div className="col-6" >
					<Button
						className="btn--bordered--no_radius"
						onClick={() => { handleCloseArchive() }}
						text="Cancel"
					/>
				</div>`
			</FormRow>
		</Modal>
	}

	const renderUnarchiveModal = () => {
		return <Modal className='modal--assign' isOpen={unarchiveModalVisible} onClose={() => handleCloseUnarchive()}>
			<FormRow>
				<h1 className="h4">Unarchive prescription?</h1>
			</FormRow>
			<FormRow>
				<h2 className="h6">Please confirm you would like to unarchive this prescription by providing a reason.</h2>
			</FormRow>
			<Textarea
				name="reason"
				placeholder="Add a reason..."
				value={rejectPrescriptionState.reason.value.value}
				error={rejectPrescriptionState.reason.value.value?.length > 255 ? "Max character limit reached" : ""}
				onChange={onReasonTextChange}
				maxLength={255}
			/>
			<FormRow>
				<div className="col-6" >
					<Button
						className="m-r-xs"
						onClick={() => { handleUnarchivePrescription() }}
						disabled={!isUnarchiveButtonEnabled}
					>
						{container.state.archivingPrescription ? <SpinnerIcon /> : <><ArchiveBox color={"white"} /> &nbsp; Unarchive </>}
					</Button>
				</div>
				<div className="col-6" >
					<Button
						className="btn--bordered--no_radius"
						onClick={() => { handleCloseUnarchive() }}
						text="Cancel"
					/>
				</div>`
			</FormRow>
		</Modal>
	}

	const renderPatientHasActivePrescriptionsModal = () => {
		return <Modal className='modal--assign' isOpen={patientHasActivePrescriptionsModalVisible} onClose={() => setPatientHasActivePrescriptionsModalVisible(false)}>
			<FormRow className='justify-c-c' >
				<ExclamationMarkTriangle size={60} />
			</FormRow>
			<FormRow>
				<h1 className="h4">Patient has active prescription(s)</h1>
			</FormRow>
			<FormRow>
				<div className="">The selected patient has active prescription(s):</div>
			</FormRow>
			<div style={{ maxHeight: "300px", overflowY: "scroll" }} className={'flex-col align-i-start  col-12'}>
				{assignPrescriptionState.patientsActivePrescriptions.value.map((prescription) => {
					return <Link
						to={`/prescriptions/edit/${prescription.id}`}
						className='link '
						target="_blank"
						rel="noopener noreferrer"
					>
						{prescription.paperPrescriptionId ? prescription.paperPrescriptionId : prescription.id}
					</Link>
				})}

			</div>


			<FormRow>
				<div className="">If the new prescription is not a re-write, please confirm you are happy to continue with patient assignment.</div>
			</FormRow>

			<FormRow>
				<div className={"col-6"}>
					<Button
						onClick={() => {
							setPatientHasActivePrescriptionsModalVisible(false)
							setShowConfrimAssignPrescriptionModal(true)
						}}
						text="Continue"
					/>
				</div>
				<div className={"col-6"}>
					<Button
						className="btn--bordered--no_radius"
						onClick={() => { setPatientHasActivePrescriptionsModalVisible(false) }}
						text="Cancel"
					/>
				</div>
			</FormRow>
		</Modal>
	}

	const renderUnlinkModal = () => {
		return <UnlinkPrescriptionModal
			unlinkModalVisible={unlinkModalVisible}
			setUnlinkModalVisible={setUnlinkModalVisible}
			handleUnlinkPrescription={handleUnlinkPrescription}
		/>
	}

	const renderPrescriptionCheckList = () => {
		return <div className='col-8'>
			<PrescriptionChecklist >
				<FormRow>
					<h2 className="h6">Prescription checklist</h2>
				</FormRow>
				<div className='m-b-s' >
					<SquareCheckbox
						key={assignPrescriptionState.patientsFullName.value}
						name="patientsFullName"
						value={assignPrescriptionState.patientsFullName.value}
						error={assignPrescriptionState.patientsFullName.error}
						onChange={onCheckboxChange}
						label={`Patient’s full name and DOB`}
						right={false}
					/>
				</div>
				<div className='m-b-s' >
					<SquareCheckbox
						key={assignPrescriptionState.patientsFullAddress.value}
						name="patientsFullAddress"
						value={assignPrescriptionState.patientsFullAddress.value}
						error={assignPrescriptionState.patientsFullAddress.error}
						onChange={onCheckboxChange}
						label={`Patient’s full address`}
						right={false}
					/>
				</div>
				<div className='m-b-s' >
					<SquareCheckbox
						key={assignPrescriptionState.productName.value}
						name="productName"
						value={assignPrescriptionState.productName.value}
						error={assignPrescriptionState.productName.error}
						onChange={onCheckboxChange}
						label={`Product name, form (sativa, hybrid or indica and flower, oil or cartridge) dose, strength, quantity and frequency`}
						right={false}
					/>
				</div>
				<div className='m-b-s' >
					<SquareCheckbox
						key={assignPrescriptionState.prescriberSigniture.value}
						name="prescriberSigniture"
						value={assignPrescriptionState.prescriberSigniture.value}
						error={assignPrescriptionState.prescriberSigniture.error}
						onChange={onCheckboxChange}
						label={`Prescriber’s signature, date of issue and address`}
						right={false}
					/>
				</div>
			</PrescriptionChecklist>
		</div>
	}

	const renderTempPatient = () => {
		return <div>
			<PrescriptionChecklist >
				<FormRow>
					<span className="h6">New patient</span>
				</FormRow>
				<div className="h6">
					<span >This patient does not have an account on the platform.</span>
				</div>
				<br />
				<div className="h6">
					<span className={"link"} onClick={() => { handleOpenQuickCreateModal(true) }}>Create account</span>
				</div>
			</PrescriptionChecklist>
		</div>
	}

	const renderLinkAsRewriteModal = () => {
		return (assignPrescriptionState.rewritePrescriptionIdForLinking.value && !hideShowLinkAsRewriteModal && !container.state.loadingAllpatientsAwaitingRewritePrescriptions) ?
			<CustomCard className='b-extra-light-orange' >
				<h2 className="h6">Awaiting re-write</h2>
				<div className="custom__card__text custom__card__text--bold m-t-s" >The patient selected has a pending re-write for:</div>

				{container.state.prescriptionsAwaitingRewrite.map(pId => {
					return (
						<div key={pId} className="m-t-s">
							<RadioSelector
								text={
									<a href={`/prescriptions/edit/${pId}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										Prescription ID {pId}
									</a>
								}
								// eslint-disable-next-line 
								selected={pId == assignPrescriptionState.rewritePrescriptionIdForLinking.value}
								onClick={() => setRewritePrescriptionIdForLinking(pId)}
							/>
						</div>
					)
				})}

				<div className="custom__card__text custom__card__text--bold m-t-s" >Is this new prescription the re-write?</div>

				<FormRow>
					<Button
						className="m-r-xs "
						onClick={() => { handleLinkPrescription() }}
						disabled={container.state.sendingToOrganisation}
					>
						{container.state.sendingToOrganisation ? <Spinner /> : "Yes"}
					</Button>
					<Button
						className="m-r-xs btn--bordered--no_radius btn--bg-white"
						onClick={() => { setRewritePrescriptionIdForLinking(null) }}
						disabled={container.state.sendingToOrganisation}
					>
						No
					</Button>
				</FormRow>
			</CustomCard>
			:
			<></>
	}

	const renderRemoveAwaitingRewriteStatusModal = () => {
		return <RemoveAwaitingReWriteStatusModal
			show={removeAwaitingRewriteStatusVisible}
			onClose={() => setRemoveAwaitingRewriteStatusVisible(false)}
			handleRemoveAwaitingRewriteStatus={() => handleRemoveAwaitingRewriteStatus()}
		/>
	}

	function renderPrompts() {
		return (
			<div>
				{/* Flag prescription */}
				<Prompt
					isOpen={showFlagPopup}
					title="Flag prescription?"
					description="Please leave a comment describing the reason why this prescription is being flagged"
					onYes={() => {
						container.flagFromAssign(currentAdministrator.name);

						setShowFlagPopup(false);
					}}
					onNo={() => setShowFlagPopup(false)}
					onClose={() => setShowFlagPopup(false)}
					yesDisabled={!flag?.flaggedReason?.value || flag?.flaggedReason?.value.length > 255}
				>
					<Textarea
						name="flaggedReason"
						placeholder="Add comment..."
						value={flag?.flaggedReason?.value || ""}
						error={flag?.flaggedReason?.error || ""}
						onChange={onFlagReasonChange}
						maxLength={255}
					/>
				</Prompt>
			</div>
		);
	}

	const flaggedBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} >
			<div className="m-r-s">
				<b>{prescription?.value?.adminFlaggedBy?.name}:</b> {prescription?.value?.flaggedReason}
			</div>
			<BannerButton
				onClick={() => container.unflagFromAssign()}
			>
				<XIconSlim /> &nbsp; <span>Remove</span>
			</BannerButton>
		</div>
	}

	const rewriteOfBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} >
			<div className="m-r-s">
				Re-write of: &nbsp;
				<Link
					to={`/prescriptions/edit/${prescription.value?.rewriteChildPrescriptionId}`}
					className='link bold'
					target="_blank"
					rel="noopener noreferrer"
				>
					Prescription ID {prescription.value?.rewriteChildPrescriptionId}
				</Link>
			</div>
			<BannerButton
				onClick={() => handleOpenUnlinkPrescriptionModal()}
				className={"banner_button--blue"}
			>
				<UnlinkIcon /> &nbsp; <span>Remove</span>
			</BannerButton>
		</div>
	}

	const renderAwaitingReWriteBannerContent = () => {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} >
			<div className="m-r-s" >
				Awaiting re-write from clinic due to : {prescription.value.rewriteReason}
			</div>
			<BannerButton
				onClick={() => handleShowRemoveRewriteRequiredStatusModal()}
			>
				<XIconSlim /> &nbsp; <span>Remove</span>
			</BannerButton>
		</div>
	}

	const renderFuturePrescriptionBanner = () => {
		if (!assignPrescriptionState.datePrescribed.value) {
			return null
		}

		if (!isPrescriptionFutureDated()) {
			return null
		}

		return <CustomBanner
			icon={<InfoIcon size={30} />}
			className={"banner--info banner--iconFlexStart m-b-s"}
		>
			The date prescribed is in the future. This
			prescription will move into the Future dated scripts section once assigned.
			When the date is reached, the prescription will become available  for processing.
		</CustomBanner>
	}

	return (
		<>
			{container.state.loadingPrescription ?
				<LoadingCard text={"Loading prescription information"} />
				:
				<div className="card p-m">
					{renderPrompts()}

					<FormRow>
						<div className="col-7">
							<h1 className="h4">Prescription ID: {prescription.value?.id}</h1>
						</div>
					</FormRow>

					{prescription?.value?.isFlagged &&
						<FormRow >
							<CustomBanner
								className="banner--red"
								icon={<FlagIconRed />}
								text={flaggedBannerContent()}
							/>
						</FormRow>}

					{currentAdministrator?.type !== AdminType.Eastone && (
						<>
							{prescription.value?.rewriteChildPrescriptionId &&
								<CustomBanner
									className="banner--blue"
									icon={<InfoIcon />}
									text={rewriteOfBannerContent()}
								/>

							}

							{prescription.value?.awaitingRewrite &&
								<FormRow >
									<CustomBanner
										className="banner--blue"
										icon={<InfoIcon />}
										text={renderAwaitingReWriteBannerContent()}
									/>
								</FormRow>
							}

							<FormRow>
								<div className="col-6">
									<div style={{ width: "100%" }}>
										<FormRow>
											<h2 className="h6">Prescription information</h2>
										</FormRow>

										<FormRow>
											<div className="col-7" id="selectprescriber">
												{/* This conditional rendering solves the problem of not seeing prescribers */}
												{!container.state.loadingprescribers && (
													<CustomSelect
														label="prescriber"
														name="prescriber"
														values={[{ label: `${assignPrescriptionState.prescriber.value?.fullName ? assignPrescriptionState.prescriber.value?.fullName : ''}`, value: '' }]}
														options={container.state.filteredprescribers}
														disabled={true}
													/>
												)}
											</div>

											<div className="col-5">
												{assignPrescriptionState.prescriber.value && (
													<span className="rounded-info-span">
														<Link to={`/prescribers/edit/${assignPrescriptionState.prescriber.value?.id}`}>View</Link>
													</span>
												)}
											</div>
										</FormRow>

										{assignPrescriptionState.tempPatient.value?.id && (
											<FormRow>
												<div>
													<h2 className="h6">{renderTempPatient()}</h2>
												</div>
											</FormRow>)}

										<FormRow>
											<div id="selectPatient" className="col-7">
												{!container.state.loadingPatients && (
													<div id="selectPatient" className="col-12">
														<CustomSelect
															label="Patient"
															name="patient"
															values={assignPrescriptionState.patient.value ? [assignPrescriptionState.patient.value] : []}
															options={container.state.filteredPatients}
															disabled={prescription.value ? prescription.value.rewriteChildPrescriptionId !== null : false}
															onChange={(e) => { onSelectChangePrescriptionPatientField(e) }}
															onKeyDown={(e) => { onSelectKeyDownPrescriptionPatientField(e) }}
															placeholder={"Search patient"}
														/>
													</div>
												)}
											</div>

											<div className="col-5">
												{assignPrescriptionState.patient.value && (
													<span className="rounded-info-span">
														<Link to={`/patients/edit/${assignPrescriptionState.patient.value.value}`}>View</Link>
													</span>
												)}
												<div
													onClick={() => { handleOpenQuickCreateModal() }}
													className="badge--no-border badge highlighted m-l-xs"
													title="Add a new patient">
													<i className="icon-plus"></i>
												</div>
											</div>
										</FormRow>

										{!prescription.value?.rewriteChildPrescriptionId &&
											<div style={{ width: "70%" }} className={"m-b-s"} >
												{renderLinkAsRewriteModal()}
											</div>
										}

										<FormRow>
											<div className='col-7'>
												<TextInput
													value={assignPrescriptionState.paperPrescriptionId?.value}
													error={assignPrescriptionState.paperPrescriptionId.error}
													name='paperPrescriptionId'
													label="Prescription Number"
													placeholder="e.g. 780146609790"
													onChange={onPaperPrescriptionIdChange}
												/>
											</div>
										</FormRow>

										<FormRow>
											<div className='col-7'>
												<DateInput
													value={assignPrescriptionState.datePrescribed.value}
													error={assignPrescriptionState.datePrescribed.error}
													onChange={onAssignPrescriptionFieldChange}
													name="datePrescribed"
													label="Date prescribed"
													placeholder="e.g. 10/09/2020"
												/>
											</div>
										</FormRow>
										{renderFuturePrescriptionBanner()}
										{renderPrescriptionCheckList()}
									</div>
								</div>

								<div style={{ display: "block" }} className="col-6">
									<div style={{ marginLeft: "auto", width: "150px" }} >
										{!prescription?.value?.isFlagged && (
											<Button
												className="m-r-xs btn--bordered--no_radius"
												style={{ border: "#FFFFFF" }}
												onClick={() => setShowFlagPopup(true)}
												disabled={
													prescription?.value?.isFlagged === true
												}
											>
												<FlagIcon />
												&nbsp; Add Flag
											</Button>
										)}
										{prescription?.value?.isFlagged && (
											<Button
												className="m-r-xs btn--bordered--no_radius"
												style={{ border: "#FFFFFF", whiteSpace: "nowrap" }}
												onClick={() => container.unflagFromAssign()}
												disabled={
													prescription?.value?.isFlagged === false
												}
											>
												<FlagIcon />
												&nbsp; Remove Flag
											</Button>
										)}
									</div>
									{
										prescription.value && <PrescriptionPreview showChangeButton={false} onChange={container.uploadPrescriptionPhoto} defaultImage={prescription.value.imageUrl} />
									}
								</div>
							</FormRow>
						</>

					)}
					<FormRow>
						<div className="col-6">
							<Button
								className="m-r-xs"
								text="Assign prescription"
								onClick={() => { onAssignPrescriptionButtonClick() }}
								disabled={
									isButtonEnabled
								}
							/>
							<Button
								className="m-r-xs btn--bordered--no_radius"
								onClick={() => { setRejectModalVisible(true) }}
								disabled={container.state.sendingToOrganisation}
							>
								{container.state.sendingToOrganisation ? <SpinnerIcon color={'black'} /> : "Send for Rewrite"}
							</Button>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-12">
							<Table
								title="Event history"
								tableData={{
									head: tableHeaders.eventHistory,
									body: assignPrescriptionState.eventHistory.value,
								}}
								small
								showSpinner={container.state.loadingPrescription}
							/>
						</div>
					</FormRow>
					<FormRow>
						<Textarea
							name="commentText"
							placeholder="Add a comment to event history"
							value={comment.commentText.value}
							error={comment.commentText.error}
							onChange={onCommentChange}
							maxLength={255}
						/>
					</FormRow>
					<FormRow>
						<Button
							disabled={container.state.creatingComment || !container.isFormValid("createComment")}
							onClick={createComment}
						>
							{container.state.creatingComment ? <SpinnerIcon /> : "Add Comment"}
						</Button>
					</FormRow>
					<Separator />
					<FormRow>
						<div className="col-4" >

						</div>
						{prescription.value?.isArchived === false &&
							<div className="col-4" >
								<Button
									className="m-r-xs btn--bordered--no_radius"
									onClick={() => { setArchiveModalVisible(true) }}
									disabled={container.state.archivingPrescription}
								>
									<ArchiveBox /> &nbsp; Archive
								</Button>
							</div>
						}

						{prescription.value?.isArchived &&
							<div className="col-4" >
								<Button
									className="m-r-xs btn--bordered--no_radius"
									onClick={() => { setUnarchiveModalVisible(true) }}
									disabled={container.state.unarchivingPrescription}
								>
									<UnarchiveBox /> &nbsp; Unarchive
								</Button>
							</div>
						}

						<div className="col-4" >

						</div>
					</FormRow>
					{renderArchiveModal()}
					{renderUnarchiveModal()}
					{renderQuickPatientCreateModal()}
					{renderConfirmAssignPrescriptionModal()}
					{renderUnlinkModal()}
					{renderRemoveAwaitingRewriteStatusModal()}
					{renderPatientHasActivePrescriptionsModal()}
					<SendToOrganisationModal
						rejectModalVisible={rejectModalVisible}
						setRejectModalVisible={setRejectModalVisible}
						imageUrl={prescription?.value?.imageUrl}
					/>
				</div>
			}</>
	)
}

function SubscribedAssignPrescription(props) {
	return (
		<Subscribe to={[PrescriptionContainer, AuthenticationContainer, PatientContainer]}>
			{(container, authContainer, patientContainer) => (
				<AssignPrescription {...props} container={container} patientContainer={patientContainer} currentAdministrator={authContainer.state.currentAdministrator} />
			)}
		</Subscribe>
	);
}

export default SubscribedAssignPrescription
