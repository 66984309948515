import { toast } from "react-toastify";
import React from 'react'
import TickedIconInCircle from "../../assets/icons/TickedIconInCircle";
import ExclamationMarkCircle from "../../assets/icons/ExclamationMarkCircle";

class NotificationHelper {
    info(message) {
        toast.info(<><div style={{ minWidth: '30px', marginRight: '1rem' }}><TickedIconInCircle /></div> {message}</>);
    }

    error(message) {
        toast.error(<><div style={{ minWidth: '30px', marginRight: '1rem' }}><ExclamationMarkCircle /></div> {message}</>);
    }
}

export default new NotificationHelper();