import React from "react";
import FormRow from "../../components/Form/FormRow";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/Form/Button";


function ResetEmailSent() {
    const history = useHistory();

    return (
        <div className="centered-form">
            <div className="card p-m">
                <FormRow>
                    <img src="/assets/images/logo-dark.svg" className="col-7 m-b-s" alt="Lyphe Dispensary logo" />
                </FormRow>
                <p className="h6" style={{ textAlign: "center", fontWeight: "400" }}>
                    An email has been sent.
                </p>
                <br />
                <p className="h6" style={{ textAlign: "center", fontWeight: "400" }} >
                    Please click on the link in the email to reset your password.
                </p>
                <br />
                <FormRow>
                    <Button style={{ maxWidth: "100%" }} text="Back to sign in" onClick={() => history.push("/login")} />
                </FormRow>
            </div>
            <span className="disclaimer">This page is intended for Lyphe Dispensary personnel only!</span>
        </div>
    )
}

export default ResetEmailSent