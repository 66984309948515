import React from 'react';

function renderOptions(options, name, onChange, defaultValue) {
    return options.map((option, index) => {
        return (
            <label key={index} className="radio">
                <input
                    type="radio"
                    name={name}
                    className="input--hidden"
                    value={option.value}
                    defaultChecked={option.value === defaultValue}
                    onChange={e => onChange({ name, value: e.target.value })} />
                <span className="inner-label"></span>
                <span className="option-text">{option.text}</span>
            </label>
        );
    });
}

function RadioInput({
    value = '',
    name = '',
    options = [],
    onChange = () => null
}) {
    return (
        <div className="radio-container">
            {renderOptions(options, name, onChange, value)}
        </div>
    );
}

export default RadioInput;