import React, { useEffect } from "react";
import { OrganisationsTables } from "../../enums";
import Table from "../../components/Table/Table";
import FormRow from "../../components/Form/FormRow";
import Button from "../../components/Form/Button";
import { PlusInACircle } from "../../assets/icons";
import TextInput from "../../components/Form/TextInput";
import { Subscribe } from "unstated";
import OrganisationsContainer from "../../state/OrganisationsContainer";
import { useHistory } from "react-router-dom";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";

function Organistaions({ container }) {
	const history = useHistory();

	useEffect(() => {
		container.getAllOrganisations();
		// eslint-disable-next-line
	}, [])

	function renderFilters() {
		return (
			<FormRow className="table-row" >
				<div className="col-5 table-row">
					<Button onClick={() => history.push('/organisations/new')} bordered={true} className="m-r-s height-search uppercase" >
						<PlusInACircle /> &nbsp;	<p style={{ fontSize: "13px" }}>New Organisation</p>
					</Button>
				</div>

				<div className={`col-5`}>
					<TextInput placeholder="Search..." onChange={(e) => container.filter(e.value)}
						clearable={true}
						onClear={() => container.filter("")} />
					<CustomIconWithTooltip className="generic_tool_tip--left"
						style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }}
						toolTipText={"You may search by organisation name or email."}
						icon={<InfoIconGray />} />
				</div>
			</FormRow>
		);
	}
	return (
		<Table
			title={`Organisations`}
			tableData={{
				head: container.state.tableHeaders[OrganisationsTables.AllOrganisations],
				body: container.state.tableData[OrganisationsTables.AllOrganisations],
			}}
			titleColumnSize={6}
			filters={renderFilters()}
			onColumnSort={(columnIndex) => container.sort(columnIndex, [OrganisationsTables.AllOrganisations])}
			showSpinner={container.state.loadingAllOrganisations}
			adjustable
		/>
	);
}

function SubscribedAllOrganisations(props) {
	return <Subscribe to={[OrganisationsContainer]}>{(container) => <Organistaions {...props} container={container} />}</Subscribe>;
}

export default SubscribedAllOrganisations;
