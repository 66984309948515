import React from 'react'

const ArchiveBox = ({ color = "black" }) => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 1.60218 0.158035 1.22064 0.43934 0.93934C0.720644 0.658035 1.10218 0.5 1.5 0.5H22.5C22.8978 0.5 23.2794 0.658035 23.5607 0.93934C23.842 1.22064 24 1.60218 24 2V5C24 5.39782 23.842 5.77936 23.5607 6.06066C23.2794 6.34196 22.8978 6.5 22.5 6.5V17.75C22.5 18.7446 22.1049 19.6984 21.4016 20.4016C20.6984 21.1049 19.7446 21.5 18.75 21.5H5.25C4.25544 21.5 3.30161 21.1049 2.59835 20.4016C1.89509 19.6984 1.5 18.7446 1.5 17.75V6.5C1.10218 6.5 0.720644 6.34196 0.43934 6.06066C0.158035 5.77936 0 5.39782 0 5V2ZM3 6.5V17.75C3 18.3467 3.23705 18.919 3.65901 19.341C4.08097 19.7629 4.65326 20 5.25 20H18.75C19.3467 20 19.919 19.7629 20.341 19.341C20.7629 18.919 21 18.3467 21 17.75V6.5H3ZM22.5 2H1.5V5H22.5V2ZM7.5 10.25C7.5 10.0511 7.57902 9.86032 7.71967 9.71967C7.86032 9.57902 8.05109 9.5 8.25 9.5H15.75C15.9489 9.5 16.1397 9.57902 16.2803 9.71967C16.421 9.86032 16.5 10.0511 16.5 10.25C16.5 10.4489 16.421 10.6397 16.2803 10.7803C16.1397 10.921 15.9489 11 15.75 11H8.25C8.05109 11 7.86032 10.921 7.71967 10.7803C7.57902 10.6397 7.5 10.4489 7.5 10.25Z" fill={color} />
        </svg>
    )
}

export default ArchiveBox
