import React from 'react'

const UrgentIconSmall = () => {
    return (
        <svg width="53" height="18" viewBox="0 0 53 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H51C52.1046 0 53 0.895431 53 2V16C53 17.1046 52.1046 18 51 18H2C0.895429 18 0 17.1046 0 16V2Z" fill="#FCEAE8" />
            <path d="M8.95 13.08C8.01667 13.08 7.29 12.8133 6.77 12.28C6.25 11.7467 5.99 10.98 5.99 9.98V6H6.99V9.94C6.99 11.44 7.64667 12.19 8.96 12.19C9.6 12.19 10.09 12.0067 10.43 11.64C10.77 11.2667 10.94 10.7 10.94 9.94V6H11.91V9.98C11.91 10.9867 11.65 11.7567 11.13 12.29C10.61 12.8167 9.88333 13.08 8.95 13.08ZM18.6402 13L17.1302 10.85C16.9435 10.8633 16.7968 10.87 16.6902 10.87H14.9602V13H13.9602V6H16.6902C17.5968 6 18.3102 6.21667 18.8302 6.65C19.3502 7.08333 19.6102 7.68 19.6102 8.44C19.6102 8.98 19.4768 9.44 19.2102 9.82C18.9435 10.2 18.5635 10.4767 18.0702 10.65L19.7302 13H18.6402ZM16.6602 10.02C17.2935 10.02 17.7768 9.88333 18.1102 9.61C18.4435 9.33667 18.6102 8.94667 18.6102 8.44C18.6102 7.93333 18.4435 7.54667 18.1102 7.28C17.7768 7.00667 17.2935 6.87 16.6602 6.87H14.9602V10.02H16.6602ZM26.0758 9.46H27.0358V12.19C26.6891 12.4767 26.2858 12.6967 25.8258 12.85C25.3658 13.0033 24.8858 13.08 24.3858 13.08C23.6791 13.08 23.0424 12.9267 22.4758 12.62C21.9091 12.3067 21.4624 11.88 21.1358 11.34C20.8158 10.7933 20.6558 10.18 20.6558 9.5C20.6558 8.82 20.8158 8.20667 21.1358 7.66C21.4624 7.11333 21.9091 6.68667 22.4758 6.38C23.0491 6.07333 23.6924 5.92 24.4058 5.92C24.9658 5.92 25.4724 6.01333 25.9258 6.2C26.3858 6.38 26.7758 6.64667 27.0958 7L26.4758 7.62C25.9158 7.08 25.2391 6.81 24.4458 6.81C23.9124 6.81 23.4324 6.92667 23.0058 7.16C22.5858 7.38667 22.2558 7.70667 22.0158 8.12C21.7758 8.52667 21.6558 8.98667 21.6558 9.5C21.6558 10.0067 21.7758 10.4667 22.0158 10.88C22.2558 11.2867 22.5858 11.6067 23.0058 11.84C23.4324 12.0733 23.9091 12.19 24.4358 12.19C25.0624 12.19 25.6091 12.04 26.0758 11.74V9.46ZM34.0304 12.13V13H28.9504V6H33.8904V6.87H29.9504V9.02H33.4604V9.87H29.9504V12.13H34.0304ZM41.6696 6V13H40.8496L36.6496 7.78V13H35.6496V6H36.4696L40.6696 11.22V6H41.6696ZM45.1548 6.87H42.7548V6H48.5448V6.87H46.1448V13H45.1548V6.87Z" fill="#E74C3C" />
            <path d="M2 1H51V-1H2V1ZM52 2V16H54V2H52ZM51 17H2V19H51V17ZM1 16V2H-1V16H1ZM2 17C1.44771 17 1 16.5523 1 16H-1C-1 17.6569 0.343143 19 2 19V17ZM52 16C52 16.5523 51.5523 17 51 17V19C52.6569 19 54 17.6569 54 16H52ZM51 1C51.5523 1 52 1.44771 52 2H54C54 0.343147 52.6569 -1 51 -1V1ZM2 -1C0.343146 -1 -1 0.343146 -1 2H1C1 1.44772 1.44772 1 2 1V-1Z" fill="#E74C3C" />
        </svg>
    )
}

export default UrgentIconSmall
