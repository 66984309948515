import React from "react";

function EditFlagButton({ children, className = "", icon = "", onClick = () => null }) {
	return (
		<button
			type={"button"}
			className={`edit-flag-button ${className}`}
			onClick={() => onClick()}
		>
			{icon && icon}
			{children}
		</button>
	);
}

export default EditFlagButton;