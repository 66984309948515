import React from "react";

function EnvelopeIcon(props) {
	return (
		<svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.15625 0V24H34.8438V0H0.15625ZM16.1125 14.35C16.5136 14.608 17.0001 14.7474 17.5 14.7474C17.9999 14.7474 18.4864 14.608 18.8875 14.35L20.5834 13.25L32.5312 21V22H2.46875V21L14.4166 13.25L16.1125 14.35ZM17.5 12.75L2.46875 3V2H32.5312V3L17.5 12.75ZM2.46875 5.5L12.4896 12L2.46875 18.5V5.5ZM32.5312 18.5L22.5104 12L32.5312 5.5V18.5Z"
				fill="black" />
		</svg>

	);
}

export default EnvelopeIcon;