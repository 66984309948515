import { formatDate, formatDateForApi } from "../util/dates";

export function carersApiToClient(carers) {
	const result = [];

	for (let carer of carers) {
		const patient = carer.patients[0];

		result.push({
			id: carer.id,
			firstName: carer.firstName,
			lastName: carer.lastName,
			email: carer.email,
			patient: { id: patient.id, firstName: patient.firstName, lastName: patient.lastName },
			createdAt: formatDate(carer.createdAt),
			agreedToTerms: carer.agreedToTerms,
			isEmailVerified: carer.isEmailVerified,
			isIDVerified: carer.isIDVerified,
		});
	}

	return result;
}

export function carerApiToClient(carer) {
	const patient = carer.patients[0];

	const result = {
		id: carer.id,
		title: carer.title,
		firstName: carer.firstName,
		lastName: carer.lastName,
		dateOfBirth: formatDate(carer.dateOfBirth, false, undefined, "DD/MM/YYYY"),
		telephoneNo: carer.telephoneNo,
		address1: carer.address1,
		address2: carer.address2,
		zipCode: carer.zipCode,

		email: carer.user.email,
		secretId: carer.user.secretId,
		secretExpiry: carer.user.secretExpiry,
		createdAt: formatDate(carer.user.createdAt),
		updatedAt: formatDate(carer.user.updatedAt),
		lastLoggedIn: formatDate(carer.user.lastLoginDateTime),
		isEmailVerified: carer.user.isEmailVerified,
		isIDVerified: carer.user.isIDVerified,
		agreedToTerms: carer.user.agreedToTerms,

		patientRelationship: carer.patientRelationship,
		patient: {
			id: patient.id,
			firstName: patient.firstName,
			lastName: patient.lastName,
			email: patient.email,
			dateOfBirth: formatDate(patient.dateOfBirth, false),
		},
	};

	return result;
}

export function carerClientToApi(carer) {
	return {
		title: carer.title,
		firstName: carer.firstName,
		lastName: carer.lastName,
		dateOfBirth: formatDateForApi(carer.dateOfBirth, "DD/MM/YYYY"),
		telephoneNo: carer.telephoneNo,

		patientRelationship: carer.patientRelationship,

		email: carer.email,
		password: carer.password,

		patient: carer.patient,
		doctorId: carer.doctorId,

		isEmailVerified: carer.isEmailVerified,
		isIDVerified: carer.isIDVerified,
	};
}
