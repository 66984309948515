import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Subscribe } from "unstated";

import TextInput from "../../components/Form/TextInput";
import NumberInput from "../../components/Form/NumberInput";

import ProductContainer from "../../state/ProductContainer";
import AuthenticationContainer from "../../state/AuthenticationContainer";


import Button from "../../components/Form/Button";
import { DownloadIcon, PlusInACircle } from "../../assets/icons";
import FormRow from "../../components/Form/FormRow";
import Modal from "../../components/Modal/Modal";
import Banner from "../../components/Banner/Banner";
import { BannerTypes, AdminType } from "../../enums";
import { AlgorithmOption } from "../../components/AlgorithmOption/AlgorithmOption";
import SquareCheckbox from "../../components/Form/SquareCheckbox";
import { ModalInfoTable } from "../../components/StockModalTable/ModalInfoTable";
import Spinner from "../../components/Spinner/Spinner";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import CustomIconWithTooltip from "../../components/SharedComponents/CustomIconWithTooltip";
import InfoIconGray from "../../assets/icons/InfoIconGray";

const allocateModalHeaderButtons = {
	Allocate: 1,
	Edit: 2,
}

Object.freeze(allocateModalHeaderButtons);

function Products({ container, currentAdministrator }) {
	const history = useHistory();

	const ordersAwaitingStock = container.state["ordersAwaitingStock"].value;

	const [stockModalVisible, toggleStockModal] = useState(false);
	const [isStockAllocationLoading, setIsStockAllocationLoading] = useState(false);
	const [isStockEditLoading, setIsStockEditLoading] = useState(false);
	const [isReportDownloadLoading, setIsReportDownloadLoading] = useState(false);

	const [stockProductId, setStockProductId] = useState(0);
	const [stockModalData, setStockModalData] = useState({});
	const [isStockConfirmed, setIsStockConfirmed] = useState(false);

	const [newQuantity, setNewQuantity] = useState(0);
	const [editQuantity, setEditQuantity] = useState(0);

	const [selectedQuantity, setSelectedQuantity] = useState(newQuantity);

	const [selectedHeaderButton, setSelectedHeaderButton] = useState(allocateModalHeaderButtons.Allocate)

	const [filterQuery, setFilterQuery] = useState("")

	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(20)
	const [sortColumnName, setSortColumnName] = useState("")
	const [sortDirection, setSortDirection] = useState(1)

	const isAdminRoleSuperAdmin = [AdminType.Super].includes(currentAdministrator.type)

	function onConfirmStock() {
		setIsStockConfirmed(true);
	}

	function closeStockModal() {
		setNewQuantity(0);
		toggleStockModal(false);
		setIsStockConfirmed(false);
		setStockModalData({});
		setStockProductId(0);
		setSelectedQuantity(0)
		setEditQuantity(0)
		setSelectedHeaderButton(allocateModalHeaderButtons.Allocate)
		container.resetOrdersAwaitingStock();
	}

	useEffect(() => {
		selectedHeaderButton == allocateModalHeaderButtons.Allocate ? setSelectedQuantity(newQuantity) : setSelectedQuantity(editQuantity)
	}, [selectedHeaderButton, editQuantity, newQuantity]);

	const changeQuantity = (event) => {
		selectedHeaderButton == allocateModalHeaderButtons.Allocate ? setNewQuantity(event.value) : setEditQuantity(event.value);
	}

	useEffect(() => {
		container.getPaginatedProducts(currentPage, pageSize);
	}, [container]);

	const onFilterQueryChange = (e) => {
		setFilterQuery(e.value);
		setCurrentPage(1);
		container.filter(e.value, currentPage, pageSize, sortColumnName, sortDirection);
	}

	const onPageChange = (newPage) => {
		setCurrentPage(newPage);
		container.filter(filterQuery, newPage, pageSize, sortColumnName, sortDirection);
	}

	const onColumnSort = (columnName, sortDirection, columnIndex) => {
		setSortColumnName(columnName);
		setSortDirection(sortDirection);

		container.filter(filterQuery, currentPage, pageSize, columnName, sortDirection);
		container.handleColumnSort(columnIndex);
	}

	const onClearFilter = () => {
		setFilterQuery("");
		setCurrentPage(1);
		container.filter("", currentPage, pageSize, sortColumnName, sortDirection);
	}

	// Functions
	function renderFilters(container) {
		return (
			<div className="col-9 justify-c-e">
				{isAdminRoleSuperAdmin &&
					<div className="col-4">
						<Button onClick={() => history.push('/products/new')} bordered={true} className="m-r-s height-search" >
							<PlusInACircle /> &nbsp; New Product
						</Button>
					</div>
				}
				<div className="col-6">
					<TextInput placeholder="Search..." onChange={(e) => onFilterQueryChange(e)} clearable={true} onClear={() => onClearFilter()} />
					<CustomIconWithTooltip className="generic_tool_tip--left" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={"You may search by product Name or SKU."} icon={<InfoIconGray />} />
				</div>
			</div>
		);
	}

	function renderBottomContent(tableName, label) {
		return (
			<div style={{ display: "flex", flexDirection: "row-reverse" }}>
				{isAdminRoleSuperAdmin && <Button onClick={() => DownloadReport(tableName)} disabled={isReportDownloadLoading} bordered={true} className="m-r-xs btn--mini">
					{isReportDownloadLoading ? <Spinner /> :
						<div style={{ display: "flex", padding: "5px" }}>
							<CustomIconWithTooltip className="generic_tool_tip--up" style={{ marginRight: '5px', textTransform: "none", whiteSpace: "break-spaces", fontSize: "14px", textAlign: "left" }} toolTipText={`Export as csv: ${label}`} icon={<DownloadIcon color="#2d495a" />}></CustomIconWithTooltip>
						</div>}
				</Button>}
			</div>
		);
	}

	async function AllocateStock() {
		if (newQuantity <= 0) return;

		setIsStockAllocationLoading(true);

		if (isStockConfirmed && ordersAwaitingStock.length === 0) {
			await container.updateStockForProduct(stockProductId, newQuantity);

			await container.getPaginatedProducts(currentPage, pageSize);
			closeStockModal();
		}

		if (isStockConfirmed && ordersAwaitingStock.length > 0) {
			let orderIds = ordersAwaitingStock.filter(order => order.isSelected).map(order => order.orderId);
			await container.updateStockForProductManually(stockProductId, newQuantity, orderIds);

			await container.getPaginatedProducts(currentPage, pageSize);
			closeStockModal();
		}

		setIsStockAllocationLoading(false);
	}

	async function EditStock() {
		setIsStockEditLoading(true);

		await container.editStockForProduct(stockProductId, editQuantity);
		await container.getPaginatedProducts(currentPage, pageSize);

		closeStockModal();

		setIsStockEditLoading(false);
	}

	async function DownloadReport() {
		setIsReportDownloadLoading(true)

		await container.getProductReport();

		setIsReportDownloadLoading(false)
	}

	// Helpers
	function getOrdersAwaitingStockCondition() {

		let sumOfAmounts = ordersAwaitingStock.reduce((accumulator, current) => {
			if (current.isSelected)
				return accumulator + current.productAmount;
			return accumulator;
		}, 0);
		return sumOfAmounts > (parseInt(newQuantity) + parseInt(stockModalData.stockQuantity));
	}

	return (
		<div>
			<Modal isOpen={stockModalVisible && isAdminRoleSuperAdmin} onClose={() => { closeStockModal() }}>
				<FormRow>
					<h2 className="h4">{stockModalData.name} stock</h2>
				</FormRow>
				<FormRow>
					<div className={`alocate_header--button ${selectedHeaderButton === allocateModalHeaderButtons.Allocate ? " active" : ""}`} onClick={() => { setSelectedHeaderButton(allocateModalHeaderButtons.Allocate) }}>Allocate</div>
					<div className={`alocate_header--button ${selectedHeaderButton === allocateModalHeaderButtons.Edit ? " active" : ""}`} onClick={() => { setSelectedHeaderButton(allocateModalHeaderButtons.Edit) }}>Edit</div>
				</FormRow>

				<div style={{ width: "100%" }}>
					<FormRow>
						<div className={"stock-quantity-input-set"}>
							<NumberInput
								name="test"
								className={"input-container--stock"}
								value={selectedQuantity}
								onChange={changeQuantity}
								label={selectedHeaderButton === allocateModalHeaderButtons.Allocate ? "Allocate stock to current quantity" : "Edit current quantity available"}
							/>
							{selectedHeaderButton === allocateModalHeaderButtons.Allocate &&
								<>
									{newQuantity > 0 && <Button style={{ width: "140px", height: "50px" }} onClick={onConfirmStock}>Add</Button>}
								</>}
						</div>
					</FormRow>
					{selectedHeaderButton === allocateModalHeaderButtons.Edit &&
						<>
							{editQuantity >= stockModalData.stockQuantity && <Banner text={"New quantity cannot be more or equal to the current. Please use Allocate to increase quantity available"} type={BannerTypes.Error2} />}
						</>}
					<FormRow>
						<ModalInfoTable
							tableHeaders={["QUANTITY AVAILABLE", "QUANTITY RESERVED", "QUANTITY REQUIRED"]}
							tableBodyData={[stockModalData]}
							tableBodyRenderMethod={(data, index) => {
								return (
									<tr key={index} className={"table-body"}>
										<td>{data.stockQuantity}</td>
										<td>{data.stockAllocated}</td>
										<td>{data.stockRequired}</td>
									</tr>)
							}}
						/>
					</FormRow>
					{selectedHeaderButton === allocateModalHeaderButtons.Edit &&
						<>
							{editQuantity < stockModalData.stockQuantity && <span>New available quantity will be: <strong>{editQuantity}</strong></span>}
						</>}
					{isStockConfirmed && newQuantity > stockModalData.stockRequired - stockModalData.stockQuantity &&
						<FormRow>
							<span>New available quantity will be: <strong>{newQuantity - stockModalData.stockRequired + stockModalData.stockQuantity}</strong></span>
						</FormRow>
					}
					{isStockConfirmed && newQuantity < stockModalData.stockRequired && ordersAwaitingStock.length === 0 &&
						<>
							<FormRow>
								<p>Stock entered is less than required, how would you like to the allocate the new stock to
									the blocked orders?</p>
							</FormRow>

							<AlgorithmOption title={"Default"} text={"Orders will be prioritised based on date of expiry and urgency"} selected />

							<AlgorithmOption
								title={"Manual"}
								text={"Select which orders will be allocated the new stock"}
								clickable
								onClick={async () => {
									container.getOrdersAwaitingStock(stockProductId, newQuantity)
								}}
							/>
						</>
					}
					{ordersAwaitingStock.length > 0 &&
						<div className={"stock-table-order-container"} >
							<p style={{ margin: '5px' }}>Select which orders will be allocated the new stock</p>
							<br />
							<ModalInfoTable
								tableHeaders={["Expires", "Prec. No", "Order No.", "Patient", "Stock Required", ""]}
								centered
								tableBodyData={ordersAwaitingStock} tableBodyRenderMethod={(order, index) => {
									return (
										<tr className={"table-body"} key={index}>
											<td>{order.expirationDateTime}</td>
											<td>{order.paperPrescriptionId}</td>
											<td>{order.orderId}</td>
											<td>{order.patientFullName}</td>
											<td>{order.productAmount}</td>
											<td>
												<SquareCheckbox
													value={order.isSelected}
													onChange={() => container.changeSelectedOrder(index)}
													label={""}
													className={"order-selected-checkbox"}
												/>
											</td>
										</tr>
									);
								}} />
						</div>
					}
					{getOrdersAwaitingStockCondition() &&
						<FormRow>
							<Banner text={"New quantity is not enough to cover orders selected"} type={BannerTypes.Error} />
						</FormRow>
					}
					{isStockConfirmed &&
						<FormRow>
							<Banner text={"Once stock is allocated to this product, any orders that are blocked\n" +
								"and require this product will immediately move into ready for payment.\n" +
								"This cannot be undone"} type={BannerTypes.Info} />
						</FormRow>
					}

				</div>

				<FormRow>
					{selectedHeaderButton === allocateModalHeaderButtons.Allocate && <Button
						title={"Confirm"}
						disabled={getOrdersAwaitingStockCondition() || !isStockConfirmed || isStockAllocationLoading}
						onClick={AllocateStock}
					>{isStockAllocationLoading ? <Spinner className='loader--gray m-auto' /> : "Confirm"}</Button>}

					{selectedHeaderButton === allocateModalHeaderButtons.Edit && <Button
						title={"Confirm"}
						disabled={editQuantity >= stockModalData.stockQuantity}
						onClick={EditStock}
					>{isStockEditLoading ? <Spinner className='loader--gray m-auto' /> : "Confirm"}</Button>}

					<Button
						title={"Cancel"}
						bordered style={{ marginLeft: "16px" }}
						onClick={closeStockModal}
					> Cancel </Button>
				</FormRow>
			</Modal>
			<PaginatedTable
				title="Products"
				tableData={{
					head: container.state.tableHeaders,
					body: container.state.tableData,
				}}
				filters={renderFilters(container)}
				onColumnSort={onColumnSort}
				showSpinner={container.state.loadingAllProducts}
				onInlineAction={(id, data) => {
					setStockProductId(id);
					setStockModalData(data);
					toggleStockModal(true);
				}}

				paginationData={container.state.paginationData}
				onPageChange={onPageChange}
				bottomContent={renderBottomContent("products", "Products")}
			/>
		</div>
	);
}

function SubscribedProducts(props) {
	return <Subscribe to={[ProductContainer, AuthenticationContainer]}>{(container, authContainer) => <Products {...props} container={container} currentAdministrator={authContainer.state.currentAdministrator} />}</Subscribe>;
}

export default SubscribedProducts;



