import React from 'react'
import { TickedIcon } from '../../assets/icons'

const SquareCheckbox = ({
  value = false,
  name = '',
  label = "Check",
  onChange = () => null,
  right = false,
  disabled = false,
  className = "",
  href = "",
}) => {
  return (
    <div className={`square-checkbox-container ${className} ${disabled ? "square-checkbox-container--disabled" : ""}`}>
      {right &&
        <label className="square-checkbox">
          {href === "" && <span className="square-checkbox-text-right">{`${label} `}</span>}
          {href !== "" && <a className="square-checkbox-text-right" target="_blank" rel="noopener noreferrer" href={href}>{`${label} `}</a>}

          <input
            type="checkbox"
            name={name}
            disabled={disabled}
            className="input--hidden"
            checked={value}
            onChange={(e) => onChange({ name, value: e.target.checked })}
          />
          <span className={`inner-label ${value ? "inner-label--checked" : ""} ${disabled ? "inner-label--disabled" : ""}`}>{value && <TickedIcon color="white" />}</span>
        </label>
      }
      {!right &&
        <label className="square-checkbox">
          <input
            type="checkbox"
            name={name}
            disabled={disabled}
            className="input--hidden"
            checked={disabled ? false : value}
            onChange={(e) => onChange({ name, value: e.target.checked })}
          />
          <span className={`inner-label ${value ? "inner-label--checked" : ""} ${disabled ? "inner-label--disabled" : ""}`}>{value && <TickedIcon color="white" />}</span>
          {href === "" && <span className="square-checkbox-text">{label}</span>}
          {href !== "" && <a className="square-checkbox-text-right" target="_blank" rel="noopener noreferrer" href={href}>{`${label} `}</a>}
        </label>
      }
    </div>
  )
}

export default SquareCheckbox
