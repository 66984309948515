import React, { useRef, useState } from "react";

import Select from "react-dropdown-select";

function CustomSelect({
	id = "",
	name = "",
	label = null,
	values = [],
	create = false,
	multi = false,
	onCreateNew = () => null,
	options = [],
	onChange = () => null,
	onKeyDown = () => null,
	clearable = false,
	disabled = false,
	higherOpacityDisabled = false,
	showDropdownOnlyOnSearch = false,
	placeholder = "Select...",
	onClear = () => null,
	dropdownPosition = "bottom",
}) {
	const isThereAValue = values.length ? values[0] : false;
	const [isDropDownHidden, setIsDropDownHidden] = useState(!showDropdownOnlyOnSearch);
	const spanThatHoldsTheLabelRef = useRef(null)
	const [lastSearch, setLastSearch] = useState("");

	const resolveHideDropdownClass = () => {
		if (!isDropDownHidden && showDropdownOnlyOnSearch) {
			return "custom-select-container--hide_dropdown"
		}
		return ""
	}

	const resolveDropdownHandle = () => {
		if (!showDropdownOnlyOnSearch) {
			return true;
		}
		return (isDropDownHidden)
	}

	const handleHideShowLabelSpan = (property) => {
		property = !!property ? "block" : "none";
		if (!spanThatHoldsTheLabelRef.current) {
			return
		}
		spanThatHoldsTheLabelRef.current.style.display = property
	}

	const searchFn = (a) => {
		const currentSearch = a.state.search;

		// Guard condition: Only proceed if the current search term is different from the last
		if (currentSearch === lastSearch) {
			return;
		}

		setLastSearch(currentSearch);

		spanThatHoldsTheLabelRef.current = a.methods.getSelectRef().querySelector('.react-dropdown-select-content').querySelector('span');
		setIsDropDownHidden(!!currentSearch);

		if (!!currentSearch) {
			onKeyDown(currentSearch);
			handleHideShowLabelSpan(false);
		} else {
			handleHideShowLabelSpan(true)
		}

	}

	const resolveClearable = () => {
		if (!clearable) return false;

		return isThereAValue;
	}
	return (
		<div className={`custom-select-container ${resolveHideDropdownClass()}  ${(disabled && higherOpacityDisabled) ? "custom-select-container--higher_opacity--disabled" : ""} `}>
			{label && <label className="component-label">{label}</label>}

			<Select
				dropdownPosition={dropdownPosition}
				disabled={disabled}
				id={id}
				name={name}
				values={values}
				create={create}
				multi={multi}
				onCreateNew={(newValue) => onCreateNew({ name, value: newValue })}
				options={options}
				onChange={(value) => {
					setIsDropDownHidden(false)
					onChange({ name, value })
				}}
				clearable={resolveClearable()}
				searchFn={searchFn}
				onDropdownCloseRequest={(a) => {
					setIsDropDownHidden(false);
					a.close();
				}}
				dropdownHandle={resolveDropdownHandle()}
				placeholder={placeholder}
				onClearAll={() => {
					onClear();
					handleHideShowLabelSpan(true)
				}}
				onDropdownClose={() => {
					handleHideShowLabelSpan(true)
				}}
				onDropdownOpen={() => {
					handleHideShowLabelSpan(true)
				}}

			/>
		</div>
	);
}

export default CustomSelect;
